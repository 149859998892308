// src/hooks/useAlertas.jsx

import { useState, useEffect } from 'react';

const useAlertas = () => {
  const [alertas, setAlertas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAlertas = async () => {
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      const cidadeId = localStorage.getItem('cidadeId') || sessionStorage.getItem('cidadeId');

      if (!token || !cidadeId) {
        throw new Error('Token de autenticação ou ID da cidade não encontrado.');
      }

      const response = await fetch('https://api.ads10.com.br/api/alerta', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      if (!response.ok) {
        throw new Error('Erro ao buscar os alertas.');
      }

      const data = await response.json();
      setAlertas(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAlertas();
  }, []);

  return { alertas, isLoading, error, refetch: fetchAlertas };
};

export default useAlertas;
