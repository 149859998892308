// src/pages/Relatorios/RelatorioInventarioPostes.jsx

import React, { useEffect, useState, useMemo } from 'react';
import usePosteDataComAlertas from '../../hooks/usePosteDataComAlertas';
import styles from './Relatorios.module.css';
import * as XLSX from 'xlsx';
import { FaFileExport } from 'react-icons/fa';

import useGruposData from '../../hooks/useGruposData'; // Hook para obter grupos
import useGroupPoste from '../../hooks/useGroupPoste'; // Hook para associações entre grupos e postes

import {
  fetchTipoPosteMap,
  fetchFormatoPosteMap,
  fetchRegiaoMap,
  fetchFormatoTampaMap,
  fetchCidadeMap,
  fetchClassificacaoViaMap,
} from '../../utils/fetchDictionaries';

import { isValidGeoLocalizacao } from '../../utils/validation';


const RelatorioInventarioPostes = () => {
  const { postesDetalhados, isLoading, error } = usePosteDataComAlertas();
  const [filteredPostes, setFilteredPostes] = useState([]);

  // Mapeamentos
  const [tipoPosteMap, setTipoPosteMap] = useState({});
  const [formatoPosteMap, setFormatoPosteMap] = useState({});
  const [regiaoMap, setRegiaoMap] = useState({});
  const [formatoTampaMap, setFormatoTampaMap] = useState({});
  const [cidadeMap, setCidadeMap] = useState({});
  const [classificacaoViaMap, setClassificacaoViaMap] = useState({});

  // Estados para filtros
  const [filterTipoPoste, setFilterTipoPoste] = useState('');
  const [filterGeolocalizacao, setFilterGeolocalizacao] = useState('Todos');
  const [filterStatusAtivacao, setFilterStatusAtivacao] = useState('Todos');
  const [filterFormatoPoste, setFilterFormatoPoste] = useState('');
  const [filterComprimentoMin, setFilterComprimentoMin] = useState('');
  const [filterComprimentoMax, setFilterComprimentoMax] = useState('');
  const [filterPotenciaMin, setFilterPotenciaMin] = useState('');
  const [filterPotenciaMax, setFilterPotenciaMax] = useState('');
  const [filterTransformador, setFilterTransformador] = useState('');

  // Estados para grupos
  const [selectedGroups, setSelectedGroups] = useState([]); // IDs dos grupos selecionados

  // Hooks para grupos e associações
  const {
    grupos,
    isLoading: isGruposLoading,
    error: gruposError,
  } = useGruposData();

  const {
    associations,
    error: associationsError,
    fetchAssociations,
  } = useGroupPoste();

  // 1. Carregar mapeamentos e associações
  useEffect(() => {
    const fetchAllDictionaries = async () => {
      try {
        const [
          tipoMap,
          formatoPMap,
          regiaoMapData,
          formatoTMap,
          cidadeMapData,
          classViaMap,
        ] = await Promise.all([
          fetchTipoPosteMap(),
          fetchFormatoPosteMap(),
          fetchRegiaoMap(),
          fetchFormatoTampaMap(),
          fetchCidadeMap(),
          fetchClassificacaoViaMap(),
        ]);

        setTipoPosteMap(tipoMap);
        setFormatoPosteMap(formatoPMap);
        setRegiaoMap(regiaoMapData);
        setFormatoTampaMap(formatoTMap);
        setCidadeMap(cidadeMapData);
        setClassificacaoViaMap(classViaMap);
      } catch (err) {
        console.error('Erro ao buscar dicionários:', err);
      }
    };
    fetchAllDictionaries();
  }, []);

  // Buscar associações ao montar o componente
  useEffect(() => {
    fetchAssociations();
  }, [fetchAssociations]);

  // 2. Mapear associações aos postes
  const postesComGrupos = useMemo(() => {
    if (!postesDetalhados || !associations) return [];

    return postesDetalhados.map((poste) => {
      const gruposDoPoste = associations
        .filter((assoc) => assoc.posteId === String(poste.id))
        .map((assoc) => assoc.grupoId);
      return { ...poste, grupoIds: gruposDoPoste };
    });
  }, [postesDetalhados, associations]);

  // 3. Aplicar filtros automaticamente via useEffect
  useEffect(() => {
    let tempPostes = [...postesComGrupos];

    // Filtro Tipo de Poste
    if (filterTipoPoste) {
      tempPostes = tempPostes.filter((poste) => poste.tipoPoste === filterTipoPoste);
    }

    // Filtro Status de Ativação
    if (filterStatusAtivacao !== 'Todos') {
      const statusValue = filterStatusAtivacao === 'Ativos' ? 'S' : 'N';
      tempPostes = tempPostes.filter((poste) => poste.ativo === statusValue);
    }

    // Filtro Geolocalização
    if (filterGeolocalizacao === 'Geolocalizados') {
      tempPostes = tempPostes.filter((poste) => isValidGeoLocalizacao(poste.geoLocalizacao));
    } else if (filterGeolocalizacao === 'Não Geolocalizados') {
      tempPostes = tempPostes.filter((poste) => !isValidGeoLocalizacao(poste.geoLocalizacao));
    }

    // Filtro Formato de Poste
    if (filterFormatoPoste) {
      tempPostes = tempPostes.filter((poste) => poste.formatoPoste === filterFormatoPoste);
    }

    // Filtro Comprimento
    if (filterComprimentoMin !== '' && !isNaN(filterComprimentoMin)) {
      tempPostes = tempPostes.filter(
        (poste) => poste.comprimento >= parseFloat(filterComprimentoMin)
      );
    }
    if (filterComprimentoMax !== '' && !isNaN(filterComprimentoMax)) {
      tempPostes = tempPostes.filter(
        (poste) => poste.comprimento <= parseFloat(filterComprimentoMax)
      );
    }

    // Filtro Potência
    if (filterPotenciaMin !== '' && !isNaN(filterPotenciaMin)) {
      tempPostes = tempPostes.filter((poste) => poste.potencia >= parseFloat(filterPotenciaMin));
    }
    if (filterPotenciaMax !== '' && !isNaN(filterPotenciaMax)) {
      tempPostes = tempPostes.filter((poste) => poste.potencia <= parseFloat(filterPotenciaMax));
    }

    // Filtro Transformador
    if (filterTransformador) {
      const transformadorValue = filterTransformador === 'Sim' ? '1' : '0';
      tempPostes = tempPostes.filter((poste) => poste.transformador === transformadorValue);
    }

    // Filtro Grupos
    if (selectedGroups.length > 0) {
      tempPostes = tempPostes.filter((poste) =>
        poste.grupoIds.some((id) => selectedGroups.includes(id))
      );
    }

    setFilteredPostes(tempPostes);
  }, [
    postesComGrupos,
    filterTipoPoste,
    filterGeolocalizacao,
    filterStatusAtivacao,
    filterFormatoPoste,
    filterComprimentoMin,
    filterComprimentoMax,
    filterPotenciaMin,
    filterPotenciaMax,
    filterTransformador,
    selectedGroups,
  ]);

  // 4. Limpar filtros
  const clearFilters = () => {
    setFilterTipoPoste('');
    setFilterStatusAtivacao('Todos');
    setFilterGeolocalizacao('Todos');
    setFilterFormatoPoste('');
    setFilterComprimentoMin('');
    setFilterComprimentoMax('');
    setFilterPotenciaMin('');
    setFilterPotenciaMax('');
    setFilterTransformador('');
    setSelectedGroups([]);
  };

  // 5. Exportar para Excel
  const handleExportToExcel = () => {
    const exportPostes = filteredPostes.map((poste) => ({
      'ID do Poste': poste.id,
      'Código do Poste': poste.codigoPoste,
      'Endereço': poste.address + ', ' + poste.posteBairro || 'N/A',
      'Geo-localização': poste.geoLocalizacao || 'N/A',
      'Região': regiaoMap[poste.posteRegiao] || 'N/A',
      'Tipo de Poste': tipoPosteMap[poste.tipoPoste] || 'Desconhecido',
      'Formato do Poste': formatoPosteMap[poste.formatoPoste] || 'N/A',
      'Comprimento (m)': poste.comprimento || 'N/A',
      'Formato da Tampa': formatoTampaMap[poste.formatoTampa] || 'N/A',
      'Potência (kW)': poste.potencia || 'Não disponível',
      'Transformador': poste.transformador === '1' ? 'Sim' : 'Não',
      'Classificação da Via': classificacaoViaMap[poste.classificacaoVia] || 'N/A',
      'Tipo de Via': poste.tipoVia || 'N/A',
      'Cidade': cidadeMap[poste.posteCidade] || 'N/A',
      'Bairro': poste.posteBairro || 'N/A',
      'Consumo (kWh)': poste.consumo || 'Não disponível',
      'Status de Ativação': poste.ativo === 'S' ? 'Ativo' : 'Inativo',
      'Grupos': grupos
        .filter((grupo) => poste.grupoIds.includes(grupo.grupoId))
        .map((grupo) => grupo.grupoNome)
        .join(', ') || 'N/A',
    }));

    const exportFotocelulas = [];
    filteredPostes.forEach((poste) => {
      if (poste.fotocelulas && poste.fotocelulas.length > 0) {
        poste.fotocelulas.forEach((fotocelula) => {
          exportFotocelulas.push({
            'ID do Poste': poste.id,
            'Código do Poste': poste.codigoPoste,
            'ID da Fotocélula': fotocelula.fotocelulaId,
            'Descrição': fotocelula.descricao || 'N/A',
            'Device EUI': fotocelula.deviceEUI || 'N/A',
            'Latitude': fotocelula.latitude || 'N/A',
            'Longitude': fotocelula.longitude || 'N/A',
            'Tensão de Rede': fotocelula.tensaoRede || 'N/A',
            'Ligado': fotocelula.ligado === 'L' ? 'Ligado' : 'Desligado',
            'Manutenção': fotocelula.manutencao === 'S' ? 'Sim' : 'Não',
            'Data da Última Leitura': fotocelula.dataUltimaLeitura
              ? new Date(fotocelula.dataUltimaLeitura).toLocaleString()
              : 'N/A',
          });
        });
      }
    });

    const worksheetPostes = XLSX.utils.json_to_sheet(exportPostes);
    const worksheetFotocelulas = XLSX.utils.json_to_sheet(exportFotocelulas);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheetPostes, 'Postes');
    XLSX.utils.book_append_sheet(workbook, worksheetFotocelulas, 'Fotocelulas Associadas');

    XLSX.writeFile(workbook, 'inventario_postes_completo.xlsx');
  };

  /**
   * Função para lidar com a seleção de grupos no dropdown.
   * @param {Event} e - Evento de mudança.
   */
  const handleGroupChange = (e) => {
    const options = e.target.options;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    setSelectedGroups(selected);
  };

  return (
    <div className={styles.container}>
      <h2>Relatório de Inventário de Postes Completo</h2>

      <div className={styles.filterGroup}>
        <h3>Filtros</h3>

        {/* Dropdown de Seleção de Grupos */}
        <div className={styles.controlGroup}>
          <label htmlFor="groupFilter">Filtrar por Grupo:</label>
          {!isGruposLoading && !gruposError && grupos && (
            <>
              <select
                id="groupFilter"
                multiple
                value={selectedGroups}
                onChange={handleGroupChange}
                className={styles.dropdown}
              >
                {grupos.map((grupo) => (
                  <option key={grupo.grupoId} value={grupo.grupoId}>
                    {grupo.grupoNome}
                  </option>
                ))}
              </select>
              <small className={styles.selectInfo}>
                Pressione Ctrl (Windows) ou Command (Mac) para selecionar múltiplos grupos.
              </small>
            </>
          )}
          {isGruposLoading && <p>Carregando grupos...</p>}
          {gruposError && <p className={styles.errorMessage}>{gruposError}</p>}
        </div>

        {/* Tipo de Poste */}
        <div className={styles.controlGroup}>
          <label htmlFor="tipoPoste">Tipo de Poste:</label>
          <select
            id="tipoPoste"
            className={styles.dropdown}
            value={filterTipoPoste}
            onChange={(e) => setFilterTipoPoste(e.target.value)}
          >
            <option value="">Todos</option>
            {Object.entries(tipoPosteMap).map(([id, nome]) => (
              <option key={id} value={id}>
                {nome}
              </option>
            ))}
          </select>
        </div>

        {/* Status de Ativação */}
        <div className={styles.controlGroup}>
          <label htmlFor="statusAtivacao">Status de Ativação:</label>
          <select
            id="statusAtivacao"
            className={styles.dropdown}
            value={filterStatusAtivacao}
            onChange={(e) => setFilterStatusAtivacao(e.target.value)}
          >
            <option value="Todos">Todos</option>
            <option value="Ativos">Ativos</option>
            <option value="Inativos">Inativos</option>
          </select>
        </div>

        {/* Geolocalização */}
        <div className={styles.controlGroup}>
          <label htmlFor="geolocalizacao">Geolocalização:</label>
          <select
            id="geolocalizacao"
            className={styles.dropdown}
            value={filterGeolocalizacao}
            onChange={(e) => setFilterGeolocalizacao(e.target.value)}
          >
            <option value="Todos">Todos</option>
            <option value="Geolocalizados">Geolocalizados</option>
            <option value="Não Geolocalizados">Não Geolocalizados</option>
          </select>
        </div>

        {/* Formato de Poste */}
        <div className={styles.controlGroup}>
          <label htmlFor="formatoPoste">Formato de Poste:</label>
          <select
            id="formatoPoste"
            className={styles.dropdown}
            value={filterFormatoPoste}
            onChange={(e) => setFilterFormatoPoste(e.target.value)}
          >
            <option value="">Todos</option>
            {Object.entries(formatoPosteMap).map(([id, nome]) => (
              <option key={id} value={id}>
                {nome}
              </option>
            ))}
          </select>
        </div>

        {/* Transformador */}
        <div className={styles.controlGroup}>
          <label htmlFor="transformador">Transformador:</label>
          <select
            id="transformador"
            className={styles.dropdown}
            value={filterTransformador}
            onChange={(e) => setFilterTransformador(e.target.value)}
          >
            <option value="">Todos</option>
            <option value="Sim">Sim</option>
            <option value="Não">Não</option>
          </select>
        </div>

        {/* Comprimento (com espaço extra) */}
        <div className={`${styles.controlGroup} ${styles.extraSpace}`}>
          <label>Comprimento (m):</label>
          <div className={styles.rangeContainer}>
            <input
              type="number"
              placeholder="Mínimo"
              value={filterComprimentoMin}
              onChange={(e) => setFilterComprimentoMin(e.target.value)}
              className={styles.numberInput}
            />
            <span> - </span>
            <input
              type="number"
              placeholder="Máximo"
              value={filterComprimentoMax}
              onChange={(e) => setFilterComprimentoMax(e.target.value)}
              className={styles.numberInput}
            />
          </div>
        </div>

        {/* Potência */}
        <div className={styles.controlGroup}>
          <label>Potência (kW):</label>
          <div className={styles.rangeContainer}>
            <input
              type="number"
              placeholder="Mínimo"
              value={filterPotenciaMin}
              onChange={(e) => setFilterPotenciaMin(e.target.value)}
              className={styles.numberInput}
            />
            <span> - </span>
            <input
              type="number"
              placeholder="Máximo"
              value={filterPotenciaMax}
              onChange={(e) => setFilterPotenciaMax(e.target.value)}
              className={styles.numberInput}
            />
          </div>
        </div>
      </div>

      {/* Botões de Ação */}
      <div className={styles.actionsContainer}>
        <button onClick={clearFilters} className={styles.clearFiltersButton}>
          Limpar Filtros
        </button>
        <button onClick={handleExportToExcel} className={styles.exportButton}>
          <FaFileExport /> Exportar para Excel
        </button>
      </div>

      {/* Mensagens de Status */}
      {isLoading && <p className={styles.infoMessage}>Carregando...</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}
      {associationsError && <p className={styles.errorMessage}>{associationsError}</p>}
      {gruposError && <p className={styles.errorMessage}>{gruposError}</p>}

      {/* Tabela de Dados */}
      {!isLoading && filteredPostes.length > 0 && (
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Código</th>
                <th>Endereço</th>
                <th>Geo-localização</th>
                <th>Região</th>
                <th>Tipo de Poste</th>
                <th>Formato do Poste</th>
                <th>Comprimento (m)</th>
                <th>Formato da Tampa</th>
                <th>Potência (kW)</th>
                <th>Transformador</th>
                <th>Classificação da Via</th>
                <th>Tipo de Via</th>
                <th>Cidade</th>
                <th>Bairro</th>
                <th>Consumo (kWh)</th>
                <th>Status</th>
                <th>Grupos</th>
                <th>Fotocelulas Associadas</th>
              </tr>
            </thead>
            <tbody>
              {filteredPostes.map((poste) => (
                <tr key={poste.id}>
                  <td data-label="ID">{poste.id}</td>
                  <td data-label="Código">{poste.codigoPoste}</td>
                  <td data-label="Endereço">{poste.address + ', ' + poste.posteBairro || 'N/A'}</td>
                  <td data-label="Geo-localização">{poste.geoLocalizacao || 'N/A'}</td>
                  <td data-label="Região">{regiaoMap[poste.posteRegiao] || 'N/A'}</td>
                  <td data-label="Tipo de Poste">
                    {tipoPosteMap[poste.tipoPoste] || 'Desconhecido'}
                  </td>
                  <td data-label="Formato do Poste">
                    {formatoPosteMap[poste.formatoPoste] || 'N/A'}
                  </td>
                  <td data-label="Comprimento (m)">{poste.comprimento || 'N/A'}</td>
                  <td data-label="Formato da Tampa">
                    {formatoTampaMap[poste.formatoTampa] || 'N/A'}
                  </td>
                  <td data-label="Potência (kW)">{poste.potencia || 'Não disponível'}</td>
                  <td data-label="Transformador">
                    {poste.transformador === '1' ? 'Sim' : 'Não'}
                  </td>
                  <td data-label="Classificação da Via">
                    {classificacaoViaMap[poste.classificacaoVia] || 'N/A'}
                  </td>
                  <td data-label="Tipo de Via">{poste.tipoVia || 'N/A'}</td>
                  <td data-label="Cidade">{cidadeMap[poste.posteCidade] || 'N/A'}</td>
                  <td data-label="Bairro">{poste.posteBairro || 'N/A'}</td>
                  <td data-label="Consumo (kWh)">{poste.consumo || 'Não disponível'}</td>
                  <td data-label="Status">{poste.ativo === 'S' ? 'Ativo' : 'Inativo'}</td>
                  <td data-label="Grupos">
                    {grupos
                      .filter((grupo) => poste.grupoIds.includes(grupo.grupoId))
                      .map((grupo) => grupo.grupoNome)
                      .join(', ') || 'N/A'}
                  </td>
                  <td data-label="Fotocelulas Associadas">
                    {poste.fotocelulas && poste.fotocelulas.length > 0 ? (
                      <ul className={styles.fotocelulasList}>
                        {poste.fotocelulas.map((fotocelula) => (
                          <li key={fotocelula.fotocelulaId}>
                            <strong>ID:</strong> {fotocelula.fotocelulaId} <br />
                            <strong>Descrição:</strong> {fotocelula.descricao || 'N/A'} <br />
                            <strong>Device EUI:</strong> {fotocelula.deviceEUI || 'N/A'} <br />
                            <strong>Tensão de Rede:</strong> {fotocelula.tensaoRede || 'N/A'} <br />
                            <strong>Ligado:</strong> {fotocelula.ligado === 'L' ? 'Ligado' : 'Desligado'} <br />
                            <strong>Manutenção:</strong> {fotocelula.manutencao === 'S' ? 'Sim' : 'Não'} <br />
                            <strong>Última Leitura:</strong>{' '}
                            {fotocelula.dataUltimaLeitura
                              ? new Date(fotocelula.dataUltimaLeitura).toLocaleString()
                              : 'N/A'}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      'Nenhuma fotocélula associada.'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {!isLoading && filteredPostes.length === 0 && (
        <p className={styles.infoMessage}>Nenhum dado disponível para exibição.</p>
      )}
    </div>
  );
};

export default RelatorioInventarioPostes;
