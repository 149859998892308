// src/pages/PerfisAcesso/PerfisAcesso.jsx

import React, { useState, useMemo } from 'react';
import { FaPlus, FaEdit, FaSortUp, FaSortDown } from 'react-icons/fa';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import styles from './PerfisAcesso.module.css';
import PageLayout from '../../components/PageLayout/PageLayout';
import PerfilModal from './PerfilModal'; // Import correto

// Mapeamento de Tipo de Acesso
const tipoAcessoMap = {
  0: 'Administrador',
  1: 'Tipo 1',
  2: 'Tipo 2',
  // Adicione mais tipos conforme necessário
};

// Lista de permissões foi removida de aqui, pois está dentro do PerfilModal

const PerfisAcesso = () => {
  // Estado para armazenar os perfis
  const [perfis, setPerfis] = useState([
    { id: 1, nome: 'Administrador', descricao: 'Administrador', tipoAcesso: 0 },
    { id: 2, nome: 'Operador', descricao: 'Operador', tipoAcesso: 2 },
    { id: 3, nome: 'Auditor', descricao: 'Auditor', tipoAcesso: 2 },
    { id: 4, nome: 'Testando', descricao: 'Testando', tipoAcesso: 0 },
  ]);

  // Estado para controle de ordenação
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  // Estados para controlar o modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPerfil, setSelectedPerfil] = useState(null);

  // Estado para o termo de busca
  const [searchTerm, setSearchTerm] = useState('');

  // Funções para abrir e fechar o modal
  const openModal = () => {
    setSelectedPerfil(null);
    setIsModalOpen(true);
  };

  const openEditModal = (perfil) => {
    setSelectedPerfil(perfil);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPerfil(null);
  };

  // Função para lidar com a submissão do formulário no modal
  const handleSave = (formData) => {
    if (selectedPerfil) {
      // Edição de perfil existente
      const perfilAtualizado = {
        ...selectedPerfil,
        nome: formData.nome,
        descricao: formData.descricao,
        tipoAcesso: parseInt(formData.tipoAcesso),
        administrador: formData.administrador,
        permissoes: formData.permissoes,
      };

      setPerfis(
        perfis.map((perfil) =>
          perfil.id === selectedPerfil.id ? perfilAtualizado : perfil
        )
      );

      toast.success('Perfil atualizado com sucesso!');
    } else {
      // Criação de novo perfil
      const novoPerfil = {
        id: perfis.length + 1, // Em um cenário real, o ID seria gerado pelo backend
        nome: formData.nome,
        descricao: formData.descricao,
        tipoAcesso: parseInt(formData.tipoAcesso),
        administrador: formData.administrador,
        permissoes: formData.permissoes,
      };

      setPerfis([...perfis, novoPerfil]);

      toast.success('Perfil criado com sucesso!');
    }

    closeModal();
  };

  // Função para ordenar a tabela
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Função para ordenar os perfis
  const sortedPerfis = useMemo(() => {
    let sortablePerfis = [...perfis];
    if (sortConfig.key !== null) {
      sortablePerfis.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortablePerfis;
  }, [perfis, sortConfig]);

  // Filtragem baseada no termo de busca
  const filteredPerfis = useMemo(() => {
    return sortedPerfis.filter((perfil) =>
      perfil.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
      perfil.descricao.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [sortedPerfis, searchTerm]);

  // Definição dos cabeçalhos para exportação em CSV
  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Nome', key: 'nome' },
    { label: 'Descrição', key: 'descricao' },
    { label: 'Tipo de Acesso', key: 'tipoAcesso' },
    { label: 'Administrador', key: 'administrador' },
  ];

  const csvData = filteredPerfis.map(perfil => ({
    id: perfil.id,
    nome: perfil.nome,
    descricao: perfil.descricao,
    tipoAcesso: tipoAcessoMap[perfil.tipoAcesso],
    administrador: perfil.administrador ? 'Sim' : 'Não',
  }));

  return (
    <PageLayout title="Perfis de Acesso">
      <div className={styles.container}>
        <div className={styles.controlsTop}>
          <button className={styles.addButton} onClick={openModal}>
            <FaPlus /> Criar Perfil
          </button>
          <CSVLink
            data={csvData}
            headers={headers}
            filename={"perfis-acesso-export.csv"}
            className={styles.exportButton}
            bom={true}
            separator={";"}
            enclosingCharacter={`"`}
          >
            Exportar para Excel
          </CSVLink>
        </div>

        <div className={styles.controls}>
          <div className={styles.searchContainer}>
            {/* Campo de Busca */}
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Pesquisar por Nome ou Descrição..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Ações</th>
                <th onClick={() => requestSort('id')}>
                  ID {sortConfig.key === 'id' && (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('nome')}>
                  Nome {sortConfig.key === 'nome' && (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('descricao')}>
                  Descrição {sortConfig.key === 'descricao' && (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('tipoAcesso')}>
                  Tipo de Acesso {sortConfig.key === 'tipoAcesso' && (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />)}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredPerfis.length > 0 ? (
                filteredPerfis.map(perfil => (
                  <tr key={perfil.id}>
                    <td>
                      <button
                        className={styles.editButton}
                        title="Editar Perfil"
                        onClick={() => openEditModal(perfil)}
                      >
                        <FaEdit />
                      </button>
                    </td>
                    <td>{perfil.id}</td>
                    <td>{perfil.nome}</td>
                    <td>{perfil.descricao}</td>
                    <td>{tipoAcessoMap[perfil.tipoAcesso]}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className={styles.noData}>
                    Nenhum perfil encontrado.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Componente Modal para Criar/Editar Perfil */}
        <PerfilModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          onSave={handleSave}
          perfilData={selectedPerfil}
        />
      </div>
    </PageLayout>
  );
};

export default PerfisAcesso;
