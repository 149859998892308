// src/pages/Postes/SubComponents/Viario.jsx

import React from 'react';
import { FaRoad, FaClipboardList } from 'react-icons/fa';
import styles from '../PosteModal.module.css';

const Viario = ({
  formData,
  handleChange,
  classificacoesVia,
  isClassificacaoViaLoading,
  classificacaoViaError,
  tiposVia,
  isTipoViaLoading,
  tipoViaError,
}) => {
  return (
    <fieldset className={styles.fieldset}>
      <legend>Viário</legend>
      <div className={styles.formGroup}>
        {/* Tipo Via */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaRoad /> Tipo Via
          </label>
          {isTipoViaLoading ? (
            <p>Carregando tipos de via...</p>
          ) : tipoViaError ? (
            <p className={styles.errorText}>{tipoViaError}</p>
          ) : (
            <select
              className={styles.input}
              name="tipoVia"
              value={formData.tipoVia}
              onChange={handleChange}
            >
              <option value="">Selecione</option>
              {tiposVia.map((tipo) => (
                <option key={tipo.tv_id} value={tipo.tv_id}>
                  {tipo.tv_nome}
                </option>
              ))}
            </select>
          )}
        </div>

        {/* Classificação Via */}
        <div className={styles.colSpan2}>
          <label className={styles.label}>
            <FaClipboardList /> Classificação Via
          </label>
          {isClassificacaoViaLoading ? (
            <p>Carregando classificações de via...</p>
          ) : classificacaoViaError ? (
            <p className={styles.errorText}>{classificacaoViaError}</p>
          ) : (
            <select
              className={styles.input}
              name="classificacaoVia"
              value={formData.classificacaoVia}
              onChange={handleChange}
            >
              <option value="">Selecione</option>
              {classificacoesVia.map((classificacao) => (
                <option key={classificacao.cv_id} value={classificacao.cv_id}>
                  {classificacao.cv_nome}
                </option>
              ))}
            </select>
          )}
        </div>       
      </div>
    </fieldset>
  );
};

export default Viario;
