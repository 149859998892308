// src/pages/MapaAvancado/MapaAvancado.jsx

import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import { GoogleMap } from '@react-google-maps/api';
import styles from './MapaAvancado.module.css';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import { AuthContext } from '../../context/AuthContext';
import { getMapCenterByCityId } from '../../config';
import useDeviceData from '../../hooks/useDeviceData';
import usePosteDataComAlertas from '../../hooks/usePosteAdvancedDataComAlertas';
import Legend from './Legend';
import {
  DeviceMarker,
  PosteMarker,
  getDeviceMarkerType,
  getPosteMarkerType,
} from '../../utils/markers';
import { isValidGeoLocalizacao } from '../../utils/validation';
import DeviceListModal from '../../components/DeviceListModal/DeviceListModal';
import SearchBar from '../../components/SearchBar/SearchBar';
import Toolbar from '../../components/Toolbar/Toolbar';
import DeviceDetailsModal from './DeviceDetailsModal';
import MarkerModal from '../../components/MarkerModal/MarkerModal';
import {
  MARKER_CATEGORIES,
  FOTOCELULA_CATEGORIES,
} from '../../utils/constants';
import { lightMapStyles } from '../../config/mapStyles';
import { toast } from 'react-toastify';

// ** Importações para Filtro de Grupos **
import FiltroGrupos from './FiltroGrupos';
import useGruposData from '../../hooks/useGruposData';
import useGroupPoste from '../../hooks/useGroupPoste';

const containerStyle = {
  width: '100%',
  height: '100vh',
};

const MapaAvancado = () => {
  const { cidadeId } = useContext(AuthContext);

  // -------------------------------------------------------
  // Declarações de estado
  // -------------------------------------------------------

  // Tipo de mapa exibido: 'device', 'post', ou 'fotocelula'
  const [displayMode, setDisplayMode] = useState('device');
  
  // Zoom e bounds do mapa
  const [zoom, setZoom] = useState(17);
  const [mapBounds, setMapBounds] = useState(null);

  // Dispositivo selecionado e modal de detalhes
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  // Carregamento e progresso
  const [loadingProgress, setLoadingProgress] = useState(0);

  // Filtros de legenda e sua visibilidade
  const [activeFilters, setActiveFilters] = useState(
    new Set(Object.keys(MARKER_CATEGORIES))
  );
  const [isLegendVisible, setIsLegendVisible] = useState(true);

  // Categoria para modal de lista (DeviceListModal)
  const [modalCategory, setModalCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Grupos selecionados (apenas para modo 'post')
  const [selectedGroups, setSelectedGroups] = useState([]);

  // Opção de "Ocultar Outros"
  const [hideUnmatched, setHideUnmatched] = useState(false);

  // Campo de busca
  const [searchInput, setSearchInput] = useState('');

  // Referência do mapa e interval para progresso de loading
  const mapRef = useRef(null);
  const intervalRef = useRef(null);

  // Centro inicial do mapa
  const initialCenter = getMapCenterByCityId(parseInt(cidadeId, 10));

  // Tipo de mapa (roadmap ou satellite)
  const [mapType, setMapType] = useState('roadmap');
  const [currentMapStyle] = useState(lightMapStyles);

  // Marcador animado
  const [animatedMarker, setAnimatedMarker] = useState({ type: null, id: null });

  // Estado e modal do poste selecionado
  const [selectedPoste, setSelectedPoste] = useState(null);
  const [isPosteModalOpen, setIsPosteModalOpen] = useState(false);

  // Inclinação do mapa (tilt)
  const [tiltEnabled, setTiltEnabled] = useState(false);

  // Hooks para dados de grupos e associações
  const {
    grupos,
    isLoading: isGruposLoading,
    error: gruposError,
    refetch: refetchGrupos,
  } = useGruposData();

  const {
    associations,
    isLoading: isAssociationsLoading,
    fetchAssociations,
    error: associationsError,
  } = useGroupPoste();

  // Hooks de dados para dispositivos e postes
  const {
    devices,
    isLoading: isLoadingDevices,
    error: devicesError,
    refetch: refetchDevices,
  } = useDeviceData();

  const {
    postesDetalhados,
    isLoading: isLoadingPostesDetalhados,
    error: postesError,
  } = usePosteDataComAlertas(true, devices);

  // -------------------------------------------------------
  // Efeitos e Funções Principais
  // -------------------------------------------------------

  // Buscar associações ao montar
  useEffect(() => {
    fetchAssociations();
  }, [fetchAssociations]);

  // Lógica de exibir progresso de loading
  useEffect(() => {
    if (isLoadingDevices || devices.length === 0) {
      return;
    }

    if (intervalRef.current) {
      return;
    }

    setLoadingProgress(0);

    intervalRef.current = setInterval(() => {
      setLoadingProgress((prevProgress) => {
        const increment = 0.055;
        const newProgress = prevProgress + increment;
        if (newProgress >= 100) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
          refetchDevices();
          return 0;
        }
        return newProgress;
      });
    }, 100);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [isLoadingDevices, devices.length, refetchDevices]);

  // Monta array de postes com grupos associados
  const postesComGrupos = useMemo(() => {
    return postesDetalhados.map((poste) => {
      const gruposDoPoste = associations
        .filter((assoc) => assoc.posteId === String(poste.id))
        .map((assoc) => assoc.grupoId);
      return { ...poste, grupoIds: gruposDoPoste };
    });
  }, [postesDetalhados, associations]);

  // Mapeia dados entre dispositivos e postes conforme o displayMode
  const mapDevicesToPostes = () => {
    if (displayMode === 'device') {
      return devices;
    }
    // Modo 'post' ou 'fotocelula'
    return postesComGrupos;
  };

  const mappedDevicesOrPostes = useMemo(
    mapDevicesToPostes,
    [devices, postesComGrupos, displayMode]
  );

  // Função para determinar a categoria do marcador (para legendas/filtros)
  const getMarkerTypeForDisplay = useCallback(
    (item) => {
      if (displayMode === 'device') {
        return getDeviceMarkerType(item);
      } else if (displayMode === 'post') {
        return getPosteMarkerType(item);
      } else if (displayMode === 'fotocelula') {
        return item.fotocelulaTipo || 'indefinido';
      }
      return 'indefinida';
    },
    [displayMode]
  );

  // Verifica se há geolocalização válida
  const hasValidGeolocation = useCallback(
    (item) => {
      if (displayMode === 'device') {
        return isValidGeoLocalizacao(`${item.deviceLat},${item.deviceLng}`);
      }
      return isValidGeoLocalizacao(`${item.lat},${item.lng}`);
    },
    [displayMode]
  );

  // Função principal de filtragem
  const visibleDevicesOrPostes = useMemo(() => {
    let filtered = mappedDevicesOrPostes;

    // Filtrar com base no bounds do mapa
    if (mapBounds) {
      filtered = filtered.filter((item) => {
        let lat, lng;
        if (displayMode === 'device') {
          lat = item.deviceLat;
          lng = item.deviceLng;
        } else {
          lat = item.lat;
          lng = item.lng;
        }

        if (!isValidGeoLocalizacao(`${lat},${lng}`)) {
          return false;
        }

        const latLng = new window.google.maps.LatLng(lat, lng);
        return mapBounds.contains(latLng);
      });
    }

    // Filtrar com base na busca e "Ocultar Outros"
    if (hideUnmatched && searchInput.trim()) {
      const searchTerm = searchInput.trim().toLowerCase();
      filtered = filtered.filter((item) => {
        if (displayMode === 'device') {
          return (
            (item.deviceEUI &&
              item.deviceEUI.toLowerCase().includes(searchTerm)) ||
            (item.deviceName &&
              item.deviceName.toLowerCase().includes(searchTerm))
          );
        }
        // Para postes ou fotocélulas:
        return (
          (item.codigoPoste &&
            item.codigoPoste.toLowerCase().includes(searchTerm)) ||
          (item.id && String(item.id).includes(searchTerm))
        );
      });
    }

    // Filtrar por categoria e grupos selecionados
    if (displayMode === 'post') {
      return filtered.filter((poste) => {
        const pertenceGrupoSelecionado =
          selectedGroups.length === 0 ||
          poste.grupoIds.some((id) => selectedGroups.includes(id));

        return (
          activeFilters.has(getMarkerTypeForDisplay(poste)) &&
          pertenceGrupoSelecionado
        );
      });
    } else {
      return filtered.filter((item) => {
        return activeFilters.has(getMarkerTypeForDisplay(item));
      });
    }
  }, [
    mappedDevicesOrPostes,
    mapBounds,
    displayMode,
    hideUnmatched,
    searchInput,
    activeFilters,
    selectedGroups,
    getMarkerTypeForDisplay,
  ]);

  // Lidar com mudança de displayMode -> resetar filtros
  useEffect(() => {
    resetFilters(displayMode);
  }, [displayMode]);

  // -------------------------------------------------------
  // Handlers de UI e Mapa
  // -------------------------------------------------------

  // Ao carregar o mapa
  const handleMapLoad = (map) => {
    mapRef.current = map;
    map.addListener('zoom_changed', handleZoomChanged);
    handleOnIdle();
  };

  // Ao mudar zoom
  const handleZoomChanged = () => {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom());
    }
  };

  // Ao ficar "idle" (sem interação) -> atualiza bounds
  const handleOnIdle = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      setMapBounds(bounds);
    }
  };

  // Alternar entre mapa satélite e roadmap
  const toggleSatelliteView = () => {
    setMapType((prevMapType) =>
      prevMapType === 'roadmap' ? 'satellite' : 'roadmap'
    );
  };

  const isSatelliteView = mapType === 'satellite';

  // Alternar visibilidade da legenda
  const toggleLegendVisibility = () => {
    setIsLegendVisible((prev) => !prev);
  };

  // Alternar inclinação (tilt)
  const toggleTiltView = () => {
    setTiltEnabled((prev) => !prev);
    if (mapRef.current) {
      const currentTilt = mapRef.current.getTilt();
      mapRef.current.setTilt(currentTilt === 0 ? 45 : 0);
    }
  };

  // Handler para clique nos itens da legenda
  const handleLegendClick = (filterType, isCtrlPressed) => {
    setActiveFilters((prevFilters) => {
      const newFilters = new Set(prevFilters);
      if (isCtrlPressed) {
        if (newFilters.has(filterType)) {
          newFilters.delete(filterType);
        } else {
          newFilters.add(filterType);
        }
      } else {
        // Se não estiver pressionando Ctrl, alternar individualmente
        if (newFilters.has(filterType)) {
          newFilters.delete(filterType);
        } else {
          newFilters.add(filterType);
        }
      }
      return newFilters;
    });
  };

  // Resetar filtros quando displayMode muda
  const resetFilters = (mode) => {
    if (mode === 'fotocelula') {
      setActiveFilters(new Set(Object.keys(FOTOCELULA_CATEGORIES)));
    } else {
      setActiveFilters(new Set(Object.keys(MARKER_CATEGORIES)));
    }
  };

  // Abrir lista de devices/postes (modal)
  const showDeviceList = (filterType) => {
    setModalCategory(filterType);
    setIsModalOpen(true);
  };

  // Fechar lista de devices/postes
  const closeDeviceList = () => {
    setIsModalOpen(false);
    setModalCategory(null);
  };

  // -------------------------------------------------------
  // Clique em Dispositivos ou Postes
  // -------------------------------------------------------

  // Dispositivo
  const handleDeviceClick = (device) => {
    setSelectedDevice(device);
    setIsDetailsModalOpen(true);

    if (mapRef.current && displayMode === 'device') {
      mapRef.current.panTo({ lat: device.deviceLat, lng: device.deviceLng });
      mapRef.current.setZoom(18);
    }
    closeDeviceList();
  };

  // Poste
  const handlePosteClick = (poste) => {
    const fotocelulas = (poste.devices || []).map((d) => {
      const ligado = d.deviceLightOn === 'S' ? 'L' : 'D';
      const potenciaEmW = d.deviceKw != null ? d.deviceKw * 1000 : null;

      return {
        fotocelulaId: d.id,
        descricao: d.deviceName || 'N/D',
        deviceEUI: d.deviceEUI,
        tensaoRede:
          d.deviceTensaoRede != null ? d.deviceTensaoRede.toString() : 'N/A',
        corrente: d.deviceCorrente != null ? d.deviceCorrente.toString() : 'N/A',
        tempolampadaligada:
          d.deviceTempoLampadaLigada != null
            ? d.deviceTempoLampadaLigada.toString()
            : 'N/A',
        potencia: potenciaEmW != null ? potenciaEmW.toString() : 'N/A',
        pf: d.devicePf != null ? d.devicePf.toString() : 'N/A',
        ligado,
        deviceAtualizacao: d.deviceRecifeDate || 'Não disponível',
      };
    });

    const markerData = {
      ...poste,
      idPoste: poste.id,
      fotocelulas,
      alerts: poste.alertas || [],
      potencia: poste.potencia || null,
      consumo: poste.consumo || null,
      address: poste.address || '',
    };

    setSelectedPoste(markerData);
    setIsPosteModalOpen(true);
  };

  // Clique em qualquer marcador (decide se é device ou poste)
  const handleMarkerClick = (marker) => {
    if (displayMode === 'device') {
      setSelectedDevice(marker);
      setIsDetailsModalOpen(true);
    } else {
      handlePosteClick(marker);
    }
  };

  // Fechar modal de detalhes (device)
  const closeDetailsModal = () => {
    setIsDetailsModalOpen(false);
    setSelectedDevice(null);
  };

  // -------------------------------------------------------
  // Contagem de itens por categoria (para Legend)
  // -------------------------------------------------------
  const counts = useMemo(() => {
    let initialCounts;

    if (displayMode === 'fotocelula') {
      initialCounts = Object.keys(FOTOCELULA_CATEGORIES).reduce((acc, key) => {
        acc[key] = 0;
        return acc;
      }, {});

      postesDetalhados.forEach((poste) => {
        const tipo = poste.fotocelulaTipo || 'indefinido';
        const type = FOTOCELULA_CATEGORIES[tipo] ? tipo : 'indefinido';
        if (hasValidGeolocation(poste)) {
          if (initialCounts[type] !== undefined) {
            initialCounts[type] += 1;
          }
        }
      });
    } else if (displayMode === 'device') {
      initialCounts = Object.keys(MARKER_CATEGORIES).reduce((acc, key) => {
        acc[key] = 0;
        return acc;
      }, {});

      devices.forEach((device) => {
        const type = getDeviceMarkerType(device);
        if (hasValidGeolocation(device)) {
          if (initialCounts[type] !== undefined) {
            initialCounts[type] += 1;
          }
        }
      });
    } else if (displayMode === 'post') {
      initialCounts = Object.keys(MARKER_CATEGORIES).reduce((acc, key) => {
        acc[key] = 0;
        return acc;
      }, {});

      postesDetalhados.forEach((poste) => {
        const type = getPosteMarkerType(poste);
        if (hasValidGeolocation(poste)) {
          if (initialCounts[type] !== undefined) {
            initialCounts[type] += 1;
          }
        }
      });
    } else {
      // Caso um displayMode inesperado seja passado
      initialCounts = {};
    }

    return initialCounts;
  }, [devices, postesDetalhados, displayMode, hasValidGeolocation]);

  // -------------------------------------------------------
  // Função de busca (case-insensitive para deviceEUI)
  // -------------------------------------------------------
  const handleSearch = () => {
    const input = searchInput.trim().toUpperCase();
    console.log(`Pesquisando por: ${input}`);

    let foundMarker = null;
    let markerType = null;

    // 1) Tentar encontrar um dispositivo (deviceEUI) em maiúsculas
    const device = devices.find((d) => {
      if (!d.deviceEUI) return false;
      return String(d.deviceEUI).toUpperCase() === input;
    });

    if (device) {
      console.log('Dispositivo encontrado:', device);
      foundMarker = device;
      markerType = 'device';
    } else {
      console.log('Dispositivo não encontrado com EUI:', input);
      // 2) Tentar encontrar um poste pelo ID ou código do poste
      const poste = postesDetalhados.find((p) => {
        const postIdUpper = String(p.id).toUpperCase();
        const codUpper = (p.codigoPoste ?? '').toUpperCase();
        return postIdUpper === input || codUpper === input;
      });

      if (poste) {
        console.log('Poste encontrado:', poste);
        foundMarker = poste;
        markerType = 'poste';
      } else {
        console.log('Poste não encontrado com ID ou código:', input);
      }
    }

    if (foundMarker) {
      // Centraliza no item encontrado
      const position =
        markerType === 'device'
          ? { lat: foundMarker.deviceLat, lng: foundMarker.deviceLng }
          : { lat: foundMarker.lat, lng: foundMarker.lng };

      if (mapRef.current) {
        mapRef.current.panTo(position);
        mapRef.current.setZoom(18);
      }

      // Anima o marcador
      setAnimatedMarker({ type: markerType, id: foundMarker.id });

      // Remove a animação após 3 segundos
      setTimeout(() => {
        setAnimatedMarker({ type: null, id: null });
      }, 3000);
    } else {
      // Se não encontrou nada
      toast.error('Dispositivo ou Poste não encontrado.');
    }
  };

  // -------------------------------------------------------
  // Renderização JSX
  // -------------------------------------------------------
  return (
    <div className={styles.mapaAvancadoPage}>
      <div className={styles.loadingBarContainer}>
        <div
          className={styles.loadingBar}
          style={{ width: `${loadingProgress}%` }}
        ></div>
      </div>

      {/* Overlay de carregamento se necessário */}
      {(isLoadingDevices ||
        isLoadingPostesDetalhados ||
        isGruposLoading ||
        isAssociationsLoading) && <LoadingOverlay />}

      {/* Mensagens de erro se houver */}
      {(devicesError || postesError || gruposError || associationsError) && (
        <p className={styles.error}>
          Erro: {devicesError || postesError || gruposError || associationsError}
        </p>
      )}

      {/* Filtro de Grupos: somente para 'post' */}
      {displayMode === 'post' && !isGruposLoading && !gruposError && (
        <div className={styles.filtroGruposContainer}>
          <FiltroGrupos
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
            grupos={grupos}
            refetchGrupos={refetchGrupos}
          />
        </div>
      )}

      {/* Dropdown para selecionar Modo de Mapa */}
      <div className={styles.controlButtons}>
        <div className={styles.dropdownContainer}>
          <label htmlFor="mapDisplayMode" className={styles.dropdownLabel}>
            Tipo de Mapa
          </label>
          <select
            id="mapDisplayMode"
            className={styles.dropdownSelect}
            value={displayMode}
            onChange={(e) => {
              const newMode = e.target.value;
              setDisplayMode(newMode);
              // A função resetFilters(newMode) é chamada no useEffect
            }}
          >
            <option value="device">Mapa de Dispositivos</option>
            <option value="post">Mapa de Postes</option>
            <option value="fotocelula">Mapa por Tipo de Dispositivo (Fotocélula)</option>
          </select>
        </div>
      </div>

      {/* Barra de Busca (SearchBar) */}
      <SearchBar
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        handleSearch={handleSearch}
        hideUnmatched={hideUnmatched}
        setHideUnmatched={setHideUnmatched}
      />

      {/* Toolbar com botões de satélite, tilt, legenda, etc. */}
      <Toolbar
        toggleSatelliteView={toggleSatelliteView}
        isSatelliteView={isSatelliteView}
        toggleTilt={toggleTiltView}
        tiltEnabled={tiltEnabled}
        toggleLegend={toggleLegendVisibility}
        isLegendVisible={isLegendVisible}
      />

      {/* GoogleMap */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={initialCenter}
        zoom={zoom}
        mapTypeId={mapType}
        options={{
          styles: currentMapStyle,
          streetViewControl: true, // Mostra o controle de Street View
          disableDefaultUI: true,
          zoomControl: true,
        }}
        onLoad={handleMapLoad}
        onIdle={handleOnIdle}
        libraries={['geometry']}
      >
        {/* Marcadores para 'device' */}
        {!isLoadingDevices &&
          !isLoadingPostesDetalhados &&
          displayMode === 'device' &&
          visibleDevicesOrPostes.map((device) => (
            <DeviceMarker
              key={device.id}
              device={device}
              onClick={() => handleMarkerClick(device)}
              animate={
                animatedMarker.type === 'device' && animatedMarker.id === device.id
              }
              isInSelectedGroup={false} // Dispositivos não filtram por grupos
            />
          ))}

        {/* Marcadores para 'post' ou 'fotocelula' */}
        {!isLoadingDevices &&
          !isLoadingPostesDetalhados &&
          (displayMode === 'post' || displayMode === 'fotocelula') &&
          visibleDevicesOrPostes.map((poste) => (
            <PosteMarker
              key={`poste-${poste.id}`}
              poste={poste}
              onClick={() => handleMarkerClick(poste)}
              displayMode={displayMode}
              animate={
                animatedMarker.type === 'poste' && poste.id === animatedMarker.id
              }
              isInSelectedGroup={
                displayMode === 'post' &&
                (selectedGroups.length === 0 ||
                  poste.grupoIds.some((id) => selectedGroups.includes(id)))
              }
            />
          ))}
      </GoogleMap>

      {/* Legenda (se estiver visível) */}
      {isLegendVisible && (
        <Legend
          counts={counts}
          onLegendClick={handleLegendClick}
          activeFilters={activeFilters}
          onShowDeviceList={showDeviceList}
          onResetFilters={resetFilters}
          displayMode={displayMode}
        />
      )}

      {/* Modal de lista de dispositivos/postes */}
      {isModalOpen && (
        <DeviceListModal
          devices={mappedDevicesOrPostes.filter((item) => {
            // Filtra itens que correspondam à categoria selecionada
            if (displayMode === 'device') {
              return getDeviceMarkerType(item) === modalCategory;
            } else if (displayMode === 'fotocelula') {
              return item.fotocelulaTipo === modalCategory;
            } else if (displayMode === 'post') {
              return getPosteMarkerType(item) === modalCategory;
            }
            return false;
          })}
          category={modalCategory}
          onClose={closeDeviceList}
          onDeviceClick={handleDeviceClick}
        />
      )}

      {/* Modal de detalhes do dispositivo */}
      {isDetailsModalOpen && selectedDevice && (
        <DeviceDetailsModal device={selectedDevice} onClose={closeDetailsModal} />
      )}

      {/* Modal de detalhes do poste */}
      {isPosteModalOpen && selectedPoste && (
        <MarkerModal
          isOpen={isPosteModalOpen}
          onClose={() => setIsPosteModalOpen(false)}
          markerData={selectedPoste}
        />
      )}
    </div>
  );
};

export default MapaAvancado;
