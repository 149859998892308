import React, { useState, useMemo, useCallback, useContext } from 'react';
import ReactPaginate from 'react-paginate';
import { FaPlus, FaEdit, FaTrash, FaSortUp, FaSortDown } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import PageLayout from '../../components/PageLayout/PageLayout';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import RegiaoModal from './RegiaoModal';
import styles from './Regioes.module.css';
import useRegiao from '../../hooks/useRegiao';
import { AuthContext } from '../../context/AuthContext';

const Regioes = () => {
  const { token, logout } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRegiao, setSelectedRegiao] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const itemsPerPage = 10;

  const { regioes, isRegiaoLoading, regiaoError, refetch } = useRegiao();

  const openModal = (regiao = null) => {
    setSelectedRegiao(regiao);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRegiao(null);
  };

  const handleDelete = useCallback(async (regiaoId) => {
    if (!window.confirm('Tem certeza que deseja deletar esta região?')) return;

    try {
      const response = await fetch(`https://api.ads10.com.br/api/regiao/${regiaoId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        throw new Error('Erro ao deletar a região.');
      }

      toast.success('Região deletada com sucesso!');
      refetch();
    } catch (error) {
      toast.error('Erro ao deletar a região: ' + error.message);
    }
  }, [token, logout, refetch]);

  const handleSave = useCallback(async (newRegiao) => {
    try {
      const method = newRegiao.regiaoId ? 'PUT' : 'POST';
      const url = newRegiao.regiaoId
        ? `https://api.ads10.com.br/api/regiao/${newRegiao.regiaoId}`
        : 'https://api.ads10.com.br/api/regiao';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newRegiao),
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        throw new Error('Erro ao salvar a região.');
      }

      toast.success('Região salva com sucesso!');
      refetch();
      closeModal();
    } catch (error) {
      toast.error('Erro ao salvar a região: ' + error.message);
    }
  }, [token, logout, refetch]);

  const requestSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === 'ascending' ? 'descending' : 'ascending',
    }));
  };

  const filteredRegioes = useMemo(() => {
    return regioes.filter((regiao) =>
      regiao.regiaoNome?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [regioes, searchTerm]);

  const sortedRegioes = useMemo(() => {
    if (!sortConfig.key) return filteredRegioes;

    return [...filteredRegioes].sort((a, b) => {
      const aValue = a[sortConfig.key]?.toLowerCase() || '';
      const bValue = b[sortConfig.key]?.toLowerCase() || '';
      return sortConfig.direction === 'ascending'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });
  }, [filteredRegioes, sortConfig]);

  const pageCount = Math.ceil(sortedRegioes.length / itemsPerPage);

  const currentPageData = sortedRegioes.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const csvHeaders = [
    { label: 'ID', key: 'regiaoId' },
    { label: 'Nome', key: 'regiaoNome' },
  ];

  const csvData = useMemo(
    () => regioes.map(({ regiaoId, regiaoNome }) => ({ regiaoId, regiaoNome })),
    [regioes]
  );

  return (
    <PageLayout title="Gerenciar Regiões">
      {isRegiaoLoading && <LoadingOverlay />}
      {!isRegiaoLoading && (
        <div className={styles.container}>
          {regiaoError && <ErrorMessage message={regiaoError} />}

          <div className={styles.controlsTop}>
            <button className={styles.addButton} onClick={() => openModal()}>
              <FaPlus /> Adicionar Nova Região
            </button>
            <CSVLink
              data={csvData}
              headers={csvHeaders}
              filename="regioes-export.csv"
              className={styles.exportButton}
            >
              Exportar para CSV
            </CSVLink>
          </div>

          <input
            type="text"
            className={styles.searchInput}
            placeholder="Buscar por nome da região..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <table className={styles.table}>
            <thead>
              <tr>
                <th onClick={() => requestSort('regiaoId')}>
                  ID {sortConfig.key === 'regiaoId' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('regiaoNome')}>
                  Nome {sortConfig.key === 'regiaoNome' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((regiao) => (
                <tr key={regiao.regiaoId}>
                  <td>{regiao.regiaoId}</td>
                  <td>{regiao.regiaoNome}</td>
                  <td>
                    <button className={styles.actionButton} onClick={() => openModal(regiao)}>
                      <FaEdit />
                    </button>
                    <button
                      className={`${styles.actionButton} ${styles.deleteButton}`}
                      onClick={() => handleDelete(regiao.regiaoId)}
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <ReactPaginate
            previousLabel="← Anterior"
            nextLabel="Próximo →"
            breakLabel="..."
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={styles.pagination}
            activeClassName={styles.paginationActive}
          />

          {isModalOpen && (
            <RegiaoModal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              regiaoData={selectedRegiao}
              onSave={handleSave}
            />
          )}
        </div>
      )}
    </PageLayout>
  );
};

export default Regioes;
