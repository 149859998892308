// src/utils/constants.js

// Importar os ícones de fotocélula
import MasterIcon from '../assets/Master.png';
import SlaveIcon from '../assets/Slave.png';
import MQEIcon from '../assets/MQE.png';
import SDLIcon from '../assets/SDL.png'; // SFL
import NFIcon from '../assets/NF.png'; // Relé Comum
import NFProtecaoIcon from '../assets/NF Proteção.png'; // Relé de Proteção
import IndefinidoIcon from '../assets/indefinido.png'; // Ícone para valores indefinidos

// Importar os ícones PNG para os modos 'device' e 'post'
import apagadoIcon from '../assets/blue-icon.png';
import acesaIcon from '../assets/green-icon.png';
import indefinidoMarkerIcon from '../assets/indefinido.png';
import sobretensaoIcon from '../assets/sobretensao-icon.png';
import subtensaoIcon from '../assets/subtensao-icon.png';
import offlineIcon from '../assets/offline-icon.png';

// Importar os novos ícones para os novos tipos de marcador
import acesoDiaIcon from '../assets/aceso-dia-icon.png';
import apagadoNoiteIcon from '../assets/apagado-noite-icon.png';
import alertaIcon from '../assets/red-icon.png'; // Novo ícone para alertas

// Definição das categorias para dispositivos
export const MARKER_CATEGORIES = {
  acesa: {
    label: 'Acesa',
    color: '#00FF00', // Verde
    icon: acesaIcon,
  },
  apagada: {
    label: 'Apagada',
    color: '#FF0000', // Vermelho
    icon: apagadoIcon,
  },
  'aceso-dia': {
    label: 'Aceso Durante o Dia',
    color: '#FFD700', // Dourado
    icon: acesoDiaIcon,
  },
  'apagado-noite': {
    label: 'Apagado Durante a Noite',
    color: '#1E90FF', // Azul
    icon: apagadoNoiteIcon,
  },
  alerta: { // Nova categoria para alertas
    label: 'Alerta',
    color: '#FF0000', // Vermelho
    icon: alertaIcon,
  },
  indefinida: {
    label: 'Indefinida',
    color: '#808080', // Cinza
    icon: indefinidoMarkerIcon,
  },
  offline: {
    label: 'Offline',
    color: '#FFA500', // Laranja
    icon: offlineIcon,
  },
  overVoltage: {
    label: 'Sobretensão',
    color: '#0000FF', // Azul
    icon: sobretensaoIcon,
  },
  underVoltage: {
    label: 'Subtensão',
    color: '#FFFF00', // Amarelo
    icon: subtensaoIcon,
  },
};

// Definição das categorias para 'fotocelula'
export const FOTOCELULA_CATEGORIES = {
  '1': { label: 'Relé Comum', icon: NFIcon },
  '2': { label: 'Relé de Proteção', icon: NFProtecaoIcon },
  '3': { label: 'SFL', icon: SDLIcon },
  '4': { label: 'MQE', icon: MQEIcon },
  '5': { label: 'Telegestor Master', icon: MasterIcon },
  '6': { label: 'Telegestor Slave', icon: SlaveIcon },
  'indefinido': { label: 'Indefinido', icon: IndefinidoIcon },
};

// Definição das categorias para 'device' e 'post'
export const DEVICE_POST_CATEGORIES = {
  apagada: { label: 'Apagado', icon: apagadoIcon },
  acesa: { label: 'Acesa', icon: acesaIcon },
  'aceso-dia': { label: 'Aceso Durante o Dia', icon: acesoDiaIcon },
  'apagado-noite': { label: 'Apagado Durante a Noite', icon: apagadoNoiteIcon },
  alerta: { label: 'Alerta', icon: alertaIcon }, // Nova categoria para alertas
  overVoltage: { label: 'Sobretensão', icon: sobretensaoIcon },
  underVoltage: { label: 'Subtensão', icon: subtensaoIcon },
  offline: { label: 'Offline', icon: offlineIcon },
  indefinida: { label: 'Indefinida', icon: indefinidoMarkerIcon }, // Pode ajustar conforme necessário
};
