import React, { useState } from 'react';
import styles from './Legend.module.css';
import PropTypes from 'prop-types';
import { FaInfoCircle, FaSyncAlt, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { MARKER_CATEGORIES, FOTOCELULA_CATEGORIES, DEVICE_POST_CATEGORIES } from '../../utils/constants';

const Legend = ({ counts, onLegendClick, activeFilters, onShowDeviceList, onResetFilters, displayMode }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = (filterType, event) => {
    const isCtrlPressed = event.ctrlKey || event.metaKey;
    onLegendClick(filterType, isCtrlPressed);
  };

  const handleInfoClick = (filterType, event) => {
    event.stopPropagation();
    onShowDeviceList(filterType);
  };

  const handleResetClick = () => {
    onResetFilters(displayMode);
  };

  const isActive = (filterType) => activeFilters.has(filterType);

  const legendItems = (displayMode === 'fotocelula' ? FOTOCELULA_CATEGORIES : DEVICE_POST_CATEGORIES);

  return (
    <div className={`${styles.legendContainer} ${isExpanded ? '' : styles.compact}`}>
      <div className={styles.header}>
        <h4 className={styles.legendTitle}>Legenda</h4>
        <button
          className={styles.toggleButton}
          onClick={() => setIsExpanded(!isExpanded)}
          aria-label={isExpanded ? 'Compactar Legenda' : 'Expandir Legenda'}
        >
          {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </div>
      {isExpanded ? (
        <>
          <button
            className={styles.resetButton}
            onClick={handleResetClick}
            title="Resetar Filtros"
          >
            <FaSyncAlt /> Resetar Filtros
          </button>
          <ul className={styles.legendList}>
            {Object.keys(legendItems).map((type) => (
              <li
                key={type}
                className={`${styles.legendItem} ${isActive(type) ? styles.active : ''}`}
                onClick={(e) => handleClick(type, e)}
              >
                <div className={styles.iconTextWrapper}>
                  <img src={legendItems[type].icon} alt={legendItems[type].label} className={styles.legendIcon} />
                  <span className={styles.legendText}>{legendItems[type].label}</span>
                  <span className={styles.legendCount}>{counts[type] || 0}</span>
                </div>
                <button
                  className={styles.infoButton}
                  onClick={(e) => handleInfoClick(type, e)}
                  title={`Ver ${legendItems[type].label.toLowerCase()}s`}
                >
                  <FaInfoCircle />
                </button>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <ul className={styles.legendCompactList}>
          {Object.keys(legendItems).map((type) => (
            <li
              key={type}
              className={`${styles.legendCompactItem} ${isActive(type) ? styles.active : ''}`}
              onClick={(e) => handleClick(type, e)}
            >
              <img src={legendItems[type].icon} alt={legendItems[type].label} className={styles.legendIconCompact} />
              <span className={styles.legendCountCompact}>{counts[type] || 0}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

Legend.propTypes = {
  counts: PropTypes.object.isRequired,
  onLegendClick: PropTypes.func.isRequired,
  activeFilters: PropTypes.instanceOf(Set).isRequired,
  onShowDeviceList: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  displayMode: PropTypes.string.isRequired,
};

export default Legend;
