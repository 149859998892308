// src/components/DeviceDetailsModal/DeviceDetailsModal.jsx

import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './DeviceDetailsModal.module.css';
import {
  FaBatteryFull,
  FaLightbulb,
  FaTachometerAlt,
  FaBolt,
  FaThermometerHalf,
  FaClock,
  FaChartLine,
  FaMapMarkerAlt,
  FaTimes,
  FaSyncAlt,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../../context/AuthContext'; // Importe se necessário

const DeviceDetailsModal = ({ device, onClose }) => {
  // Acesso ao AuthContext (se necessário para autenticação)
  const { token, cidadeId, logout } = useContext(AuthContext);

  // Estado para controlar a aba selecionada
  const [tabIndex, setTabIndex] = useState(0);

  // Estados para gráficos
  const [graphData, setGraphData] = useState([]);
  const [isLoadingGraph, setIsLoadingGraph] = useState(false);
  const [graphError, setGraphError] = useState(null);

  // Estados para filtros de data e hora
  const today = new Date();
  const formattedTodayDate = today.toISOString().split('T')[0]; // YYYY-MM-DD
  const formattedTodayStartTime = '00:00';
  const formattedTodayEndTime = '23:59';
  const [startDateTime, setStartDateTime] = useState(`${formattedTodayDate}T${formattedTodayStartTime}`);
  const [endDateTime, setEndDateTime] = useState(`${formattedTodayDate}T${formattedTodayEndTime}`);

  // Estados para intervalo do eixo Y
  const [yAxisMin, setYAxisMin] = useState('');
  const [yAxisMax, setYAxisMax] = useState('');

  // Estado para controlar a exibição de filtros
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(false);

  // Estado para controlar a métrica selecionada
  const [selectedMetric, setSelectedMetric] = useState('deviceTensaoRede');

  // Definição das métricas disponíveis
  const metricsOptions = useMemo(() => [
    { value: 'deviceTensaoRede', label: 'Tensão', unit: 'V' },
    { value: 'deviceConsumo', label: 'Consumo', unit: 'kWh' },
    { value: 'deviceKw', label: 'Potência', unit: 'kW' },
    { value: 'deviceCorrente', label: 'Corrente', unit: 'A' },
    { value: 'devicePr', label: 'PR', unit: '' },
    { value: 'devicePa', label: 'PA', unit: '' },
    { value: 'deviceTempoLampadaLigada', label: 'Tempo Ligado', unit: 's' },
    { value: 'devicePf', label: 'PF', unit: '' },
    { value: 'deviceLux', label: 'Lux', unit: '' },
  ], []);

  const metricsMap = useMemo(() => {
    const map = {};
    metricsOptions.forEach(metric => {
      map[metric.value] = { label: metric.label, unit: metric.unit };
    });
    return map;
  }, [metricsOptions]);

  // --------------- Definir fetchGraphData Antes de Usar ---------------

  // Função para buscar dados do gráfico
  const fetchGraphData = useCallback(async () => {
    if (!device?.deviceEUI || !selectedMetric) {
      setGraphData([]);
      console.log('Dispositivo EUI ou métrica não definida.');
      return;
    }

    setIsLoadingGraph(true);
    setGraphError(null);

    try {
      console.log(`Buscando dados para o dispositivo EUI: ${device.deviceEUI}`);
      console.log(`Métrica selecionada: ${selectedMetric}`);

      // Endpoint correto para buscar dados do dispositivo específico
      const response = await fetch(`https://api.ads10.com.br/api/device/${device.deviceEUI}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          toast.error('Sessão expirada. Faça login novamente.');
          logout();
          return;
        }
        throw new Error('Erro ao buscar dados do gráfico');
      }

      const result = await response.json();
      console.log('Dados recebidos do endpoint:', result);

      // Verificar se os dados são um array
      if (!Array.isArray(result)) {
        throw new Error('Formato de dados inválido recebido do endpoint.');
      }

      // Filtrar e mapear os dados para o gráfico usando o campo 'Data'
      const transformedData = result
        .map(item => ({
          // Converter a string 'Data' para timestamp (número)
          data: new Date(item.Data.replace(' ', 'T')).getTime(), // "2024-12-11 11:04:57" -> timestamp
          valor: parseFloat(item[selectedMetric]),
        }))
        .filter(item => {
          const itemDate = item.data;
          return (
            (!startDateTime || itemDate >= new Date(startDateTime).getTime()) &&
            (!endDateTime || itemDate <= new Date(endDateTime).getTime())
          );
        });

      console.log('Dados transformados e filtrados para o gráfico:', transformedData);

      // Remover valores NaN
      const validData = transformedData.filter(d => !isNaN(d.valor));
      console.log('Dados válidos para o gráfico (sem NaN):', validData);

      // Ordenar os dados por data e hora
      const sortedData = validData.sort((a, b) => a.data - b.data);
      console.log('Dados ordenados para o gráfico:', sortedData);

      setGraphData(sortedData);
    } catch (err) {
      console.error(err);
      setGraphError(err.message);
      toast.error(`Erro: ${err.message}`);
    } finally {
      setIsLoadingGraph(false);
    }
  }, [
    device,
    selectedMetric,
    token,
    cidadeId,
    logout,
    startDateTime,
    endDateTime,
  ]);

  // --------------- Agora, Usar fetchGraphData no useEffect ---------------
  useEffect(() => {
    fetchGraphData();
  }, [fetchGraphData]);

  // Timer para atualização automática (a cada 10 minutos)
  useEffect(() => {
    let intervalId;

    const startAutoUpdate = () => {
      fetchGraphData();

      intervalId = setInterval(() => {
        fetchGraphData();
      }, 600000); // 600000 ms = 10 minutos
    };

    if (device) {
      startAutoUpdate();
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [device, fetchGraphData]);

  // Função para atualizar os dados do gráfico
  const handleUpdateData = useCallback(() => {
    fetchGraphData();
  }, [fetchGraphData]);

  // Função para limpar os filtros e redefinir para o dia atual
  const handleClearFilters = useCallback(() => {
    setStartDateTime(`${formattedTodayDate}T${formattedTodayStartTime}`);
    setEndDateTime(`${formattedTodayDate}T${formattedTodayEndTime}`);
    setYAxisMin('');
    setYAxisMax('');
    fetchGraphData();
  }, [formattedTodayDate, formattedTodayStartTime, formattedTodayEndTime, fetchGraphData]);

  // Função para alternar a exibição de filtros
  const toggleFilters = () => {
    setIsFiltersExpanded(prev => !prev);
  };

  // Obtém a última medição disponível
  const lastMeasurement = useMemo(() => {
    if (graphData.length === 0) return null;
    return graphData[graphData.length - 1];
  }, [graphData]);

  // Função para formatar a data
  const formatDate = (date) => {
    if (!date) return 'N/A';
    return new Date(date).toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  // Função para formatar o tempo de lâmpada ligada (em segundos) para HH:MM:SS
  const formatTempoLampada = (seconds) => {
    if (seconds === null || seconds === undefined) return 'N/A';
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs}h ${mins}m ${secs}s`;
  };

  // Adicionar Log para Verificar os Dados do Gráfico
  useEffect(() => {
    console.log('Dados para o Gráfico:', graphData);
  }, [graphData]);

  return (
    <>
      {device && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <button className={styles.closeButton} onClick={onClose}>
              <FaTimes />
            </button>
            <h2 className={styles.title}>{device.deviceEUI}</h2>
            {device.posteCodigo && (
              <p className={styles.poste}>
                <FaMapMarkerAlt /> <strong>Poste Associado:</strong> {device.posteCodigo}
              </p>
            )}
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
              <TabList>
                <Tab>Informações Gerais</Tab>
                <Tab>Métricas e Gráficos</Tab>
              </TabList>

              {/* Aba 1: Informações Gerais */}
              <TabPanel>
                <div className={styles.generalInfoContainer}>
                  <div className={styles.leftSection}>
                    <div className={styles.details}>
                      <div className={styles.detailItem}>
                        <FaLightbulb className={styles.icon} />
                        <span><strong>Luz:</strong> {device.deviceLightOn === 'S' ? 'Acesa' : 'Apagada'}</span>
                      </div>
                      <div className={styles.detailItem}>
                        <FaBolt className={styles.icon} />
                        <span><strong>Tensão da Rede:</strong> {device.deviceTensaoRede} V</span>
                      </div>
                      <div className={styles.detailItem}>
                        <FaTachometerAlt className={styles.icon} />
                        <span><strong>Corrente:</strong> {device.deviceCorrente} A</span>
                      </div>
                      <div className={styles.detailItem}>
                        <FaThermometerHalf className={styles.icon} />
                        <span><strong>PR:</strong> {device.devicePr}</span>
                      </div>
                      <div className={styles.detailItem}>
                        <FaChartLine className={styles.icon} />
                        <span><strong>PA:</strong> {device.devicePa}</span>
                      </div>
                      <div className={styles.detailItem}>
                        <FaBatteryFull className={styles.icon} />
                        <span><strong>PF:</strong> {device.devicePf}</span>
                      </div>
                      <div className={styles.detailItem}>
                        <FaClock className={styles.icon} />
                        <span><strong>Tempo de Lâmpada Ligada:</strong> {formatTempoLampada(device.deviceTempoLampadaLigada)}</span>
                      </div>
                      <div className={styles.detailItem}>
                        <FaChartLine className={styles.icon} />
                        <span><strong>Consumo:</strong> {device.deviceConsumo} kW</span>
                      </div>
                      <div className={styles.detailItem}>
                        <FaBatteryFull className={styles.icon} />
                        <span><strong>Data:</strong> {formatDate(device.Data.replace(' ', 'T'))}</span>
                      </div>
                      <div className={styles.detailItem}>
                        <FaLightbulb className={styles.icon} />
                        <span><strong>Modo Fotocélula:</strong> {device.deviceModoFotoCelula === 'S' ? 'Ativado' : 'Desativado'}</span>
                      </div>
                      <div className={styles.detailItem}>
                        <FaBatteryFull className={styles.icon} />
                        <span><strong>Lux:</strong> {device.deviceLux !== null ? device.deviceLux : 'N/A'}</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.rightSection}>
                    <div className={styles.imageContainer}>
                      <img
                        src={`https://ceasa.ads10.com.br/images/${device.posteCodigo}.jpg`}
                        alt={`Dispositivo ${device.deviceEUI}`}
                        className={styles.deviceImage}
                        onClick={() => {
                          // Abrir a imagem em uma nova aba
                          window.open(`https://ceasa.ads10.com.br/images/${device.posteCodigo}.jpg`, '_blank');
                        }}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>

              {/* Aba 2: Métricas e Gráficos */}
              <TabPanel>
                <div className={styles.metricsTab}>
                  {/* Botão para alternar a exibição de filtros */}
                  <button className={styles.filterToggleButton} onClick={toggleFilters}>
                    {isFiltersExpanded ? (
                      <>
                        <FaChevronUp /> Ocultar Filtros
                      </>
                    ) : (
                      <>
                        <FaChevronDown /> Mostrar Filtros
                      </>
                    )}
                  </button>

                  {/* Filtros */}
                  {isFiltersExpanded && (
                    <div className={styles.filtersContainer}>
                      <div className={styles.filterItem}>
                        <label htmlFor="startDateTime">Data e Hora Início:</label>
                        <input
                          type="datetime-local"
                          id="startDateTime"
                          value={startDateTime}
                          onChange={(e) => setStartDateTime(e.target.value)}
                          className={styles.input}
                        />
                      </div>
                      <div className={styles.filterItem}>
                        <label htmlFor="endDateTime">Data e Hora Fim:</label>
                        <input
                          type="datetime-local"
                          id="endDateTime"
                          value={endDateTime}
                          onChange={(e) => setEndDateTime(e.target.value)}
                          className={styles.input}
                        />
                      </div>
                      <div className={styles.filterItem}>
                        <label htmlFor="yAxisMin">Y-Axis Mínimo:</label>
                        <input
                          type="number"
                          id="yAxisMin"
                          value={yAxisMin}
                          onChange={(e) => setYAxisMin(e.target.value)}
                          className={styles.input}
                          placeholder="Auto"
                        />
                      </div>
                      <div className={styles.filterItem}>
                        <label htmlFor="yAxisMax">Y-Axis Máximo:</label>
                        <input
                          type="number"
                          id="yAxisMax"
                          value={yAxisMax}
                          onChange={(e) => setYAxisMax(e.target.value)}
                          className={styles.input}
                          placeholder="Auto"
                        />
                      </div>
                      <div className={styles.actionsContainer}>
                        <button onClick={handleUpdateData} className={styles.updateButton}>
                          <FaSyncAlt /> Atualizar
                        </button>
                        <button onClick={handleClearFilters} className={styles.clearFiltersButton}>
                          Limpar Filtros
                        </button>
                      </div>
                    </div>
                  )}

                  {/* Seleção de Métrica */}
                  <div className={styles.metricSelection}>
                    <label htmlFor="metric">Métrica:</label>
                    <select
                      id="metric"
                      value={selectedMetric}
                      onChange={(e) => setSelectedMetric(e.target.value)}
                      className={styles.select}
                    >
                      {metricsOptions.map((metric) => (
                        <option key={metric.value} value={metric.value}>
                          {metric.label} {metric.unit && `(${metric.unit})`}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Gráfico */}
                  <div className={styles.graphContainer}>
                    {isLoadingGraph ? (
                      <p>Carregando gráfico...</p>
                    ) : graphError ? (
                      <p className={styles.error}>Erro: {graphError}</p>
                    ) : graphData.length > 0 ? (
                      <>
                        <h4>
                          {metricsMap[selectedMetric].label} {metricsMap[selectedMetric].unit && `(${metricsMap[selectedMetric].unit})`} -
                          {startDateTime && endDateTime ? (
                            <>
                              {new Date(startDateTime).toLocaleString('pt-BR')} a {new Date(endDateTime).toLocaleString('pt-BR')}
                            </>
                          ) : (
                            'Intervalo Personalizado'
                          )}
                        </h4>
                        <ResponsiveContainer width="100%" height={400}>
                          <LineChart data={graphData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="data"
                              type="number"
                              domain={['auto', 'auto']}
                              scale="time"
                              tickFormatter={(tick) => new Date(tick).toLocaleString('pt-BR', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                              })}
                            />
                            <YAxis
                              domain={
                                yAxisMin !== '' && yAxisMax !== ''
                                  ? [Number(yAxisMin), Number(yAxisMax)]
                                  : [Math.min(...graphData.map(d => d.valor)) - 10, Math.max(...graphData.map(d => d.valor)) + 10]
                              }
                              label={{
                                value: `${metricsMap[selectedMetric].label} ${metricsMap[selectedMetric].unit}`,
                                angle: -90,
                                position: 'insideLeft',
                                offset: 10,
                              }}
                            />
                            <Tooltip
                              labelFormatter={(label) => new Date(label).toLocaleString('pt-BR', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                              })}
                              formatter={(value) => `${value} ${metricsMap[selectedMetric].unit}`}
                            />
                            <Legend verticalAlign="top" height={36} />
                            <Line
                              type="monotone"
                              dataKey="valor"
                              name={`${metricsMap[selectedMetric].label} ${metricsMap[selectedMetric].unit}`}
                              stroke="#FF0000" // Temporariamente vermelho para maior contraste
                              activeDot={{ r: 8 }}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </>
                    ) : (
                      <p>Nenhum dado disponível para exibir o gráfico.</p>
                    )}
                  </div>

                  {/* Última Medição */}
                  <div className={styles.lastMeasurement}>
                    <h4>Última Medição</h4>
                    {lastMeasurement ? (
                      <p>
                        <strong>Valor:</strong> {lastMeasurement.valor} {metricsMap[selectedMetric].unit}<br />
                        <strong>Data e Hora:</strong> {formatDate(lastMeasurement.data)}
                      </p>
                    ) : (
                      <p>Nenhuma medição disponível.</p>
                    )}
                  </div>
                </div>
              </TabPanel>
            </Tabs>
            <ToastContainer /> {/* Container para notificações */}
          </div>
        </div>
      )}
    </>
  );
};

DeviceDetailsModal.propTypes = {
  device: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeviceDetailsModal;
