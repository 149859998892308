// src/pages/Alertas/Alertas.jsx

import React, { useState, useMemo, useCallback, useContext } from 'react';
import ReactPaginate from 'react-paginate';
import { FaSortUp, FaSortDown, FaPlus, FaTimes } from 'react-icons/fa';
import { CSVLink } from 'react-csv';
import PageLayout from '../../components/PageLayout/PageLayout';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import ServiceOrderModal from './ServiceOrderModal';
import AlertaModal from './AlertaModal';
import useAlertaSituacao from '../../hooks/useAlertaSituacao';
import useAlertasComOrdemServico from '../../hooks/useAlertasComOrdemServico';
import usePostes from '../OrdensServicos/usePostes';
import styles from './Alertas.module.css';
import { toast } from 'react-toastify';
import { getLocalDate } from '../../utils/dateUtils';
import { AuthContext } from '../../context/AuthContext';

const Alertas = () => {
  const { token, logout, cidadeId, usuarioId } = useContext(AuthContext);
  const { alertasComOS: alertas, isLoading, error, refetch } = useAlertasComOrdemServico();
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [internoExternoFilter, setInternoExternoFilter] = useState('');
  const [tipoFilter, setTipoFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'alertaDataGeracao', direction: 'descending' });
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAlertData, setSelectedAlertData] = useState(null);
  const [isAlertaModalOpen, setIsAlertaModalOpen] = useState(false);
  const itemsPerPage = 10;
  const { postes, isLoading: isPostesLoading, error: postesError } = usePostes();

  const { situacoes, isLoading: situacaoLoading, error: situacaoError } = useAlertaSituacao();

  const tiposAlertaInternos = useMemo(() => [
    "Ponto Apagado",
    "Material Danificado",
    "Implantação",
    "Outros"
  ], []);

  const tiposAlertaExternos = useMemo(() => [
    "Falta de Energia",
    "Queda de Tensão",
    "Risco de Choque Elétrico",
    "Limpeza das Instalações",
    "Novas Instalações",
    "Ampliação de Rede",
    "Material Danificado",
    "Outros"
  ], []);

  // Função para buscar o endereço do poste
  const getEndereco = useCallback((alertaPosteId) => {
    const poste = postes.find(p => p.posteAppId === alertaPosteId);
    if (poste) {
      const address = poste.address || 'Sem endereço';
      const bairro = poste.posteBairro || 'Sem bairro';
      return `${address}, ${bairro}`;
    }
    return 'Endereço não disponível';
  }, [postes]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(0);
  };

  const handleInternoExternoChange = (e) => {
    setInternoExternoFilter(e.target.value);
    setTipoFilter('');
    setCurrentPage(0);
  };

  const handleTipoFilterChange = (e) => {
    setTipoFilter(e.target.value);
    setCurrentPage(0);
  };

  const tiposAlertaAtuais = useMemo(() => {
    if (internoExternoFilter === 'Interno') {
      return tiposAlertaInternos;
    } else if (internoExternoFilter === 'Externo') {
      return tiposAlertaExternos;
    }
    return [];
  }, [internoExternoFilter, tiposAlertaInternos, tiposAlertaExternos]);

  const filteredAlertas = useMemo(() => {
    return alertas.filter((alerta) => {
      const matchesSearchTerm =
        alerta.alertaId.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        alerta.alertaMotivo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        alerta.alertaPosteId.toString().toLowerCase().includes(searchTerm.toLowerCase());

      const matchesStatusFilter = statusFilter
        ? situacoes[alerta.alertaSituacao] === statusFilter
        : true;

      const tipoAlerta = alerta.alertaMotivo.split('/')[0].trim();
      const motivoAlerta = alerta.alertaMotivo.split('/')[1]?.trim() || '';

      const matchesInternoExterno =
        internoExternoFilter === ''
          ? true
          : internoExternoFilter === 'Interno'
            ? tipoAlerta.includes('Interno')
            : tipoAlerta.includes('Externo');

      const matchesTipoFilter = tipoFilter ? motivoAlerta.includes(tipoFilter) : true;

      return (
        matchesSearchTerm &&
        matchesStatusFilter &&
        matchesInternoExterno &&
        matchesTipoFilter
      );
    });
  }, [alertas, searchTerm, statusFilter, internoExternoFilter, tipoFilter, situacoes]);

  const sortedAlertas = useMemo(() => {
    const sorted = [...filteredAlertas];
    if (sortConfig.key) {
      sorted.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key.toLowerCase().includes('data')) {
          aValue = new Date(aValue);
          bValue = new Date(bValue);
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  }, [filteredAlertas, sortConfig]);

  const pageCount = Math.ceil(sortedAlertas.length / itemsPerPage);

  const currentPageData = useMemo(() => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return sortedAlertas.slice(start, end);
  }, [sortedAlertas, currentPage, itemsPerPage]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleOpenModal = (alerta) => {
    setSelectedAlertData(alerta);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedAlertData(null);
  };

  // Função para abrir o modal de criar alerta
  const handleOpenAlertaModal = () => {
    setIsAlertaModalOpen(true);
  };

  const handleCloseAlertaModal = () => {
    setIsAlertaModalOpen(false);
  };

  // Função para salvar um novo alerta
  const handleSaveAlerta = useCallback(async (newAlertaData) => {
    try {
      if (!token || !cidadeId || !usuarioId) {
        throw new Error('Token, ID da cidade ou ID do usuário não encontrado.');
      }

      const payload = {
        ...newAlertaData,
        alertaDataGeracao: new Date().toISOString().split('T')[0], // YYYY-MM-DD
        alertaHoraGeracao: new Date().toLocaleTimeString('pt-BR', { hour12: false }),
        alertaUsuarioGeracaoId: usuarioId,
      };

      const response = await fetch('https://api.ads10.com.br/api/alerta', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Erro ao criar alerta: ${errorData.message || response.statusText}`);
      }

      const createdAlerta = await response.json();
      refetch();
      toast.success(`Alerta #${createdAlerta.alertaId} criado com sucesso!`);
      handleCloseAlertaModal();
    } catch (error) {
      console.error(error);
      toast.error(error.message || 'Ocorreu um erro ao criar o alerta.');
    }
  }, [token, cidadeId, usuarioId, logout, refetch]);

  // Função para cancelar um alerta
  const handleCancelarAlerta = async (alerta) => { // Recebe o objeto alerta completo
    const confirmCancel = window.confirm('Tem certeza de que deseja cancelar este alerta?');
    if (!confirmCancel) return;

    try {
      if (!token) {
        throw new Error('Token de autenticação ausente.');
      }

      // Construir o payload completo, mantendo todas as propriedades existentes
      const payload = {
        ...alerta, // Inclui todas as propriedades existentes do alerta
        alertaSituacao: 3, // Atualiza 'alertaSituacao' para "Cancelada"
      };

      // Opcional: Remover propriedades que não devem ser enviadas ou que são read-only
      // Exemplo: se 'alertaId' não deve ser atualizado, remova-o do payload
      // const { alertaId, ...rest } = alerta;
      // const payload = { ...rest, alertaSituacao: 3 };

      console.log('Payload para cancelar alerta:', payload); // Para depuração

      const response = await fetch(`https://api.ads10.com.br/api/alerta/${alerta.alertaId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Erro ao cancelar alerta: ${errorData.message || response.statusText}`);
      }

      toast.success(`Alerta #${alerta.alertaId} cancelado com sucesso!`);
      refetch();
    } catch (error) {
      console.error(error);
      toast.error(error.message || 'Ocorreu um erro ao cancelar o alerta.');
    }
  };

  const handleSubmitServiceOrder = async (formData) => {
    const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
    const alertaId = formData.idAlerta;

    const updatedAlerta = {
      ...selectedAlertData,
      alertaSituacao: 2,
      alertaParamId: 2,
    };

    const newServiceOrder = {
      osPosteId: parseInt(formData.idPoste),
      osPrestadorId: parseInt(formData.prestador),
      osDataAbertura: getLocalDate(),
      osHoraAbertura: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }),
      osUsuarioAbertura: parseInt(localStorage.getItem('userId') || '0'),
      osObs: '',
      osSituacaoId: 2,
      osAlertaId: parseInt(alertaId),
      osCidadeId: parseInt(formData.cidade),
      osDataEncerramento: null,
      osHoraEncerramento: null,
      osUsuarioEncerramento: null,
    };

    try {
      const updateResponse = await fetch(`https://api.ads10.com.br/api/alerta/${alertaId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedAlerta),
      });

      if (!updateResponse.ok) {
        throw new Error('Erro ao atualizar o alerta.');
      }

      const createOSResponse = await fetch('https://api.ads10.com.br/api/os', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newServiceOrder),
      });

      if (!createOSResponse.ok) {
        throw new Error('Erro ao criar a ordem de serviço.');
      }

      const createdOS = await createOSResponse.json();
      refetch();

      toast.success(`Ordem de serviço #${createdOS.osId} criada com sucesso para o alerta ID: ${alertaId}`);
      handleCloseModal();
    } catch (error) {
      toast.error(`Erro: ${error.message}`);
    }
  };

  const headers = [
    { label: 'ID', key: 'alertaId' },
    { label: 'Poste ID', key: 'alertaPosteId' },
    { label: 'Endereço', key: 'endereco' }, // Adicionando o endereço
    { label: 'Motivo', key: 'alertaMotivo' },
    { label: 'Data de Geração', key: 'alertaDataGeracao' },
    { label: 'Hora de Geração', key: 'alertaHoraGeracao' },
    { label: 'Origem', key: 'alertaOrigem' },
    { label: 'Situação', key: 'alertaSituacaoNome' },
    { label: 'Data de Encerramento', key: 'osDataEncerramento' },
    { label: 'Hora de Encerramento', key: 'osHoraEncerramento' },
  ];

  const csvData = sortedAlertas.map(alerta => {
    const osCorrespondente = alerta.ordensServico ? alerta.ordensServico.find(os => os.osAlertaId === alerta.alertaId) : null;

    return {
      alertaId: alerta.alertaId,
      alertaPosteId: alerta.alertaPosteId,
      alertaMotivo: alerta.alertaMotivo,
      alertaDataGeracao: new Date(alerta.alertaDataGeracao).toLocaleDateString(),
      alertaHoraGeracao: alerta.alertaHoraGeracao,
      alertaOrigem: alerta.alertaOrigem,
      alertaSituacaoNome: situacoes[alerta.alertaSituacao] || 'Desconhecido',
      osDataEncerramento: osCorrespondente && osCorrespondente.osDataEncerramento
        ? new Date(osCorrespondente.osDataEncerramento).toLocaleDateString()
        : 'Não Encerrada',
      osHoraEncerramento: osCorrespondente && osCorrespondente.osHoraEncerramento
        ? osCorrespondente.osHoraEncerramento
        : 'Não Encerrada',
    };
  });

  if (isLoading || situacaoLoading) {
    return <LoadingOverlay />;
  }

  if (error || situacaoError) {
    return (
      <PageLayout title="Alertas">
        <div className={styles.errorContainer}>
          <p className={styles.errorMessage}>Erro: {error || situacaoError}</p>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout title="Lista de Alertas">
      <div className={styles.container}>
        <div className={styles.controlsTop}>
          <button className={styles.addButton} onClick={handleOpenAlertaModal}>
            <FaPlus /> Criar Novo Alerta
          </button>
          <CSVLink
            data={csvData}
            headers={headers}
            filename={"alertas-export.csv"}
            className={styles.exportButton}
            bom="true"
            separator={";"}
            enclosingCharacter={`"`}
          >
            Exportar para CSV
          </CSVLink>
        </div>

        <div className={styles.controls}>
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Pesquisar por ID, Motivo ou Poste..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <select
            className={`${styles.searchInput} ${styles.selectInput}`}
            value={statusFilter}
            onChange={handleStatusFilterChange}
          >
            <option value="">Filtrar por Situação...</option>
            {Object.values(situacoes).map((situacaoNome) => (
              <option key={situacaoNome} value={situacaoNome}>{situacaoNome}</option>
            ))}
          </select>

          <select
            className={`${styles.searchInput} ${styles.selectInput}`}
            value={internoExternoFilter}
            onChange={handleInternoExternoChange}
          >
            <option value="">Todos os Alertas</option>
            <option value="Interno">Interno</option>
            <option value="Externo">Externo</option>
          </select>

          <select
            className={`${styles.searchInput} ${styles.selectInput}`}
            value={tipoFilter}
            onChange={handleTipoFilterChange}
            disabled={internoExternoFilter === ''}
          >
            <option value="">Filtrar por Tipo de Alerta...</option>
            {tiposAlertaAtuais.map((tipo) => (
              <option key={tipo} value={tipo}>{tipo}</option>
            ))}
          </select>
        </div>

        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th onClick={() => requestSort('alertaId')}>
                  ID {sortConfig.key === 'alertaId' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('alertaPosteId')}>
                  Poste ID {sortConfig.key === 'alertaPosteId' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th>Endereço</th> {/* Nova coluna */}

                <th onClick={() => requestSort('alertaMotivo')}>
                  Motivo {sortConfig.key === 'alertaMotivo' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('alertaDataGeracao')}>
                  Data de Geração {sortConfig.key === 'alertaDataGeracao' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('alertaHoraGeracao')}>
                  Hora de Geração {sortConfig.key === 'alertaHoraGeracao' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('alertaOrigem')}>
                  Origem {sortConfig.key === 'alertaOrigem' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('alertaSituacao')}>
                  Situação {sortConfig.key === 'alertaSituacao' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('osDataEncerramento')}>
                  Data de Encerramento {sortConfig.key === 'osDataEncerramento' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('osHoraEncerramento')}>
                  Hora de Encerramento {sortConfig.key === 'osHoraEncerramento' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.length > 0 ? (
                currentPageData.map((alerta) => (
                  <tr key={alerta.alertaId}>
                    <td>{alerta.alertaId}</td>
                    <td>{alerta.alertaPosteId}</td>
                    <td>{getEndereco(alerta.alertaPosteId)}</td> {/* Endereço */}

                    <td>{alerta.alertaMotivo}</td>
                    <td>{new Date(alerta.alertaDataGeracao).toLocaleDateString()}</td>
                    <td>{alerta.alertaHoraGeracao}</td>
                    <td>{alerta.alertaOrigem}</td>
                    <td>{situacoes[alerta.alertaSituacao] || 'Desconhecido'}</td>
                    <td>
                      {(() => {
                        const osCorrespondente = alerta.ordensServico ? alerta.ordensServico.find(os => os.osAlertaId === alerta.alertaId) : null;
                        return osCorrespondente && osCorrespondente.osDataEncerramento
                          ? new Date(osCorrespondente.osDataEncerramento).toLocaleDateString()
                          : 'Não Encerrada';
                      })()}
                    </td>
                    <td>
                      {(() => {
                        const osCorrespondente = alerta.ordensServico ? alerta.ordensServico.find(os => os.osAlertaId === alerta.alertaId) : null;
                        return osCorrespondente && osCorrespondente.osHoraEncerramento
                          ? osCorrespondente.osHoraEncerramento
                          : 'Não Encerrada';
                      })()}
                    </td>
                    <td>
                      <div className={styles.actionButtons}>
                        <button
                          className={styles.actionButton}
                          onClick={() => handleOpenModal(alerta)}
                          title="Cadastrar Ordem de Serviço"
                          disabled={alerta.alertaSituacao > 1}
                        >
                          <FaPlus />
                        </button>
                        {situacoes[alerta.alertaSituacao] !== 'Cancelada' && situacoes[alerta.alertaSituacao] !== 'Finalizada' && (
                          <button
                            className={styles.cancelButton}
                            onClick={() => handleCancelarAlerta(alerta)}
                            title="Cancelar Alerta"
                          >
                            <FaTimes />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" className={styles.noData}>Nenhum alerta encontrado.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className={styles.paginationContainer}>
          <ReactPaginate
            previousLabel={"← Anterior"}
            nextLabel={"Próximo →"}
            breakLabel={"..."}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={styles.pagination}
            previousLinkClassName={styles.paginationLink}
            nextLinkClassName={styles.paginationLink}
            breakLinkClassName={styles.paginationLink}
            disabledClassName={styles.paginationDisabled}
            activeClassName={styles.paginationActive}
            forcePage={currentPage}
          />
        </div>

        {isModalOpen && (
          <ServiceOrderModal
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
            onSubmit={handleSubmitServiceOrder}
            alertData={selectedAlertData}
            postes={postes} // Passando os postes aqui
          />
        )}

        {isAlertaModalOpen && (
          <AlertaModal
            isOpen={isAlertaModalOpen}
            onRequestClose={handleCloseAlertaModal}
            onSave={handleSaveAlerta}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default Alertas;
