import React, { useEffect, useState, useMemo, useContext } from 'react';
import XLSX from 'xlsx-js-style'; // Para exportar com estilos
import { FaFileExport } from 'react-icons/fa';
import usePosteDataComAlertas from '../../hooks/usePosteDataComAlertas';
import styles from './Relatorios.module.css';
import { AuthContext } from '../../context/AuthContext';
import useGruposData from '../../hooks/useGruposData';
import useGroupPoste from '../../hooks/useGroupPoste';
import { toast } from 'react-toastify';

import { isValidGeolocation } from '../../utils/geolocation';

const RelatorioPostesGeolocalizacao = () => {
  const { postesDetalhados, isLoading, error } = usePosteDataComAlertas();
  const { cidadeId } = useContext(AuthContext); // Adicione isso no componente

  const [geoFilter, setGeoFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [filteredPostes, setFilteredPostes] = useState([]);
  const [geoCounts, setGeoCounts] = useState({
    geolocalizados: 0,
    naoGeolocalizados: 0,
    invalidos: 0,
  });

  const {
    grupos,
    isLoading: isGruposLoading,
    error: gruposError,
  } = useGruposData();

  const {
    associations,
    error: associationsError,
    fetchAssociations,
  } = useGroupPoste();

  useEffect(() => {
    fetchAssociations();
  }, [fetchAssociations]);

  const postesComGrupos = useMemo(() => {
    if (!postesDetalhados || !associations) return [];
    return postesDetalhados.map((poste) => {
      const gruposDoPoste = associations
        .filter((assoc) => assoc.posteId === String(poste.id))
        .map((assoc) => assoc.grupoId);

      return { ...poste, grupoIds: gruposDoPoste };
    });
  }, [postesDetalhados, associations]);

  useEffect(() => {
    let result = [...postesComGrupos];

    if (geoFilter === 'geolocalizados') {
      result = result.filter((p) => p.geoLocalizacao);
    } else if (geoFilter === 'nao-geolocalizados') {
      result = result.filter((p) => !p.geoLocalizacao);
    }

    if (statusFilter === 'ativo') {
      result = result.filter((p) => p.ativo === 'S');
    } else if (statusFilter === 'inativo') {
      result = result.filter((p) => p.ativo === 'N');
    }

    if (selectedGroups.length > 0) {
      result = result.filter((p) =>
        p.grupoIds.some((id) => selectedGroups.includes(id))
      );
    }

    setFilteredPostes(result);
  }, [geoFilter, statusFilter, selectedGroups, postesComGrupos]);

  useEffect(() => {
    let geolocalizados = 0;
    let naoGeolocalizados = 0;
    let invalidos = 0;

    filteredPostes.forEach((poste) => {
      const geo = poste.geoLocalizacao?.trim() || '';

      if (!geo || geo === '-, -') {
        naoGeolocalizados++;
        return;
      }

      const [lat, lng] = geo.split(',').map((s) => s.trim());
      if (!isValidGeolocation(lat, lng)) {
        invalidos++;
      } else {
        geolocalizados++;
      }
    });

    setGeoCounts({ geolocalizados, naoGeolocalizados, invalidos });
  }, [filteredPostes]);

  const renderGeoLocalizacao = (poste) => {
    const geo = poste.geoLocalizacao?.trim() || '';

    if (!geo || geo === '-, -') {
      return <span className={styles.naStyle}>N/A</span>;
    }

    const [latRaw, lngRaw] = geo.split(',').map((c) => c.trim());
    if (!isValidGeolocation(latRaw, lngRaw)) {
      return <span className={styles.invalidGeo}>{geo}</span>;
    }

    return geo;
  };

  const handleExportToExcel = () => {
    if (!cidadeId) {
      toast.error('ID da cidade não encontrado.');
      return;
    }

    // 1. Obter a data e hora atuais
    const now = new Date();
    const dataAtual = now.toLocaleDateString('pt-BR').replace(/\//g, '-'); // Formato DD-MM-AAAA
    const horaAtual = now.toLocaleTimeString('pt-BR', { hour12: false }).replace(/:/g, '-'); // Formato HH-MM-SS

    // 2. Nome do arquivo
    const fileName = `relatório_geolocalização_${cidadeId}_${dataAtual}_${horaAtual}.xlsx`;

    // 3. Converter os dados filtrados em um array de objetos
    const exportData = filteredPostes.map((poste) => ({
      'ID do Poste': poste.id,
      'Código do Poste': poste.codigoPoste, 
      'Endereço': poste.address + ', ' + poste.posteBairro || 'N/A',
      'Geo-localização': poste.geoLocalizacao || 'N/A',
      'Status': poste.ativo === 'S' ? 'Ativo' : 'Inativo',
      'Grupos':
        grupos
          .filter((grupo) => poste.grupoIds.includes(grupo.grupoId))
          .map((grupo) => grupo.grupoNome)
          .join(', ') || 'N/A',
    }));

    // 4. Gerar worksheet
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // 5. Iterar sobre as células para aplicar estilo
    const sheetRange = XLSX.utils.decode_range(worksheet['!ref'] || 'A1');
    for (let row = sheetRange.s.r; row <= sheetRange.e.r; row++) {
      for (let col = sheetRange.s.c; col <= sheetRange.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
        const cell = worksheet[cellAddress];
        if (!cell || !cell.v) continue;

        if (row === 0) {
          cell.s = {
            font: { bold: true },
            fill: { fgColor: { rgb: 'FFD700' } },
          };
        } else {
          if (col === 3) {
            const val = String(cell.v);
            if (val === 'N/A') {
              cell.s = {
                fill: { fgColor: { rgb: 'FFFFA500' } },
                font: { bold: true },
              };
            } else {
              const [lat, lng] = val.split(',').map((s) => s.trim());
              if (!isValidGeolocation(lat, lng)) {
                cell.s = {
                  fill: { fgColor: { rgb: 'FFFF0000' } },
                  font: { color: { rgb: 'FFFFFFFF' }, bold: true },
                };
              }
            }
          }
        }
      }
    }

    // 6. Criar workbook e salvar
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Postes Geolocalizados');
    XLSX.writeFile(workbook, fileName);
  };


  const handleGroupChange = (e) => {
    const options = e.target.options;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    setSelectedGroups(selected);
  };

  const handleClearFilters = () => {
    setGeoFilter('all');
    setStatusFilter('all');
    setSelectedGroups([]);
  };

  return (
    <div className={styles.container}>
      <div className={styles.controlsTop}>
        <div className={styles.filterGroup}>
          {/* Filtro de geolocalização */}
          <div className={styles.controlGroup}>
            <label htmlFor="geoFilter">Filtrar por Geolocalização:</label>
            <select
              id="geoFilter"
              value={geoFilter}
              onChange={(e) => setGeoFilter(e.target.value)}
              className={styles.dropdown}
            >
              <option value="all">Exibir Todos</option>
              <option value="geolocalizados">Somente Geolocalizados</option>
              <option value="nao-geolocalizados">Somente Não Geolocalizados</option>
            </select>
          </div>

          {/* Filtro de status */}
          <div className={styles.controlGroup}>
            <label htmlFor="statusFilter">Filtrar por Status:</label>
            <select
              id="statusFilter"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className={styles.dropdown}
            >
              <option value="all">Todos</option>
              <option value="ativo">Ativos</option>
              <option value="inativo">Inativos</option>
            </select>
          </div>

          {/* Filtro de grupos */}
          {!isGruposLoading && !gruposError && grupos && (
            <div className={styles.controlGroup}>
              <label htmlFor="groupFilter">Filtrar por Grupo:</label>
              <select
                id="groupFilter"
                multiple
                value={selectedGroups}
                onChange={handleGroupChange}
                className={styles.dropdown}
              >
                {grupos.map((grupo) => (
                  <option key={grupo.grupoId} value={grupo.grupoId}>
                    {grupo.grupoNome}
                  </option>
                ))}
              </select>
              <small className={styles.selectInfo}>
                Pressione Ctrl (Windows) ou Command (Mac) para selecionar múltiplos grupos.
              </small>
            </div>
          )}
        </div>

        {/* Botões de ação */}
        <div className={styles.actionsContainer}>
          <button onClick={handleExportToExcel} className={styles.exportButton}>
            <FaFileExport /> Exportar para Excel
          </button>
          <button onClick={handleClearFilters} className={styles.clearFiltersButton}>
            Limpar Filtros
          </button>
        </div>
      </div>

      {/* Mensagens de Status */}
      {isLoading && <p className={styles.infoMessage}>Carregando dados...</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}
      {gruposError && <p className={styles.errorMessage}>{gruposError}</p>}
      {associationsError && <p className={styles.errorMessage}>{associationsError}</p>}

      {/* Resumo */}
      <div className={styles.geoSummary}>
        <p>
          <strong>GEOLOCALIZADOS:</strong> {geoCounts.geolocalizados}
        </p>
        <p>
          <strong>NÃO GEOLOCALIZADOS:</strong> {geoCounts.naoGeolocalizados}
        </p>
        <p>
          <strong>INVÁLIDOS:</strong> {geoCounts.invalidos}
        </p>
      </div>

      {/* Tabela */}
      {!isLoading && filteredPostes.length > 0 && (
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Código</th>
                <th>Endereço</th>
                <th>Geo-localização</th>
                <th>Status</th>
                <th>Grupos</th>
              </tr>
            </thead>
            <tbody>
              {filteredPostes.map((poste) => (
                <tr key={poste.id}>
                  <td>{poste.id}</td>
                  <td>{poste.codigoPoste}</td>
                  <td>{poste.address + ', ' + poste.posteBairro || 'N/A'}</td>
                  <td>{renderGeoLocalizacao(poste)}</td>
                  <td>{poste.ativo === 'S' ? 'Ativo' : 'Inativo'}</td>
                  <td>
                    {grupos
                      .filter((grupo) => poste.grupoIds.includes(grupo.grupoId))
                      .map((grupo) => grupo.grupoNome)
                      .join(', ') || 'N/A'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {!isLoading && filteredPostes.length === 0 && (
        <p className={styles.infoMessage}>
          Nenhum dado encontrado para os filtros selecionados.
        </p>
      )}
    </div>
  );
};

export default RelatorioPostesGeolocalizacao;
