// src/utils/geolocation.js

/**
 * Função para validar as coordenadas geográficas.
 * Aceita números ou strings que podem ser convertidos em números.
 * Considera inválidas coordenadas (0,0) e entradas malformadas como ( , ).
 * @param {number|string} lat - Latitude.
 * @param {number|string} lng - Longitude.
 * @returns {boolean} - Retorna true se as coordenadas forem válidas e não forem (0,0), false caso contrário.
 */
export const isValidGeolocation = (lat, lng) => {
  // Verifica se ambos os parâmetros foram fornecidos
  if (lat === undefined || lng === undefined || lat === null || lng === null) {
    console.warn('Geolocalização inválida: Parâmetros ausentes.', { lat, lng });
    return false;
  }

  /**
   * Função auxiliar para converter e validar cada coordenada.
   * @param {number|string} coord - Coordenada (latitude ou longitude).
   * @param {string} type - Tipo da coordenada ('lat' ou 'lng').
   * @returns {number|null} - Retorna o número convertido se válido, ou null se inválido.
   */
  const convertAndValidate = (coord, type) => {
    // Se a coordenada for uma string, tenta converter para número após remover espaços
    if (typeof coord === 'string') {
      coord = coord.trim();
      // Verifica se a string está vazia após o trim
      if (coord === '') {
        console.warn(`Geolocalização inválida: ${type} está vazio após trim.`);
        return null;
      }
      // Tenta converter para número
      coord = Number(coord);
    }

    // Verifica se a coordenada é um número
    if (typeof coord !== 'number') {
      console.warn(`Geolocalização inválida: ${type} não é um número.`, { coord });
      return null;
    }

    // Verifica se a coordenada é um número finito e não NaN
    if (!isFinite(coord) || isNaN(coord)) {
      console.warn(`Geolocalização inválida: ${type} não é finito ou é NaN.`, { coord });
      return null;
    }

    // Verifica os intervalos de latitude e longitude
    if (type === 'lat') {
      if (coord < -90 || coord > 90) {
        console.warn(`Geolocalização inválida: Latitude ${coord} fora do intervalo permitido (-90 a 90).`);
        return null;
      }
    } else if (type === 'lng') {
      if (coord < -180 || coord > 180) {
        console.warn(`Geolocalização inválida: Longitude ${coord} fora do intervalo permitido (-180 a 180).`);
        return null;
      }
    }

    return coord;
  };

  // Valida latitude e longitude separadamente
  const validLat = convertAndValidate(lat, 'lat');
  const validLng = convertAndValidate(lng, 'lng');

  // Verifica se ambos são válidos
  if (validLat === null || validLng === null) {
    return false;
  }

  // Verifica se as coordenadas não são (0,0)
  if (validLat === 0 && validLng === 0) {
    console.warn('Geolocalização inválida: Coordenadas são (0,0).');
    return false;
  }

  return true;
};
