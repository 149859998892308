// src/hooks/useDeviceData.js

import { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

/**
 * Função para verificar se uma data é mais antiga que o intervalo especificado em horas.
 * @param {Date | null} lastUpdate - A última data de atualização do dispositivo.
 * @param {number} hours - O número de horas para verificar.
 * @returns {boolean} - Retorna true se a última atualização foi há mais de 'hours' horas.
 */
const isOffline = (lastUpdate, hours = 3) => {
  if (!lastUpdate) return true; // Se não houver data, considera offline

  const now = new Date();
  const diffInMs = now - lastUpdate;
  const diffInHours = diffInMs / (1000 * 60 * 60);

  return diffInHours > hours;
};

/**
 * Função para determinar o status da tensão elétrica de um dispositivo.
 * @param {number} voltage - Tensão elétrica do dispositivo em volts.
 * @returns {string} - Retorna 'overVoltage', 'underVoltage' ou 'normal'.
 */
const determineVoltageStatus = (voltage) => {
  const baseVoltage = 220; // Valor base em volts
  const threshold = 0.10; // 10%

  const upperLimit = baseVoltage * (1 + threshold); // 242V
  const lowerLimit = baseVoltage * (1 - threshold); // 198V

  if (voltage > upperLimit) {
    return 'overVoltage';
  } else if (voltage < lowerLimit) {
    return 'underVoltage';
  } else {
    return 'normal';
  }
};

/**
 * Hook personalizado para buscar e processar dados dos dispositivos.
 * @returns {object} - Retorna os dispositivos, estado de carregamento, erro e função para refetch.
 */
const useDeviceData = () => {
  const { token, cidadeId, logout } = useContext(AuthContext);
  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Função para buscar os dados dos dispositivos da API.
   */
  const fetchDevices = useCallback(async () => {
    // Verifica se o `token` e `cidadeId` estão disponíveis
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Configura os headers da requisição
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        cidadeId: cidadeId,
      };

      // Realiza a requisição à API de dispositivos
      const response = await fetch('https://api.ads10.com.br/api/device/lastall', {
        method: 'GET',
        headers,
      });

      // Trata erros de autenticação
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        throw new Error('Erro ao buscar dados dos dispositivos');
      }

      const data = await response.json();

      // Processa os dispositivos para determinar se estão offline e verificar a tensão
      const processedDevices = data
        .filter(
          (device) =>
            device.fotocelulaId !== null && // Filtra dispositivos que possuem fotocelulaId
            device.deviceLat !== null &&
            device.deviceLng !== null &&
            typeof device.deviceLat === 'number' &&
            typeof device.deviceLng === 'number'
        )
        .map((device) => {
          // Converte o campo 'Data' para um objeto Date
          const lastUpdate = device.Data ? new Date(device.Data.replace(' ', 'T')) : null;

          // Determina se o dispositivo está offline (3 horas)
          const offline = isOffline(lastUpdate, 3); // 3 horas

          // Determina o status da tensão elétrica
          const voltageStatus = determineVoltageStatus(device.deviceTensaoRede);

          return {
            ...device,
            lastUpdate,
            offline,
            voltageStatus,
            // Inclui fotocelulaId para uso posterior no cruzamento de dados
            fotocelulaId: device.fotocelulaId,
          };
        });

      setDevices(processedDevices);
      console.log('Dados dos dispositivos:', processedDevices);
    } catch (error) {
      console.error('Erro ao buscar dados dos dispositivos:', error);
      setError(error.message);
      toast.error(`Erro ao buscar dados dos dispositivos: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId, logout]);

  useEffect(() => {
    fetchDevices();

    // Recarrega os dados a cada 3 minutos
    const intervalId = setInterval(() => {
      fetchDevices();
    }, 180000); // 180.000 ms = 3 minutos

    return () => clearInterval(intervalId);
  }, [fetchDevices]);

  return { devices, isLoading, error, refetch: fetchDevices };
};

export default useDeviceData;
