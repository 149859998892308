// src/pages/OrdensServicos/OrdensServicos.jsx

import React, { useState, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import { FaSortUp, FaSortDown, FaEdit, FaEye, FaComment } from 'react-icons/fa'; // Adicionado FaComment
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';

import PageLayout from '../../components/PageLayout/PageLayout';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import EditServiceOrderModal from './EditServiceOrderModal';

import usePrestadores from '../../hooks/usePrestadores';
import useAlertaSituacao from '../../hooks/useAlertaSituacao';
import useAlertas from '../../hooks/useAlertas'; // Importando o novo hook
import useOrdensServicos from '../../hooks/useOrdensServicos'; // Importando o novo hook
import usePostes from './usePostes'; // Importando o hook de Postes

import styles from './OrdensServicos.module.css';

const OrdensServicos = () => {
  // Estados para o modal
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [isEditingObservation, setIsEditingObservation] = useState(false); // Novo estado para editar observação

  // Estados para filtros e ordenação
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [prestadorFilter, setPrestadorFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'osDataAbertura', direction: 'descending' });
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  // Hooks de dados
  const { ordensServicos, isLoading: isOSLoading, error: osError, refetch: refetchOS } = useOrdensServicos();
  const { alertas, isLoading: isAlertasLoading, error: alertasError, refetch: refetchAlertas } = useAlertas();
  const { prestadores, isLoading: prestadoresLoading, error: prestadoresError } = usePrestadores();
  const { situacoes, isLoading: situacoesLoading, error: situacoesError } = useAlertaSituacao();
  const { postes, isLoading: isPostesLoading, error: postesError } = usePostes(); // Utilizando o hook de Postes

  // Logs para depuração
  console.log('Ordens de Serviço:', ordensServicos);
  console.log('Alertas:', alertas);
  console.log('Postes:', postes); // Log dos postes

  // Função para obter o nome da situação com base no ID
  const getSituacaoNome = (situacaoId) => {
    return situacoes[situacaoId] || 'Desconhecido';
  };

  // Função para obter o nome do prestador com base no ID
  const getPrestadorNome = (prestadorId) => {
    const prestador = prestadores.find(p => p.pessoaId === prestadorId);
    return prestador ? prestador.pessoaNome : 'Desconhecido';
  };

  // Função para obter o motivo do alerta com base no ID do alerta
  const getMotivoFromAlerta = (alertaId) => {
    const alerta = alertas.find(a => a.alertaId === alertaId);
    return alerta ? alerta.alertaMotivo : 'Motivo não encontrado';
  };

  // Função para obter o endereço completo do poste
  const getPosteEndereco = (posteId) => {
    const poste = postes.find(p => p.id === posteId);
    if (poste) {
      const address = poste.address || '';
      const bairro = poste.posteBairro || '';
      return `${address}, ${bairro}`;
    }
    return 'Endereço não disponível';
  };

  // Função para lidar com o clique nos botões de ação (Editar ou Visualizar)
  const handleActionClick = (order, viewOnly = false) => {
    setIsViewOnly(viewOnly);
    setIsEditingObservation(false); // Garantir que não está editando observação
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  // Função para lidar com o clique no botão de editar observação
  const handleEditObservation = (order) => {
    setSelectedOrder(order);
    setIsViewOnly(false);
    setIsEditingObservation(true);
    setIsModalOpen(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
    setIsViewOnly(false);
    setIsEditingObservation(false); // Resetar o estado de edição de observação
  };

  // Função para salvar as alterações na Ordem de Serviço
  const handleSaveOrder = async (updatedOrder) => {
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      const cidadeId = localStorage.getItem('cidadeId') || sessionStorage.getItem('cidadeId');

      if (!token || !cidadeId) {
        throw new Error('Token de autenticação ou ID da cidade não encontrado.');
      }

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'cidadeId': cidadeId,
      };

      // Atualizar Ordem de Serviço (OS)
      const responseOS = await fetch(`https://api.ads10.com.br/api/OS/${updatedOrder.osId}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(updatedOrder),
      });

      console.log('Resposta da API para OS:', responseOS);

      if (!responseOS.ok) {
        throw new Error('Erro ao atualizar a Ordem de Serviço.');
      }

      // Atualizar Alerta correspondente, se houver
      if (updatedOrder.osAlertaId && updatedOrder.osAlertaId !== "0") { // Verificar se existe um alerta válido
        const alertaCorrespondente = alertas.find(a => a.alertaId === updatedOrder.osAlertaId);
        if (alertaCorrespondente) {
          const updatedAlerta = {
            ...alertaCorrespondente,
            alertaSituacao: updatedOrder.osSituacaoId,
          };

          const responseAlerta = await fetch(`https://api.ads10.com.br/api/alerta/${updatedAlerta.alertaId}`, {
            method: 'PUT',
            headers,
            body: JSON.stringify(updatedAlerta),
          });

          console.log('Resposta da API para Alerta:', responseAlerta);

          if (!responseAlerta.ok) {
            throw new Error('Erro ao atualizar o Alerta.');
          }
        }
      }

      // Recarregar os dados após atualização
      await Promise.all([refetchOS(), refetchAlertas()]);

      setIsModalOpen(false);
      setSelectedOrder(null);
      setIsViewOnly(false);
      setIsEditingObservation(false);
      toast.success('Ordem de Serviço atualizada com sucesso.');
    } catch (error) {
      console.error('Erro ao salvar a Ordem de Serviço:', error);
      toast.error(`Erro ao salvar a Ordem de Serviço: ${error.message}`);
    }
  };

  // Funções para lidar com os filtros de pesquisa
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(0);
  };

  const handlePrestadorFilterChange = (e) => {
    setPrestadorFilter(e.target.value);
    setCurrentPage(0);
  };

  // Função para solicitar a ordenação
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Filtrar os dados com base na pesquisa e nos filtros selecionados
  const filteredData = useMemo(() => {
    if (!ordensServicos) return [];

    const lowercasedSearchTerm = searchTerm.toLowerCase();

    return ordensServicos.filter(order => {
      const osIdStr = order.osId?.toString() || '';
      const osAlertaIdStr = order.osAlertaId?.toString() || '';
      const osPosteIdStr = order.osPosteId?.toString() || '';

      const matchesSearchTerm =
        osIdStr.includes(lowercasedSearchTerm) ||
        osAlertaIdStr.includes(lowercasedSearchTerm) ||
        osPosteIdStr.includes(lowercasedSearchTerm);

      const matchesStatusFilter = statusFilter
        ? (order.osSituacaoId?.toString() || '') === statusFilter
        : true;

      const matchesPrestadorFilter = prestadorFilter
        ? (order.osPrestadorId?.toString() || '') === prestadorFilter
        : true;

      return (
        matchesSearchTerm &&
        matchesStatusFilter &&
        matchesPrestadorFilter
      );
    });
  }, [ordensServicos, searchTerm, statusFilter, prestadorFilter]);

  // Ordenar os dados filtrados
  const sortedServiceOrders = useMemo(() => {
    const sortedOrders = [...filteredData];
    if (sortConfig.key) {
      sortedOrders.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Se a chave for data, converter para objeto Date
        if (sortConfig.key.toLowerCase().includes('data')) {
          aValue = aValue ? new Date(aValue) : new Date(0);
          bValue = bValue ? new Date(bValue) : new Date(0);
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedOrders;
  }, [filteredData, sortConfig]);

  // Calcular o número total de páginas
  const pageCount = Math.ceil(sortedServiceOrders.length / itemsPerPage);

  // Obter os dados da página atual
  const currentPageData = useMemo(() => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return sortedServiceOrders.slice(start, end);
  }, [sortedServiceOrders, currentPage, itemsPerPage]);

  // Função para lidar com a mudança de página
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  // Definir os headers para exportação CSV (Atualizado para remover Cidade e adicionar Endereço)
  const headers = [
    { label: 'ID', key: 'osId' },
    { label: 'ID do Alerta', key: 'osAlertaId' },
    { label: 'Poste ID', key: 'osPosteId' },
    { label: 'Endereço', key: 'endereco' }, // Novo campo para Endereço
    { label: 'Data de Abertura', key: 'osDataAbertura' },
    { label: 'Hora de Abertura', key: 'osHoraAbertura' },
    { label: 'Prestador', key: 'prestadorNome' },
    { label: 'Status', key: 'statusNome' },
    { label: 'Motivo', key: 'motivo' },
    { label: 'Data de Encerramento', key: 'osDataEncerramento' },
    { label: 'Hora de Encerramento', key: 'osHoraEncerramento' },
    { label: 'Observação (Limitada a 100 caracteres)', key: 'osObs' },
  ];

  // Preparar os dados para exportação CSV (Incluindo Endereço e Limitação da Observação)
  const csvData = sortedServiceOrders.map((order) => {
    const alertaCorrespondente = alertas.find(a => a.alertaId === order.osAlertaId);
    const endereco = getPosteEndereco(order.osPosteId);
    const observacaoTruncada = order.osObs
      ? order.osObs.length > 100
        ? `${order.osObs.substring(0, 100)}...`
        : order.osObs
      : 'Nenhuma Observação';

    return {
      osId: order.osId || 'Não Disponível',
      osAlertaId: order.osAlertaId || 'Não Disponível',
      osPosteId: order.osPosteId || 'Não Disponível',
      endereco: endereco || 'Não Disponível',
      osDataAbertura: order.osDataAbertura
        ? new Date(order.osDataAbertura).toLocaleDateString()
        : 'Não Disponível',
      osHoraAbertura: order.osHoraAbertura || 'Não Disponível',
      prestadorNome: getPrestadorNome(order.osPrestadorId),
      statusNome: getSituacaoNome(order.osSituacaoId),
      motivo: alertaCorrespondente ? alertaCorrespondente.alertaMotivo : 'Motivo não encontrado',
      osDataEncerramento: order.osDataEncerramento
        ? new Date(order.osDataEncerramento).toLocaleDateString()
        : 'Não Encerrada',
      osHoraEncerramento: order.osHoraEncerramento || 'Não Encerrada',
      osObs: observacaoTruncada, // Observação truncada
    };
  });

  // Renderizar o componente de carregamento se os dados estiverem sendo carregados
  if (isOSLoading || isAlertasLoading || prestadoresLoading || situacoesLoading || isPostesLoading) {
    return <LoadingOverlay />;
  }

  // Renderizar mensagens de erro se houver problemas ao carregar os dados
  if (osError) {
    return <div className={styles.error}>Erro ao carregar as Ordens de Serviço: {osError}</div>;
  }

  if (alertasError) {
    return <div className={styles.error}>Erro ao carregar os Alertas: {alertasError}</div>;
  }

  if (prestadoresError) {
    return <div className={styles.error}>Erro ao carregar os Prestadores: {prestadoresError}</div>;
  }

  if (situacoesError) {
    return <div className={styles.error}>Erro ao carregar as Situações: {situacoesError}</div>;
  }

  if (postesError) {
    return <div className={styles.error}>Erro ao carregar os Postes: {postesError}</div>;
  }

  return (
    <PageLayout title="Lista de Ordens de Serviço">
      <div className={styles.container}>
        {/* Seção de Controle Superior: Botão de Exportação */}
        <div className={styles.controlsTop}>
          <CSVLink
            data={csvData}
            headers={headers}
            filename={"ordens-servico-export.csv"}
            className={styles.exportButton}
            bom="true"
            separator={";"}
            enclosingCharacter={`"`}
          >
            Exportar para CSV
          </CSVLink>
        </div>

        {/* Seção de Filtros e Pesquisa */}
        <div className={styles.controls}>
          {/* Campo de Pesquisa Geral */}
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Pesquisar por ID, Alerta ou Poste..."
            value={searchTerm}
            onChange={handleSearchChange}
          />

          {/* Filtros */}
          <select
            className={`${styles.searchInput} ${styles.selectInput}`}
            value={statusFilter}
            onChange={handleStatusFilterChange}
          >
            <option value="">Filtrar por Situação...</option>
            {Object.entries(situacoes).map(([id, nome]) => (
              <option key={id} value={id}>
                {nome}
              </option>
            ))}
          </select>

          <select
            className={`${styles.searchInput} ${styles.selectInput}`}
            value={prestadorFilter}
            onChange={handlePrestadorFilterChange}
          >
            <option value="">Filtrar por Prestador...</option>
            {prestadores.map((prestador) => (
              <option key={prestador.pessoaId} value={prestador.pessoaId}>
                {prestador.pessoaNome}
              </option>
            ))}
          </select>
        </div>

        {/* Tabela de Ordens de Serviço */}
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th onClick={() => requestSort('osId')}>
                  ID {sortConfig.key === 'osId' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('osAlertaId')}>
                  ID do Alerta {sortConfig.key === 'osAlertaId' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('osPosteId')}>
                  Poste {sortConfig.key === 'osPosteId' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th>Endereço</th> {/* Nova coluna para Endereço */}
                {/* Removida a coluna Cidade */}
                <th onClick={() => requestSort('osDataAbertura')}>
                  Data de Abertura {sortConfig.key === 'osDataAbertura' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('osHoraAbertura')}>
                  Hora de Abertura {sortConfig.key === 'osHoraAbertura' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('osPrestadorId')}>
                  Prestador {sortConfig.key === 'osPrestadorId' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('osSituacaoId')}>
                  Status {sortConfig.key === 'osSituacaoId' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('osDataEncerramento')}>
                  Data de Encerramento {sortConfig.key === 'osDataEncerramento' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('osHoraEncerramento')}>
                  Hora de Encerramento {sortConfig.key === 'osHoraEncerramento' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th>Motivo</th>
                <th>Observação (Limitada a 100 caracteres)</th> {/* Atualizado */}
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.length > 0 ? (
                currentPageData.map((order) => (
                  <tr key={order.osId || order.osAlertaId}>
                    <td>{order.osId || 'Não Disponível'}</td>
                    <td>{order.osAlertaId || 'Não Disponível'}</td>
                    <td>{order.osPosteId || 'Não Disponível'}</td>
                    <td>{getPosteEndereco(order.osPosteId)}</td> {/* Exibe o Endereço */}
                    <td>
                      {order.osDataAbertura
                        ? new Date(order.osDataAbertura).toLocaleDateString()
                        : 'Não Disponível'}
                    </td>
                    <td>{order.osHoraAbertura || 'Não Disponível'}</td>
                    <td>{getPrestadorNome(order.osPrestadorId)}</td>
                    <td>{getSituacaoNome(order.osSituacaoId)}</td>
                    <td>
                      {order.osDataEncerramento
                        ? new Date(order.osDataEncerramento).toLocaleDateString()
                        : 'Não Encerrada'}
                    </td>
                    <td>{order.osHoraEncerramento || 'Não Encerrada'}</td>
                    <td>{getMotivoFromAlerta(order.osAlertaId)}</td>
                    <td>
                      {order.osObs ? (
                        order.osObs.length > 100 ? (
                          <span title={order.osObs}>
                            {order.osObs.substring(0, 100)}...
                          </span>
                        ) : (
                          order.osObs
                        )
                      ) : (
                        'Nenhuma Observação'
                      )}
                    </td>
                    <td>
                      {/* Botões de Ação */}
                      {Number(order.osSituacaoId) === 3 || Number(order.osSituacaoId) === 4 ? (
                        // Botão de Visualizar com ícone de olho
                        <button
                          className={styles.actionButton}
                          onClick={() => handleActionClick(order, true)} // Passa true para indicar modo de visualização
                          title="Visualizar Ordem de Serviço"
                          aria-label="Visualizar Ordem de Serviço"
                        >
                          <FaEye />
                        </button>
                      ) : (
                        <>
                          {/* Botão de Editar com ícone de lápis */}
                          <button
                            className={styles.actionButton}
                            onClick={() => handleActionClick(order, false)}
                            title="Editar Ordem de Serviço"
                            aria-label="Editar Ordem de Serviço"
                          >
                            <FaEdit />
                          </button>
                        </>
                      )}

                      {/* Botão de Editar Observação com ícone de comentário - Sempre visível */}
                      <button
                        className={styles.commentButton} // Aplicar a classe azul
                        onClick={() => handleEditObservation(order)}
                        title="Editar Observação"
                        aria-label="Editar Observação"
                      >
                        <FaComment />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="14" className={styles.noData}>Nenhuma ordem de serviço encontrada.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Component de Paginação */}
        <div className={styles.paginationContainer}>
          <ReactPaginate
            previousLabel={"← Anterior"}
            nextLabel={"Próximo →"}
            breakLabel={"..."}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={styles.pagination}
            previousLinkClassName={styles.paginationLink}
            nextLinkClassName={styles.paginationLink}
            breakLinkClassName={styles.paginationLink}
            disabledClassName={styles.paginationDisabled}
            activeClassName={styles.paginationActive}
            forcePage={currentPage}
          />
        </div>

        {/* Modal de Edição de Ordem de Serviço */}
        {selectedOrder && (
          <EditServiceOrderModal
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
            order={selectedOrder}
            alerta={alertas.find(a => a.alertaId === selectedOrder.osAlertaId)} // Passando o alerta correspondente
            onSave={handleSaveOrder}
            isViewOnly={isViewOnly}
            isEditingObservation={isEditingObservation} // Passar o novo estado
          />
        )}
      </div>
    </PageLayout>
  );
};

export default OrdensServicos;
