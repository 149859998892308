// src/pages/GarantiasProdutos/GarantiasProdutos.jsx

import React, { useState, useMemo } from 'react';
import { FaPlus, FaEdit, FaTrash, FaSortUp, FaSortDown } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify'; // Import do toast
import 'react-toastify/dist/ReactToastify.css'; // Import do CSS
import PageLayout from '../../components/PageLayout/PageLayout';
import GarantiasProdutosModal from './GarantiasProdutosModal'; // Importe o modal corretamente
import styles from './GarantiasProdutos.module.css'; // CSS Module

const GarantiasProdutos = () => {
  const [lotes, setLotes] = useState([
    {
      id: 1,
      grupo: 'Eletrônicos',
      componente: 'Smartphone',
      marca: 'Marca X',
      modelo: 'Modelo Y',
      numeroSerie: 'SN123456789',
      dataCriacao: '2024-12-01T14:30',
      dataCompra: '2024-11-20',
      descricao: 'Lote de smartphones modelo X.',
      quantidade: 50,
      garantiaMeses: 12,
    },
    // Adicione outros lotes para simular dados
  ]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentLote, setCurrentLote] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState('');

  const calculateTimeToExpire = (garantiaMeses, dataCompra) => {
    const compraDate = new Date(dataCompra);
    const expiryDate = new Date(compraDate.setMonth(compraDate.getMonth() + garantiaMeses));
    const today = new Date();
    const diffTime = Math.max(expiryDate - today, 0);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedLotes = useMemo(() => {
    const sorted = [...lotes];
    if (sortConfig.key) {
      sorted.sort((a, b) => {
        // Para datas, converta para timestamps para comparação
        if (sortConfig.key === 'dataCriacao' || sortConfig.key === 'dataCompra') {
          return sortConfig.direction === 'ascending'
            ? new Date(a[sortConfig.key]) - new Date(b[sortConfig.key])
            : new Date(b[sortConfig.key]) - new Date(a[sortConfig.key]);
        }

        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  }, [lotes, sortConfig]);

  const filteredLotes = useMemo(() => {
    return sortedLotes.filter((lote) =>
      lote.grupo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lote.componente.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lote.descricao.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [sortedLotes, searchTerm]);

  const pageCount = Math.ceil(filteredLotes.length / itemsPerPage);

  const currentPageData = useMemo(() => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return filteredLotes.slice(start, end);
  }, [filteredLotes, currentPage, itemsPerPage]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  // Função para abrir o modal de edição
  const handleEditLote = (lote) => {
    setCurrentLote(lote);
    setModalIsOpen(true);
  };

  // Função para abrir o modal de criação
  const handleAddNew = () => {
    setCurrentLote(null);
    setModalIsOpen(true);
  };

  // Função para salvar (criar ou atualizar) um lote
  const handleSaveLote = (lote) => {
    if (lote.id) {
      // Atualizar lote existente
      setLotes((prevLotes) =>
        prevLotes.map((item) => (item.id === lote.id ? lote : item))
      );
      toast.success('Lote atualizado com sucesso!');
    } else {
      // Criar novo lote
      const newLote = { ...lote, id: Date.now() };
      setLotes((prevLotes) => [...prevLotes, newLote]);
      toast.success('Novo lote adicionado com sucesso!');
    }
    setModalIsOpen(false);
  };

  // Função para deletar um lote
  const handleDeleteLote = (id) => {
    const confirmDelete = window.confirm('Tem certeza que deseja deletar este lote?');
    if (!confirmDelete) return;

    setLotes((prevLotes) => prevLotes.filter((lote) => lote.id !== id));
    toast.success('Lote deletado com sucesso!');
  };

  return (
    <PageLayout title="Cadastro de Garantias de Produtos">
      <div className={styles.container}>
        <div className={styles.controlsTop}>
          <button className={styles.addButton} onClick={handleAddNew}>
            <FaPlus /> Adicionar Novo Lote
          </button>
          {/* Você pode adicionar um botão de exportação aqui, se necessário */}
        </div>
        <div className={styles.controls}>
          <div className={styles.searchContainer}>
            {/* Campo de Busca */}
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Pesquisar por Grupo, Componente ou Descrição..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th onClick={() => requestSort('grupo')}>
                  Grupo {sortConfig.key === 'grupo' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('componente')}>
                  Componente {sortConfig.key === 'componente' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('marca')}>
                  Marca {sortConfig.key === 'marca' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('modelo')}>
                  Modelo {sortConfig.key === 'modelo' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('numeroSerie')}>
                  Nº de Série {sortConfig.key === 'numeroSerie' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('dataCriacao')}>
                  Data Criação {sortConfig.key === 'dataCriacao' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('dataCompra')}>
                  Data Compra {sortConfig.key === 'dataCompra' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th>Descrição</th>
                <th onClick={() => requestSort('quantidade')}>
                  Quantidade {sortConfig.key === 'quantidade' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('garantiaMeses')}>
                  Garantia (Meses) {sortConfig.key === 'garantiaMeses' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th>Tempo Restante</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.length > 0 ? (
                currentPageData.map((lote) => {
                  const diasRestantes = calculateTimeToExpire(lote.garantiaMeses, lote.dataCompra);
                  const rowStyle = {
                    backgroundColor:
                      diasRestantes <= 30 ? '#f8d7da' :
                      diasRestantes <= 60 ? '#fff3cd' :
                      'inherit',
                    color: diasRestantes <= 30 ? '#721c24' :
                           diasRestantes <= 60 ? '#856404' :
                           'inherit',
                  };
                  return (
                    <tr key={lote.id} style={rowStyle}>
                      <td>{lote.grupo}</td>
                      <td>{lote.componente}</td>
                      <td>{lote.marca}</td>
                      <td>{lote.modelo}</td>
                      <td>{lote.numeroSerie}</td>
                      <td>{new Date(lote.dataCriacao).toLocaleString()}</td>
                      <td>{new Date(lote.dataCompra).toLocaleDateString()}</td>
                      <td>{lote.descricao}</td>
                      <td>{lote.quantidade}</td>
                      <td>{lote.garantiaMeses}</td>
                      <td>{diasRestantes} dias</td>
                      <td>
                        <div className={styles.actionsContainer}>
                          <button className={styles.actionButton} onClick={() => handleEditLote(lote)} title="Editar">
                            <FaEdit />
                          </button>
                          <button
                            className={`${styles.actionButton} ${styles.deleteButton}`}
                            onClick={() => handleDeleteLote(lote.id)}
                            title="Deletar"
                          >
                            <FaTrash />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="12" className={styles.noData}>
                    Nenhum lote encontrado.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={styles.paginationContainer}>
          <ReactPaginate
            previousLabel={'← Anterior'}
            nextLabel={'Próximo →'}
            breakLabel={'...'}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={styles.pagination}
            previousLinkClassName={styles.paginationLink}
            nextLinkClassName={styles.paginationLink}
            breakLinkClassName={styles.paginationLink}
            disabledClassName={styles.paginationDisabled}
            activeClassName={styles.paginationActive}
            forcePage={currentPage}
          />
        </div>
        {modalIsOpen && (
          <GarantiasProdutosModal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            onSave={handleSaveLote}
            initialData={currentLote}
          />
        )}
        {/* Se estiver adicionando o ToastContainer aqui */}
        {/* <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        /> */}
      </div>
    </PageLayout>
  );
};

export default GarantiasProdutos;
