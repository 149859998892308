// src/components/AlertasRelatorio/AlertasRelatorio.jsx

import React, { useState, useEffect, useMemo } from 'react';
import { FaFileExport } from 'react-icons/fa';
import useAlertasComOrdemServico from '../../hooks/useAlertasComOrdemServico';
import usePosteData from '../OrdensServicos/usePostes'; // Import do novo hook
import styles from './Relatorios.module.css';
import * as XLSX from 'xlsx';

// Import das funções de dicionário
import { fetchAlertaSituacaoMap, fetchCidadeMap } from '../../utils/fetchDictionaries';

const AlertasRelatorio = () => {
  // ----- ESTADOS PARA FILTROS (DATA/HORA) -----
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  // ----- ESTADOS PARA FILTROS COM DROPDOWNS -----
  const [filterOS, setFilterOS] = useState('Todos'); // "Todos", "Com OS", "Sem OS"
  const [filterSituacao, setFilterSituacao] = useState(''); // Filtro de status

  // ----- ESTADOS PARA OS MAPS DE TRADUÇÃO -----
  const [alertaSituacaoMap, setAlertaSituacaoMap] = useState({});
  const [cidadeMap, setCidadeMap] = useState({});
  const [posteAddressMap, setPosteAddressMap] = useState({}); // Novo estado para endereços

  // Hooks para alertas e postes
  const { alertasComOS, isLoading, error } = useAlertasComOrdemServico();
  const { postes, isLoadingPostes, errorPostes } = usePosteData(); // Uso do hook de postes

  // 1. Buscar os mapas de Alerta Situação, Cidade e Endereço dos Postes
  useEffect(() => {
    console.log('Postes:', postes);
    console.log('Is Loading Postes:', isLoadingPostes);
    console.log('Error Postes:', errorPostes);

    const getDictionaries = async () => {
      try {
        const [situacaoMap, cityMap] = await Promise.all([
          fetchAlertaSituacaoMap(),
          fetchCidadeMap(),
        ]);
        setAlertaSituacaoMap(situacaoMap);
        setCidadeMap(cityMap);
      } catch (err) {
        console.error('Erro ao buscar dicionários:', err);
      }
    };

    const createPosteAddressMap = () => {
      if (!Array.isArray(postes)) {
        console.error('postes não é um array:', postes);
        return;
      }
      const map = {};
      postes.forEach((poste) => {
        map[poste.id] = `${poste.address}, ${poste.posteBairro || ''}`;
      });
      setPosteAddressMap(map);
    };

    getDictionaries();

    if (!isLoadingPostes && !errorPostes && Array.isArray(postes) && postes.length > 0) {
      createPosteAddressMap();
    }
  }, [isLoadingPostes, postes, errorPostes]);

  // 2. Filtrar os alertas conforme datas/horas, dropdown "Ordem de Serviço" e "Status"
  const filteredAlertas = useMemo(() => {
    if (isLoading || isLoadingPostes) return [];

    if (!Array.isArray(alertasComOS)) {
      console.error('alertasComOS não é um array:', alertasComOS);
      return [];
    }

    return alertasComOS.filter((alerta) => {
      // Filtrar por data/hora
      const alertDateTime = new Date(`${alerta.alertaDataGeracao}T${alerta.alertaHoraGeracao}`);
      const startDateTime = startDate ? new Date(`${startDate}T${startTime || '00:00'}`) : null;
      const endDateTime = endDate ? new Date(`${endDate}T${endTime || '23:59'}`) : null;

      const isAfterStart = startDateTime ? alertDateTime >= startDateTime : true;
      const isBeforeEnd = endDateTime ? alertDateTime <= endDateTime : true;

      // Filtrar por OS
      let hasOrderService = true;
      if (filterOS === 'Com OS') {
        hasOrderService = !!alerta.ordemServico;
      } else if (filterOS === 'Sem OS') {
        hasOrderService = !alerta.ordemServico;
      }

      // Filtrar por Status (alertaSituacao)
      let matchesSituacao = true;
      if (filterSituacao) {
        matchesSituacao = alerta.alertaSituacao === filterSituacao;
      }

      return isAfterStart && isBeforeEnd && hasOrderService && matchesSituacao;
    });
  }, [
    alertasComOS,
    isLoading,
    isLoadingPostes,
    startDate,
    startTime,
    endDate,
    endTime,
    filterOS,
    filterSituacao,
  ]);

  // 3. Exportar dados para Excel com Endereço
  const handleExportToExcel = () => {
    const exportData = filteredAlertas.map((alerta) => ({
      'Alerta ID': alerta.alertaId,
      'Situação': alertaSituacaoMap[alerta.alertaSituacao] || 'N/A',
      'Cidade': cidadeMap[alerta.alertaCidade] || 'N/A',
      'Endereço do Poste': posteAddressMap[alerta.alertaPosteId] || 'N/A', // Adicionado Endereço
      'Poste ID': alerta.alertaPosteId || 'N/A',
      'Telefone': alerta.alertaTelefone || 'N/A',
      'Origem': alerta.alertaOrigem || 'N/A',
      'Hora Geração': alerta.alertaHoraGeracao || 'N/A',
      'Data Geração': alerta.alertaDataGeracao || 'N/A',
      'Motivo': alerta.alertaMotivo || 'N/A',

      // OS vinculada
      'OS ID': alerta.ordemServico?.osId || 'N/A',
      'Data Abertura OS': alerta.ordemServico?.osDataAbertura || 'N/A',
      'Hora Abertura OS': alerta.ordemServico?.osHoraAbertura || 'N/A',
      'Situação OS': alerta.ordemServico?.osSituacaoId || 'N/A',
      'Data Encerramento OS': alerta.ordemServico?.osDataEncerramento || 'N/A',
      'Hora Encerramento OS': alerta.ordemServico?.osHoraEncerramento || 'N/A',
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Alertas');
    XLSX.writeFile(workbook, 'relatorio_alertas_com_enderecos.xlsx');
  };

  return (
    <div className={styles.container}>
      <div className={styles.controlsTop}>
        {/* Seção de filtros: datas, horas e dropdowns */}
        <div className={styles.filterGroup}>
          <h3>Filtros</h3>

          {/* Data Inicial */}
          <div className={styles.controlGroup}>
            <label htmlFor="startDate">Data Inicial:</label>
            <input
              type="date"
              id="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className={styles.dateInput}
            />
          </div>

          {/* Hora Inicial */}
          <div className={styles.controlGroup}>
            <label htmlFor="startTime">Hora Inicial:</label>
            <input
              type="time"
              id="startTime"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              className={styles.timeInput}
            />
          </div>

          {/* Data Final */}
          <div className={styles.controlGroup}>
            <label htmlFor="endDate">Data Final:</label>
            <input
              type="date"
              id="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className={styles.dateInput}
            />
          </div>

          {/* Hora Final */}
          <div className={styles.controlGroup}>
            <label htmlFor="endTime">Hora Final:</label>
            <input
              type="time"
              id="endTime"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              className={styles.timeInput}
            />
          </div>

          {/* Dropdown para Ordem de Serviço */}
          <div className={styles.controlGroup}>
            <label htmlFor="filterOS">Ordem de Serviço:</label>
            <select
              id="filterOS"
              className={styles.dropdown}
              value={filterOS}
              onChange={(e) => setFilterOS(e.target.value)}
            >
              <option value="Todos">Todos</option>
              <option value="Com OS">Com OS</option>
              <option value="Sem OS">Sem OS</option>
            </select>
          </div>

          {/* Novo Dropdown: Filtro de Status (alertaSituacao) */}
          <div className={styles.controlGroup}>
            <label htmlFor="filterSituacao">Status do Alerta:</label>
            <select
              id="filterSituacao"
              className={styles.dropdown}
              value={filterSituacao}
              onChange={(e) => setFilterSituacao(e.target.value)}
            >
              <option value="">Todos</option>
              {Object.entries(alertaSituacaoMap).map(([id, nome]) => (
                <option key={id} value={id}>
                  {nome}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Botões de Ação */}
        <div className={styles.actionsContainer}>
          <button onClick={handleExportToExcel} className={styles.exportButton}>
            <FaFileExport /> Exportar para Excel
          </button>
        </div>
      </div>

      {/* Mensagens de estado (loading, erro) */}
      {(isLoading || isLoadingPostes) && <p className={styles.infoMessage}>Carregando dados...</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}
      {errorPostes && <p className={styles.errorMessage}>{errorPostes}</p>}

      {/* Tabela com dados filtrados */}
      {!isLoading && !isLoadingPostes && filteredAlertas.length > 0 && (
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Situação</th>
                <th>Cidade</th>
                <th>Endereço do Poste</th> {/* Adicionado cabeçalho de Endereço */}
                <th>Poste ID</th>
                <th>Telefone</th>
                <th>Origem</th>
                <th>Hora Geração</th>
                <th>Data Geração</th>
                <th>Motivo</th>
                <th>OS ID</th>
                <th>Data Abertura OS</th>
                <th>Hora Abertura OS</th>
                <th>Situação OS</th>
                <th>Data Encerramento OS</th>
                <th>Hora Encerramento OS</th>
              </tr>
            </thead>
            <tbody>
              {filteredAlertas.map((alerta) => (
                <tr key={alerta.alertaId}>
                  <td>{alerta.alertaId}</td>
                  <td>{alertaSituacaoMap[alerta.alertaSituacao] || 'N/A'}</td>
                  <td>{cidadeMap[alerta.alertaCidade] || 'N/A'}</td>
                  <td>{posteAddressMap[alerta.alertaPosteId] || 'N/A'}</td> {/* Exibição do Endereço */}
                  <td>{alerta.alertaPosteId || 'N/A'}</td>
                  <td>{alerta.alertaTelefone || 'N/A'}</td>
                  <td>{alerta.alertaOrigem || 'N/A'}</td>
                  <td>{alerta.alertaHoraGeracao || 'N/A'}</td>
                  <td>{alerta.alertaDataGeracao || 'N/A'}</td>
                  <td>{alerta.alertaMotivo || 'N/A'}</td>
                  <td>{alerta.ordemServico?.osId || 'N/A'}</td>
                  <td>{alerta.ordemServico?.osDataAbertura || 'N/A'}</td>
                  <td>{alerta.ordemServico?.osHoraAbertura || 'N/A'}</td>
                  <td>{alerta.ordemServico?.osSituacaoId || 'N/A'}</td>
                  <td>{alerta.ordemServico?.osDataEncerramento || 'N/A'}</td>
                  <td>{alerta.ordemServico?.osHoraEncerramento || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Mensagem caso não haja resultados */}
      {!isLoading && !isLoadingPostes && filteredAlertas.length === 0 && (
        <p className={styles.infoMessage}>Nenhum alerta encontrado para o período selecionado.</p>
      )}
    </div>
  );
};

export default AlertasRelatorio;
