// src/hooks/usePosteAdvancedDataComAlertas.js

import { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';
import { isValidGeolocation } from '../utils/geolocation';

const usePosteAdvancedDataComAlertas = (filtroAtivo = false, devicesFromHook = []) => {
  const { token, cidadeId, logout } = useContext(AuthContext);
  const [postesDetalhados, setPostesDetalhados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        cidadeId,
      };

      // Buscar dados das APIs necessárias em paralelo
      const [
        posteResponse,
        fotocelulaResponse,
        postefotocelulaResponse,
        alertaResponse,
        consumoResponse,
      ] = await Promise.all([
        fetch('https://api.ads10.com.br/api/poste', { headers }),
        fetch('https://api.ads10.com.br/api/fotocelula', { headers }),
        fetch('https://api.ads10.com.br/api/postefotocelula', { headers }),
        fetch('https://api.ads10.com.br/api/alerta', { headers }),
        fetch('https://api.ads10.com.br/api/posteconsumo', { headers }),
      ]);

      // Verifica se algum endpoint retornou 401 (sessão expirada)
      const responses = [
        posteResponse,
        fotocelulaResponse,
        postefotocelulaResponse,
        alertaResponse,
        consumoResponse,
      ];
      if (responses.some((res) => res.status === 401)) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      // Verifica se todos os responses estão OK
      if (!responses.every((res) => res.ok)) {
        throw new Error('Erro ao buscar dados das APIs');
      }

      // Converte todas as respostas para JSON
      const [
        postes,
        fotocelulas,
        postefotocelulas,
        alertas,
        consumos,
      ] = await Promise.all(responses.map((res) => res.json()));

      // ------------------------------------------------------------------
      // Montar deviceMap a partir de devicesFromHook (leituras do lastall)
      // ------------------------------------------------------------------
      // Aqui a chave é fotocelulaId
      const deviceMap = devicesFromHook.reduce((map, dev) => {
        // dev.fotocelulaId deve existir
        if (dev.fotocelulaId != null) {
          map[dev.fotocelulaId] = dev;
        }
        return map;
      }, {});

      // ------------------------------------------------------------------
      // Filtra postes se filtroAtivo = true
      // ------------------------------------------------------------------
      const postesFiltrados = filtroAtivo
        ? postes.filter((poste) => poste.ativo === 'S')
        : postes;

      // ------------------------------------------------------------------
      // Criar um mapa fotocelulaId -> fotocelula
      // ------------------------------------------------------------------
      const fotocelulaMap = fotocelulas.reduce((map, f) => {
        map[f.fotocelulaId] = f;
        return map;
      }, {});

      // ------------------------------------------------------------------
      // Criar um mapa de alertas por posteId
      // ------------------------------------------------------------------
      const posteToAlertas = alertas.reduce((map, alerta) => {
        if (!map[alerta.alertaPosteId]) {
          map[alerta.alertaPosteId] = [];
        }
        map[alerta.alertaPosteId].push(alerta);
        return map;
      }, {});

      // ------------------------------------------------------------------
      // Criar um mapa de consumos por posteId
      // ------------------------------------------------------------------
      const posteToConsumo = consumos.reduce((map, c) => {
        map[c.posteconsumoPoste] = c.posteconsumoConsumo;
        return map;
      }, {});

      // ------------------------------------------------------------------
      // Criar posteToDevices -> junta postefotocelulas com deviceMap
      // ------------------------------------------------------------------
      const posteToDevices = postefotocelulas.reduce((map, rel) => {
        // Considerar apenas as relações ativas
        if (rel.ativo === 'S') {
          const fotocelulaId = rel.fotocelulaId;
          if (!map[rel.posteId]) {
            map[rel.posteId] = [];
          }

          // Verifica se existe no deviceMap (ou seja, se há leitura no lastall)
          if (fotocelulaId && deviceMap[fotocelulaId]) {
            // Dispositivo "real" encontrado
            const originalDevice = deviceMap[fotocelulaId];

            // Merge de algumas infos de postefotocelula, se desejar
            const mergedDevice = {
              ...originalDevice,
              linkedAt: rel.data,      // data de link
              ligado: rel.ligado,     // 'L' ou 'D'
              manutencao: rel.manutencao,
              tensaoRede: rel.tensaoRede
                ? parseFloat(rel.tensaoRede)
                : originalDevice.deviceTensaoRede,
            };

            map[rel.posteId].push(mergedDevice);
          } else {
            // ------------------------------
            // Caso não exista no deviceMap,
            // significa que não há leitura
            // => Criar dispositivo sintético
            // ------------------------------
            const syntheticDevice = {
              fotocelulaId,
              descricao: 'Sem Leitura (Offline)',
              deviceEUI: null, // Indica ausência de EUI real
              offline: true,    // Marcando como offline
              deviceLightOn: 'N', // Arbitrário
              voltageStatus: 'normal',
              // Propriedades adicionais para compatibilidade com MarkerModal.jsx
              deviceAtualizacao: 'N/A',
              potencia: 'N/A',
              consumo: 'N/A',
              ligado: 'D', // 'D' para Desligado
              tempolampadaligada: 'N/A',
              corrente: 'N/A',
              tensaoRede: 'N/A',
              pf: 'N/A',
              deviceTensaoRede: 'N/A',
              deviceConsumo: 'N/A',
              deviceKw: 'N/A',
              deviceCorrente: 'N/A',
              devicePf: 'N/A',
              deviceTempoLampadaLigada: 'N/A',
            };

            map[rel.posteId].push(syntheticDevice);
          }
        }
        return map;
      }, {});

      // ------------------------------------------------------------------
      // Montar array de postesDetalhados
      // ------------------------------------------------------------------
      const postesComDetalhes = postesFiltrados.map((p) => {
        const devices = posteToDevices[p.id] || [];
        const alertasAssociados = posteToAlertas[p.id] || [];
        const consumoAssociado = posteToConsumo[p.id] || null;

        // Extrair lat/lng da geoLocalizacao, se disponível e válida
        let lat = null;
        let lng = null;
        if (p.geoLocalizacao && p.geoLocalizacao.includes(',')) {
          const [latStr, lngStr] = p.geoLocalizacao
            .split(',')
            .map((s) => s.trim());
          const latNum = parseFloat(latStr);
          const lngNum = parseFloat(lngStr);
          if (isValidGeolocation(latNum, lngNum)) {
            lat = latNum;
            lng = lngNum;
          }
        }

        return {
          ...p,
          devices, // Contém dispositivos reais + dispositivos sintéticos (offline)
          alertas: alertasAssociados,
          consumo: consumoAssociado,
          lat,
          lng,
        };
      });

      // ------------------------------------------------------------------
      // Salvar no state
      // ------------------------------------------------------------------
      setPostesDetalhados(postesComDetalhes);
    } catch (error) {
      setError(error.message);
      toast.error(`Erro ao buscar dados: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId, logout, filtroAtivo, devicesFromHook]); // Removido 'fotocelulas'

  // Carrega os dados inicialmente e sempre que algo em fetchData mudar
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    postesDetalhados,
    isLoading,
    error,
    refetch: fetchData,
  };
};

export default usePosteAdvancedDataComAlertas;
