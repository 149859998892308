// src/utils/fetchDictionaries.js

/**
 * Busca o mapeamento de Tipo de Poste
 * Endpoint: https://api.ads10.com.br/api/tipoposte
 * Retorna objeto { '1': 'Metálico', '2': 'Concreto', ... }
 */
export const fetchTipoPosteMap = async () => {
    try {
      const response = await fetch('https://api.ads10.com.br/api/tipoposte');
      const data = await response.json();
  
      const mapping = {};
      data.forEach((item) => {
        mapping[item.tp_id] = item.tp_nome;
      });
      return mapping;
  
    } catch (error) {
      console.error('Erro ao buscar tipos de poste:', error);
      return {}; // Retorna objeto vazio em caso de erro
    }
  };
  
  /**
   * Busca o mapeamento de Formato de Poste
   * Endpoint: https://api.ads10.com.br/api/formatoposte
   * Retorna objeto { '1': 'Curso Simples', '2': 'Curvo Duplo', ... }
   */
  export const fetchFormatoPosteMap = async () => {
    try {
      const response = await fetch('https://api.ads10.com.br/api/formatoposte');
      const data = await response.json();
  
      const mapping = {};
      data.forEach((item) => {
        mapping[item.fp_id] = item.fp_nome;
      });
      return mapping;
  
    } catch (error) {
      console.error('Erro ao buscar formatos de poste:', error);
      return {};
    }
  };
  
  /**
   * Busca o mapeamento de Região
   * Endpoint: https://api.ads10.com.br/api/regiao
   * Retorna objeto { '5': 'CEASA', '7': 'Testando Região 2', ... }
   */
  export const fetchRegiaoMap = async () => {
    try {
      const response = await fetch('https://api.ads10.com.br/api/regiao');
      const data = await response.json();
  
      const mapping = {};
      data.forEach((item) => {
        mapping[item.regiaoId] = item.regiaoNome;
      });
      return mapping;
  
    } catch (error) {
      console.error('Erro ao buscar regiões:', error);
      return {};
    }
  };
  
  /**
   * Busca o mapeamento de Formato da Tampa
   * Endpoint: https://api.ads10.com.br/api/formatotampa
   * Retorna objeto { '1': 'Cônico', '2': 'Telefônico', ... }
   */
  export const fetchFormatoTampaMap = async () => {
    try {
      const response = await fetch('https://api.ads10.com.br/api/formatotampa');
      const data = await response.json();
  
      const mapping = {};
      data.forEach((item) => {
        mapping[item.ft_id] = item.ft_nome;
      });
      return mapping;
  
    } catch (error) {
      console.error('Erro ao buscar formatos de tampa:', error);
      return {};
    }
  };
  
  /**
   * Busca o mapeamento de Cidade
   * Endpoint: https://api.ads10.com.br/api/cidade
   * Retorna objeto { '1': 'Afonso Cláudio', '2': 'Água Doce do Norte', ... }
   */
  export const fetchCidadeMap = async () => {
    try {
      const response = await fetch('https://api.ads10.com.br/api/cidade');
      const data = await response.json();
  
      const mapping = {};
      data.forEach((item) => {
        mapping[item.id] = item.nome;
      });
      return mapping;
  
    } catch (error) {
      console.error('Erro ao buscar cidades:', error);
      return {};
    }
  };
  
  /**
   * Busca o mapeamento de Classificação da Via
   * Endpoint: https://api.ads10.com.br/api/classificacaovia
   * Retorna objeto { '1': 'Viária - V1', '2': 'Viária - V2', '3': 'Viária - V3', ... }
   */
  export const fetchClassificacaoViaMap = async () => {
    try {
      const response = await fetch('https://api.ads10.com.br/api/classificacaovia');
      const data = await response.json();
  
      const mapping = {};
      data.forEach((item) => {
        mapping[item.cv_id] = item.cv_nome;
      });
      return mapping;
  
    } catch (error) {
      console.error('Erro ao buscar Classificação da Via:', error);
      return {};
    }
  };
  
  /**
   * Busca o mapeamento de Alerta Situação
   * Endpoint: https://api.ads10.com.br/api/alertasituacao
   * Retorna objeto { '1': 'Aguardando', '2': 'Atendendo', '3': 'Cancelada', '4': 'Finalizada' }
   */
  export const fetchAlertaSituacaoMap = async () => {
    try {
      const response = await fetch('https://api.ads10.com.br/api/alertasituacao');
      const data = await response.json();
  
      const mapping = {};
      data.forEach((item) => {
        mapping[item.alertasituacaoId] = item.alertasituacaoNome;
      });
      return mapping;
  
    } catch (error) {
      console.error('Erro ao buscar Alerta Situação:', error);
      return {};
    }
  };
  