// src/pages/Relatorios/Relatorios.jsx

import React, { useState } from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import AlertasRelatorio from './AlertasRelatorio';
import OrdensServicoRelatorio from './OrdensServicoRelatorio';
import RelatorioInventarioPostes from './RelatorioInventarioPostes';
import RelatorioPostesGeolocalizacao from './RelatorioPostesGeolocalizacao';
import UltimasLeiturasDispositivos from './UltimasLeiturasDispositivos';
import RelatorioConsumoPorPoste from './RelatorioConsumoPorPoste';
import RelatorioPostesComFoto from './RelatorioPostesComFoto';
import ModalRelatorios from './ModalRelatorios'; // Importar o componente ModalRelatorios
import styles from './Relatorios.module.css';
import { toast } from 'react-toastify';

const Relatorios = () => {
  const [mainCategory, setMainCategory] = useState('');
  const [posteReportType, setPosteReportType] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Determinar qual relatório exibir no Modal
  const getSelectedReport = () => {
    switch (mainCategory) {
      case 'postes':
        switch (posteReportType) {
          case 'inventarioPostes':
            return <RelatorioInventarioPostes />;
          case 'postesGeolocalizados':
            return <RelatorioPostesGeolocalizacao />;
          case 'ultimasLeiturasDispositivos':
            return <UltimasLeiturasDispositivos />;
          case 'consumoPorPoste':
            return <RelatorioConsumoPorPoste />;
          case 'postesComFoto':
            return <RelatorioPostesComFoto />;
          default:
            return <p className={styles.infoMessage}>Por favor, selecione o tipo de relatório de postes.</p>;
        }
      case 'alertas':
        return <AlertasRelatorio />;
      case 'ordensServico':
        return <OrdensServicoRelatorio />;
      default:
        return <p className={styles.infoMessage}>Selecione uma categoria para ver os tipos de relatórios disponíveis.</p>;
    }
  };

  const handleGenerateReport = () => {
    if (mainCategory === '' || (mainCategory === 'postes' && posteReportType === '')) {
      toast.warn('Por favor, selecione uma categoria e tipo de relatório.');
      return;
    }
    setIsModalOpen(true);
  };

  return (
    <PageLayout title="Relatórios">
      <div className={styles.container}>
        <div className={styles.controlsTop}>
          <div className={styles.inputsContainer}>
            {/* Primeiro dropdown para categoria principal */}
            <div className={styles.controlGroup}>
              <label htmlFor="mainCategory">Categoria:</label>
              <select
                id="mainCategory"
                value={mainCategory}
                onChange={(e) => {
                  setMainCategory(e.target.value);
                  setPosteReportType(''); // Resetar o segundo dropdown ao mudar a categoria
                }}
                className={styles.dropdown}
              >
                <option value="">Selecione a categoria</option>
                <option value="postes">Postes</option>
                <option value="alertas">Alertas</option>
                <option value="ordensServico">Ordens de Serviço</option>
              </select>
            </div>

            {/* Segundo dropdown para tipos de relatórios de postes */}
            {mainCategory === 'postes' && (
              <div className={styles.controlGroup}>
                <label htmlFor="posteReportType">Tipo de Relatório de Postes:</label>
                <select
                  id="posteReportType"
                  value={posteReportType}
                  onChange={(e) => setPosteReportType(e.target.value)}
                  className={styles.dropdown}
                >
                  <option value="">Selecione o tipo de relatório</option>
                  <option value="inventarioPostes">Inventário de Postes</option>
                  <option value="postesGeolocalizados">Postes Geolocalizados</option>
                  <option value="ultimasLeiturasDispositivos">Últimas Leituras dos Dispositivos</option>
                  <option value="consumoPorPoste">Consumo por Poste</option>
                  <option value="postesComFoto">Postes com Foto</option>
                </select>
              </div>
            )}
          </div>
        </div>

        {/* Botão Gerar Relatório */}
        <div className={styles.actionsContainer}>
          <button onClick={handleGenerateReport} className={styles.exportButton}>
            Gerar Relatório
          </button>
        </div>

        {/* Modal */}
        <ModalRelatorios
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={
            mainCategory === 'postes'
              ? `Relatório: ${
                  posteReportType === 'inventarioPostes'
                    ? 'Inventário de Postes'
                    : posteReportType === 'postesGeolocalizados'
                    ? 'Postes Geolocalizados'
                    : posteReportType === 'ultimasLeiturasDispositivos'
                    ? 'Últimas Leituras dos Dispositivos'
                    : posteReportType === 'consumoPorPoste'
                    ? 'Consumo por Poste'
                    : 'Postes com Foto'
                }`
              : mainCategory === 'alertas'
              ? 'Relatório de Alertas'
              : mainCategory === 'ordensServico'
              ? 'Relatório de Ordens de Serviço'
              : 'Relatório'
          }
        >
          {getSelectedReport()}
        </ModalRelatorios>
      </div>
    </PageLayout>
  );
};

export default Relatorios;
