import React from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import styles from './SearchBar.module.css';

const SearchBar = ({
  searchInput,
  setSearchInput,
  handleSearch,
  hideUnmatched,
  setHideUnmatched,
}) => {
  return (
    <div className={styles.searchContainer}>
      <FaSearch className={styles.searchIcon} />
      <input
        type="text"
        placeholder="Buscar pelo ID do Poste ou Dispositivo"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        className={styles.searchInput}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
      />
      <button
        onClick={handleSearch}
        className={styles.searchButton}
        aria-label="Buscar"
      >
        <FaSearch />
      </button>

      {/* Checkbox para ocultar postes fora da pesquisa */}
      <label className={styles.checkboxLabel}>
        <input
          type="checkbox"
          checked={hideUnmatched}
          onChange={(e) => setHideUnmatched(e.target.checked)}
        />
        Ocultar Excessões
      </label>
    </div>
  );
};

SearchBar.propTypes = {
  searchInput: PropTypes.string.isRequired,
  setSearchInput: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  hideUnmatched: PropTypes.bool.isRequired,
  setHideUnmatched: PropTypes.func.isRequired,
};

export default SearchBar;
