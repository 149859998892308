import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api'; // StreetViewPanorama removido
import styles from './MoverPontos.module.css';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import Toolbar from '../../components/Toolbar/Toolbar';
import SearchBar from '../../components/SearchBar/SearchBar';
import { AuthContext } from '../../context/AuthContext';
import { getMapCenterByCityId } from '../../config';
import { isValidGeolocation } from '../../utils/geolocation';
import { lightMapStyles } from '../../config/mapStyles';
import { toast } from 'react-toastify';

// Ícones dos marcadores
import markerIcon from '../../assets/marker.png';
import selectedMarkerIcon from '../../assets/selected-marker.png';
import editedMarkerIcon from '../../assets/edited-marker.png';

// Ícone de lupa
import { FaSearch } from 'react-icons/fa';

// **Import do ModalConfirmEdit** (Arquivo que criamos para confirmação)
import ModalConfirmEdit from './ModalConfirmEdit';

const containerStyle = {
  width: '100%',
  height: '100vh',
};

const MoverPontos = () => {
  const { cidadeId, token } = useContext(AuthContext);

  const [postes, setPostes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [selectedPoste, setSelectedPoste] = useState(null);
  const [mapType, setMapType] = useState('roadmap');
  const [zoom, setZoom] = useState(17);
  const [isMoveMode, setIsMoveMode] = useState(false);
  const [originalPosition, setOriginalPosition] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  const [hideUnmatched, setHideUnmatched] = useState(false);

  // Tilt (inclinação)
  const [tiltEnabled, setTiltEnabled] = useState(false);

  // Barra de busca
  const [searchBarInput, setSearchBarInput] = useState('');
  const [newAddress, setNewAddress] = useState('');

  // Animação do marcador (pulinho)
  const [animatedMarkerId, setAnimatedMarkerId] = useState(null);

  // Resultados da pesquisa
  const [searchResults, setSearchResults] = useState([]);

  // Localização do usuário
  const [userLocation, setUserLocation] = useState(null);

  // Ref do mapa
  const mapRef = useRef(null);

  // Timeout para animação
  const animationTimeoutRef = useRef(null);

  // Dados originais do poste (endereço/bairro) antes de mover
  const [originalData, setOriginalData] = useState({
    lat: null,
    lng: null,
    address: null,
    posteBairro: null,
  });

  // **Estado do ModalConfirmEdit**
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Centro inicial do mapa
  const initialCenter = getMapCenterByCityId(parseInt(cidadeId, 10));

  /**
   * Buscar dados de postes
   */
  const fetchData = useCallback(async () => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      toast.error('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        cidadeId,
      };

      const response = await fetch('https://api.ads10.com.br/api/poste', { headers });

      if (!response.ok) {
        if (response.status === 401) {
          toast.error('Sessão expirada. Faça login novamente.');
        } else {
          toast.error('Erro ao buscar os postes.');
        }
        throw new Error('Erro ao buscar os postes.');
      }

      const postesData = await response.json();

      // Validar e formatar
      const postesComGeolocalizacao = postesData
        .map((p) => {
          let lat = null;
          let lng = null;

          if (p.lat && p.lng) {
            lat = parseFloat(p.lat);
            lng = parseFloat(p.lng);
          } else if (p.geoLocalizacao) {
            const [latStr, lngStr] = p.geoLocalizacao.split(',').map((coord) => coord.trim());
            lat = parseFloat(latStr);
            lng = parseFloat(lngStr);
          }

          return {
            ...p,
            lat,
            lng,
          };
        })
        .filter((p) => isValidGeolocation(p.lat, p.lng));

      console.log('Postes com geolocalização válida:', postesComGeolocalizacao.length);

      setPostes(postesComGeolocalizacao);
    } catch (err) {
      setError(err.message);
      toast.error(`Erro ao buscar postes: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /**
   * Obter localização do usuário
   */
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          console.log('Localização do usuário obtida:', position.coords);
        },
        (error) => {
          console.error('Erro ao obter localização do usuário:', error);
          toast.info('Não foi possível obter sua localização.');
        }
      );
    } else {
      console.error('Geolocalização não é suportada.');
      toast.error('Geolocalização não é suportada por este navegador.');
    }
  }, []);

  // Atualizar zoom ao mudar
  const handleZoomChanged = () => {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom());
    }
  };

  // Quando carrega o mapa
  const handleMapLoad = (map) => {
    mapRef.current = map;
    map.addListener('zoom_changed', handleZoomChanged);
  };

  // Quando mapa fica idle
  const handleOnIdle = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      setMapBounds(bounds);
    }
  };

  /**
   * Postes visíveis (filtro por bounds + busca)
   */
  const visiblePostes = useMemo(() => {
    let filteredPostes = postes;

    if (mapBounds) {
      const ne = mapBounds.getNorthEast();
      const sw = mapBounds.getSouthWest();

      filteredPostes = filteredPostes.filter((p) => {
        return (
          p.lat >= sw.lat() &&
          p.lat <= ne.lat() &&
          p.lng >= sw.lng() &&
          p.lng <= ne.lng()
        );
      });
    }

    if (hideUnmatched && searchBarInput.trim()) {
      const searchTerm = searchBarInput.toLowerCase();
      filteredPostes = filteredPostes.filter(
        (p) =>
          (p.codigoPoste && p.codigoPoste.toLowerCase().includes(searchTerm)) ||
          (p.dispositivoId && p.dispositivoId.toLowerCase().includes(searchTerm))
      );
    }

    return filteredPostes;
  }, [postes, hideUnmatched, searchBarInput, mapBounds]);

  // Clique no marcador
  const handleMarkerClick = (poste) => {
    if (isMoveMode) {
      toast.warn('Finalize a movimentação antes de selecionar outro poste.');
      return;
    }
    setSelectedPoste(poste);
  };

  // Extrair bairro
  const extractBairro = (addressComponents) => {
    const bairroComp = addressComponents.find((comp) =>
      comp.types.includes('sublocality') || comp.types.includes('neighborhood')
    );
    return bairroComp ? bairroComp.long_name : 'Bairro não disponível';
  };

  // Extrair logradouro e número
  const extractLogradouroNumero = (formattedAddress) => {
    return new Promise((resolve) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: formattedAddress }, (results, status) => {
        if (status === 'OK' && results[0]) {
          const addressComponents = results[0].address_components;
          let logradouro = '';
          let numero = '';

          addressComponents.forEach((component) => {
            if (component.types.includes('route')) {
              logradouro = component.long_name;
            }
            if (component.types.includes('street_number')) {
              numero = component.long_name;
            }
          });

          if (!logradouro) {
            logradouro = 'Logradouro não disponível';
          }

          resolve({ logradouro, numero });
        } else {
          resolve({ logradouro: 'Logradouro não disponível', numero: '' });
        }
      });
    });
  };

  // Geocode address
  const geocodeAddress = (address) => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          if (results.length > 0) {
            const resultsWithDistance = results.map((result) => {
              const location = result.geometry.location;
              let distance = Infinity;
              if (userLocation) {
                distance = calculateDistance(
                  userLocation.lat,
                  userLocation.lng,
                  location.lat(),
                  location.lng()
                );
              }
              return { ...result, distance };
            });
            resultsWithDistance.sort((a, b) => a.distance - b.distance);
            resolve(resultsWithDistance);
          } else {
            reject('Nenhum resultado encontrado.');
          }
        } else {
          reject('Falha na geocodificação: ' + status);
        }
      });
    });
  };

  // Distância (Haversine)
  const calculateDistance = (lat1, lng1, lat2, lng2) => {
    const toRad = (x) => (x * Math.PI) / 180;
    const R = 6371e3;
    const φ1 = toRad(lat1);
    const φ2 = toRad(lat2);
    const Δφ = toRad(lat2 - lat1);
    const Δλ = toRad(lng2 - lng1);

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  // Clique no mapa para mover via clique
  const handleMapClick = (event) => {
    if (isMoveMode && selectedPoste) {
      const newLat = event.latLng.lat();
      const newLng = event.latLng.lng();

      const geoLoc = `${newLat}, ${newLng}`;

      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat: newLat, lng: newLng } }, async (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const formattedAddr = results[0].formatted_address;

            try {
              const { logradouro, numero } = await extractLogradouroNumero(formattedAddr);
              const bairro = extractBairro(results[0].address_components);

              setNewAddress(`${logradouro}, ${numero}`);

              const updatedPostes = postes.map((p) =>
                p.id === selectedPoste.id
                  ? {
                    ...p,
                    lat: newLat,
                    lng: newLng,
                    geoLocalizacao: geoLoc,
                    address: `${logradouro}, ${numero}`,
                    posteBairro: bairro,
                    isBeingMoved: true,
                  }
                  : p
              );

              setPostes(updatedPostes);
              const updated = updatedPostes.find((p) => p.id === selectedPoste.id);
              setSelectedPoste(updated);
            } catch (error) {
              toast.error(error);
            }
          } else {
            toast.error('Nenhum resultado para este local clicado.');
          }
        } else {
          toast.error('Falha na geocodificação reversa: ' + status);
        }
      });
    } else if (selectedPoste) {
      // Se clicar no mapa e já tiver um poste selecionado, deselecionar
      setSelectedPoste(null);
      setNewAddress('');
      setSearchResults([]);
    }
  };

  // Mover poste para endereço pesquisado
  const movePosteToAddress = async (address) => {
    if (!isMoveMode || !selectedPoste) {
      toast.error('Ative modo de mover e selecione um poste.');
      return;
    }

    try {
      const results = await geocodeAddress(address);
      if (results.length === 0) {
        toast.error('Nenhum resultado encontrado.');
        return;
      }
      setSearchResults(results);
      if (results.length === 1) {
        finalizeMove(results[0]);
      } else {
        toast.info('Selecione uma das opções encontradas...');
      }
    } catch (error) {
      toast.error(error);
    }
  };

  // Finalizar movimentação (selecionando um resultado)
  const finalizeMove = async (result) => {
    const location = result.geometry.location;
    const formattedAddr = result.formatted_address;

    try {
      const { logradouro, numero } = await extractLogradouroNumero(formattedAddr);
      const bairro = extractBairro(result.address_components);

      const newLat = location.lat();
      const newLng = location.lng();
      const geoLoc = `${newLat}, ${newLng}`;

      setNewAddress(`${logradouro}, ${numero}`);

      const updatedPostes = postes.map((p) =>
        p.id === selectedPoste.id
          ? {
            ...p,
            lat: newLat,
            lng: newLng,
            geoLocalizacao: geoLoc,
            address: `${logradouro}, ${numero}`,
            posteBairro: bairro,
            isBeingMoved: true,
          }
          : p
      );

      setPostes(updatedPostes);
      const updated = updatedPostes.find((p) => p.id === selectedPoste.id);
      setSelectedPoste(updated);

      if (mapRef.current) {
        mapRef.current.panTo({ lat: newLat, lng: newLng });
        mapRef.current.setZoom(18);
      }
      setSearchResults([]);
      toast.success('Poste movido para o endereço pesquisado.');
    } catch (error) {
      toast.error(error);
    }
  };

  // Atualizar no servidor
  const handleDragEnd = async (posteId, newLat, newLng, addressToUpdate, bairroToUpdate) => {
    const posteToUpdate = postes.find((p) => p.id === posteId);
    if (!posteToUpdate) {
      toast.error('Poste não encontrado.');
      return;
    }

    try {
      const newGeoLoc = `${newLat}, ${newLng}`;
      const updatedPoste = {
        ...posteToUpdate,
        lat: String(newLat),
        lng: String(newLng),
        geoLocalizacao: newGeoLoc,
        address: addressToUpdate,
        posteBairro: bairroToUpdate,
        isBeingMoved: false,
      };

      setPostes((prevPostes) =>
        prevPostes.map((p) => (p.id === posteId ? updatedPoste : p))
      );
      setSelectedPoste(updatedPoste);

      try {
        const response = await fetch(`https://api.ads10.com.br/api/poste/${posteId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            cidadeId,
          },
          body: JSON.stringify(updatedPoste),
        });

        if (!response.ok) {
          throw new Error('Erro ao atualizar poste no servidor.');
        }

        toast.success('Posição do poste atualizada com sucesso.');
      } catch (error) {
        toast.error('Falha ao atualizar no servidor.');
      }

      // Encerrar modo mover
      setIsMoveMode(false);
      setOriginalPosition(null);
      setNewAddress('');
    } catch (error) {
      toast.error(error);
    }
  };

  // Ativar modo de mover
  const handleMovePosition = () => {
    if (!selectedPoste) return;

    setOriginalPosition({ lat: selectedPoste.lat, lng: selectedPoste.lng });
    setOriginalData({
      lat: selectedPoste.lat,
      lng: selectedPoste.lng,
      address: selectedPoste.address,
      posteBairro: selectedPoste.posteBairro,
    });

    setIsMoveMode(true);
    setNewAddress('');
    setSearchResults([]);
  };

  // Aplicar posição (onde abrimos o modal se endereço/bairro mudou)
  const handleApplyPosition = () => {
    if (!selectedPoste) return;

    const isStillBeingMoved = postes.some(
      (p) => p.id === selectedPoste.id && p.isBeingMoved
    );

    if (!isStillBeingMoved) {
      // Se não estiver mais marcado como "em movimento"
      setIsMoveMode(false);
      setOriginalPosition(null);
      setNewAddress('');
      setSearchResults([]);
      return;
    }

    // Verifica se endereço/bairro mudaram
    const addressChanged =
      originalData.address !== selectedPoste.address ||
      originalData.posteBairro !== selectedPoste.posteBairro;

    if (addressChanged) {
      // Abrir modal
      setIsModalOpen(true);
    } else {
      // Se não mudou, atualizar direto
      handleConfirmUpdate(selectedPoste.address, selectedPoste.posteBairro);
    }
  };

  // Confirma atualizar endereço/bairro (chamado pelo modal)
  const handleConfirmUpdate = (finalAddress, finalBairro) => {
    // Se não vierem dados, usa o do selectedPoste
    const addr = finalAddress || selectedPoste.address;
    const brr = finalBairro || selectedPoste.posteBairro;

    handleDragEnd(
      selectedPoste.id,
      selectedPoste.lat,
      selectedPoste.lng,
      addr,
      brr
    );
    setIsModalOpen(false);
  };

  // Cancela atualização do endereço (usa original)
  const handleCancelUpdate = () => {
    handleDragEnd(
      selectedPoste.id,
      selectedPoste.lat,
      selectedPoste.lng,
      originalData.address,
      originalData.posteBairro
    );
    setIsModalOpen(false);
  };

  // Cancelar posição (reverter)
  const handleCancelPosition = () => {
    if (!selectedPoste || !originalPosition) return;

    const revertedPostes = postes.map((p) =>
      p.id === selectedPoste.id
        ? {
          ...p,
          lat: originalPosition.lat,
          lng: originalPosition.lng,
          geoLocalizacao: `${originalPosition.lat}, ${originalPosition.lng}`,
          isBeingMoved: false,
          address: originalData.address,
          posteBairro: originalData.posteBairro,
        }
        : p
    );

    setPostes(revertedPostes);

    const reverted = revertedPostes.find((p) => p.id === selectedPoste.id);
    setSelectedPoste(reverted);

    setIsMoveMode(false);
    setOriginalPosition(null);
    setNewAddress('');
    setSearchResults([]);
    toast.info('Movimentação cancelada, posição revertida.');
  };

  // Toggle tilt
  const toggleTiltView = () => {
    if (mapRef.current) {
      const currentTilt = mapRef.current.getTilt();
      const newTilt = currentTilt === 0 ? 45 : 0;
      mapRef.current.setTilt(newTilt);
      setTiltEnabled(newTilt !== 0);
    }
  };

  // Buscar por código do poste
  const handleSearchBar = async () => {
    const input = searchBarInput.trim().toUpperCase();
    const digitsOnlyRegex = /^\d{1,9}$/;

    // Remover os 3 primeiros caracteres do prefixo, se existirem
    let processedInput = input.length > 3 ? input.slice(3) : input;

    // Completar com zeros à esquerda para garantir 9 dígitos
    processedInput = processedInput.padStart(9, '0');

    if (digitsOnlyRegex.test(processedInput)) {
      // Busca postes com base nos últimos 9 dígitos, ignorando prefixo
      const foundList = postes.filter(
        (p) => p.codigoPoste && p.codigoPoste.slice(-9) === processedInput
      );

      if (foundList.length === 1) {
        centerOnPoste(foundList[0]);
      } else if (foundList.length > 1) {
        toast.info('Múltiplos postes encontrados. Selecione no mapa.');
      } else {
        toast.error('Nenhum poste encontrado com esses dígitos.');
      }
    } else {
      toast.error('Formato inválido. Use até 9 dígitos.');
    }
  };


  // Centralizar no poste
  const centerOnPoste = (poste) => {
    setSelectedPoste(poste);
    if (mapRef.current) {
      mapRef.current.panTo({ lat: poste.lat, lng: poste.lng });
      mapRef.current.setZoom(18);
    }
    setAnimatedMarkerId(poste.id);

    if (animationTimeoutRef.current) {
      clearTimeout(animationTimeoutRef.current);
    }
    animationTimeoutRef.current = setTimeout(() => {
      setAnimatedMarkerId(null);
    }, 3000);
  };

  // Limpar timeout ao desmontar
  useEffect(() => {
    return () => {
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
    };
  }, []);

  // Ícone do marcador
  const getMarkerIcon = (poste) => {
    if (animatedMarkerId === poste.id) {
      return editedMarkerIcon;
    }
    if (selectedPoste && poste.id === selectedPoste.id) {
      return selectedMarkerIcon;
    }
    return markerIcon;
  };

  return (
    <div className={styles.moverPontosPage}>
      {isLoading && <LoadingOverlay />}
      {error && <p className={styles.errorMessage}>{error}</p>}

      <Toolbar
        toggleSatelliteView={() =>
          setMapType((prev) => (prev === 'roadmap' ? 'satellite' : 'roadmap'))
        }
        isSatelliteView={mapType === 'satellite'}
        disableTilt={false}
        toggleTilt={toggleTiltView}
        tiltEnabled={tiltEnabled}
        disableLegend={true}
      // Removidas props referentes ao Street View
      />

      <SearchBar
        searchInput={searchBarInput}
        setSearchInput={setSearchBarInput}
        handleSearch={handleSearchBar}
        hideUnmatched={hideUnmatched}
        setHideUnmatched={setHideUnmatched}
      />

      {/* Card do poste selecionado */}
      {selectedPoste && (
        <div className={styles.posteInfoCard}>
          {/* Foto do poste */}
          {selectedPoste.fotoPoste ? (
            <img
              src={selectedPoste.fotoPoste}
              alt={`Foto do Poste ${selectedPoste.codigoPoste}`}
              className={styles.postePhoto}
            />
          ) : (
            <div className={styles.photoPlaceholder}>Foto não disponível</div>
          )}

          <h3>Informações do Poste</h3>
          <p>
            <strong>Código:</strong> {selectedPoste.codigoPoste}
          </p>
          <span
            className={
              selectedPoste.address &&
                selectedPoste.address.includes('Logradouro não disponível')
                ? styles.addressError
                : ''
            }
          >
            {selectedPoste.address
              ? `${selectedPoste.address} - ${selectedPoste.posteBairro || ''}`
              : 'Endereço não disponível'}
          </span>

          {isMoveMode && (
            <div className={styles.newAddressContainer}>
              <label htmlFor="newAddress">
                <strong>Pesquisar Endereço:</strong>
              </label>
              <div className={styles.searchInputContainer}>
                <input
                  type="text"
                  id="newAddress"
                  value={newAddress}
                  onChange={(e) => setNewAddress(e.target.value)}
                  placeholder="Digite o novo endereço"
                  className={styles.newAddressInput}
                />
                <button
                  onClick={() => movePosteToAddress(newAddress)}
                  className={styles.searchButton}
                  aria-label="Pesquisar"
                >
                  <FaSearch />
                </button>
              </div>

              {searchResults.length > 0 && (
                <div className={styles.searchResults}>
                  <h4>Selecione uma opção:</h4>
                  <ul>
                    {searchResults.map((result, index) => (
                      <li
                        key={index}
                        onClick={() => finalizeMove(result)}
                        className={styles.searchResultItem}
                      >
                        {result.formatted_address}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}

          <div className={styles.buttonGroup}>
            {!isMoveMode ? (
              <button
                onClick={handleMovePosition}
                className={styles.movePositionButton}
              >
                Mover Posição do Marcador
              </button>
            ) : (
              <>
                <button
                  onClick={handleApplyPosition}
                  className={styles.applyPositionButton}
                >
                  Aplicar
                </button>
                <button
                  onClick={handleCancelPosition}
                  className={styles.cancelPositionButton}
                >
                  Cancelar
                </button>
              </>
            )}
          </div>
        </div>
      )}

      {/* Mapa */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={initialCenter}
        zoom={zoom}
        mapTypeId={mapType}
        onLoad={handleMapLoad}
        onClick={handleMapClick}
        onIdle={handleOnIdle}
        options={{
          styles: lightMapStyles,
          disableDefaultUI: true,
          streetViewControl: true, // Mostra o controle de Street View
          zoomControl: true,
          tilt: tiltEnabled ? 45 : 0,
        }}
      >
        {visiblePostes.map((poste) =>
          isValidGeolocation(poste.lat, poste.lng) ? (
            <Marker
              key={poste.id}
              position={{ lat: poste.lat, lng: poste.lng }}
              icon={{
                url: getMarkerIcon(poste),
                scaledSize: new window.google.maps.Size(16, 16),
              }}
              onClick={() => handleMarkerClick(poste)}
              animation={
                animatedMarkerId === poste.id
                  ? window.google.maps.Animation.BOUNCE
                  : null
              }
              draggable={false}
            />
          ) : null
        )}
      </GoogleMap>

      {/* Modal para confirmar a edição do endereço/bairro */}
      <ModalConfirmEdit
        isOpen={isModalOpen}
        previousAddress={originalData.address}
        newAddress={selectedPoste?.address}
        previousBairro={originalData.posteBairro}
        newBairro={selectedPoste?.posteBairro}
        // Quando o usuário confirma (manual ou automático)
        onConfirm={(finalAddr, finalBrr) => {
          handleConfirmUpdate(finalAddr, finalBrr);
        }}
        // Quando o usuário cancela a atualização
        onCancel={handleCancelUpdate}
        // Fecha o modal sem confirmar
        onClose={() => {
          // Reverte o estado para os dados originais
          handleCancelPosition();
          setIsModalOpen(false);
        }}
      />
    </div>
  );
};

export default MoverPontos;
