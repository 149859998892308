// src/pages/Relatorios/UltimasLeiturasDispositivos.jsx

import React, { useState, useEffect, useMemo } from 'react';
import usePosteDataComAlertas from '../../hooks/usePosteDataComAlertas';
import styles from './Relatorios.module.css';
import * as XLSX from 'xlsx';
import { FaFileExport } from 'react-icons/fa';

import useGruposData from '../../hooks/useGruposData'; // Hook para obter grupos
import useGroupPoste from '../../hooks/useGroupPoste'; // Hook para associações entre grupos e postes

const UltimasLeiturasDispositivos = () => {
  const { postesDetalhados, isLoading, error } = usePosteDataComAlertas();
  const [leiturasDispositivos, setLeiturasDispositivos] = useState([]);

  // Estados para filtros
  const [selectedGroups, setSelectedGroups] = useState([]); // IDs dos grupos selecionados

  // Hooks para grupos e associações
  const {
    grupos,
    isLoading: isGruposLoading,
    error: gruposError,
  } = useGruposData();

  const {
    associations,
    error: associationsError,
    fetchAssociations,
  } = useGroupPoste();

  // Buscar associações ao montar o componente
  useEffect(() => {
    fetchAssociations();
  }, [fetchAssociations]);

  // Mapear associações aos postes
  const postesComGrupos = useMemo(() => {
    if (!postesDetalhados || !associations) return [];

    return postesDetalhados.map((poste) => {
      const gruposDoPoste = associations
        .filter((assoc) => assoc.posteId === String(poste.id))
        .map((assoc) => assoc.grupoId);
      return { ...poste, grupoIds: gruposDoPoste };
    });
  }, [postesDetalhados, associations]);

  // Lógica de filtragem e preparação dos dados de leituras dos dispositivos
  useEffect(() => {
    let filteredPostes = [...postesComGrupos];

    // Filtrar por grupos selecionados
    if (selectedGroups.length > 0) {
      filteredPostes = filteredPostes.filter((poste) =>
        poste.grupoIds.some((id) => selectedGroups.includes(id))
      );
    }

    // Extrair as últimas leituras dos dispositivos (fotocélulas)
    const leituras = filteredPostes
      .flatMap((poste) =>
        (poste.fotocelulas || []).map((fotocelula) => ({
          descricao: fotocelula.descricao,
          dataAtualizacao: fotocelula.deviceAtualizacao,
          latitude: fotocelula.latitude || 'N/A',
          longitude: fotocelula.longitude || 'N/A',
          corrente: fotocelula.corrente || 'N/A',
          potenciaFotocelula: fotocelula.potencia || 'N/A',
          tensaoRede: fotocelula.tensaoRede || 'N/A',
          tempoLampadaLigada: fotocelula.tempolampadaligada || 'N/A',
          modoOperacao: fotocelula.modofotocelula === 'S' ? 'Automático' : 'Manual',
          pa: fotocelula.pa || 'N/A',
          pr: fotocelula.pr || 'N/A',
          pf: fotocelula.pf || 'N/A',
          ativo: fotocelula.ativo === 'S' ? 'Ativo' : 'Inativo',
          numeroPoste: poste.codigoPoste || 'N/A',
          potenciaPoste: poste.potencia || 'N/A',
          consumoPoste: poste.consumo || 'N/A', // Inclui o consumo do poste
          grupos: grupos
            .filter((grupo) => poste.grupoIds.includes(grupo.grupoId))
            .map((grupo) => grupo.grupoNome)
            .join(', ') || 'N/A',
        }))
      )
      .filter((fotocelula) => fotocelula.dataAtualizacao); // Apenas dispositivos com atualizações

    setLeiturasDispositivos(leituras);
  }, [postesComGrupos, selectedGroups, grupos]);

  const handleExportToExcel = () => {
    const exportData = leiturasDispositivos.map((leitura) => ({
      'Descrição': leitura.descricao,
      'Número do Poste': leitura.numeroPoste,
      'Potência do Poste': leitura.potenciaPoste,
      'Consumo do Poste': leitura.consumoPoste,
      'Grupos': leitura.grupos,
      'Data da Última Atualização': leitura.dataAtualizacao,
      'Latitude': leitura.latitude,
      'Longitude': leitura.longitude,
      'Corrente': leitura.corrente,
      'Potência da Fotocélula': leitura.potenciaFotocelula,
      'Tensão da Rede': leitura.tensaoRede,
      'Tempo de Lâmpada Ligada': leitura.tempoLampadaLigada,
      'Modo de Operação': leitura.modoOperacao,
      'Potência Aparente (PA)': leitura.pa,
      'Potência Reativa (PR)': leitura.pr,
      'Fator de Potência (PF)': leitura.pf,
      'Status de Ativação': leitura.ativo,
    }));

    // Adicionar total e média se necessário
    // const totalConsumo = leiturasDispositivos.reduce((total, leitura) => total + parseFloat(leitura.consumoPoste || 0), 0);
    // const mediaConsumo = leiturasDispositivos.length > 0 ? (totalConsumo / leiturasDispositivos.length).toFixed(2) : 0;
    // exportData.push({ 'Descrição': 'Total', 'Número do Poste': '', ... });
    // exportData.push({ 'Descrição': 'Consumo Médio Por Poste', 'Número do Poste': '', ... });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Últimas Leituras');
    XLSX.writeFile(workbook, 'ultimas_leituras_dispositivos.xlsx');
  };

  /**
   * Função para lidar com a seleção de grupos no dropdown.
   * @param {Event} e - Evento de mudança.
   */
  const handleGroupChange = (e) => {
    const options = e.target.options;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    setSelectedGroups(selected);
  };

  const handleClearFilters = () => {
    setSelectedGroups([]);
  };

  return (
    <div className={styles.container}>
      <h2>Relatório de Últimas Leituras dos Dispositivos</h2>
      <div className={styles.controlsTop}>
        {/* Dropdown de Seleção de Grupos */}
        <div className={styles.controlGroup}>
          <label htmlFor="groupFilter">Filtrar por Grupo:</label>
          {!isGruposLoading && !gruposError && grupos && (
            <>
              <select
                id="groupFilter"
                multiple
                value={selectedGroups}
                onChange={handleGroupChange}
                className={styles.dropdown}
              >
                {grupos.map((grupo) => (
                  <option key={grupo.grupoId} value={grupo.grupoId}>
                    {grupo.grupoNome}
                  </option>
                ))}
              </select>
              <small className={styles.selectInfo}>
                Pressione Ctrl (Windows) ou Command (Mac) para selecionar múltiplos grupos.
              </small>
            </>
          )}
          {isGruposLoading && <p>Carregando grupos...</p>}
          {gruposError && <p className={styles.errorMessage}>{gruposError}</p>}
        </div>

        {/* Botões de Ação */}
        <div className={styles.actionsContainer}>
          <button onClick={handleExportToExcel} className={styles.exportButton}>
            <FaFileExport /> Exportar para Excel
          </button>
          <button onClick={handleClearFilters} className={styles.clearFiltersButton}>
            Limpar Filtros
          </button>
        </div>
      </div>

      {/* Mensagens de Status */}
      {isLoading && <p>Carregando dados...</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}
      {associationsError && <p className={styles.errorMessage}>{associationsError}</p>}

      {/* Tabela de Dados */}
      {!isLoading && leiturasDispositivos.length > 0 && (
        <>
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th>Número do Poste</th>
                  <th>Potência do Poste</th>
                  <th>Consumo do Poste</th>
                  <th>Grupos</th>
                  <th>Data da Última Atualização</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th>Corrente</th>
                  <th>Potência da Fotocélula</th>
                  <th>Tensão da Rede</th>
                  <th>Tempo de Lâmpada Ligada</th>
                  <th>Modo de Operação</th>
                  <th>PA</th>
                  <th>PR</th>
                  <th>PF</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {leiturasDispositivos.map((leitura, index) => (
                  <tr key={index}>
                    <td>{leitura.descricao}</td>
                    <td>{leitura.numeroPoste}</td>
                    <td>{leitura.potenciaPoste}</td>
                    <td>{leitura.consumoPoste}</td>
                    <td>{leitura.grupos}</td>
                    <td>{leitura.dataAtualizacao}</td>
                    <td>{leitura.latitude}</td>
                    <td>{leitura.longitude}</td>
                    <td>{leitura.corrente}</td>
                    <td>{leitura.potenciaFotocelula}</td>
                    <td>{leitura.tensaoRede}</td>
                    <td>{leitura.tempoLampadaLigada}</td>
                    <td>{leitura.modoOperacao}</td>
                    <td>{leitura.pa}</td>
                    <td>{leitura.pr}</td>
                    <td>{leitura.pf}</td>
                    <td>{leitura.ativo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {!isLoading && leiturasDispositivos.length === 0 && (
        <p>Nenhuma leitura disponível para os filtros selecionados.</p>
      )}
    </div>
  );
};

export default UltimasLeiturasDispositivos;
