// src/pages/PosteGrupos/JanelaPostes.jsx

import React from 'react';
import styles from './JanelaPostes.module.css';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';

const JanelaPostes = ({ groupName, groupPosts, markersToAdd, markersToRemove, onPosteClick }) => {
  // Filtrar os postes para adicionar e remover
  const postesSelecionadosParaAdicionar = markersToAdd;
  const postesSelecionadosParaRemover = markersToRemove;

  // Filtrar os postes que pertencem ao grupo, excluindo aqueles marcados para remoção
  const postesDoGrupo = groupPosts
    .filter((poste) => !postesSelecionadosParaRemover.find((p) => p.id === poste.id))
    .sort((a, b) => a.codigoPoste.localeCompare(b.codigoPoste));

  return (
    <div className={styles.janelaPostes}>
      <h4 className={styles.title}>{groupName || 'Grupo Não Selecionado'}</h4>

      {/* Postes Selecionados para Adicionar */}
      {postesSelecionadosParaAdicionar.length > 0 && (
        <div className={styles.section}>
          <h5 className={styles.sectionTitle}>
            <FaPlusCircle color="#28a745" /> Postes Selecionados para Adicionar
          </h5>
          <ul className={styles.posteList}>
            {postesSelecionadosParaAdicionar.map((poste) => (
              <li
                key={poste.id}
                className={styles.posteItemSelecionado}
                onClick={() => onPosteClick(poste.id, 'add')}
              >
                {poste.codigoPoste}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Postes Selecionados para Remover */}
      {postesSelecionadosParaRemover.length > 0 && (
        <div className={styles.section}>
          <h5 className={styles.sectionTitle}>
            <FaMinusCircle color="#dc3545" /> Postes Selecionados para Remover
          </h5>
          <ul className={styles.posteList}>
            {postesSelecionadosParaRemover.map((poste) => (
              <li
                key={poste.id}
                className={styles.posteItemSelecionado}
                onClick={() => onPosteClick(poste.id, 'remove')}
              >
                {poste.codigoPoste}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Postes do Grupo */}
      <div className={styles.section}>
        <h5 className={styles.sectionTitle}>Postes do Grupo</h5>
        <ul className={styles.posteList}>
          {postesDoGrupo.map((poste) => (
            <li
              key={poste.id}
              className={styles.posteItem}
              onClick={() => onPosteClick(poste.id, 'toggle')}
            >
              {poste.codigoPoste}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default JanelaPostes;
