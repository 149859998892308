import { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

/**
 * Hook customizado para buscar e gerenciar a lista de regiões com base na cidade, estado e país selecionados.
 * @returns {object} - Contém a lista de regiões, estado de carregamento, erro e função para refetch.
 */
const useRegiao = () => {
  const { token, cidadeId, logout } = useContext(AuthContext);
  const [regioes, setRegioes] = useState([]);
  const [isRegiaoLoading, setIsRegiaoLoading] = useState(false);
  const [regiaoError, setRegiaoError] = useState(null);

  /**
   * Função para buscar as regiões da API com base nos parâmetros.
   */
  const fetchRegioes = useCallback(async () => {
    if (!token || !cidadeId) {
      setRegiaoError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsRegiaoLoading(true);
    setRegiaoError(null);

    try {
      const response = await fetch('https://api.ads10.com.br/api/regiao', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          cidadeId,
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        throw new Error('Erro ao buscar regiões.');
      }

      const data = await response.json();
      setRegioes(data);
    } catch (error) {
      console.error('Erro ao buscar regiões:', error);
      setRegiaoError(error.message);
      toast.error(`Erro ao buscar regiões: ${error.message}`);
    } finally {
      setIsRegiaoLoading(false);
    }
  }, [token, cidadeId, logout]);

  useEffect(() => {
    fetchRegioes();
  }, [fetchRegioes]);

  return { regioes, isRegiaoLoading, regiaoError, refetch: fetchRegioes };
};

export default useRegiao;
