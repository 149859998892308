// src/pages/Administrador/Administrador.jsx

import React, { useState, useEffect } from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import styles from './Administrador.module.css';
import {
  FaSearch,
  FaEdit,
  FaKey,
  FaToggleOn,
  FaToggleOff,
  FaSort,
  FaSortUp,
  FaSortDown,
  FaUserTie,
  FaUser,
  FaHardHat,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import UserModal from '../../components/UserModal/UserModal'; // Modal de edição de usuário
import PasswordModal from '../../components/PasswordModal/PasswordModal'; // Modal de alteração de senha

const Administrador = () => {
  // Estados para os modais
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [selectedPasswordUser, setSelectedPasswordUser] = useState(null);

  // Outros estados
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Estados para ordenação
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  // Funções para abrir e fechar os modais
  const openEditModal = (user) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedUser(null);
    setIsEditModalOpen(false);
  };

  const openPasswordModal = (user) => {
    setSelectedPasswordUser(user);
    setIsPasswordModalOpen(true);
  };

  const closePasswordModal = () => {
    setSelectedPasswordUser(null);
    setIsPasswordModalOpen(false);
  };

  // Função para salvar as alterações de usuário
  const handleSaveUser = async (updatedUser) => {
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      const cidadeId = localStorage.getItem('cidadeId') || sessionStorage.getItem('cidadeId');

      const response = await fetch(`https://api.ads10.com.br/api/usuario/${updatedUser.usuarioId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify({
          usuarioId: updatedUser.usuarioId,
          usuarioUserName: updatedUser.login,
          usuarioNome: updatedUser.nome,
          usuarioSenha: updatedUser.usuarioSenha || "ManterSenhaExistente", // Preservar senha se não alterada
          usuarioAtivo: updatedUser.ativo ? 's' : 'n',
          usuarioDataInclusao: updatedUser.usuarioDataInclusao,
          usuarioUserInclusao: updatedUser.usuarioUserInclusao,
          usuarioDataAlteracao: new Date().toISOString().split('T')[0],
          usuarioUserAlteracao: "admin", // Substitua por identificação do admin atual
          usuarioMotorista: updatedUser.usuarioMotorista || "",
          usuarioAdmin: updatedUser.administrador ? 's' : 'n',
          usuarioEmail: updatedUser.email || "",
          usuarioCelular: updatedUser.celular || "",
          usuarioPrestador: updatedUser.prestador ? 's' : 'n',
          usuarioCidade: updatedUser.usuarioCidade || 0,
          usuarioAreaLat: updatedUser.usuarioAreaLat || "",
          usuarioAreaLng: updatedUser.usuarioAreaLng || "",
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erro ao salvar o usuário.');
      }

      // Atualizar o estado local
      setUsers(prevUsers =>
        prevUsers.map(user => (user.usuarioId === updatedUser.usuarioId ? updatedUser : user))
      );
      toast.success('Usuário atualizado com sucesso!');
      closeEditModal();
    } catch (err) {
      console.error(err);
      setError(err.message);
      toast.error(`Falha ao salvar o usuário: ${err.message}`);
    }
  };

  // Função para salvar a nova senha do usuário
  const handleSavePassword = async (updatedUser) => {
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      const cidadeId = localStorage.getItem('cidadeId') || sessionStorage.getItem('cidadeId');

      const response = await fetch(`https://api.ads10.com.br/api/usuario/${updatedUser.usuarioId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify({
          usuarioId: updatedUser.usuarioId,
          usuarioUserName: updatedUser.login,
          usuarioNome: updatedUser.nome,
          usuarioSenha: updatedUser.usuarioSenha, // Nova senha definida no modal
          usuarioAtivo: updatedUser.ativo ? 's' : 'n',
          usuarioDataInclusao: updatedUser.usuarioDataInclusao,
          usuarioUserInclusao: updatedUser.usuarioUserInclusao,
          usuarioDataAlteracao: new Date().toISOString().split('T')[0],
          usuarioUserAlteracao: "admin", // Substitua pelo identificador do admin atual
          usuarioMotorista: updatedUser.usuarioMotorista || "",
          usuarioAdmin: updatedUser.administrador ? 's' : 'n',
          usuarioEmail: updatedUser.email || "",
          usuarioCelular: updatedUser.celular || "",
          usuarioPrestador: updatedUser.prestador ? 's' : 'n',
          usuarioCidade: updatedUser.usuarioCidade || 0,
          usuarioAreaLat: updatedUser.usuarioAreaLat || "",
          usuarioAreaLng: updatedUser.usuarioAreaLng || "",
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erro ao alterar a senha.');
      }

      toast.success('Senha alterada com sucesso!');
      closePasswordModal();
    } catch (err) {
      console.error(err);
      setError(err.message);
      toast.error(`Falha ao alterar a senha: ${err.message}`);
    }
  };

  // Função para alternar o status ativo do usuário com confirmação
  const handleToggleAtivo = async (userId) => {
    const user = users.find(u => u.usuarioId === userId);
    if (!user) {
      toast.error('Usuário não encontrado.');
      return;
    }

    // Mensagem de confirmação
    const confirmAction = window.confirm(`Você tem certeza que deseja ${user.ativo ? 'desativar' : 'ativar'} o usuário "${user.nome}"?`);

    if (!confirmAction) {
      return; // Se o usuário cancelar, não faz nada
    }

    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      const cidadeId = localStorage.getItem('cidadeId') || sessionStorage.getItem('cidadeId');
      const updatedAtivo = user.ativo ? 'n' : 's';

      const response = await fetch(`https://api.ads10.com.br/api/usuario/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify({
          usuarioId: user.usuarioId,
          usuarioUserName: user.login,
          usuarioNome: user.nome,
          usuarioSenha: user.usuarioSenha || "ManterSenhaExistente", // Preservar a senha existente
          usuarioAtivo: updatedAtivo,
          usuarioDataInclusao: user.usuarioDataInclusao,
          usuarioUserInclusao: user.usuarioUserInclusao,
          usuarioDataAlteracao: new Date().toISOString().split('T')[0],
          usuarioUserAlteracao: "admin", // Substitua pelo identificador do admin atual
          usuarioMotorista: user.usuarioMotorista || "",
          usuarioAdmin: user.administrador ? 's' : 'n',
          usuarioEmail: user.email || "",
          usuarioCelular: user.celular || "",
          usuarioPrestador: user.prestador ? 's' : 'n',
          usuarioCidade: user.usuarioCidade || 0,
          usuarioAreaLat: user.usuarioAreaLat || "",
          usuarioAreaLng: user.usuarioAreaLng || "",
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erro ao atualizar o status do usuário.');
      }

      // Atualizar o estado local
      setUsers(prevUsers =>
        prevUsers.map(u =>
          u.usuarioId === userId ? { ...u, ativo: updatedAtivo === 's' } : u
        )
      );

      toast.success(`Usuário ${updatedAtivo === 's' ? 'ativado' : 'desativado'} com sucesso!`);
    } catch (err) {
      console.error(err);
      setError(err.message);
      toast.error(`Falha ao atualizar o status do usuário: ${err.message}`);
    }
  };

  // Função para ordenar os usuários
  const sortUsers = (field) => {
    let newSortOrder = 'asc';
    if (sortField === field && sortOrder === 'asc') {
      newSortOrder = 'desc';
    }
    setSortField(field);
    setSortOrder(newSortOrder);

    const sortedUsers = [...users].sort((a, b) => {
      if (typeof a[field] === 'string') {
        const valueA = a[field].toUpperCase();
        const valueB = b[field].toUpperCase();
        if (valueA < valueB) return newSortOrder === 'asc' ? -1 : 1;
        if (valueA > valueB) return newSortOrder === 'asc' ? 1 : -1;
        return 0;
      } else if (typeof a[field] === 'boolean') {
        return newSortOrder === 'asc'
          ? (a[field] === b[field] ? 0 : a[field] ? -1 : 1)
          : (a[field] === b[field] ? 0 : a[field] ? 1 : -1);
      } else {
        return 0;
      }
    });

    setUsers(sortedUsers);
  };

  // Função para buscar usuários reais da API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
        const cidadeId = localStorage.getItem('cidadeId') || sessionStorage.getItem('cidadeId');

        const response = await fetch('https://api.ads10.com.br/api/usuario', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'cidadeId': cidadeId,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Erro ao buscar os usuários.');
        }

        const data = await response.json();

        // Mapear os dados recebidos para o formato esperado pelo componente
        const mappedUsers = data.map(user => ({
          usuarioId: user.usuarioId,
          login: user.usuarioUserName,
          nome: user.usuarioNome,
          usuarioSenha: user.usuarioSenha, // Inclua a senha se necessário
          administrador: user.usuarioAdmin.toLowerCase() === 's',
          prestador: user.usuarioPrestador.toLowerCase() === 's',
          email: user.usuarioEmail !== "0" ? user.usuarioEmail : '',
          celular: user.usuarioCelular !== "0" ? user.usuarioCelular : '',
          ativo: user.usuarioAtivo.toLowerCase() === 's',
          usuarioDataInclusao: user.usuarioDataInclusao,
          usuarioUserInclusao: user.usuarioUserInclusao,
          usuarioDataAlteracao: user.usuarioDataAlteracao,
          usuarioUserAlteracao: user.usuarioUserAlteracao,
          usuarioMotorista: user.usuarioMotorista || "",
          usuarioCidade: user.usuarioCidade || 0,
          usuarioAreaLat: user.usuarioAreaLat || "",
          usuarioAreaLng: user.usuarioAreaLng || "",
        }));

        setUsers(mappedUsers);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setError(err.message);
        setIsLoading(false);
        toast.error(`Falha ao carregar os usuários: ${err.message}`);
      }
    };

    fetchUsers();
  }, []);

  // Filtragem de usuários com base no termo de pesquisa
  const filteredUsers = users.filter(user =>
    user.login.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.celular.includes(searchTerm)
  );

  // Função para obter o ícone de acordo com o tipo de usuário
  const getUserTypeIcon = (user) => {
    if (user.administrador) {
      return <FaUserTie title="Administrador" />;
    } else if (user.prestador) {
      return <FaHardHat title="Prestador de Serviço" />;
    } else {
      return <FaUser title="Usuário Comum" />;
    }
  };

  return (
    <PageLayout title="Administrador">
      <div className={styles.administradorContainer}>
        <div className={styles.topControls}>
          <div className={styles.searchContainer}>
            <FaSearch className={styles.searchIcon} />
            <input
              type="text"
              placeholder="Pesquisar usuários..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={styles.searchInput}
            />
          </div>
        </div>

        <div className={styles.tableContainer}>
          {isLoading ? (
            <p>Carregando usuários...</p>
          ) : error ? (
            <p className={styles.error}>{error}</p>
          ) : (
            <table className={styles.userTable}>
              <thead>
                <tr>
                  <th>ID</th> {/* Nova coluna para o ID */}
                  <th></th> {/* Coluna para o ícone */}
                  <th onClick={() => sortUsers('login')} className={styles.sortable}>
                    Login
                    {sortField === 'login' ? (
                      sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </th>
                  <th onClick={() => sortUsers('nome')} className={styles.sortable}>
                    Nome
                    {sortField === 'nome' ? (
                      sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </th>
                  <th onClick={() => sortUsers('administrador')} className={styles.sortable}>
                    Administrador
                    {sortField === 'administrador' ? (
                      sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </th>
                  <th onClick={() => sortUsers('email')} className={styles.sortable}>
                    Email
                    {sortField === 'email' ? (
                      sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </th>
                  <th onClick={() => sortUsers('celular')} className={styles.sortable}>
                    Celular
                    {sortField === 'celular' ? (
                      sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.length > 0 ? (
                  filteredUsers.map(user => (
                    <tr key={user.usuarioId}>
                      <td>{user.usuarioId}</td> {/* Exibindo o ID do usuário */}
                      <td className={styles.iconCell}>
                        <div className={styles.tooltipContainer}>
                          {getUserTypeIcon(user)}
                          <span className={styles.tooltipText}>
                            {user.nome} - {user.administrador ? 'Administrador' : user.prestador ? 'Prestador de Serviço' : 'Usuário Comum'}
                          </span>
                        </div>
                      </td>
                      <td>{user.login}</td>
                      <td>{user.nome}</td>
                      <td>{user.administrador ? 'Sim' : 'Não'}</td>
                      <td>{user.email}</td>
                      <td>{user.celular}</td>
                      <td>
                        <button
                          className={styles.actionButton}
                          onClick={() => openEditModal(user)}
                          title="Editar Usuário"
                          aria-label={`Editar usuário ${user.nome}`}
                        >
                          <FaEdit />
                        </button>
                        <button
                          className={styles.actionButton}
                          onClick={() => openPasswordModal(user)}
                          title="Alterar Senha"
                          aria-label={`Alterar senha do usuário ${user.nome}`}
                        >
                          <FaKey />
                        </button>
                        <button
                          className={`${styles.actionButton} ${user.ativo ? styles.toggleActive : styles.toggleInactive}`}
                          onClick={() => handleToggleAtivo(user.usuarioId)}
                          title={user.ativo ? "Desativar Usuário" : "Ativar Usuário"}
                          aria-label={`${user.ativo ? "Desativar" : "Ativar"} usuário ${user.nome}`}
                        >
                          {user.ativo ? <FaToggleOn /> : <FaToggleOff />}
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className={styles.noData}>
                      Nenhum usuário encontrado.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>

        {/* Modal de Edição de Usuário */}
        {isEditModalOpen && (
          <UserModal
            isOpen={isEditModalOpen}
            onRequestClose={closeEditModal}
            userData={selectedUser}
            onSave={handleSaveUser}
          />
        )}

        {/* Modal de Alteração de Senha */}
        {isPasswordModalOpen && (
          <PasswordModal
            isOpen={isPasswordModalOpen}
            onRequestClose={closePasswordModal}
            userData={selectedPasswordUser}
            onSave={handleSavePassword}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default Administrador;
