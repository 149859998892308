// src/components/Toolbar/Toolbar.js

import React from 'react';
import PropTypes from 'prop-types';
import { FaSatellite, FaCamera, FaEye, FaEyeSlash } from 'react-icons/fa'; // FaStreetView removido
import styles from './Toolbar.module.css';

const Toolbar = ({
  toggleSatelliteView,
  isSatelliteView,
  toggleTilt,
  tiltEnabled,
  toggleLegend,
  isLegendVisible,
  disableTilt,
  disableLegend,
}) => {
  let buttonCount = 1;
  if (!disableTilt) buttonCount += 1;
  if (!disableLegend) buttonCount += 1;

  const isSingleButton = buttonCount === 1;
  const containerClass = `${styles.toolbarContainer} ${
    isSingleButton ? styles.singleButton : ''
  }`;

  return (
    <div className={containerClass}>
      {/* Botão de Satélite/Mapa Padrão */}
      <button
        onClick={toggleSatelliteView}
        className={styles.iconButton}
        aria-label="Alternar Visão do Mapa"
      >
        <FaSatellite color={isSatelliteView ? '#000' : '#555'} />
      </button>

      {/* Botão de Inclinação */}
      {!disableTilt && (
        <button
          onClick={toggleTilt}
          className={styles.iconButton}
          aria-label="Alternar Inclinação da Câmera"
        >
          <FaCamera color={tiltEnabled ? '#000' : '#555'} />
        </button>
      )}

      {/* Botão de Legenda */}
      {!disableLegend && (
        <button
          onClick={toggleLegend}
          className={`${styles.iconButton} ${
            isLegendVisible ? styles.active : ''
          }`}
          aria-label="Ligar/Desligar Legenda"
        >
          {isLegendVisible ? <FaEyeSlash /> : <FaEye />}
        </button>
      )}
    </div>
  );
};

Toolbar.propTypes = {
  toggleSatelliteView: PropTypes.func.isRequired,
  isSatelliteView: PropTypes.bool.isRequired,
  toggleTilt: PropTypes.func,
  tiltEnabled: PropTypes.bool,
  toggleLegend: PropTypes.func,
  isLegendVisible: PropTypes.bool,
  disableTilt: PropTypes.bool,
  disableLegend: PropTypes.bool,
};

Toolbar.defaultProps = {
  toggleTilt: () => {},
  tiltEnabled: false,
  toggleLegend: () => {},
  isLegendVisible: false,
  disableTilt: false,
  disableLegend: false,
};

export default Toolbar;
