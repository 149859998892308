import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styles from './EditServiceOrderModal.module.css';
import { toast } from 'react-toastify';
import useAlertaSituacao from '../../hooks/useAlertaSituacao';

Modal.setAppElement('#root');

const EditServiceOrderModal = ({ isOpen, onRequestClose, order, alerta, onSave, isViewOnly, isEditingObservation }) => {
  const [status, setStatus] = useState('');
  const [observacao, setObservacao] = useState('');
  const [isAlertInfoVisible, setIsAlertInfoVisible] = useState(false);

  const { situacoes, isLoading: situacoesLoading, error: situacoesError } = useAlertaSituacao();

  useEffect(() => {
    if (order) {
      setStatus(isViewOnly || !isEditingObservation ? order.osSituacaoId.toString() || '' : '');
      setObservacao(order.osObs || '');
      setIsAlertInfoVisible(false);
    }
  }, [order, isOpen, isViewOnly, isEditingObservation]);

  const handleSave = () => {
    if (isEditingObservation) {
      // Lógica para salvar observações
      const updatedData = { 
        ...order, 
        osObs: observacao,
      };
      onSave(updatedData);
    } else {
      // Lógica para salvar status
      if (status === '') {
        toast.error('Por favor, selecione um status válido.');
        return;
      }

      const validStatusIds = ['3', '4'];
      if (!validStatusIds.includes(status)) {
        toast.error('Selecione um status válido: Cancelada ou Finalizada.');
        return;
      }

      const updatedData = { 
        ...order, 
        osSituacaoId: parseInt(status),
      };
      
      console.log('Dados atualizados para salvar:', updatedData);
      onSave(updatedData);
    }
  };

  const toggleAlertInfo = () => {
    setIsAlertInfoVisible((prevState) => !prevState);
  };

  const getSituacaoNome = (situacaoId) => {
    return situacoes[situacaoId] || 'Desconhecido';
  };

  if (situacoesLoading) {
    return <div>Carregando...</div>;
  }

  if (situacoesError) {
    return <div className={styles.error}>Erro ao carregar as situações: {situacoesError}</div>;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={
        isViewOnly 
          ? "Visualizar Ordem de Serviço" 
          : isEditingObservation 
            ? "Editar Observação da OS" 
            : "Editar Ordem de Serviço"
      }
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <button className={styles.closeModalButton} onClick={onRequestClose}>
        <FaTimes />
      </button>
      <h2>
        {isViewOnly 
          ? "Visualizar Ordem de Serviço" 
          : isEditingObservation 
            ? "Editar Observação da OS" 
            : "Editar Ordem de Serviço"
        }
      </h2>

      {/* Botão para expandir/colapsar informações do alerta */}
      <button className={styles.toggleButton} onClick={toggleAlertInfo}>
        {isAlertInfoVisible ? (
          <>
            <FaChevronUp /> Ocultar Informações do Alerta
          </>
        ) : (
          <>
            <FaChevronDown /> Exibir Informações do Alerta
          </>
        )}
      </button>

      {/* Seção de Informações do Alerta */}
      {isAlertInfoVisible && alerta && (
        <div className={styles.infoSection}>
          <h3>Informações do Alerta</h3>
          <p><strong>ID do Alerta:</strong> {alerta.alertaId}</p>
          <p><strong>Motivo:</strong> {alerta.alertaMotivo}</p>
          <p><strong>Poste ID:</strong> {alerta.alertaPosteId}</p>
          <p><strong>Cidade:</strong> {alerta.alertaCidade}</p>
          <p><strong>Telefone:</strong> {alerta.alertaTelefone}</p>
          <p><strong>Origem:</strong> {alerta.alertaOrigem}</p>
          <p><strong>Prestador:</strong> {alerta.prestadorNome || 'Desconhecido'}</p>
        </div>
      )}

      {/* Seção de Informações da OS */}
      <div className={styles.infoSection}>
        <h3>Informações da Ordem de Serviço</h3>
        <p><strong>ID da OS:</strong> {order.osId || 'Não Disponível'}</p>
        <p><strong>Poste ID:</strong> {order.osPosteId || 'Não Disponível'}</p>
        <p><strong>Cidade ID:</strong> {order.osCidadeId || 'Não Disponível'}</p>
        <p><strong>Data de Abertura:</strong> {order.osDataAbertura ? new Date(order.osDataAbertura).toLocaleDateString() : 'Não Disponível'}</p>
        <p><strong>Hora de Abertura:</strong> {order.osHoraAbertura || 'Não Disponível'}</p>
        <p><strong>Prestador:</strong> {alerta?.prestadorNome || 'Desconhecido'}</p>
        <p><strong>Status Atual:</strong> {getSituacaoNome(order.osSituacaoId)}</p>
        <p><strong>Observações:</strong> {order.osObs || 'Nenhuma Observação'}</p>
      </div>

      {/* Formulário de Edição */}
      {!isViewOnly && (
        <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
          {isEditingObservation ? (
            <div className={styles.formGroup}>
              <label>Observação:</label>
              <textarea 
                value={observacao}
                onChange={(e) => setObservacao(e.target.value)}
                rows="4"
                className={styles.textarea}
                required
              />
            </div>
          ) : (
            <div className={styles.formGroup}>
              <label>Status:</label>
              <select value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="">Selecione</option>
                {Object.entries(situacoes).map(([id, nome]) => (
                  (id === '3' || id === '4') && (
                    <option key={id} value={id}>
                      {nome}
                    </option>
                  )
                ))}
              </select>
            </div>
          )}
          <button type="submit" className={styles.saveButton}>Salvar</button>
        </form>
      )}
    </Modal>
  );
};

export default EditServiceOrderModal;
