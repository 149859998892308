import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    FaUser,
    FaClipboardList,
    FaBolt,
    FaFileAlt,
    FaCog,
    FaBell,
    FaTools,
    FaLightbulb,
    FaTachometerAlt,
    FaMap,
    FaSignOutAlt,
    FaBars,
    FaTimes,
    FaUserShield,
    FaUserCog,
    FaCoins,
    FaRoad,
    FaCogs,
    FaCity,
    FaMapMarkedAlt,
    FaLaptop, // Importando FaLaptop
} from 'react-icons/fa';
import { BiMove } from 'react-icons/bi'; // Importando o ícone BiMove
import styles from './Sidebar.module.css';
import NavItem from './NavItem';
import SubMenu from './SubMenu';
import directLuzLogo from '../../assets/login-directluz-icone.png';
import { AuthContext } from '../../context/AuthContext';
import { BASE_URL, getCityNameById } from '../../config';

const Sidebar = () => {
    const [isCadastrosOpen, setIsCadastrosOpen] = useState(false);
    const [isCadastrosDeSistemaOpen, setIsCadastrosDeSistemaOpen] = useState(false);
    const [isConfiguracoesOpen, setIsConfiguracoesOpen] = useState(false);
    const [isMapaOpen, setIsMapaOpen] = useState(false);
    const [unresolvedAlerts, setUnresolvedAlerts] = useState(0);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [usuarioNome, setUsuarioNome] = useState('');
    const [nomeCidade, setNomeCidade] = useState('');
    const { logout, cidadeId } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const sidebarRef = useRef(null);
    const toggleButtonRef = useRef(null);

    const authToken = () => {
        return localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
    };

    useEffect(() => {
        const fetchAlertas = async () => {
            try {
                const token = authToken();
                if (!token) {
                    console.error("Token de autenticação não encontrado");
                    return;
                }

                const response = await fetch(`${BASE_URL}/alerta`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    console.error("Erro ao carregar alertas: Status da resposta não é OK");
                    return;
                }

                const data = await response.json();
                const unresolvedCount = data.filter(alert => alert.alertaSituacao === '1').length;
                setUnresolvedAlerts(unresolvedCount);
            } catch (error) {
                console.error("Erro ao carregar os alertas:", error);
            }
        };

        fetchAlertas();
    }, [cidadeId]);

    useEffect(() => {
        const storedUsuarioNome = localStorage.getItem('usuarioNome') || sessionStorage.getItem('usuarioNome');
        if (storedUsuarioNome) {
            setUsuarioNome(storedUsuarioNome);
        } else {
            const fetchUsuario = async () => {
                try {
                    const token = authToken();
                    if (!token) {
                        console.error("Token de autenticação não encontrado");
                        return;
                    }

                    const response = await fetch(`${BASE_URL}/usuario`, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        const usuario = data.find(user => user.usuarioNome);
                        if (usuario) {
                            setUsuarioNome(usuario.usuarioNome);
                        }
                    } else {
                        console.error('Erro ao buscar dados do usuário.');
                    }
                } catch (error) {
                    console.error('Erro ao carregar dados do usuário:', error);
                }
            };

            fetchUsuario();
        }

        if (cidadeId) {
            const cidade = getCityNameById(cidadeId);
            setNomeCidade(cidade || 'Cidade Desconhecida');
        }
    }, [cidadeId]);

    useEffect(() => {
        setIsSidebarOpen(false);
    }, [location.pathname]);

    useEffect(() => {
        if (isSidebarOpen && sidebarRef.current) {
            sidebarRef.current.focus();
        } else if (!isSidebarOpen && toggleButtonRef.current) {
            toggleButtonRef.current.focus();
        }
    }, [isSidebarOpen]);

    const toggleCadastros = () => {
        setIsCadastrosOpen(!isCadastrosOpen);
    };
    const toggleCadastrosDeSistema = () => {
        setIsCadastrosDeSistemaOpen(!isCadastrosDeSistemaOpen);
    };
    const toggleConfiguracoes = () => {
        setIsConfiguracoesOpen(!isConfiguracoesOpen);
    };
    const toggleMapa = () => {
        setIsMapaOpen(!isMapaOpen);
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleLogout = () => {
        const confirmLogout = window.confirm('Tem certeza de que deseja sair?');
        if (confirmLogout) {
            logout();
            navigate('/login');
        }
    };

    const navItems = [
        { to: '/dashboard', icon: FaTachometerAlt, label: 'Dashboard' },
        {
            label: 'Mapa',
            icon: FaMap,
            onClick: toggleMapa,
            children: [
                { to: '/mapa-dispositivo', icon: FaLaptop, label: 'Telegestão' }, // Substituído FaTools por FaLaptop
                { to: '/mover-pontos', icon: BiMove, label: 'Mover Pontos' }, // Substituí o ícone para BiMove
            ],
        },
        {
            label: 'Cadastros',
            icon: FaClipboardList,
            onClick: toggleCadastros,
            children: [
                { to: '/postes', icon: FaLightbulb, label: 'Postes' },
                { to: '/dispositivos', icon: FaTools, label: 'Dispositivos' },
                { to: '/perfis-configuracao', icon: FaCog, label: 'Perfis de Configuração' },
                { to: '/grupos-postes', icon: FaMap, label: 'Criação de Grupos' },
            ],
        },
        {
            label: 'Cadastros de Sistema',
            icon: FaCoins,
            onClick: toggleCadastrosDeSistema,
            children: [
                { to: '/bracos', icon: FaTools, label: 'Braços' },
                { to: '/lampadas', icon: FaLightbulb, label: 'Lâmpadas' },
                { to: '/classificacaovias', icon: FaRoad, label: 'Classificação Via' },
                { to: '/formatopostes', icon: FaTools, label: 'Formato Postes' },
                { to: '/formatotampas', icon: FaCogs, label: 'Formato Tampas' },
                { to: '/regioes', icon: FaMapMarkedAlt, label: 'Regiões' },
                { to: '/tipopostes', icon: FaCog, label: 'Tipos de Poste' },
                { to: '/tipovias', icon: FaRoad, label: 'Tipos de Via' },
            ],
        },
        { to: '/levantamentos', icon: FaBolt, label: 'Levantamentos' },
        { to: '/relatorios', icon: FaFileAlt, label: 'Relatórios' },
        {
            label: 'Configurações',
            icon: FaCog,
            onClick: toggleConfiguracoes,
            children: [
                { to: '/minhas-configuracoes', icon: FaUserCog, label: 'Minhas Configurações' },
                { to: '/administrador', icon: FaUserShield, label: 'Administrador' },
            ],
        },
        { to: '/alertas', icon: FaBell, label: 'Alertas', notification: unresolvedAlerts },
        { to: '/ordens-servico', icon: FaTools, label: 'Ordens de Serviço' },
    ];

    return (
        <>
            <button
                className={styles.toggleButton}
                onClick={toggleSidebar}
                aria-label={isSidebarOpen ? 'Fechar menu' : 'Abrir menu'}
                aria-expanded={isSidebarOpen}
                ref={toggleButtonRef}
            >
                {isSidebarOpen ? <FaTimes /> : <FaBars />}
            </button>

            {isSidebarOpen && <div className={styles.overlay} onClick={toggleSidebar} aria-hidden="true"></div>}

            <aside
                className={`${styles.sidebar} ${isSidebarOpen ? styles.open : ''}`}
                ref={sidebarRef}
                tabIndex={-1}
                aria-hidden={!isSidebarOpen}
            >
                <div className={styles.logo}>
                    <img src={directLuzLogo} alt="DirectLuz Logo" className={styles.logoImage} />
                </div>
                <div className={styles.user}>
                    <FaUser className={styles.icon} />
                    <div className={styles.userInfo}>
                        <span className={styles.userName}>{usuarioNome}</span>
                        {nomeCidade && (
                            <div className={styles.tooltipContainer}>
                                <span className={styles.cityName}>
                                    <FaCity className={styles.cityIcon} />
                                    {nomeCidade}
                                </span>
                                <span className={styles.tooltipText}>Código da Cidade: {cidadeId}</span>
                            </div>
                        )}
                    </div>
                </div>
                <nav className={styles.nav} aria-label="Navegação Principal">
                    <ul>
                        {navItems.map((item, index) => (
                            item.children ? (
                                <React.Fragment key={index}>
                                    <NavItem
                                        icon={item.icon}
                                        label={item.label}
                                        onClick={item.onClick}
                                    />
                                    {item.label === 'Mapa' && isMapaOpen && <SubMenu items={item.children} />}
                                    {item.label === 'Cadastros' && isCadastrosOpen && <SubMenu items={item.children} />}
                                    {item.label === 'Cadastros de Sistema' && isCadastrosDeSistemaOpen && <SubMenu items={item.children} />}
                                    {item.label === 'Configurações' && isConfiguracoesOpen && <SubMenu items={item.children} />}
                                </React.Fragment>
                            ) : (
                                <NavItem
                                    key={index}
                                    to={item.to}
                                    icon={item.icon}
                                    label={item.label}
                                    notification={item.notification}
                                />
                            )
                        ))}
                    </ul>
                </nav>
                <div className={styles.logoutSection}>
                    <button onClick={handleLogout} className={styles.logoutButton}>
                        <FaSignOutAlt className={styles.icon} />
                        <span>Sair</span>
                    </button>
                </div>
            </aside>
        </>
    );
};

export default Sidebar;
