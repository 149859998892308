// src/pages/MinhasConfiguracoes/MinhasConfiguracoes.jsx

import React, { useState, useContext, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { FaTimes, FaEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import styles from './MinhasConfiguracoes.module.css';
import { AuthContext } from '../../context/AuthContext';
import PageLayout from '../../components/PageLayout/PageLayout';

const MinhasConfiguracoes = () => {
  const { usuarioId, token, cidadeId, isAuthenticated, loading } = useContext(AuthContext);

  const [userData, setUserData] = useState(null);
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [error, setError] = useState('');
  const [isFetching, setIsFetching] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Função para buscar os dados do usuário
  const fetchUserData = useCallback(async () => {
    try {
      const response = await fetch(`https://api.ads10.com.br/api/usuario/${usuarioId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erro ao buscar os dados do usuário.');
      }

      const data = await response.json();
      setUserData(data);
    } catch (err) {
      toast.error(err.message || 'Erro ao carregar os dados do usuário.');
    } finally {
      setIsFetching(false);
    }
  }, [usuarioId, token, cidadeId]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const closeModal = () => {
    setIsModalOpen(false);
    setNovaSenha('');
    setConfirmarSenha('');
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (novaSenha.length < 6) {
      setError('A senha deve ter no mínimo 6 caracteres.');
      return;
    }

    if (novaSenha !== confirmarSenha) {
      setError('As senhas não coincidem.');
      return;
    }

    setError('');

    try {
      const response = await fetch(`https://api.ads10.com.br/api/usuario/${usuarioId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify({
          ...userData,
          usuarioSenha: novaSenha,
          usuarioDataAlteracao: new Date().toISOString().split('T')[0],
          usuarioUserAlteracao: usuarioId,
        }),
      });

      if (response.ok) {
        toast.success('Senha alterada com sucesso.');
        closeModal();
        fetchUserData();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erro ao alterar a senha.');
      }
    } catch (err) {
      toast.error(err.message || 'Erro ao alterar a senha.');
    }
  };

  if (loading || isFetching) {
    return <p>Carregando...</p>;
  }

  if (!isAuthenticated) {
    return <p className={styles.error}>Usuário não autenticado.</p>;
  }

  return (
    <PageLayout title="Minhas Configurações">
      <div className={styles.container}>
        <div className={styles.controlsTop}>
          <button className={styles.addButton} onClick={() => setIsModalOpen(true)}>
            <FaEdit /> Alterar Senha
          </button>
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          className={styles.modal}
          overlayClassName={styles.overlay}
          ariaHideApp={false}
        >
          <button className={styles.closeModalButton} onClick={closeModal}>
            <FaTimes />
          </button>
          <h2 className={styles.modalTitle}>Alterar Senha</h2>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.formGroup}>
              <label htmlFor="novaSenha">Nova Senha</label>
              <input
                type="password"
                id="novaSenha"
                className={styles.searchInput}
                value={novaSenha}
                onChange={(e) => setNovaSenha(e.target.value)}
                minLength="6"
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="confirmarSenha">Confirmar Nova Senha</label>
              <input
                type="password"
                id="confirmarSenha"
                className={styles.searchInput}
                value={confirmarSenha}
                onChange={(e) => setConfirmarSenha(e.target.value)}
                minLength="6"
                required
              />
            </div>
            {error && <p className={styles.error}>{error}</p>}
            <div className={styles.formActions}>
              <button type="button" className={styles.deleteButton} onClick={closeModal}>
                Cancelar
              </button>
              <button type="submit" className={styles.saveButton}>
                Salvar
              </button>
            </div>
          </form>
        </Modal>
      </div>
    </PageLayout>
  );
};

export default MinhasConfiguracoes;
