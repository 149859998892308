// src/pages/Relatorios/OrdensServicoRelatorio.jsx

import React, { useState, useEffect, useMemo } from 'react';
import { FaFileExport } from 'react-icons/fa';
import useAlertasComOrdemServico from '../../hooks/useAlertasComOrdemServico';
import usePrestadores from '../../hooks/usePrestadores'; // Hook necessário
import usePosteData from '../OrdensServicos/usePostes'; // Import do novo hook
import styles from './Relatorios.module.css';
import * as XLSX from 'xlsx';

// Importamos a função de dicionário para Situação do Alerta
import { fetchAlertaSituacaoMap } from '../../utils/fetchDictionaries';

const OrdensServicoRelatorio = () => {
  // ----- ESTADOS PARA FILTROS -----
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const [filterByEndDate, setFilterByEndDate] = useState(false);
  const [endStartDate, setEndStartDate] = useState('');
  const [endEndDate, setEndEndDate] = useState('');
  const [endStartTime, setEndStartTime] = useState('');
  const [endEndTime, setEndEndTime] = useState('');

  // Novo estado para Situação do Alerta
  const [filterSituacao, setFilterSituacao] = useState('Todos'); // "Todos", "Aguardando", "Atendendo", etc.

  // Novo estado para Prestador
  const [filterPrestador, setFilterPrestador] = useState('Todos'); // "Todos", "Prestador 1", "Prestador 2", etc.

  // Estado para mapear Situação do Alerta
  const [alertaSituacaoMap, setAlertaSituacaoMap] = useState({});

  // Estado para mapear PosteId para Endereço
  const [posteAddressMap, setPosteAddressMap] = useState({});

  // Hooks que carregam Alertas + OS, Prestadores e Postes
  const { alertasComOS, isLoading, error } = useAlertasComOrdemServico();
  const { prestadores, isLoading: loadingPrestadores, error: prestadoresError } = usePrestadores();
  const { postes, isLoadingPostes, errorPostes } = usePosteData(); // Uso do hook de postes

  // Função que converte o ID do prestador em nome
  const getQuemAtendeu = (prestadorId) => {
    if (!Array.isArray(prestadores)) return 'N/A';
    const p = prestadores.find((item) => item.pessoaId === prestadorId);
    return p ? p.pessoaNome : 'N/A';
  };

  // 1. Buscar o mapeamento de Situação do Alerta
  useEffect(() => {
    const getAlertaSituacao = async () => {
      try {
        const situacaoMap = await fetchAlertaSituacaoMap();
        setAlertaSituacaoMap(situacaoMap);
        console.log('Situação do Alerta Mapeada:', situacaoMap);
      } catch (err) {
        console.error('Erro ao buscar mapeamento de Situação do Alerta:', err);
      }
    };
    getAlertaSituacao();
  }, []);

  // 2. Criar mapeamento de PosteId para Endereço
  useEffect(() => {
    if (!isLoadingPostes && !errorPostes && Array.isArray(postes)) {
      const map = {};
      postes.forEach((poste) => {
        map[poste.id] = `${poste.address}, ${poste.posteBairro || ''}`;
      });
      setPosteAddressMap(map);
      console.log('Mapeamento de PosteId para Endereço:', map);
    }
  }, [isLoadingPostes, postes, errorPostes]);

  const handleClearFilters = () => {
    setStartDate('');
    setEndDate('');
    setStartTime('');
    setEndTime('');
    setFilterByEndDate(false);
    setEndStartDate('');
    setEndEndDate('');
    setEndStartTime('');
    setEndEndTime('');
    setFilterSituacao('Todos'); // Resetar filtro de Situação
    setFilterPrestador('Todos'); // Resetar filtro de Prestador
  };

  // 3. Juntamos cada ordemServico com alertaMotivo e filtramos
  const filteredOrdens = useMemo(() => {
    if (isLoading || loadingPrestadores || isLoadingPostes) return [];

    if (!Array.isArray(alertasComOS)) {
      console.error('alertasComOS não é um array:', alertasComOS);
      return [];
    }

    return alertasComOS
      .map((alerta) => {
        if (!alerta.ordemServico) return null;
        return {
          ...alerta.ordemServico,
          alertaMotivo: alerta.alertaMotivo, // descrição do problema
        };
      })
      .filter(Boolean)
      .filter((os) => {
        // Filtragem por data/hora de abertura
        const osOpenDateTime = os.osDataAbertura && os.osHoraAbertura
          ? new Date(`${os.osDataAbertura}T${os.osHoraAbertura}`)
          : null;

        const osCloseDateTime = os.osDataEncerramento && os.osHoraEncerramento
          ? new Date(`${os.osDataEncerramento}T${os.osHoraEncerramento}`)
          : null;

        // Filtro data/hora de Abertura
        const startDateTime = startDate
          ? new Date(`${startDate}T${startTime || '00:00'}`)
          : null;
        const endDateTime = endDate
          ? new Date(`${endDate}T${endTime || '23:59'}`)
          : null;
        const isAfterStart = startDateTime ? osOpenDateTime >= startDateTime : true;
        const isBeforeEnd = endDateTime ? osOpenDateTime <= endDateTime : true;

        // Filtro data/hora de Encerramento
        const endStartDateTime = filterByEndDate && endStartDate
          ? new Date(`${endStartDate}T${endStartTime || '00:00'}`)
          : null;
        const endEndDateTime = filterByEndDate && endEndDate
          ? new Date(`${endEndDate}T${endEndTime || '23:59'}`)
          : null;
        const isAfterCloseStart =
          endStartDateTime && osCloseDateTime
            ? osCloseDateTime >= endStartDateTime
            : true;
        const isBeforeCloseEnd =
          endEndDateTime && osCloseDateTime
            ? osCloseDateTime <= endEndDateTime
            : true;

        // Filtro por Situação do Alerta
        let matchesSituacao = true;
        if (filterSituacao !== 'Todos') {
          matchesSituacao = os.osSituacaoId === filterSituacao;
        }

        // Filtro por Prestador
        let matchesPrestador = true;
        if (filterPrestador !== 'Todos') {
          matchesPrestador = os.osPrestadorId === filterPrestador;
        }

        const isMatch =
          isAfterStart &&
          isBeforeEnd &&
          isAfterCloseStart &&
          isBeforeCloseEnd &&
          (!filterByEndDate || osCloseDateTime) &&
          matchesSituacao &&
          matchesPrestador;

        if (!isMatch) {
          console.log('Ordem de Serviço filtrada:', os);
        }

        return isMatch;
      });
  }, [
    alertasComOS,
    isLoading,
    loadingPrestadores,
    isLoadingPostes,
    endStartDate,
    endEndDate,
    endStartTime,
    endEndTime,
    filterByEndDate,
    startDate,
    startTime,
    endDate,
    endTime,
    filterSituacao,
    filterPrestador,
  ]);

  // 4. Exportar para Excel incluindo "Descrição do Problema", "Quem Atendeu" e "Endereço do Poste"
  const handleExportToExcel = () => {
    if (!Array.isArray(filteredOrdens)) {
      console.error('filteredOrdens não é um array:', filteredOrdens);
      return;
    }

    const exportData = filteredOrdens.map((os) => ({
      'ID da Ordem de Serviço': os.osId,
      'ID do Poste': os.osPosteId || 'N/A',
      'Endereço do Poste': posteAddressMap[os.osPosteId] || 'N/A', // Adicionado Endereço
      'Data de Abertura': os.osDataAbertura || 'N/A',
      'Hora de Abertura': os.osHoraAbertura || 'N/A',
      'Situação': alertaSituacaoMap[os.osSituacaoId] || os.osSituacaoId || 'N/A',
      'Data de Encerramento': os.osDataEncerramento || 'N/A',
      'Hora de Encerramento': os.osHoraEncerramento || 'N/A',
      'Observações': os.osObs || 'N/A',
      'Descrição do Problema': os.alertaMotivo || 'N/A',
      'Quem Atendeu': getQuemAtendeu(os.osPrestadorId) || 'N/A',
      'Prestador de Serviço ID': os.osPrestadorId || 'N/A',
      'Usuário Abertura ID': os.osUsuarioAbertura || 'N/A',
      'Usuário Encerramento ID': os.osUsuarioEncerramento || 'N/A',
      'Cidade ID': os.osCidadeId || 'N/A',
      'ID do Alerta': os.osAlertaId || 'N/A',
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Ordens de Serviço');
    XLSX.writeFile(workbook, 'relatorio_ordens_servico.xlsx');
  };

  return (
    <div className={styles.container}>
      <div className={styles.controlsTop}>

        {/* Filtro de abertura */}
        <div className={styles.filterGroup}>
          <h3>Filtro de Geração</h3>
          <div className={styles.dateGroup}>
            <label htmlFor="startDate">Data Inicial de Abertura:</label>
            <input
              type="date"
              id="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className={styles.dateInput}
            />
          </div>
          <div className={styles.dateGroup}>
            <label htmlFor="startTime">Hora Inicial de Abertura:</label>
            <input
              type="time"
              id="startTime"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              className={styles.timeInput}
            />
          </div>
          <div className={styles.dateGroup}>
            <label htmlFor="endDate">Data Final de Abertura:</label>
            <input
              type="date"
              id="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className={styles.dateInput}
            />
          </div>
          <div className={styles.dateGroup}>
            <label htmlFor="endTime">Hora Final de Abertura:</label>
            <input
              type="time"
              id="endTime"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              className={styles.timeInput}
            />
          </div>
        </div>

        {/* Checkbox e filtros de encerramento */}
        <div className={styles.filterGroup}>
          <div className={styles.checkboxGroup}>
            <label htmlFor="filterByEndDate">
              <input
                type="checkbox"
                id="filterByEndDate"
                checked={filterByEndDate}
                onChange={(e) => setFilterByEndDate(e.target.checked)}
              />
              Filtrar por data de encerramento
            </label>
          </div>
          {filterByEndDate && (
            <>
              <div className={styles.dateGroup}>
                <label htmlFor="endStartDate">Data Inicial de Encerramento:</label>
                <input
                  type="date"
                  id="endStartDate"
                  value={endStartDate}
                  onChange={(e) => setEndStartDate(e.target.value)}
                  className={styles.dateInput}
                />
              </div>
              <div className={styles.dateGroup}>
                <label htmlFor="endStartTime">Hora Inicial de Encerramento:</label>
                <input
                  type="time"
                  id="endStartTime"
                  value={endStartTime}
                  onChange={(e) => setEndStartTime(e.target.value)}
                  className={styles.timeInput}
                />
              </div>
              <div className={styles.dateGroup}>
                <label htmlFor="endEndDate">Data Final de Encerramento:</label>
                <input
                  type="date"
                  id="endEndDate"
                  value={endEndDate}
                  onChange={(e) => setEndEndDate(e.target.value)}
                  className={styles.dateInput}
                />
              </div>
              <div className={styles.dateGroup}>
                <label htmlFor="endEndTime">Hora Final de Encerramento:</label>
                <input
                  type="time"
                  id="endEndTime"
                  value={endEndTime}
                  onChange={(e) => setEndEndTime(e.target.value)}
                  className={styles.timeInput}
                />
              </div>
            </>
          )}
        </div>

        {/* Novo Filtro: Situação do Alerta */}
        <div className={styles.filterGroup}>
          <h3>Filtro de Situação</h3>
          <div className={styles.controlGroup}>
            <label htmlFor="filterSituacao">Situação do Alerta:</label>
            <select
              id="filterSituacao"
              className={styles.dropdown}
              value={filterSituacao}
              onChange={(e) => setFilterSituacao(e.target.value)}
            >
              <option value="Todos">Todos</option>
              {Object.entries(alertaSituacaoMap).map(([id, nome]) => (
                <option key={id} value={id}>
                  {nome}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Novo Filtro: Quem Atendeu */}
        <div className={styles.filterGroup}>
          <h3>Filtro de Prestador</h3>
          <div className={styles.controlGroup}>
            <label htmlFor="filterPrestador">Quem Atendeu:</label>
            <select
              id="filterPrestador"
              className={styles.dropdown}
              value={filterPrestador}
              onChange={(e) => setFilterPrestador(e.target.value)}
            >
              <option value="Todos">Todos</option>
              {Array.isArray(prestadores) && prestadores.length > 0 ? (
                prestadores.map((prestador) => (
                  <option key={prestador.pessoaId} value={prestador.pessoaId}>
                    {prestador.pessoaNome}
                  </option>
                ))
              ) : (
                <option value="N/A">Nenhum prestador disponível</option>
              )}
            </select>
          </div>
        </div>

        {/* Botões de Ação */}
        <div className={styles.actionsContainer}>
          <button onClick={handleExportToExcel} className={styles.exportButton}>
            <FaFileExport /> Exportar para Excel
          </button>
          <button onClick={handleClearFilters} className={styles.clearFiltersButton}>
            Limpar Filtros
          </button>
        </div>
      </div>

      {/* Mensagens de Estado */}
      {(isLoading || loadingPrestadores || isLoadingPostes) && (
        <p className={styles.infoMessage}>Carregando dados...</p>
      )}
      {(error || prestadoresError || errorPostes) && (
        <>
          {error && <p className={styles.errorMessage}>{error}</p>}
          {prestadoresError && <p className={styles.errorMessage}>{prestadoresError}</p>}
          {errorPostes && <p className={styles.errorMessage}>{errorPostes}</p>}
        </>
      )}

      {/* Tabela */}
      {!isLoading && !loadingPrestadores && !isLoadingPostes && Array.isArray(filteredOrdens) && filteredOrdens.length > 0 ? (
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>ID do Poste</th>
                <th>Endereço do Poste</th> {/* Nova coluna */}
                <th>Data Abertura</th>
                <th>Hora Abertura</th>
                <th>Situação</th>
                <th>Data Encerramento</th>
                <th>Hora Encerramento</th>
                <th>Observação</th>
                <th>Descrição do Problema</th>
                <th>Quem Atendeu</th> {/* Nova coluna */}
              </tr>
            </thead>
            <tbody>
              {filteredOrdens.map((os) => (
                <tr key={os.osId}>
                  <td>{os.osId}</td>
                  <td>{os.osPosteId || 'N/A'}</td>
                  <td>{posteAddressMap[os.osPosteId] || 'N/A'}</td> {/* Exibição do Endereço */}
                  <td>{os.osDataAbertura || 'N/A'}</td>
                  <td>{os.osHoraAbertura || 'N/A'}</td>
                  <td>{alertaSituacaoMap[os.osSituacaoId] || os.osSituacaoId || 'N/A'}</td>
                  <td>{os.osDataEncerramento || 'N/A'}</td>
                  <td>{os.osHoraEncerramento || 'N/A'}</td>
                  <td>{os.osObs || 'N/A'}</td>
                  <td>{os.alertaMotivo || 'N/A'}</td>
                  <td>{getQuemAtendeu(os.osPrestadorId)}</td> {/* Exibindo o prestador */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        !isLoading && !loadingPrestadores && !isLoadingPostes && Array.isArray(filteredOrdens) && filteredOrdens.length === 0 && (
          <p className={styles.infoMessage}>
            Nenhuma ordem de serviço encontrada para os critérios selecionados.
          </p>
        )
      )}
    </div>
  );
};

export default OrdensServicoRelatorio;
