import React, { useState } from 'react';
import Modal from 'react-modal';
import { FaTimes, FaSave } from 'react-icons/fa';
import styles from './DetalhesVerificacaoModal.module.css';

// Lista de Verificações Básicas
const verificacoesBasicas = [
  'Condição Física',
  'Integridade Estrutural',
  'Funcionalidade Elétrica',
  'Estabilidade de Montagem',
  'Precisão da Localização',
  'Segurança',
  'Conformidade com Regulamentos',
];

const DetalhesVerificacaoModal = ({
  isOpen,
  onRequestClose,
  poste,
  onAtualizarPoste,
}) => {
  const [verificacoes, setVerificacoes] = useState(poste.verificacoes || []);

  const handleVerificacaoChange = (nomeVerificacao, novoStatus) => {
    setVerificacoes((prev) =>
      prev.map((verificacao) =>
        verificacao.nome === nomeVerificacao
          ? { ...verificacao, status: novoStatus }
          : verificacao
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const posteAtualizado = { ...poste, verificacoes, verificado: true };
    onAtualizarPoste(posteAtualizado);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Detalhes da Verificação"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalHeader}>
        <h2>Verificação do Poste: {poste.nomePoste}</h2>
        <button className={styles.closeButton} onClick={onRequestClose}>
          <FaTimes />
        </button>
      </div>
      <form onSubmit={handleSubmit} className={styles.form}>
        {verificacoes.map((verificacao, index) => (
          <div key={index} className={styles.verificacaoItem}>
            <label>{verificacao.nome}</label>
            <select
              value={verificacao.status}
              onChange={(e) =>
                handleVerificacaoChange(verificacao.nome, e.target.value)
              }
              required
            >
              <option value="">Selecione</option>
              <option value="OK">OK</option>
              <option value="Necessita Manutenção">Necessita Manutenção</option>
              <option value="Não Aplicável">Não Aplicável</option>
            </select>
          </div>
        ))}

        <div className={styles.formActions}>
          <button type="button" className={styles.cancelButton} onClick={onRequestClose}>
            Cancelar
          </button>
          <button type="submit" className={styles.submitButton}>
            <FaSave /> Salvar Verificação
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default DetalhesVerificacaoModal;
