// src/pages/Auditoria/Auditoria.jsx

import React, { useState, useMemo } from 'react';
import { FaPlus, FaEdit, FaTrash, FaCheck, FaTimes } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageLayout from '../../components/PageLayout/PageLayout';
import CriarAuditoriaModal from './CriarAuditoriaModal';
import VerificarPostesModal from './VerificarPostesModal';
import styles from './Auditoria.module.css';

const Auditoria = () => {
  const [auditorias, setAuditorias] = useState([
    {
      id: 1,
      dataAuditoria: '2024-12-01',
      localizacao: 'Rua A, Bairro B',
      equipamentoVerificado: 'Poste 123',
      statusEquipamento: 'Funcionando',
      resultadoAuditoria: 'Aprovado',
      observacoes: 'Nenhuma observação.',
      restricaoAtiva: true, // Controle de restrição
      diasRestricao: 30, // Número de dias para restrição
      dataCriacao: '2024-12-01T10:00',
      dataAtualizacao: '2024-12-01T10:00',
      postesAuditados: [
        {
          idPoste: 1,
          nomePoste: 'Poste 123',
          verificacoes: [
            { nome: 'Condição Física', status: 'OK' },
            { nome: 'Integridade Estrutural', status: 'OK' },
            { nome: 'Funcionalidade Elétrica', status: 'OK' },
            { nome: 'Estabilidade de Montagem', status: 'OK' },
            { nome: 'Precisão da Localização', status: 'OK' },
            { nome: 'Segurança', status: 'OK' },
            { nome: 'Conformidade com Regulamentos', status: 'OK' },
          ],
        },
      ],
    },
    // Adicione outras auditorias conforme necessário
  ]);

  const [modalCriarIsOpen, setModalCriarIsOpen] = useState(false);
  const [modalVerificarIsOpen, setModalVerificarIsOpen] = useState(false);
  const [auditoriaSelecionada, setAuditoriaSelecionada] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState('');

  // Lista de Postes Disponíveis (Simulação)
  const listaPostes = [
    { id: 1, nome: 'Poste 123', localizacao: 'Rua A, Bairro B' },
    { id: 2, nome: 'Poste 456', localizacao: 'Rua C, Bairro D' },
    { id: 3, nome: 'Poste 789', localizacao: 'Rua E, Bairro F' },
    { id: 4, nome: 'Poste 101112', localizacao: 'Rua G, Bairro H' },
    { id: 5, nome: 'Poste 131415', localizacao: 'Rua I, Bairro J' },
    { id: 6, nome: 'Poste 161718', localizacao: 'Rua K, Bairro L' },
    { id: 7, nome: 'Poste 192021', localizacao: 'Rua M, Bairro N' },
    { id: 8, nome: 'Poste 222324', localizacao: 'Rua O, Bairro P' },
    { id: 9, nome: 'Poste 252627', localizacao: 'Rua Q, Bairro R' },
    { id: 10, nome: 'Poste 282930', localizacao: 'Rua S, Bairro T' },
    // Adicione mais postes conforme necessário
  ];

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedAuditorias = useMemo(() => {
    const sorted = [...auditorias];
    if (sortConfig.key) {
      sorted.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  }, [auditorias, sortConfig]);

  const filteredAuditorias = useMemo(() => {
    return sortedAuditorias.filter((auditoria) =>
      auditoria.localizacao.toLowerCase().includes(searchTerm.toLowerCase()) ||
      auditoria.equipamentoVerificado.toLowerCase().includes(searchTerm.toLowerCase()) ||
      auditoria.observacoes.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [sortedAuditorias, searchTerm]);

  const pageCount = Math.ceil(filteredAuditorias.length / itemsPerPage);

  const currentPageData = useMemo(() => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return filteredAuditorias.slice(start, end);
  }, [filteredAuditorias, currentPage, itemsPerPage]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleEditAuditoria = (auditoria) => {
    setAuditoriaSelecionada(auditoria);
    setModalCriarIsOpen(true);
  };

  const handleDeleteAuditoria = (id) => {
    const confirmDelete = window.confirm('Tem certeza que deseja deletar esta auditoria?');
    if (!confirmDelete) return;

    setAuditorias((prevAuditorias) => prevAuditorias.filter((auditoria) => auditoria.id !== id));
    toast.success('Auditoria deletada com sucesso!');
  };

  // Função de callback para salvar uma nova auditoria ou atualizar uma existente
  const handleSaveAuditoria = (novaAuditoria) => {
    if (novaAuditoria.id) {
      // Atualizar auditoria existente
      setAuditorias((prevAuditorias) =>
        prevAuditorias.map((item) => (item.id === novaAuditoria.id ? novaAuditoria : item))
      );
      toast.success('Auditoria atualizada com sucesso!');
    } else {
      // Criar nova auditoria
      const auditToAdd = {
        ...novaAuditoria,
        id: Date.now(),
        dataCriacao: new Date().toISOString(),
        dataAtualizacao: new Date().toISOString(),
      };
      setAuditorias((prevAuditorias) => [...prevAuditorias, auditToAdd]);
      toast.success('Nova auditoria adicionada com sucesso!');
    }
    setModalCriarIsOpen(false);
  };

  // Função para realizar a auditoria (verificar postes)
  const handleRealizarAuditoria = (auditoria) => {
    setAuditoriaSelecionada(auditoria);
    setModalVerificarIsOpen(true);
  };

  // Função de callback para atualizar os postes auditados após verificação
  const handleAtualizarPostesAuditados = (auditoriaAtualizada) => {
    setAuditorias((prevAuditorias) =>
      prevAuditorias.map((item) => (item.id === auditoriaAtualizada.id ? auditoriaAtualizada : item))
    );
    toast.success('Auditoria verificada com sucesso!');
    setModalVerificarIsOpen(false);
  };

  return (
    <PageLayout title="Automatizar Auditoria">
      <div className={styles.container}>
        <div className={styles.controlsTop}>
          <button className={styles.addButton} onClick={() => setModalCriarIsOpen(true)}>
            <FaPlus /> Criar Nova Auditoria
          </button>
          {/* Botão para realizar auditoria individual, se necessário */}
        </div>
        <div className={styles.controls}>
          <div className={styles.searchContainer}>
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Pesquisar por Localização, Equipamento ou Observações..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th onClick={() => requestSort('dataAuditoria')}>
                  Data Auditoria{' '}
                  {sortConfig.key === 'dataAuditoria' &&
                    (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </th>
                <th onClick={() => requestSort('localizacao')}>
                  Localização{' '}
                  {sortConfig.key === 'localizacao' &&
                    (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </th>
                <th onClick={() => requestSort('equipamentoVerificado')}>
                  Equipamento Verificado{' '}
                  {sortConfig.key === 'equipamentoVerificado' &&
                    (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </th>
                <th onClick={() => requestSort('statusEquipamento')}>
                  Status Equipamento{' '}
                  {sortConfig.key === 'statusEquipamento' &&
                    (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </th>
                <th onClick={() => requestSort('resultadoAuditoria')}>
                  Resultado Auditoria{' '}
                  {sortConfig.key === 'resultadoAuditoria' &&
                    (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </th>
                {/* Removida a coluna "Postes Auditados" */}
                <th>Observações</th>
                <th>Data de Criação</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.length > 0 ? (
                currentPageData.map((auditoria) => (
                  <tr key={auditoria.id}>
                    <td>{new Date(auditoria.dataAuditoria).toLocaleDateString()}</td>
                    <td>{auditoria.localizacao}</td>
                    <td>{auditoria.equipamentoVerificado}</td>
                    <td>{auditoria.statusEquipamento}</td>
                    <td>{auditoria.resultadoAuditoria}</td>
                    {/* Removida a coluna "Postes Auditados" */}
                    <td>{auditoria.observacoes}</td>
                    <td>{new Date(auditoria.dataCriacao).toLocaleString()}</td>
                    <td>
                      <div className={styles.actionsContainer}>
                        <button
                          className={styles.actionButton}
                          onClick={() => handleEditAuditoria(auditoria)}
                          title="Editar"
                        >
                          <FaEdit />
                        </button>
                        <button
                          className={`${styles.actionButton} ${styles.deleteButton}`}
                          onClick={() => handleDeleteAuditoria(auditoria.id)}
                          title="Deletar"
                        >
                          <FaTrash />
                        </button>
                        <button
                          className={`${styles.actionButton} ${styles.verifyButton}`}
                          onClick={() => handleRealizarAuditoria(auditoria)}
                          title="Verificar Postes Auditados"
                        >
                          <FaCheck /> Verificar
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className={styles.noData}>
                    Nenhuma auditoria encontrada.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={styles.paginationContainer}>
          <ReactPaginate
            previousLabel={'← Anterior'}
            nextLabel={'Próximo →'}
            breakLabel={'...'}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={styles.pagination}
            previousLinkClassName={styles.paginationLink}
            nextLinkClassName={styles.paginationLink}
            breakLinkClassName={styles.paginationLink}
            disabledClassName={styles.paginationDisabled}
            activeClassName={styles.paginationActive}
            forcePage={currentPage}
          />
        </div>
        {modalCriarIsOpen && (
          <CriarAuditoriaModal
            isOpen={modalCriarIsOpen}
            onRequestClose={() => setModalCriarIsOpen(false)}
            onSave={handleSaveAuditoria}
            listaPostes={listaPostes}
            auditoriaSelecionada={auditoriaSelecionada}
          />
        )}
        {modalVerificarIsOpen && auditoriaSelecionada && (
          <VerificarPostesModal
            isOpen={modalVerificarIsOpen}
            onRequestClose={() => setModalVerificarIsOpen(false)}
            auditoria={auditoriaSelecionada}
            onAtualizarAuditoria={handleAtualizarPostesAuditados}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default Auditoria;
