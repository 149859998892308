// src/utils/validation.js

/**
 * Valida a geolocalização do poste.
 * Considera inválidas geolocalizações como "0,0" ou valores muito próximos.
 * @param {string} geoLocalizacao - A geolocalização em formato "lat,lng".
 * @returns {boolean} - Retorna true se a geolocalização for válida, false caso contrário.
 */
export const isValidGeoLocalizacao = (geoLocalizacao) => {
  if (!geoLocalizacao) return false;

  const parts = geoLocalizacao.split(',');

  if (parts.length !== 2) return false;

  const lat = parseFloat(parts[0].trim());
  const lng = parseFloat(parts[1].trim());

  // Verifica se latitude e longitude são números válidos
  if (isNaN(lat) || isNaN(lng)) return false;

  // Define limites razoáveis para latitude e longitude
  if (lat === 0 && lng === 0) return false; // Exemplo específico
  if (lat < -90 || lat > 90) return false;
  if (lng < -180 || lng > 180) return false;

  // Adicione mais condições conforme necessário
  // Por exemplo, considerar inválido se estiver muito próximo de 0,0
  const threshold = 0.0001; // Define um limiar
  if (Math.abs(lat) < threshold && Math.abs(lng) < threshold) return false;

  return true;
};
