// src/components/GarantiasProdutosModal/GarantiasProdutosModal.jsx

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import styles from './GarantiasProdutosModal.module.css';

// Definindo o AppElement para acessibilidade
Modal.setAppElement('#root');

const GarantiasProdutosModal = ({ isOpen, onRequestClose, onSave, initialData }) => {
  // Estado para o formulário do modal
  const [formData, setFormData] = useState({
    grupo: '',
    componente: '',
    marca: '',
    modelo: '',
    numeroSerie: '',
    dataCriacao: '',
    dataCompra: '',
    descricao: '',
    quantidade: '',
    garantiaMeses: '',
  });

  // Determinar se estamos em modo de edição
  const isEditMode = Boolean(initialData);

  useEffect(() => {
    if (isEditMode) {
      setFormData({
        grupo: initialData.grupo || '',
        componente: initialData.componente || '',
        marca: initialData.marca || '',
        modelo: initialData.modelo || '',
        numeroSerie: initialData.numeroSerie || '',
        dataCriacao: initialData.dataCriacao || '',
        dataCompra: initialData.dataCompra || '',
        descricao: initialData.descricao || '',
        quantidade: initialData.quantidade || '',
        garantiaMeses: initialData.garantiaMeses || '',
      });
    } else {
      setFormData({
        grupo: '',
        componente: '',
        marca: '',
        modelo: '',
        numeroSerie: '',
        dataCriacao: '',
        dataCompra: '',
        descricao: '',
        quantidade: '',
        garantiaMeses: '',
      });
    }
  }, [isEditMode, initialData]);

  // Função para lidar com mudanças nos campos do formulário
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Função para lidar com o envio do formulário
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validação básica
    const { grupo, componente, marca, modelo, numeroSerie, dataCriacao, dataCompra, descricao, quantidade, garantiaMeses } = formData;
    if (!grupo || !componente || !marca || !modelo || !numeroSerie || !dataCriacao || !dataCompra || !descricao || !quantidade || !garantiaMeses) {
      toast.error('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    // Validar datas
    const compraDate = new Date(dataCompra);
    const criacaoDate = new Date(dataCriacao);
    if (criacaoDate > compraDate) {
      toast.error('A data de criação não pode ser posterior à data de compra.');
      return;
    }

    // Validar quantidade e garantiaMeses como números positivos
    if (isNaN(quantidade) || quantidade <= 0) {
      toast.error('Quantidade deve ser um número positivo.');
      return;
    }
    if (isNaN(garantiaMeses) || garantiaMeses <= 0) {
      toast.error('Garantia (Meses) deve ser um número positivo.');
      return;
    }

    // Passar os dados para a função onSave
    onSave({
      ...formData,
      quantidade: parseInt(quantidade, 10),
      garantiaMeses: parseInt(garantiaMeses, 10),
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={isEditMode ? 'Editar Lote de Garantia' : 'Adicionar Novo Lote de Garantia'}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalHeader}>
        <h2>{isEditMode ? 'Editar Lote de Garantia' : 'Adicionar Novo Lote de Garantia'}</h2>
        <button className={styles.closeButton} onClick={onRequestClose}>
          <FaTimes />
        </button>
      </div>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="grupo">Grupo</label>
          <input
            type="text"
            id="grupo"
            name="grupo"
            value={formData.grupo}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="componente">Componente</label>
          <input
            type="text"
            id="componente"
            name="componente"
            value={formData.componente}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="marca">Marca</label>
          <input
            type="text"
            id="marca"
            name="marca"
            value={formData.marca}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="modelo">Modelo</label>
          <input
            type="text"
            id="modelo"
            name="modelo"
            value={formData.modelo}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="numeroSerie">Número de Série</label>
          <input
            type="text"
            id="numeroSerie"
            name="numeroSerie"
            value={formData.numeroSerie}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="dataCriacao">Data Criação</label>
          <input
            type="datetime-local"
            id="dataCriacao"
            name="dataCriacao"
            value={formData.dataCriacao}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="dataCompra">Data Compra</label>
          <input
            type="date"
            id="dataCompra"
            name="dataCompra"
            value={formData.dataCompra}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="descricao">Descrição</label>
          <textarea
            id="descricao"
            name="descricao"
            value={formData.descricao}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="quantidade">Quantidade</label>
          <input
            type="number"
            id="quantidade"
            name="quantidade"
            value={formData.quantidade}
            onChange={handleChange}
            required
            min="1"
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="garantiaMeses">Garantia (Meses)</label>
          <input
            type="number"
            id="garantiaMeses"
            name="garantiaMeses"
            value={formData.garantiaMeses}
            onChange={handleChange}
            required
            min="1"
          />
        </div>

        <div className={styles.formActions}>
          <button type="button" className={styles.cancelButton} onClick={onRequestClose}>
            Cancelar
          </button>
          <button type="submit" className={styles.submitButton}>
            {isEditMode ? 'Atualizar' : 'Salvar'}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default GarantiasProdutosModal;
