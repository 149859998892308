import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import useCountries from '../../hooks/useCountries';
import useStates from '../../hooks/useStates';
import useCities from '../../hooks/useCities';
import styles from './RegiaoModal.module.css';

const RegiaoModal = ({ isOpen, onRequestClose, regiaoData, onSave }) => {
  const [nome, setNome] = useState('');
  const [paisId, setPaisId] = useState('');
  const [estadoId, setEstadoId] = useState('');
  const [cidadeId, setCidadeId] = useState('');

  const { countries, isCountriesLoading, countriesError } = useCountries(isOpen);
  const { states, isStatesLoading, statesError } = useStates(isOpen, paisId);
  const { cities, isCitiesLoading, citiesError } = useCities(isOpen, estadoId);

  useEffect(() => {
    if (regiaoData) {
      setNome(regiaoData.regiaoNome || '');
      setPaisId(regiaoData.regiaoPais?.toString() || '');
      setEstadoId(regiaoData.regiaoEstado?.toString() || '');
      setCidadeId(regiaoData.regiaocidade?.toString() || '');
    } else {
      setNome('');
      setPaisId('');
      setEstadoId('');
      setCidadeId('');
    }
  }, [regiaoData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!nome.trim() || !paisId || !estadoId || !cidadeId) {
      alert('Todos os campos são obrigatórios.');
      return;
    }

    const newRegiao = {
      regiaoId: regiaoData?.regiaoId || null,
      regiaoNome: nome.trim(),
      regiaoPais: parseInt(paisId, 10),
      regiaoEstado: parseInt(estadoId, 10),
      regiaocidade: parseInt(cidadeId, 10),
    };

    onSave(newRegiao);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <button className={styles.closeModalButton} onClick={onRequestClose}>
        <FaTimes />
      </button>
      <h2 className={styles.header}>{regiaoData ? 'Editar Região' : 'Nova Região'}</h2>
      <form className={styles.form} onSubmit={handleSubmit}>
        <fieldset className={styles.fieldset}>
          <label className={styles.label} htmlFor="nome">Nome da Região:</label>
          <input
            className={styles.input}
            type="text"
            id="nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            required
          />
          <label className={styles.label} htmlFor="pais">País:</label>
          {isCountriesLoading ? (
            <p>Carregando países...</p>
          ) : countriesError ? (
            <p>Erro ao carregar países.</p>
          ) : (
            <select
              className={styles.input}
              id="pais"
              value={paisId}
              onChange={(e) => setPaisId(e.target.value)}
              required
            >
              <option value="">Selecione um país</option>
              {countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.nome}
                </option>
              ))}
            </select>
          )}
          <label className={styles.label} htmlFor="estado">Estado:</label>
          {isStatesLoading ? (
            <p>Carregando estados...</p>
          ) : statesError ? (
            <p>Erro ao carregar estados.</p>
          ) : (
            <select
              className={styles.input}
              id="estado"
              value={estadoId}
              onChange={(e) => setEstadoId(e.target.value)}
              required
            >
              <option value="">Selecione um estado</option>
              {states.map((state) => (
                <option key={state.id} value={state.id}>
                  {state.nome}
                </option>
              ))}
            </select>
          )}
          <label className={styles.label} htmlFor="cidade">Cidade:</label>
          {isCitiesLoading ? (
            <p>Carregando cidades...</p>
          ) : citiesError ? (
            <p>Erro ao carregar cidades.</p>
          ) : (
            <select
              className={styles.input}
              id="cidade"
              value={cidadeId}
              onChange={(e) => setCidadeId(e.target.value)}
              required
            >
              <option value="">Selecione uma cidade</option>
              {cities.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.nome}
                </option>
              ))}
            </select>
          )}
        </fieldset>
        <div className={styles.actions}>
          <button type="button" className={styles.cancelButton} onClick={onRequestClose}>
            Cancelar
          </button>
          <button type="submit" className={styles.saveButton}>
            Salvar
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default RegiaoModal;
