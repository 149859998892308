import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes, FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import styles from './VerificarPostesModal.module.css';

// Importar o modal de detalhes da verificação no topo
import DetalhesVerificacaoModal from './DetalhesVerificacaoModal';

Modal.setAppElement('#root');

const VerificarPostesModal = ({
  isOpen,
  onRequestClose,
  auditoria,
  onAtualizarAuditoria,
}) => {
  const [postesAuditados, setPostesAuditados] = useState([]);
  const [modalDetalhesAberto, setModalDetalhesAberto] = useState(false);
  const [posteSelecionado, setPosteSelecionado] = useState(null);

  useEffect(() => {
    if (isOpen && auditoria) {
      setPostesAuditados(auditoria.postesAuditados || []);
    }
  }, [isOpen, auditoria]);

  const handleAbrirModalDetalhes = (poste) => {
    setPosteSelecionado(poste);
    setModalDetalhesAberto(true);
  };

  const handleFecharModalDetalhes = () => {
    setModalDetalhesAberto(false);
    setPosteSelecionado(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Verificar se todos os postes foram verificados
    for (let poste of postesAuditados) {
      if (!poste.verificado) {
        toast.error(`O poste "${poste.nomePoste}" ainda não foi verificado.`);
        return;
      }
    }

    // Atualizar a auditoria com os novos status
    const auditoriaAtualizada = {
      ...auditoria,
      postesAuditados: postesAuditados.map((poste) => ({
        ...poste,
      })),
      dataAtualizacao: new Date().toISOString(),
    };

    onAtualizarAuditoria(auditoriaAtualizada);
    toast.success('Auditoria verificada com sucesso!');
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Verificar Postes Auditados"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalHeader}>
        <h2>Verificar Postes Auditados</h2>
        <button className={styles.closeButton} onClick={onRequestClose}>
          <FaTimes />
        </button>
      </div>
      <form onSubmit={handleSubmit} className={styles.form}>
        {postesAuditados.map((poste, index) => (
          <div key={index} className={styles.posteItem}>
            <h3>{poste.nomePoste}</h3>
            <p>Status: {poste.verificado ? 'Verificado' : 'Não Verificado'}</p>
            <button
              type="button"
              className={styles.verifyButton}
              onClick={() => handleAbrirModalDetalhes(poste)}
            >
              <FaCheck /> Fazer Verificação
            </button>
          </div>
        ))}

        <div className={styles.formActions}>
          <button type="button" className={styles.cancelButton} onClick={onRequestClose}>
            Cancelar
          </button>
          <button type="submit" className={styles.submitButton}>
            Concluir Auditoria
          </button>
        </div>
      </form>

      {/* Modal de Detalhes da Verificação */}
      {modalDetalhesAberto && posteSelecionado && (
        <DetalhesVerificacaoModal
          isOpen={modalDetalhesAberto}
          onRequestClose={handleFecharModalDetalhes}
          poste={posteSelecionado}
          onAtualizarPoste={(posteAtualizado) => {
            setPostesAuditados((prev) =>
              prev.map((p) => (p.idPoste === posteAtualizado.idPoste ? posteAtualizado : p))
            );
            handleFecharModalDetalhes();
          }}
        />
      )}
    </Modal>
  );
};

export default VerificarPostesModal;
