// src/components/AlertaModal/AlertaModal.jsx

import React, { useState, useEffect, useContext, useMemo } from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import Select from 'react-select'; // Importação do react-select
import styles from './AlertaModal.module.css';
import { AuthContext } from '../../context/AuthContext';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

// Definição das opções de motivo
const motivosInternos = [
  { value: 'Ponto Apagado', label: 'Ponto Apagado' },
  { value: 'Material Danificado', label: 'Material Danificado' },
  { value: 'Implantação', label: 'Implantação' },
  { value: 'Outros', label: 'Outros' },
];

const motivosExternos = [
  { value: 'Falta de Energia', label: 'Falta de Energia' },
  { value: 'Queda de Tensão', label: 'Queda de Tensão' },
  { value: 'Risco de Choque Elétrico', label: 'Risco de Choque Elétrico' },
  { value: 'Limpeza das Instalações', label: 'Limpeza das Instalações' },
  { value: 'Novas Instalações', label: 'Novas Instalações' },
  { value: 'Ampliação de Rede', label: 'Ampliação de Rede' },
  { value: 'Material Danificado', label: 'Material Danificado' },
  { value: 'Outros', label: 'Outros' },
];

// Definição das opções de Interno/Externo para a cidade 3315
const internoExternoOptions = [
  { value: 'Interno', label: 'Interno' },
  { value: 'Externo', label: 'Externo' },
];

Modal.setAppElement('#root');

const AlertaModal = ({ isOpen, onRequestClose, onSave }) => {
  const { token, cidadeId, usuarioId, usuarioNome, logout } = useContext(AuthContext);
  const [postes, setPostes] = useState([]);
  const [postesLoading, setPostesLoading] = useState(false);
  const [postesError, setPostesError] = useState(null);

  const [formData, setFormData] = useState({
    alertaMunicipio: cidadeId || '',
    alertaCidade: cidadeId || '',
    alertaPosteId: '',
    alertaParamId: '',
    alertaParamAcaoId: '',
    alertaMotivo: '',
    alertaDataGeracao: '',
    alertaHoraGeracao: '',
    alertaUsuarioGeracaoId: usuarioId || '0',
    alertaOrigem: 'Directluz',
    alertaSituacao: '1',
    alertaTelefone: '',
  });

  const [selectedMotivo, setSelectedMotivo] = useState(null);
  const [motivoOutros, setMotivoOutros] = useState('');
  const [selectedInternoExterno, setSelectedInternoExterno] = useState(null); // Novo estado

  // Preencher data e hora quando o modal é aberto
  useEffect(() => {
    if (isOpen) {
      const now = new Date();
      const alertaDataGeracao = format(now, 'yyyy-MM-dd'); // AAAA-MM-DD
      const alertaHoraGeracao = format(now, 'HH:mm:ss');   // HH:MM:SS

      setFormData(prev => ({
        ...prev,
        alertaDataGeracao,
        alertaHoraGeracao,
        alertaPosteId: '',
        alertaParamId: '',
        alertaParamAcaoId: '',
        alertaMotivo: '',
        alertaTelefone: '',
      }));
      setSelectedMotivo(null);
      setMotivoOutros('');
      setSelectedInternoExterno(null); // Resetar seleção Interno/Externo
      setPostesError(null);
    }
  }, [isOpen]);

  // Função para buscar os postes diretamente no componente
  useEffect(() => {
    const fetchPostes = async () => {
      if (!token || !cidadeId) {
        setPostesError('Token ou ID da cidade ausente.');
        toast.error('Erro de autenticação: token ou cidadeId ausente.');
        return;
      }

      setPostesLoading(true);
      try {
        const response = await fetch('https://api.ads10.com.br/api/poste', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'cidadeId': cidadeId,
          },
        });

        if (response.status === 401) {
          toast.error('Sessão expirada. Faça login novamente.');
          logout();
          return;
        }

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Erro ao buscar postes: ${errorData.message || response.statusText}`);
        }

        const data = await response.json();

        // Filtrar apenas os postes com "ativo": "S"
        const postesAtivos = data.filter(poste => poste.ativo === "S");

        // Mapear os postes para o formato esperado pelo react-select
        const options = postesAtivos.map(poste => ({
          value: poste.id,
          label: `${poste.id} - ${poste.codigoPoste}`,
          codigoPoste: poste.codigoPoste || '',
        }));

        setPostes(options);
      } catch (err) {
        console.error(err);
        setPostesError(err.message || 'Ocorreu um erro ao buscar os postes.');
        toast.error(err.message || 'Ocorreu um erro ao buscar os postes.');
      } finally {
        setPostesLoading(false);
      }
    };

    if (isOpen) {
      fetchPostes();
    }
  }, [token, cidadeId, logout, isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  // Função para lidar com a seleção do poste no react-select
  const handlePosteChange = (selectedOption) => {
    setFormData(prev => ({
      ...prev,
      alertaPosteId: selectedOption ? selectedOption.value : '',
    }));
  };

  // Função para lidar com a seleção do motivo
  const handleMotivoChange = (selectedOption) => {
    setSelectedMotivo(selectedOption);
    if (selectedOption && selectedOption.value !== 'Outros') {
      setMotivoOutros('');
    }
  };

  // Função para lidar com a mudança no campo "Outros"
  const handleMotivoOutrosChange = (e) => {
    setMotivoOutros(e.target.value);
  };

  // Função para lidar com a seleção Interno/Externo (apenas para cidade 3315)
  const handleInternoExternoChange = (selectedOption) => {
    setSelectedInternoExterno(selectedOption);
  };

  // Definir as opções de motivo com base no cidadeId e na seleção Interno/Externo
  const motivoOptions = useMemo(() => {
    if (parseInt(cidadeId, 10) === 3315) {
      if (selectedInternoExterno) {
        return selectedInternoExterno.value === 'Interno' ? motivosInternos : motivosExternos;
      }
      // Se Interno/Externo não está selecionado, não mostrar motivos
      return [];
    } else {
      return motivosExternos; // Ou motivos padrão para outras cidades
    }
  }, [cidadeId, selectedInternoExterno]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validação básica
    if (!selectedMotivo || (selectedMotivo.value === 'Outros' && !motivoOutros.trim())) {
      toast.error('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    // Para cidade 3315, verificar Interno/Externo
    if (parseInt(cidadeId, 10) === 3315 && !selectedInternoExterno) {
      toast.error('Por favor, selecione Interno ou Externo.');
      return;
    }

    // Construir o alertaMotivo com base na seleção
    let alertaMotivoFinal = '';

    if (parseInt(cidadeId, 10) === 3315) {
      alertaMotivoFinal += `Ordem de Serviço aberta para ${selectedInternoExterno.value} aos Galpões / `;
    }

    alertaMotivoFinal += selectedMotivo.value;

    if (selectedMotivo.value === 'Outros') {
      alertaMotivoFinal += ` : ${motivoOutros.trim()}`;
    }

    // Atualizar o formData com o alertaMotivoFinal
    const finalFormData = {
      ...formData,
      alertaMotivo: alertaMotivoFinal,
    };

    onSave(finalFormData);
  };

  useEffect(() => {
    if (!cidadeId) {
      // Fechar o modal se o ID da cidade estiver ausente
      toast.error('ID da cidade ausente.');
      onRequestClose();
    }
  }, [cidadeId, onRequestClose]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Criar Novo Alerta"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <button className={styles.closeModalButton} onClick={onRequestClose} aria-label="Fechar Modal">
        <FaTimes />
      </button>
      <div className={styles.alertaPage}>
        <h2 className={styles.header}>Criar Novo Alerta</h2>
        <form onSubmit={handleSubmit}>
          <fieldset className={styles.fieldset}>
            <div className={styles.formGroup}>
              {/* Campo Poste com react-select */}
              <div className={styles.colSpan1}>
                <label className={styles.label} htmlFor="alertaPosteId">Poste:</label>
                {postesLoading ? (
                  <p>Carregando postes...</p>
                ) : postesError ? (
                  <p className={styles.errorMessage}>Erro: {postesError}</p>
                ) : (
                  <Select
                    options={postes}
                    onChange={handlePosteChange}
                    isClearable
                    placeholder="Selecione um Poste..."
                    className={styles.reactSelect}
                    classNamePrefix="react-select"
                    // Customizar a busca para pesquisar pelo código do poste
                    filterOption={(option, inputValue) => {
                      const label = option.data.label || '';
                      const codigo = option.data.codigoPoste || '';
                      return (
                        label.toLowerCase().includes(inputValue.toLowerCase()) ||
                        codigo.toLowerCase().includes(inputValue.toLowerCase())
                      );
                    }}
                  />
                )}
              </div>

              {/* Campo Interno/Externo (apenas para cidade 3315) */}
              {parseInt(cidadeId, 10) === 3315 && (
                <div className={styles.colSpan1}>
                  <label className={styles.label} htmlFor="internoExterno">Interno/Externo:</label>
                  <Select
                    options={internoExternoOptions}
                    onChange={handleInternoExternoChange}
                    isClearable
                    placeholder="Selecione Interno ou Externo..."
                    className={styles.reactSelect}
                    classNamePrefix="react-select"
                  />
                </div>
              )}

              {/* Campo Motivo com select */}
              <div className={styles.colSpan1}>
                <label className={styles.label} htmlFor="alertaMotivo">Motivo:</label>
                <Select
                  options={motivoOptions}
                  onChange={handleMotivoChange}
                  isClearable
                  placeholder="Selecione um Motivo..."
                  className={styles.reactSelect}
                  classNamePrefix="react-select"
                  isDisabled={parseInt(cidadeId, 10) === 3315 && !selectedInternoExterno}
                />
              </div>

              {/* Campo "Outros" que aparece condicionalmente */}
              {selectedMotivo && selectedMotivo.value === 'Outros' && (
                <div className={styles.colSpan1}>
                  <label className={styles.label} htmlFor="motivoOutros">Descreva o Motivo:</label>
                  <input
                    className={styles.input}
                    type="text"
                    name="motivoOutros"
                    id="motivoOutros"
                    value={motivoOutros}
                    onChange={handleMotivoOutrosChange}
                    placeholder="Digite o motivo..."
                    required
                  />
                </div>
              )}

              {/* Campo Origem (Fixed as "Directluz") */}
              <div className={styles.colSpan1}>
                <label className={styles.label} htmlFor="alertaOrigem">Origem:</label>
                <input
                  className={styles.input}
                  type="text"
                  name="alertaOrigem"
                  id="alertaOrigem"
                  value={formData.alertaOrigem}
                  readOnly
                />
              </div>

              {/* Exibição do ID do Usuário */}
              <div className={styles.colSpan1}>
                <label className={styles.label} htmlFor="alertaUsuarioGeracaoIdDisplay">Usuário ID:</label>
                <input
                  className={styles.input}
                  type="text"
                  name="alertaUsuarioGeracaoIdDisplay"
                  id="alertaUsuarioGeracaoIdDisplay"
                  value={usuarioId}
                  readOnly
                />
              </div>

              {/* Exibição do Nome do Usuário */}
              <div className={styles.colSpan1}>
                <label className={styles.label} htmlFor="alertaUsuarioNomeDisplay">Nome do Usuário:</label>
                <input
                  className={styles.input}
                  type="text"
                  name="alertaUsuarioNomeDisplay"
                  id="alertaUsuarioNomeDisplay"
                  value={usuarioNome}
                  readOnly
                />
              </div>

              {/* Campo Telefone */}
              <div className={styles.colSpan1}>
                <label className={styles.label} htmlFor="alertaTelefone">Telefone:</label>
                <input
                  className={styles.input}
                  type="tel"
                  name="alertaTelefone"
                  id="alertaTelefone"
                  value={formData.alertaTelefone}
                  onChange={handleChange}
                  placeholder="Digite o telefone..."
                />
              </div>

              {/* Campos de Data e Hora (hidden) */}
              <input
                type="hidden"
                name="alertaDataGeracao"
                value={formData.alertaDataGeracao}
              />
              <input
                type="hidden"
                name="alertaHoraGeracao"
                value={formData.alertaHoraGeracao}
              />

              {/* Adicione mais campos conforme necessário */}
            </div>
          </fieldset>
          <button type="submit" className={styles.button}>
            Salvar Alerta
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default AlertaModal;
