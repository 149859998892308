// src/components/FiltroGrupos/FiltroGrupos.jsx

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './FiltroGrupos.module.css';
import { FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa';

/**
 * Componente para filtrar postes por grupos.
 */
const FiltroGrupos = ({ selectedGroups, setSelectedGroups, grupos }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  /**
   * Função para alternar a seleção de um grupo.
   * @param {string} grupoId - ID do grupo a ser alternado.
   */
  const handleGroupToggle = (grupoId) => {
    if (selectedGroups.includes(grupoId)) {
      setSelectedGroups(selectedGroups.filter((id) => id !== grupoId));
    } else {
      setSelectedGroups([...selectedGroups, grupoId]);
    }
  };

  if (!grupos) return null;

  return (
    <div className={`${styles.filtroGrupos} ${isExpanded ? '' : styles.collapsed}`}>
      <h4>
        Filtrar por Grupos
        <button
          className={styles.toggleButton}
          onClick={() => setIsExpanded(!isExpanded)}
          aria-label={isExpanded ? 'Ocultar Filtro' : 'Expandir Filtro'}
        >
          {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </h4>
      {isExpanded && (
        <>
          <ul className={styles.grupoList}>
            {grupos.map((grupo) => (
              <li key={grupo.grupoId} className={styles.grupoItem}>
                <label className={styles.grupoLabel}>
                  <input
                    type="checkbox"
                    checked={selectedGroups.includes(grupo.grupoId)}
                    onChange={() => handleGroupToggle(grupo.grupoId)}
                  />
                  <span className={styles.grupoNome}>{grupo.grupoNome}</span>
                  <span
                    className={styles.grupoCor}
                    style={{ backgroundColor: grupo.grupoCor }}
                    title={`Cor do grupo: ${grupo.grupoCor}`}
                  ></span>
                </label>
              </li>
            ))}
          </ul>
          <div className={styles.actions}>
            <button onClick={() => setSelectedGroups([])} className={styles.clearButton}>
              <FaTimes /> Limpar Filtros
            </button>
          </div>
        </>
      )}
    </div>
  );
};

FiltroGrupos.propTypes = {
  selectedGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedGroups: PropTypes.func.isRequired,
  grupos: PropTypes.arrayOf(
    PropTypes.shape({
      grupoId: PropTypes.string.isRequired,
      grupoNome: PropTypes.string.isRequired,
      grupoCor: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FiltroGrupos;
