// src/pages/DispositivosIOT/DispositivosIOT.js

import React, { useState, useMemo, useCallback, useContext, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { FaPlus, FaEdit, FaToggleOn, FaToggleOff, FaSortUp, FaSortDown } from 'react-icons/fa';
import { CSVLink } from 'react-csv';
import DeviceModal from '../../components/DeviceModal/DeviceModal';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import PageLayout from '../../components/PageLayout/PageLayout';
import styles from './DispositivosIOT.module.css';

import usePosteData from '../../hooks/usePosteData';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';

const DispositivosIOT = () => {
  const { token, logout, cidadeId, usuarioId } = useContext(AuthContext);
  const { postesDetalhados, isLoading, error, refetch } = usePosteData();

  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [descricaoFilter, setDescricaoFilter] = useState('');
  const [deviceEUIFilter, setDeviceEUIFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [mostrarApenasNaoAssociados, setMostrarApenasNaoAssociados] = useState(false); // Novo estado para o filtro
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    if (!token || !cidadeId) {
      toast.error('Erro de autenticação: token ou cidadeId ausente.');
    }
  }, [token, cidadeId]);

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDevice(null);
  };

  const handleUnauthorized = useCallback(() => {
    toast.error('Sessão expirada. Faça login novamente.');
    logout();
  }, [logout]);

  const handleAddNew = () => {
    setSelectedDevice(null);
    setIsModalOpen(true);
  };

  const handleEdit = (device) => {
    setSelectedDevice(device);
    setIsModalOpen(true);
  };

  /**
   * Função para ativar ou desativar uma fotocélula.
   * Envia todos os dados da fotocélula para o servidor.
   */
  const handleToggleAtivo = useCallback(async (device) => {
    const confirmToggle = window.confirm(`Você tem certeza que deseja ${device.ativo === 'S' ? 'desativar' : 'ativar'} esta fotocélula?`);
    if (!confirmToggle) return;

    try {
      if (!token || !cidadeId) {
        throw new Error('Token ou ID da cidade não encontrado.');
      }

      // Atualizar o status 'ativo'
      const updatedDevice = { ...device, ativo: device.ativo === 'S' ? 'N' : 'S' };

      const response = await fetch(`https://api.ads10.com.br/api/fotocelula/${device.fotocelulaId}`, {
        method: 'PUT', // Usamos PUT para substituir todo o recurso
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(updatedDevice),
      });

      if (response.status === 401) {
        handleUnauthorized();
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Erro ao atualizar status: ${errorData.message || response.statusText}`);
      }

      refetch();
      toast.success(`Fotocélula ${updatedDevice.ativo === 'S' ? 'ativada' : 'desativada'} com sucesso!`);
    } catch (err) {
      console.error(err);
      toast.error(err.message || 'Ocorreu um erro ao atualizar o status da fotocélula.');
    }
  }, [token, cidadeId, handleUnauthorized, refetch]);

  const handleSave = useCallback(async (savedDevice) => {
    try {
      console.log('Tentando salvar o dispositivo:', savedDevice);
      if (!token || !cidadeId || !usuarioId) {
        throw new Error('Token, ID da cidade ou ID do usuário não encontrado.');
      }

      const method = savedDevice.fotocelulaId ? 'PUT' : 'POST';
      const url = savedDevice.fotocelulaId
        ? `https://api.ads10.com.br/api/fotocelula/${savedDevice.fotocelulaId}`
        : 'https://api.ads10.com.br/api/fotocelula';

      // Adicionar usuarioId, latitude e longitude
      const payload = {
        ...savedDevice,
        usuarioId: savedDevice.usuarioId || usuarioId, // Usa usuarioId do contexto
        latitude: 0,
        longitude: 0,
      };

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 401) {
        handleUnauthorized();
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Erro ao salvar o dispositivo: ${errorData.message || response.statusText}`);
      }

      refetch();
      toast.success('Dispositivo salvo com sucesso!');
      closeModal();
    } catch (err) {
      console.error(err);
      toast.error(err.message || 'Ocorreu um erro ao salvar o dispositivo.');
    }
  }, [token, cidadeId, usuarioId, handleUnauthorized, refetch]);

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0);
  };

  const handleDescricaoFilterChange = (e) => {
    setDescricaoFilter(e.target.value);
    setCurrentPage(0);
  };

  const handleDeviceEUIFilterChange = (e) => {
    setDeviceEUIFilter(e.target.value);
    setCurrentPage(0);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(0);
  };

  const handleMostrarApenasNaoAssociadosChange = (e) => {
    setMostrarApenasNaoAssociados(e.target.checked);
    setCurrentPage(0);
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const filteredDevices = useMemo(() => {
    const devices = postesDetalhados.flatMap(item => {
      if (item.fotocelulas) {
        // É um poste com fotocélulas associadas
        return item.fotocelulas.map(fotocelula => ({
          ...fotocelula,
          codigoPoste: item.codigoPoste, // Inclui o código do poste
        }));
      } else {
        // É uma fotocélula não associada
        return [{
          ...item,
          codigoPoste: null, // Sem poste associado
        }];
      }
    });

    const filtered = devices.filter(device => {
      const matchesSearchTerm = searchTerm
        ? device.fotocelulaId.toString().includes(searchTerm)
        : true;
      const matchesDescricao = descricaoFilter
        ? device.descricao?.toLowerCase().includes(descricaoFilter.toLowerCase())
        : true;
      const matchesDeviceEUI = deviceEUIFilter
        ? device.deviceEUI?.toLowerCase().includes(deviceEUIFilter.toLowerCase())
        : true;
      const matchesStatus = statusFilter
        ? device.ativo === statusFilter
        : true;
      const matchesAssociation = mostrarApenasNaoAssociados
        ? device.codigoPoste === null
        : true;

      return matchesSearchTerm && matchesDescricao && matchesDeviceEUI && matchesStatus && matchesAssociation;
    });

    // Ordenar para priorizar correspondências exatas e IDs que começam com o termo
    const sortedFiltered = filtered.sort((a, b) => {
      if (searchTerm) {
        const aId = a.fotocelulaId.toString();
        const bId = b.fotocelulaId.toString();
        const searchTermStr = searchTerm.toString();

        const aExactMatch = aId === searchTermStr;
        const bExactMatch = bId === searchTermStr;

        if (aExactMatch && !bExactMatch) return -1;
        if (!aExactMatch && bExactMatch) return 1;

        const aStartsWith = aId.startsWith(searchTermStr);
        const bStartsWith = bId.startsWith(searchTermStr);

        if (aStartsWith && !bStartsWith) return -1;
        if (!aStartsWith && bStartsWith) return 1;

        // Se ambos ou nenhum começam com o termo, ordenar por ID
        return a.fotocelulaId - b.fotocelulaId;
      } else {
        // Se não houver termo de pesquisa, aplicar ordenação padrão
        return a.fotocelulaId - b.fotocelulaId;
      }
    });

    return sortedFiltered;
  }, [postesDetalhados, searchTerm, descricaoFilter, deviceEUIFilter, statusFilter, mostrarApenasNaoAssociados]);

  // Aplicar a ordenação selecionada na tabela
  const sortedDevices = useMemo(() => {
    const sorted = [...filteredDevices];
    if (sortConfig.key) {
      sorted.sort((a, b) => {
        const aValue = a[sortConfig.key] ? a[sortConfig.key].toString().toLowerCase() : '';
        const bValue = b[sortConfig.key] ? b[sortConfig.key].toString().toLowerCase() : '';

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  }, [filteredDevices, sortConfig]);

  const pageCount = Math.ceil(sortedDevices.length / itemsPerPage);

  const currentPageData = useMemo(() => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return sortedDevices.slice(start, end);
  }, [sortedDevices, currentPage, itemsPerPage]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const headers = [
    { label: 'Fotocélula ID', key: 'fotocelulaId' },
    { label: 'Descrição', key: 'descricao' },
    { label: 'Device EUI', key: 'deviceEUI' },
    { label: 'Ativo', key: 'ativo' },
    { label: 'Código do Poste', key: 'codigoPoste' },
  ];

  const csvData = useMemo(() => {
    return sortedDevices.map(device => ({
      fotocelulaId: device.fotocelulaId,
      descricao: device.descricao,
      deviceEUI: device.deviceEUI,
      ativo: device.ativo === 'S' ? 'Sim' : 'Não',
      codigoPoste: device.codigoPoste || 'Não Associado',
    }));
  }, [sortedDevices]);

  return (
    <PageLayout title="Dispositivos IoT">
      {isLoading && <LoadingOverlay />}
      {!isLoading && (
        <div className={styles.container}>
          {error && <ErrorMessage message={error} />}
          <div className={styles.controlsTop}>
            <button className={styles.addButton} onClick={handleAddNew}>
              <FaPlus /> Adicionar Novo Dispositivo
            </button>
            <CSVLink
              data={csvData}
              headers={headers}
              filename={"dispositivos-iot-export.csv"}
              className={styles.exportButton}
              bom={true}
              separator={";"}
              enclosingCharacter={`"`}
            >
              Exportar para Excel
            </CSVLink>
          </div>

          <div className={styles.controls}>
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Pesquisar por ID da Fotocélula..."
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Filtrar por Descrição..."
              value={descricaoFilter}
              onChange={handleDescricaoFilterChange}
            />
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Filtrar por Device EUI..."
              value={deviceEUIFilter}
              onChange={handleDeviceEUIFilterChange}
            />
            <select
              className={`${styles.searchInput} ${styles.selectInput}`}
              value={statusFilter}
              onChange={handleStatusFilterChange}
            >
              <option value="">Filtrar por Status...</option>
              <option value="S">Ativo</option>
              <option value="N">Inativo</option>
            </select>
            <label className={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={mostrarApenasNaoAssociados}
                onChange={handleMostrarApenasNaoAssociadosChange}
              />
              Mostrar apenas não associados
            </label>
          </div>

          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th onClick={() => requestSort('fotocelulaId')}>
                    Fotocélula ID {sortConfig.key === 'fotocelulaId' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('descricao')}>
                    Descrição {sortConfig.key === 'descricao' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('deviceEUI')}>
                    Device EUI {sortConfig.key === 'deviceEUI' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('ativo')}>
                    Ativo {sortConfig.key === 'ativo' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('codigoPoste')}>
                    Poste Associado {sortConfig.key === 'codigoPoste' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {currentPageData.length > 0 ? (
                  currentPageData.map(device => (
                    <tr key={device.fotocelulaId} className={styles.tableRow}>
                      <td>{device.fotocelulaId}</td>
                      <td>{device.descricao}</td>
                      <td>{device.deviceEUI}</td>
                      <td>{device.ativo === 'S' ? 'Sim' : 'Não'}</td>
                      <td>{device.codigoPoste || 'Não Associado'}</td>
                      <td>
                        <button className={styles.actionButton} onClick={() => handleEdit(device)}>
                          <FaEdit />
                        </button>
                        <button
                          className={`${styles.actionButton} ${device.ativo === 'S' ? styles.deactivateButton : styles.activateButton}`}
                          onClick={() => handleToggleAtivo(device)}
                          title={device.ativo === 'S' ? 'Desativar' : 'Ativar'}
                        >
                          {device.ativo === 'S' ? <FaToggleOn /> : <FaToggleOff />}
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className={styles.noData}>
                      Nenhum dispositivo encontrado.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className={styles.paginationContainer}>
            <ReactPaginate
              previousLabel={"← Anterior"}
              nextLabel={"Próximo →"}
              breakLabel={"..."}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={styles.pagination}
              previousLinkClassName={styles.paginationLink}
              nextLinkClassName={styles.paginationLink}
              breakLinkClassName={styles.paginationLink}
              disabledClassName={styles.paginationDisabled}
              activeClassName={styles.paginationActive}
              forcePage={currentPage}
            />
          </div>

          {isModalOpen && (
            <DeviceModal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              deviceData={selectedDevice}
              onSave={handleSave}
            />
          )}
        </div>
      )}
    </PageLayout>
  );
};

export default DispositivosIOT;
