import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes, FaPlus, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import styles from './CriarAuditoriaModal.module.css';

// Definindo o AppElement para acessibilidade
Modal.setAppElement('#root');

const CriarAuditoriaModal = ({
  isOpen,
  onRequestClose,
  onSave,
  listaPostes,
  auditoriaSelecionada,
}) => {
  const isEditMode = Boolean(auditoriaSelecionada);
  const [formData, setFormData] = useState({
    dataAuditoria: '',
    localizacao: '',
    equipamentoVerificado: '',
    statusEquipamento: '',
    resultadoAuditoria: '',
    observacoes: '',
    restricaoAtiva: false, // Controle de restrição
    diasRestricao: 30, // Número de dias para restrição
  });

  const [postesAuditados, setPostesAuditados] = useState([]);
  const [quantidadeRandom, setQuantidadeRandom] = useState(1); // Estado para quantidade aleatória

  useEffect(() => {
    if (isEditMode) {
      setFormData({
        dataAuditoria: auditoriaSelecionada.dataAuditoria || '',
        localizacao: auditoriaSelecionada.localizacao || '',
        equipamentoVerificado: auditoriaSelecionada.equipamentoVerificado || '',
        statusEquipamento: auditoriaSelecionada.statusEquipamento || '',
        resultadoAuditoria: auditoriaSelecionada.resultadoAuditoria || '',
        observacoes: auditoriaSelecionada.observacoes || '',
        restricaoAtiva: auditoriaSelecionada.restricaoAtiva || false,
        diasRestricao: auditoriaSelecionada.diasRestricao || 30,
      });

      setPostesAuditados(auditoriaSelecionada.postesAuditados || []);
    } else {
      setFormData({
        dataAuditoria: '',
        localizacao: '',
        equipamentoVerificado: '',
        statusEquipamento: '',
        resultadoAuditoria: '',
        observacoes: '',
        restricaoAtiva: false,
        diasRestricao: 30,
      });
      setPostesAuditados([]);
      setQuantidadeRandom(1);
    }
  }, [isEditMode, auditoriaSelecionada]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    setFormData((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const handleAddPoste = () => {
    setPostesAuditados((prev) => [
      ...prev,
      {
        id: Date.now(),
        idPoste: '',
        nomePoste: '',
      },
    ]);
  };

  const handleRemovePoste = (id) => {
    setPostesAuditados((prev) => prev.filter((poste) => poste.id !== id));
  };

  const handlePosteChange = (id, field, value) => {
    setPostesAuditados((prev) =>
      prev.map((poste) => (poste.id === id ? { ...poste, [field]: value } : poste))
    );
  };

  // Função para adicionar postes aleatórios
  const handleAddRandomPostes = () => {
    const quantidade = parseInt(quantidadeRandom);
    if (isNaN(quantidade) || quantidade <= 0) {
      toast.error('Por favor, insira uma quantidade válida.');
      return;
    }

    let postesDisponiveis = listaPostes.filter(
      (poste) => !postesAuditados.some((audit) => audit.idPoste === poste.id)
    );

    if (postesDisponiveis.length === 0) {
      toast.error('Nenhum poste disponível para adicionar.');
      return;
    }

    const quantidadeReal = Math.min(quantidade, postesDisponiveis.length);

    const postesSelecionados = [];
    const indicesSelecionados = new Set();

    while (postesSelecionados.length < quantidadeReal) {
      const indiceAleatorio = Math.floor(Math.random() * postesDisponiveis.length);
      if (!indicesSelecionados.has(indiceAleatorio)) {
        indicesSelecionados.add(indiceAleatorio);
        const poste = postesDisponiveis[indiceAleatorio];
        postesSelecionados.push({
          id: Date.now() + indiceAleatorio,
          idPoste: poste.id,
          nomePoste: poste.nome,
        });
      }
    }

    setPostesAuditados((prev) => [...prev, ...postesSelecionados]);
    toast.success(`${quantidadeReal} poste(s) aleatório(s) adicionado(s) com sucesso!`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      dataAuditoria,
      localizacao,
      equipamentoVerificado,
      statusEquipamento,
      resultadoAuditoria,
      observacoes,
      restricaoAtiva,
      diasRestricao,
    } = formData;

    if (
      !dataAuditoria ||
      !localizacao ||
      !equipamentoVerificado ||
      !statusEquipamento ||
      !resultadoAuditoria
    ) {
      toast.error('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    const auditDate = new Date(dataAuditoria);
    const today = new Date();
    if (auditDate > today) {
      toast.error('A data da auditoria não pode ser futura.');
      return;
    }

    if (postesAuditados.length === 0) {
      toast.error('Por favor, adicione pelo menos um poste auditado.');
      return;
    }

    const auditoriaParaSalvar = {
      ...formData,
      postesAuditados,
      dataAtualizacao: new Date().toISOString(),
    };

    if (isEditMode) {
      auditoriaParaSalvar.id = auditoriaSelecionada.id;
    }

    onSave(auditoriaParaSalvar);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={isEditMode ? 'Editar Auditoria' : 'Criar Nova Auditoria'}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalHeader}>
        <h2>{isEditMode ? 'Editar Auditoria' : 'Criar Nova Auditoria'}</h2>
        <button className={styles.closeButton} onClick={onRequestClose}>
          <FaTimes />
        </button>
      </div>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="dataAuditoria">Data da Auditoria</label>
          <input
            type="date"
            id="dataAuditoria"
            name="dataAuditoria"
            value={formData.dataAuditoria}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="localizacao">Localização</label>
          <input
            type="text"
            id="localizacao"
            name="localizacao"
            value={formData.localizacao}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="equipamentoVerificado">Equipamento Verificado</label>
          <input
            type="text"
            id="equipamentoVerificado"
            name="equipamentoVerificado"
            value={formData.equipamentoVerificado}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="statusEquipamento">Status do Equipamento</label>
          <select
            id="statusEquipamento"
            name="statusEquipamento"
            value={formData.statusEquipamento}
            onChange={handleChange}
            required
          >
            <option value="">Selecione</option>
            <option value="Funcionando">Funcionando</option>
            <option value="Necessita Manutenção">Necessita Manutenção</option>
            <option value="Desativado">Desativado</option>
          </select>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="resultadoAuditoria">Resultado da Auditoria</label>
          <select
            id="resultadoAuditoria"
            name="resultadoAuditoria"
            value={formData.resultadoAuditoria}
            onChange={handleChange}
            required
          >
            <option value="">Selecione</option>
            <option value="Aprovado">Aprovado</option>
            <option value="Reprovado">Reprovado</option>
          </select>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="observacoes">Observações</label>
          <textarea
            id="observacoes"
            name="observacoes"
            value={formData.observacoes}
            onChange={handleChange}
            rows="3"
          ></textarea>
        </div>

        {/* Seção para Controle de Restrição */}
        <div className={styles.formGroup}>
          <label htmlFor="restricaoAtiva">
            <input
              type="checkbox"
              id="restricaoAtiva"
              name="restricaoAtiva"
              checked={formData.restricaoAtiva}
              onChange={handleChange}
            />
            {' '}Ativar Restrição de Postes Auditados Recentemente
          </label>
        </div>

        {formData.restricaoAtiva && (
          <div className={styles.formGroup}>
            <label htmlFor="diasRestricao">Dias de Restrição</label>
            <input
              type="number"
              id="diasRestricao"
              name="diasRestricao"
              min="1"
              value={formData.diasRestricao}
              onChange={handleChange}
              required={formData.restricaoAtiva}
            />
          </div>
        )}

        {/* Seção para Seleção de Postes Auditados */}
        <div className={styles.postesAuditados}>
          <label>Postes Auditados</label>
          {postesAuditados.map((poste) => (
            <div key={poste.id} className={styles.posteItem}>
              <select
                value={poste.idPoste}
                onChange={(e) => handlePosteChange(poste.id, 'idPoste', e.target.value)}
                required
              >
                <option value="">Selecione um Poste</option>
                {listaPostes.map((posteOption) => (
                  <option key={posteOption.id} value={posteOption.id}>
                    {posteOption.nome}
                  </option>
                ))}
              </select>
              <button
                type="button"
                onClick={() => handleRemovePoste(poste.id)}
                className={styles.removeButton}
                title="Remover Poste"
              >
                <FaTrash />
              </button>
            </div>
          ))}
          <button type="button" onClick={handleAddPoste} className={styles.addPosteButton}>
            <FaPlus /> Adicionar Poste
          </button>
        </div>

        {/* Seção para Adicionar Postes Aleatórios */}
        <div className={styles.randomPostesSection}>
          <label htmlFor="quantidadeRandom">Adicionar Postes Aleatórios</label>
          <div className={styles.randomPostesControls}>
            <input
              type="number"
              id="quantidadeRandom"
              name="quantidadeRandom"
              min="1"
              value={quantidadeRandom}
              onChange={(e) => setQuantidadeRandom(e.target.value)}
              placeholder="Quantidade"
            />
            <button
              type="button"
              onClick={handleAddRandomPostes}
              className={styles.addRandomPosteButton}
            >
              <FaPlus /> Adicionar Aleatoriamente
            </button>
          </div>
          {formData.restricaoAtiva && (
            <p className={styles.restrictionNotice}>
              Não adicionar postes que participaram de auditorias nos últimos {formData.diasRestricao} dias.
            </p>
          )}
        </div>

        <div className={styles.formActions}>
          <button type="button" className={styles.cancelButton} onClick={onRequestClose}>
            Cancelar
          </button>
          <button type="submit" className={styles.submitButton}>
            {isEditMode ? 'Atualizar Auditoria' : 'Salvar Auditoria'}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CriarAuditoriaModal;
