// src/components/PerfilModal/PerfilModal.jsx

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import styles from './PerfilModal.module.css';

// Definindo o AppElement para acessibilidade
Modal.setAppElement('#root');

// Lista de permissões
const permissoesList = [
  'Alertas',
  'Alertas Cor',
  'Alertas Situação',
  'Faturas',
  'Fotocélulas',
  'Levantamentos',
  'Levantamentos Lâmpadas',
  'Ordem de Serviço',
  'Ordem de Serviço Registros',
  'Perfil de Acesso',
  'Prestadores de Serviço',
  'Usuários Registros',
  'Postes',
  'Postes Consumo',
  'Postes Estruturas',
  'Postes/Fotocélulas',
  'Produtos Grupos',
  'Produtos',
  'Regiões',
  'Usuários',
  'Municipios',
  'Lampadas Tipo',
];

const PerfilModal = ({ isOpen, onRequestClose, onSave, perfilData }) => {
  // Estado para o formulário do modal
  const [formData, setFormData] = useState({
    nome: '',
    descricao: '',
    tipoAcesso: '0',
    administrador: false,
    permissoes: {},
  });

  // Estado para controle de edição
  const isEditMode = Boolean(perfilData);

  useEffect(() => {
    if (isEditMode) {
      setFormData({
        nome: perfilData.nome || '',
        descricao: perfilData.descricao || '',
        tipoAcesso: perfilData.tipoAcesso.toString() || '0',
        administrador: perfilData.administrador || false,
        permissoes: perfilData.permissoes || {},
      });
    } else {
      setFormData({
        nome: '',
        descricao: '',
        tipoAcesso: '0',
        administrador: false,
        permissoes: {},
      });
    }
  }, [isEditMode, perfilData]);

  // Função para lidar com mudanças nos campos do formulário
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith('permissao_')) {
      const [permissao, acao] = name.replace('permissao_', '').split('_');
      setFormData((prev) => ({
        ...prev,
        permissoes: {
          ...prev.permissoes,
          [permissao]: {
            ...prev.permissoes[permissao],
            [acao]: checked,
          },
        },
      }));
    } else if (type === 'checkbox') {
      setFormData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Função para lidar com o envio do formulário
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validação básica
    if (!formData.nome || !formData.descricao) {
      toast.error('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    // Passar os dados para a função onSave
    onSave(formData);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={isEditMode ? 'Editar Perfil de Acesso' : 'Criar Perfil de Acesso'}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalHeader}>
        <h2>{isEditMode ? 'Editar Perfil de Acesso' : 'Criar Perfil de Acesso'}</h2>
        <button className={styles.closeButton} onClick={onRequestClose}>
          <FaTimes />
        </button>
      </div>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="nome">Nome</label>
          <input
            type="text"
            id="nome"
            name="nome"
            value={formData.nome}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="descricao">Descrição</label>
          <input
            type="text"
            id="descricao"
            name="descricao"
            value={formData.descricao}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="tipoAcesso">Tipo de Acesso</label>
          <select
            id="tipoAcesso"
            name="tipoAcesso"
            value={formData.tipoAcesso}
            onChange={handleChange}
            required
          >
            <option value="0">Administrador</option>
            <option value="1">Tipo 1</option>
            <option value="2">Tipo 2</option>
            {/* Adicione mais opções conforme necessário */}
          </select>
        </div>

        <div className={styles.formGroupCheckbox}>
          <label>
            <input
              type="checkbox"
              name="administrador"
              checked={formData.administrador}
              onChange={handleChange}
            />
            Administrador
          </label>
        </div>

        <h3>Permissões</h3>
        <table className={styles.permissoesTable}>
          <thead>
            <tr>
              <th>Descrição</th>
              <th>Consultar</th>
              <th>Cadastrar</th>
              <th>Editar</th>
              <th>Excluir</th>
            </tr>
          </thead>
          <tbody>
            {permissoesList.map((permissao) => (
              <tr key={permissao}>
                <td>{permissao}</td>
                <td>
                  <input
                    type="checkbox"
                    name={`permissao_${permissao}_consultar`}
                    checked={
                      formData.permissoes[permissao]
                        ? formData.permissoes[permissao].consultar
                        : false
                    }
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    name={`permissao_${permissao}_cadastrar`}
                    checked={
                      formData.permissoes[permissao]
                        ? formData.permissoes[permissao].cadastrar
                        : false
                    }
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    name={`permissao_${permissao}_editar`}
                    checked={
                      formData.permissoes[permissao]
                        ? formData.permissoes[permissao].editar
                        : false
                    }
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    name={`permissao_${permissao}_excluir`}
                    checked={
                      formData.permissoes[permissao]
                        ? formData.permissoes[permissao].excluir
                        : false
                    }
                    onChange={handleChange}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className={styles.formActions}>
          <button type="button" className={styles.cancelButton} onClick={onRequestClose}>
            Cancelar
          </button>
          <button type="submit" className={styles.submitButton}>
            {isEditMode ? 'Atualizar' : 'Salvar'}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default PerfilModal;
