// src/components/DeviceListModal/DeviceListModal.jsx

import React from 'react';
import PropTypes from 'prop-types';
import styles from './DeviceListModal.module.css';
import { FaTimes } from 'react-icons/fa';

const DeviceListModal = ({ devices, category, onClose, onDeviceClick }) => {
  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <h5>Dispositivos - {category}</h5>
          <button className={styles.closeButton} onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <ul className={styles.deviceList}>
          {devices.map((device) => (
            <li key={device.id} onClick={() => onDeviceClick(device)}>
              <span className={styles.deviceName}>{device.deviceName}</span>
              <span className={styles.deviceTime}>
                {new Date(device.lastUpdate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

DeviceListModal.propTypes = {
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      deviceName: PropTypes.string.isRequired,
      lastUpdate: PropTypes.instanceOf(Date).isRequired,
      deviceLat: PropTypes.number,
      deviceLng: PropTypes.number,
    })
  ).isRequired,
  category: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeviceClick: PropTypes.func.isRequired,
};

export default DeviceListModal;
