// src/components/MarkerModal/MarkerModal.jsx

import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styles from './MarkerModal.module.css';
import {
  FaLightbulb,
  FaSyncAlt,
  FaCheckCircle,
  FaExclamationTriangle,
  FaClock,
  FaMapMarkerAlt,
  FaChevronDown,
  FaChevronUp,
  FaTimes,
  FaBatteryHalf,
  FaThermometerHalf,
  FaBolt,
  FaTachometerAlt,
  FaChartLine,
} from 'react-icons/fa';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';
import { parseISO } from 'date-fns';

const MarkerModal = ({ isOpen, onClose, markerData }) => {
  // Acesso ao AuthContext
  const { token, cidadeId, logout } = useContext(AuthContext);

  // Estados do componente
  const [isFullImageVisible, setIsFullImageVisible] = useState(false);
  const [expandedAlertId, setExpandedAlertId] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState('deviceTensaoRede'); // Métrica inicial: Tensão
  const [selectedDeviceIndex, setSelectedDeviceIndex] = useState(0); // Dispositivo IoT selecionado
  const [tabIndex, setTabIndex] = useState(0); // Aba ativa
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(false); // Estado para controlar a visibilidade dos filtros
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false); // Visibilidade das informações adicionais

  // Estados para gráficos
  const [graphData, setGraphData] = useState([]);
  const [isLoadingGraph, setIsLoadingGraph] = useState(false);
  const [graphError, setGraphError] = useState(null);

  // Estados para o seletor de data e hora
  const today = new Date();
  const formattedTodayDate = today.toISOString().split('T')[0]; // Formato YYYY-MM-DD
  const formattedTodayStartTime = '00:00';
  const formattedTodayEndTime = '23:59';
  const [startDateTime, setStartDateTime] = useState(`${formattedTodayDate}T${formattedTodayStartTime}`);
  const [endDateTime, setEndDateTime] = useState(`${formattedTodayDate}T${formattedTodayEndTime}`);

  // Estados para o intervalo do eixo Y
  const [yAxisMin, setYAxisMin] = useState('');
  const [yAxisMax, setYAxisMax] = useState('');

  // Uso de useMemo para definir variáveis derivadas
  const formattedId = useMemo(() => markerData?.codigoPoste, [markerData]);
  const fotocelulas = useMemo(() => markerData?.fotocelulas || [], [markerData]);
  const selectedDeviceData = useMemo(() => fotocelulas[selectedDeviceIndex] || {}, [fotocelulas, selectedDeviceIndex]);
  const firstDeviceData = useMemo(() => (fotocelulas.length > 0 ? fotocelulas[0] : {}), [fotocelulas]);

  // Construir a URL da imagem usando codigoPoste
  const imageUrl = useMemo(() => {
    return formattedId ? `https://ceasa.ads10.com.br/images/${formattedId}.jpg` : '';
  }, [formattedId]);

  // Função para alternar a exibição do alerta expandido
  const toggleAlert = useCallback((id) => {
    setExpandedAlertId((prevId) => (prevId === id ? null : id));
  }, []);

  // Mapeamento das situações dos alertas
  const statusMap = useMemo(
    () => ({
      '1': 'Aguardando',
      '2': 'Atendendo',
      '3': 'Cancelada',
      '4': 'Finalizada',
    }),
    []
  );

  // Selecionar os 3 últimos alertas, ordenados por data e hora
  const latestAlerts = useMemo(() => {
    if (!markerData?.alerts) return [];
    return [...markerData.alerts]
      .sort((a, b) => {
        const dateA = new Date(`${a.alertaDataGeracao}T${a.alertaHoraGeracao}`);
        const dateB = new Date(`${b.alertaDataGeracao}T${b.alertaHoraGeracao}`);
        return dateB - dateA;
      })
      .slice(0, 3);
  }, [markerData]);

  // Definição das métricas com rótulos e unidades
  const metricsOptions = useMemo(() => [
    { value: 'deviceTensaoRede', label: 'Tensão', unit: 'V' }, // Primeira opção: Tensão
    { value: 'deviceConsumo', label: 'Consumo', unit: 'kWh' },
    { value: 'deviceKw', label: 'Potência', unit: 'kW' },
    { value: 'deviceCorrente', label: 'Corrente', unit: 'A' },
    { value: 'devicePf', label: 'Fator de Potência', unit: '' },
    { value: 'deviceTempoLampadaLigada', label: 'Tempo Ligado', unit: 's' },
  ], []);

  const metricsMap = useMemo(() => {
    const map = {};
    metricsOptions.forEach(metric => {
      map[metric.value] = { label: metric.label, unit: metric.unit };
    });
    return map;
  }, [metricsOptions]);

  const getTensaoColor = useCallback((tensao) => {
    if (tensao >= 198 && tensao <= 242) {
      return 'green'; // Tensão Boa
    } else if (tensao > 242 && tensao <= 264) {
      return 'yellow'; // Tensão Média Alta
    } else if (tensao > 264) {
      return 'red'; // Tensão Alta
    } else if (tensao >= 176 && tensao < 198) {
      return 'lightblue'; // Tensão Média Baixa (Azul claro)
    } else if (tensao < 176) {
      return 'darkblue'; // Tensão Baixa (Azul escuro)
    }
    return 'black'; // Caso a tensão não esteja disponível ou inválida
  }, []);

  // Função para buscar dados do gráfico
  const fetchGraphData = useCallback(async () => {
    if (!markerData?.idPoste || !selectedDeviceData?.deviceEUI || !selectedMetric) {
      setGraphData([]);
      return;
    }

    // **Adicionar verificação se o dispositivo está offline**
    console.log('Dispositivo está offline:', selectedDeviceData.offline); // Log de depuração
    if (selectedDeviceData.offline) {
      setGraphData([]);
      setGraphError('Dispositivo está offline. Nenhum dado disponível.');
      return;
    }

    setIsLoadingGraph(true);
    setGraphError(null);

    try {
      // Novo endpoint conforme orientação
      const response = await fetch(`https://api.ads10.com.br/api/device/${selectedDeviceData.deviceEUI}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          cidadeId: cidadeId, // Envia o cidadeId no header
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          toast.error('Sessão expirada. Faça login novamente.');
          logout();
          return;
        }
        if (response.status === 404) {
          // Dispositivo sem dados
          setGraphData([]);
          setGraphError('Nenhum dado disponível para este dispositivo.');
          return;
        }
        throw new Error('Erro ao buscar dados do gráfico');
      }

      const result = await response.json();

      // Log para verificar a estrutura dos dados
      console.log('Dados do Gráfico:', result);

      // Verificar se os dados estão no formato esperado
      if (!Array.isArray(result)) {
        throw new Error('Formato de dados inválido recebido do servidor.');
      }

      // Combinar data e hora para filtrar
      const startDateTimeObj = startDateTime ? new Date(startDateTime) : null;
      const endDateTimeObj = endDateTime ? new Date(endDateTime) : null;

      // Transformar e filtrar os dados com base no intervalo de data e hora selecionado
      const transformedData = result
        .map(item => ({
          data: item.Data, // Mantém data e hora
          valor: parseFloat(item[selectedMetric]),
        }))
        .filter(item => {
          const itemDate = parseISO(item.data);
          return (
            (!startDateTimeObj || itemDate >= startDateTimeObj) &&
            (!endDateTimeObj || itemDate <= endDateTimeObj)
          );
        });

      // Log para verificar os dados transformados e filtrados
      console.log('Dados Transformados e Filtrados:', transformedData);

      // Verificar se 'valor' está corretamente definido
      transformedData.forEach((d, index) => {
        if (isNaN(d.valor)) {
          console.warn(`Valor NaN encontrado no índice ${index}:`, d);
        }
      });

      // Ordenar os dados por data e hora
      const sortedData = transformedData.sort((a, b) => new Date(a.data) - new Date(b.data));

      setGraphData(sortedData);
    } catch (err) {
      console.error(err);
      setGraphError(err.message);
      toast.error(`Erro: ${err.message}`);
    } finally {
      setIsLoadingGraph(false);
    }
  }, [
    markerData,
    selectedDeviceData,
    selectedMetric,
    token,
    cidadeId,
    logout,
    startDateTime,
    endDateTime,
  ]);

  // Buscar dados do gráfico quando as dependências mudarem
  useEffect(() => {
    fetchGraphData();
  }, [fetchGraphData]);

  // Timer de atualização automática
  useEffect(() => {
    let intervalId;

    // Função para iniciar o timer
    const startAutoUpdate = () => {
      // Atualiza os dados imediatamente, se não for offline
      if (!selectedDeviceData.offline) {
        fetchGraphData();
      }

      // Define o intervalo para 10 minutos (600000 ms)
      intervalId = setInterval(() => {
        if (!selectedDeviceData.offline) {
          fetchGraphData();
        }
      }, 600000); // 600000 ms = 10 minutos
    };

    if (isOpen) {
      startAutoUpdate();
    }

    // Limpa o intervalo quando o modal for fechado ou o componente desmontar
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isOpen, selectedDeviceData.offline, fetchGraphData]);

  // Resetar o estado ao abrir um novo modal para um poste diferente
  useEffect(() => {
    if (isOpen && markerData && markerData.idPoste) {
      setSelectedDeviceIndex(0);
      setSelectedMetric('deviceTensaoRede'); // Definir Tensão como métrica inicial
      setTabIndex(0);
      setGraphData([]);
      setGraphError(null);
      setStartDateTime(`${formattedTodayDate}T${formattedTodayStartTime}`); // Define a data e hora inicial para o dia atual
      setEndDateTime(`${formattedTodayDate}T${formattedTodayEndTime}`); // Define a data e hora final para o dia atual
      setIsFiltersExpanded(false); // Minimiza os filtros ao abrir um novo modal
      setYAxisMin(''); // Reseta o intervalo do eixo Y
      setYAxisMax('');
      setShowAdditionalInfo(false); // Reseta a visibilidade das informações adicionais
      console.log('MarkerData atualizado:', markerData);
      console.log('Potência:', markerData.potencia);
      console.log('Consumo:', markerData.consumo);
    }
  }, [isOpen, markerData, formattedTodayDate, formattedTodayStartTime, formattedTodayEndTime]);

  // Função para atualizar os dados do gráfico
  const handleUpdateData = useCallback(() => {
    fetchGraphData();
  }, [fetchGraphData]);

  // Função para limpar os filtros e redefinir para o dia atual
  const handleClearFilters = useCallback(() => {
    setStartDateTime(`${formattedTodayDate}T${formattedTodayStartTime}`);
    setEndDateTime(`${formattedTodayDate}T${formattedTodayEndTime}`);
    setYAxisMin(''); // Reseta o intervalo do eixo Y
    setYAxisMax('');
    fetchGraphData();
  }, [formattedTodayDate, formattedTodayStartTime, formattedTodayEndTime, fetchGraphData]);

  // Obtém a última medição disponível
  const lastMeasurement = useMemo(() => {
    if (graphData.length === 0) return null;
    return graphData[graphData.length - 1];
  }, [graphData]);

  return (
    <>
      {isOpen && markerData && (
        <div className={styles.overlay}>
          <div
            className={`${styles.modalContentWrapper} ${isFullImageVisible ? styles.blurredBackground : ''}`}
          >
            <div className={styles.modal}>
              <button className={styles.closeButton} onClick={onClose}>
                <FaTimes />
              </button>

              {/* Usar formattedId no título */}
              <h2 className={styles.title}>Informações do Poste {formattedId}</h2>

              <div className={styles.modalContentArea}>
                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                  <TabList>
                    <Tab>Informações Gerais</Tab>
                    <Tab>Alertas</Tab>
                    <Tab disabled={fotocelulas.length === 0}>Dispositivos IoT</Tab>
                    <Tab disabled={fotocelulas.length === 0}>Métricas e Gráficos</Tab>
                  </TabList>

                  {/* Aba 1: Informações Gerais */}
                  <TabPanel>
                    <div className={styles.generalInfoContainer}>
                      <div className={styles.leftSection}>
                        {/* Informações Principais */}
                        <p>
                          <FaMapMarkerAlt /> <strong>Endereço:</strong> {markerData?.address || 'Endereço não disponível'}
                        </p>
                        {/* Exibindo 'tensaoRede' do primeiro dispositivo IoT se disponível */}
                        <p style={{ color: getTensaoColor(parseFloat(firstDeviceData?.tensaoRede)) }}>
                          <FaBolt /> <strong>Tensão de Rede:</strong> {firstDeviceData?.tensaoRede || 'N/A'} V
                        </p>
                        {/* Exibindo a potência instalada do poste */}
                        <p>
                          <FaTachometerAlt /> <strong>Potência Instalada:</strong> {markerData?.potencia || 'Não disponível'} W
                        </p>
                        {/* Exibindo o consumo do poste */}
                        <p>
                          <FaChartLine /> <strong>Consumo:</strong> {markerData?.consumo || 'Não disponível'} kWh
                        </p>

                        {/* Botão para Expandir/Ocultar Informações Adicionais */}
                        <button
                          className={styles.expandButton}
                          onClick={() => setShowAdditionalInfo(prev => !prev)}
                        >
                          {showAdditionalInfo ? (
                            <>
                              <FaChevronUp /> Ocultar Informações Adicionais
                            </>
                          ) : (
                            <>
                              <FaChevronDown /> Mostrar Informações Adicionais
                            </>
                          )}
                        </button>

                        {/* Informações Adicionais Ocultas/Iniciais */}
                        {showAdditionalInfo && (
                          <div className={styles.additionalInfo}>
                            <p>
                              <FaBatteryHalf /> <strong>Tipo de Cabo de Alimentação:</strong> {markerData?.tipo_cabo_alimentacao || 'N/A'}
                            </p>
                            <p>
                              <FaBolt /> <strong>Alimentação do Transformador:</strong> {markerData?.alimentacao_transformador || 'N/A'}
                            </p>
                            <p>
                              <FaSyncAlt /> <strong>Número da Plaqueta do Transformador:</strong> {markerData?.numero_plaqueta_transformador || 'N/A'}
                            </p>
                            <p>
                              <FaLightbulb /> <strong>Lâmpada Medida:</strong> {markerData?.lampada_medida || 'N/A'}
                            </p>
                            <p>
                              <FaExclamationTriangle /> <strong>Rede de Alta Tensão:</strong> {markerData?.rede_alta_tencao || 'N/A'}
                            </p>
                            <p>
                              <FaThermometerHalf /> <strong>Número do Medidor da Lâmpada:</strong> {markerData?.numero_medidor_lampada || 'N/A'}
                            </p>
                            <p>
                              <FaChartLine /> <strong>Grafo de Conexão:</strong> {markerData?.grafo_conexao || 'N/A'}
                            </p>
                            <p>
                              <FaMapMarkerAlt /> <strong>Tipo de Via:</strong> {markerData?.tipoVia || 'N/A'}
                            </p>
                            <p>
                              <FaMapMarkerAlt /> <strong>Classificação da Via:</strong> {markerData?.classificacaoVia || 'N/A'}
                            </p>
                            <p>
                              <FaBolt /> <strong>Transformador:</strong> {markerData?.transformador || 'N/A'}
                            </p>
                            <p>
                              <FaMapMarkerAlt /> <strong>Tipo de Poste:</strong> {markerData?.tipoPoste || 'N/A'}
                            </p>
                            <p>
                              <FaMapMarkerAlt /> <strong>Formato do Poste:</strong> {markerData?.formatoPoste || 'N/A'}
                            </p>
                            <p>
                              <FaMapMarkerAlt /> <strong>Comprimento:</strong> {markerData?.comprimento || 'N/A'} m
                            </p>
                            <p>
                              <FaMapMarkerAlt /> <strong>Formato da Tampa:</strong> {markerData?.formatoTampa || 'N/A'}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className={styles.rightSection}>
                        <div className={styles.imageContainer}>
                          <img
                            src={imageUrl}
                            alt={`Poste ${formattedId}`}
                            className={styles.posteImage}
                            onClick={() => setIsFullImageVisible(true)}
                          />
                        </div>
                      </div>
                    </div>

                    {isFullImageVisible && (
                      <div
                        className={styles.fullImageOverlay}
                        onClick={() => setIsFullImageVisible(false)}
                      >
                        <img
                          src={imageUrl}
                          alt={`Poste ${formattedId} em tamanho real`}
                          className={styles.fullsizeImage}
                        />
                      </div>
                    )}
                  </TabPanel>

                  {/* Aba 2: Alertas */}
                  <TabPanel>
                    <div className={styles.alertSection}>
                      <h3>Últimos Alertas</h3>
                      {latestAlerts.length > 0 ? (
                        latestAlerts.map((alert) => {
                          const isResolved = ['3', '4'].includes(alert.alertaSituacao);
                          return (
                            <div
                              key={alert.alertaId}
                              className={`${styles.alertHeader} ${isResolved ? styles.resolvedAlert : styles.unresolvedAlert}`}
                              onClick={() => toggleAlert(alert.alertaId)}
                            >
                              <div>
                                {isResolved ? (
                                  <FaCheckCircle className={styles.resolvedIcon} />
                                ) : (
                                  <FaExclamationTriangle className={styles.unresolvedIcon} />
                                )}
                                {alert.alertaMotivo}
                              </div>
                              {expandedAlertId === alert.alertaId ? (
                                <FaChevronUp className={styles.chevronIcon} />
                              ) : (
                                <FaChevronDown className={styles.chevronIcon} />
                              )}
                              {expandedAlertId === alert.alertaId && (
                                <div className={styles.alertDetails}>
                                  {/* Exibindo o ID do Alerta */}
                                  <p>
                                    <strong>ID do Alerta:</strong> {alert.alertaId}
                                  </p>
                                  <p>
                                    <FaClock /> <strong>Data:</strong> {alert.alertaDataGeracao} {alert.alertaHoraGeracao}
                                  </p>
                                  <p>
                                    <FaMapMarkerAlt /> <strong>Origem:</strong> {alert.alertaOrigem}
                                  </p>
                                  <p><strong>Motivo:</strong> {alert.alertaMotivo}</p>
                                  <p><strong>Situação:</strong> {statusMap[alert.alertaSituacao]}</p>
                                </div>
                              )}
                            </div>
                          );
                        })
                      ) : (
                        <p>Nenhum alerta recente.</p>
                      )}
                    </div>
                  </TabPanel>

                  {/* Aba 3: Dispositivos IoT */}
                  <TabPanel>
                    <div className={styles.deviceTab}>
                      {fotocelulas.length > 0 ? (
                        <>
                          <div className={styles.deviceIcons}>
                            {/* Mapeando cada dispositivo IoT associado ao poste */}
                            {fotocelulas.map((device, index) => (
                              <div key={device.fotocelulaId} className={styles.deviceIconContainer}>
                                <FaLightbulb
                                  className={
                                    selectedDeviceIndex === index
                                      ? device.ligado === 'L'
                                        ? styles.activeDeviceIconOn // Lâmpada acesa - Amarelo
                                        : styles.activeDeviceIconOff // Lâmpada apagada - Azul
                                      : styles.inactiveDeviceIcon
                                  }
                                  onClick={() => setSelectedDeviceIndex(index)}
                                  title="Status do dispositivo"
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                />
                              </div>
                            ))}
                          </div>

                          <div className={styles.deviceSection}>
                            <h3>Dados do Dispositivo</h3>
                            <p>
                              <FaLightbulb /> <strong>Descrição:</strong> {selectedDeviceData?.descricao || 'Não disponível'}
                            </p>
                            <p>
                              <FaTachometerAlt /> <strong>ID do dispositivo:</strong> {selectedDeviceData?.deviceEUI || 'Não disponível'}
                            </p>
                            <p>
                              <FaClock /> <strong>Última atualização:</strong> {selectedDeviceData?.deviceAtualizacao || 'Não disponível'}
                            </p>
                            {/* Adicionando informação de status: Acesa ou Apagada */}
                            <p>
                              <FaLightbulb /> <strong>Status da Lâmpada:</strong> {selectedDeviceData?.ligado === 'L' ? 'Acesa' : 'Apagada'}
                            </p>

                            <p>
                              <FaClock /> <strong>Tempo Ligada:</strong> {selectedDeviceData?.tempolampadaligada || 'N/A'} h
                            </p>
                            <p>
                              <FaBatteryHalf /> <strong>Corrente:</strong> {selectedDeviceData?.corrente || 'N/A'} A
                            </p>
                            <p
                              style={{
                                color: getTensaoColor(parseFloat(selectedDeviceData?.tensaoRede || 0)),
                              }}
                            >
                              <FaBolt /> <strong>Tensão:</strong> {selectedDeviceData?.tensaoRede || 'N/A'} V
                            </p>
                            <p>
                              <FaTachometerAlt /> <strong>Fator de Potência:</strong> {selectedDeviceData?.pf || 'N/A'}
                            </p>

                            <p>
                              <FaTachometerAlt /> <strong>Fator de Potência:</strong> {selectedDeviceData?.pf || 'N/A'}
                            </p>
                          </div>
                        </>
                      ) : (
                        <p>Nenhum dispositivo IoT neste poste.</p>
                      )}
                    </div>
                  </TabPanel>

                  {/* Aba 4: Métricas e Gráficos */}
                  <TabPanel>
                    <div className={styles.metricsTab}>
                      {fotocelulas.length > 0 ? (
                        <>
                          {/* Botão de Toggle para Filtros */}
                          <div className={styles.filterToggleContainer}>
                            <button
                              className={styles.filterToggleButton}
                              onClick={() => setIsFiltersExpanded(prev => !prev)}
                            >
                              {isFiltersExpanded ? (
                                <>
                                  <FaChevronUp /> Ocultar Filtros
                                </>
                              ) : (
                                <>
                                  <FaChevronDown /> Mostrar Filtros
                                </>
                              )}
                            </button>
                          </div>

                          {/* Filtros Condicionalmente Renderizados */}
                          {isFiltersExpanded && (
                            <div className={styles.filterContainer}>
                              <h3 className={styles.filterTitle}>Filtro de Dados</h3>

                              {/* Filtros de Data e Hora */}
                              <div className={styles.filterGroup}>
                                <div className={styles.filterItem}>
                                  <label htmlFor="startDateTime" className={styles.filterLabel}>Data e Hora Início:</label>
                                  <input
                                    type="datetime-local"
                                    id="startDateTime"
                                    value={startDateTime}
                                    onChange={(e) => setStartDateTime(e.target.value)}
                                    className={styles.dropdown}
                                  />
                                </div>

                                <div className={styles.filterItem}>
                                  <label htmlFor="endDateTime" className={styles.filterLabel}>Data e Hora Fim:</label>
                                  <input
                                    type="datetime-local"
                                    id="endDateTime"
                                    value={endDateTime}
                                    onChange={(e) => setEndDateTime(e.target.value)}
                                    className={styles.dropdown}
                                  />
                                </div>

                                {/* Campos para Intervalo do Eixo Y */}
                                <div className={styles.filterItem}>
                                  <label htmlFor="yAxisMin" className={styles.filterLabel}>Y-Axis Mínimo:</label>
                                  <input
                                    type="number"
                                    id="yAxisMin"
                                    value={yAxisMin}
                                    onChange={(e) => setYAxisMin(e.target.value)}
                                    className={styles.dropdown}
                                    placeholder="Auto"
                                  />
                                </div>

                                <div className={styles.filterItem}>
                                  <label htmlFor="yAxisMax" className={styles.filterLabel}>Y-Axis Máximo:</label>
                                  <input
                                    type="number"
                                    id="yAxisMax"
                                    value={yAxisMax}
                                    onChange={(e) => setYAxisMax(e.target.value)}
                                    className={styles.dropdown}
                                    placeholder="Auto"
                                  />
                                </div>
                              </div>

                              {/* Botões de Ação */}
                              <div className={styles.actionsContainer}>
                                <button onClick={handleUpdateData} className={styles.button}>
                                  <FaSyncAlt /> Atualizar
                                </button>
                                <button onClick={handleClearFilters} className={styles.clearFiltersButton}>
                                  Limpar Filtros
                                </button>
                              </div>
                            </div>
                          )}

                          <div className={styles.deviceSelection}>
                            <label htmlFor="deviceSelect"><strong>Dispositivo:</strong></label>
                            <select
                              id="deviceSelect"
                              value={selectedDeviceIndex}
                              onChange={(e) => setSelectedDeviceIndex(Number(e.target.value))}
                            >
                              {fotocelulas.map((device, index) => (
                                <option key={device.fotocelulaId} value={index}>
                                  {device.descricao} - {device.deviceEUI}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className={styles.metricSelection}>
                            <label htmlFor="metric"><strong>Métrica:</strong></label>
                            <select
                              id="metric"
                              value={selectedMetric}
                              onChange={(e) => setSelectedMetric(e.target.value)}
                            >
                              {metricsOptions.map((metric) => (
                                <option key={metric.value} value={metric.value}>
                                  {metric.label} {metric.unit && `(${metric.unit})`}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className={styles.graphContainer}>
                            {isLoadingGraph ? (
                              <p>Carregando gráfico...</p>
                            ) : graphError ? (
                              <p>Erro: {graphError}</p>
                            ) : graphData.length > 0 ? (
                              <>
                                <h4>
                                  {selectedDeviceData?.descricao} - {selectedDeviceData?.deviceEUI}
                                </h4>
                                <h4>
                                  {metricsMap[selectedMetric].label} {metricsMap[selectedMetric].unit && `(${metricsMap[selectedMetric].unit})`} -
                                  {startDateTime && endDateTime ? (
                                    <>
                                      {new Date(startDateTime).toLocaleString('pt-BR')} a {new Date(endDateTime).toLocaleString('pt-BR')}
                                    </>
                                  ) : (
                                    'Intervalo Personalizado'
                                  )}
                                </h4>
                                <ResponsiveContainer width="100%" height={400}>
                                  <LineChart data={graphData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                      dataKey="data"
                                      tickFormatter={(tick) => new Date(tick).toLocaleDateString('pt-BR', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                      })}
                                      minTickGap={20}
                                    />
                                    <YAxis
                                      domain={
                                        yAxisMin !== '' && yAxisMax !== ''
                                          ? [Number(yAxisMin), Number(yAxisMax)]
                                          : ['auto', 'auto']
                                      }
                                      label={{
                                        value: `${metricsMap[selectedMetric].label} ${metricsMap[selectedMetric].unit}`,
                                        angle: -90,
                                        position: 'insideLeft',
                                        offset: 10,
                                      }}
                                    />
                                    <Tooltip
                                      labelFormatter={(label) => new Date(label).toLocaleString('pt-BR', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit'
                                      })}
                                      formatter={(value) => `${value} ${metricsMap[selectedMetric].unit}`}
                                    />
                                    <Legend verticalAlign="top" height={36} />
                                    <Line
                                      type="monotone"
                                      dataKey="valor"
                                      name={`${metricsMap[selectedMetric].label} ${metricsMap[selectedMetric].unit}`}
                                      stroke="#8884d8"
                                      activeDot={{ r: 8 }}
                                    />
                                  </LineChart>
                                </ResponsiveContainer>
                              </>
                            ) : (
                              <p>Nenhum dado disponível para exibir o gráfico.</p>
                            )}
                          </div>

                          {/* Campo de Última Medição */}
                          <div className={styles.lastMeasurement}>
                            <h4>Última Medição</h4>
                            {lastMeasurement ? (
                              <p>
                                <strong>Valor:</strong> {lastMeasurement.valor} {metricsMap[selectedMetric].unit}<br />
                                <strong>Data e Hora:</strong> {parseISO(lastMeasurement.data).toLocaleString('pt-BR', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  second: '2-digit',
                                })}
                              </p>
                            ) : (
                              <p>Nenhuma medição disponível.</p>
                            )}
                          </div>
                        </>
                      ) : (
                        <p>Nenhum dispositivo IoT disponível para exibir métricas.</p>
                      )}
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MarkerModal;
