// src/pages/Relatorios/ModalRelatorios.jsx

import React from 'react';
import styles from './ModalRelatorios.module.css';

const ModalRelatorios = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div
        className={styles.modalContent}
        onClick={(e) => e.stopPropagation()}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-title"
      >
        <div className={styles.modalHeader}>
          <h2 id="modal-title">{title}</h2>
          <button
            className={styles.closeButton}
            onClick={onClose}
            aria-label="Fechar Modal"
          >
            &times;
          </button>
        </div>
        <div className={styles.modalBody}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalRelatorios;
