import React, { useEffect, useState, useContext } from 'react';
import {
  format,
  subMonths,
  endOfMonth,
  parseISO,
  isSameMonth,
  getDate,
  isValid,
} from 'date-fns';
import PageLayout from '../../components/PageLayout/PageLayout';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import ChartCard from '../../components/ChartCard/ChartCard';
import { FaCog } from 'react-icons/fa';
import styles from './Dashboard.module.css';
import { AuthContext } from '../../context/AuthContext';
import { toast } from 'react-toastify';
import ConfigModal from './ConfigModal';
import { BASE_URL } from '../../config'; // Importando BASE_URL de config.js

// Import da função de geolocalização
import { isValidGeolocation } from '../../utils/geolocation';

// Definição das cores para os gráficos
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6384'];
const STATUS_COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'];
const POSTE_COLORS = ['#00C49F', '#FF8042']; // Verde para aceso, laranja para apagado

// Agora adicionamos mais cores para 3 categorias de geolocalização, se desejado
const GEOLABEL_COLOR = ['#00C49F', '#FF8042', '#FF6384']; // 3 cores

const BAR_COLOR = '#8884d8';
const LINE_COLOR = '#82ca9d';

// Mapeamento de status para facilitar a contagem
const statusMap = {
  '1': 'Aguardando',
  '2': 'Atendendo',
  '3': 'Cancelada',
  '4': 'Finalizada',
};

// Mapeamento de situação de OS para nomes mais descritivos (ajuste conforme necessário)
const osSituationMap = {
  '1': 'Aberta',
  '2': 'Em Progresso',
  '3': 'Cancelada',
  '4': 'Encerrada',
};

// Função para transformar objetos em dados de gráfico
const transformToChartData = (obj) => {
  return Object.keys(obj).map((key) => ({
    name: key,
    value: obj[key],
  }));
};

// Função auxiliar para obter o nome de exibição dos gráficos
const getChartDisplayName = (key) => {
  const displayNames = {
    internos: 'Alertas Internos',
    externos: 'Alertas Externos',
    todos: 'Todos os Alertas',
    status: 'Alertas por Status',
    postesGeo: 'Postes Geolocalizados',
    last4Months: 'Total de Alertas nos Últimos 4 Meses',
    currentMonth: 'Alertas Criados no Mês Atual',
    osBySituation: 'Ordens de Serviço por Situação',
    osLast4Months: 'Ordens de Serviço Abertas nos Últimos 4 Meses',
    osCurrentMonth: 'Ordens de Serviço Encerradas no Mês Atual',
    posteStatusFotocelula: 'Status de Postes (Fotocélula)',
  };
  return displayNames[key] || key;
};

// Função para verificar se uma data é válida
const isValidDate = (dateString) => {
  const date = parseISO(dateString);
  return isValid(date);
};

const Dashboard = () => {
  const { token, cidadeId, logout } = useContext(AuthContext);
  const [alertData, setAlertData] = useState({
    internos: [],
    externos: [],
    todos: [],
    status: [],
    postesGeo: [],
    last4Months: [],
    currentMonth: [],
  });
  const [osData, setOSData] = useState({
    bySituation: [],
    last4Months: [],
    currentMonth: [],
  });
  const [posteStatusFotocelulaData, setPosteStatusFotocelulaData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeLeft, setTimeLeft] = useState(60); // Tempo restante para a próxima atualização

  // Estados para configurações
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [visibleCharts, setVisibleCharts] = useState({
    internos: true,
    externos: true,
    todos: true,
    status: true,
    postesGeo: true,
    last4Months: true,
    currentMonth: true,
    osBySituation: true,
    osLast4Months: true,
    osCurrentMonth: true,
    posteStatusFotocelula: true,
    isCompactMode: false,
  });
  const [chartOrder, setChartOrder] = useState([
    'internos',
    'externos',
    'todos',
    'status',
    'postesGeo',
    'last4Months',
    'currentMonth',
    'osBySituation',
    'osLast4Months',
    'osCurrentMonth',
    'posteStatusFotocelula',
  ]);

  // Função para processar dados de alertas
  const processAlertData = (data, cidadeId) => {
    if (parseInt(cidadeId, 10) === 3315) {
      // Lógica existente para separar internos e externos
      const internos = {
        'Ponto Apagado': 0,
        'Material Danificado': 0,
        'Implantação': 0,
        'Outros': 0,
      };
      const externos = {
        'Falta de Energia': 0,
        'Queda de Tensão': 0,
        'Risco de Choque Elétrico': 0,
        'Limpeza das Instalações': 0,
        'Novas Instalações': 0,
        'Ampliação de Rede': 0,
        'Material Danificado': 0,
        'Outros': 0,
      };
      const status = {
        'Aguardando': 0,
        'Atendendo': 0,
        'Cancelada': 0,
        'Finalizada': 0,
      };

      data.forEach((alert) => {
        // Verificar se a data é válida
        if (!isValidDate(alert.alertaDataGeracao)) {
          console.warn(
            `Alerta ID ${alert.alertaId} possui data inválida: ${alert.alertaDataGeracao}`
          );
          return; // Ignora este alerta
        }

        // Desestruturar com valores padrão
        const [tipoAlerta = '', motivoRaw = ''] = alert.alertaMotivo
          .split('/')
          .map((item) => item.trim());

        // Opcional: Log para alertas sem '/'
        if (!alert.alertaMotivo.includes('/')) {
          console.warn(
            `Alerta ID ${alert.alertaId} possui 'alertaMotivo' sem separador '/': ${alert.alertaMotivo}`
          );
        }

        if (tipoAlerta.includes('Interno aos Galpões')) {
          if (motivoRaw.includes('Ponto Apagado')) {
            internos['Ponto Apagado'] += 1;
          } else if (motivoRaw.includes('Material Danificado')) {
            internos['Material Danificado'] += 1;
          } else if (motivoRaw.includes('Implantação')) {
            internos['Implantação'] += 1;
          } else {
            internos['Outros'] += 1;
          }
        } else if (tipoAlerta.includes('Externo aos Galpões')) {
          if (motivoRaw.includes('Falta de Energia')) {
            externos['Falta de Energia'] += 1;
          } else if (motivoRaw.includes('Queda de Tensão')) {
            externos['Queda de Tensão'] += 1;
          } else if (motivoRaw.includes('Risco de Choque Elétrico')) {
            externos['Risco de Choque Elétrico'] += 1;
          } else if (motivoRaw.includes('Limpeza das Instalações')) {
            externos['Limpeza das Instalações'] += 1;
          } else if (motivoRaw.includes('Novas Instalações')) {
            externos['Novas Instalações'] += 1;
          } else if (motivoRaw.includes('Ampliação de Rede')) {
            externos['Ampliação de Rede'] += 1;
          } else if (motivoRaw.includes('Material Danificado')) {
            externos['Material Danificado'] += 1;
          } else {
            externos['Outros'] += 1;
          }
        }

        const situacao = statusMap[alert.alertaSituacao] || 'Desconhecido';
        if (situacao !== 'Desconhecido') {
          status[situacao] += 1;
        }
      });

      return {
        internos: transformToChartData(internos),
        externos: transformToChartData(externos),
        status: [
          { name: 'Aguardando', value: status['Aguardando'] },
          { name: 'Atendendo', value: status['Atendendo'] },
          { name: 'Cancelada', value: status['Cancelada'] },
          { name: 'Finalizada', value: status['Finalizada'] },
        ],
      };
    } else {
      // Nova lógica para cidadeId diferente de 3315
      const categorias = {
        'Ponto Apagado durante a Noite': 0,
        'Material Danificado Causando Risco de Choque/Queda': 0,
        'Implantação': 0,
        'Outros': 0,
      };
      const status = {
        'Aguardando': 0,
        'Atendendo': 0,
        'Cancelada': 0,
        'Finalizada': 0,
      };

      data.forEach((alert) => {
        // Verificar se a data é válida
        if (!isValidDate(alert.alertaDataGeracao)) {
          console.warn(
            `Alerta ID ${alert.alertaId} possui data inválida: ${alert.alertaDataGeracao}`
          );
          return; // Ignora este alerta
        }

        // Desestruturar com valores padrão
        const [tipoAlerta = '', motivoRaw = ''] = alert.alertaMotivo
          .split('/')
          .map((item) => item.trim());

        // Opcional: Log para alertas sem '/'
        if (!alert.alertaMotivo.includes('/')) {
          console.warn(
            `Alerta ID ${alert.alertaId} possui 'alertaMotivo' sem separador '/': ${alert.alertaMotivo}`
          );
        }

        // Mapeamento das novas categorias
        if (motivoRaw.includes('Ponto Apagado durante a Noite')) {
          categorias['Ponto Apagado durante a Noite'] += 1;
        } else if (
          motivoRaw.includes('Material Danificado') &&
          (motivoRaw.includes('Risco de Choque') ||
            motivoRaw.includes('Queda'))
        ) {
          categorias['Material Danificado Causando Risco de Choque/Queda'] += 1;
        } else if (motivoRaw.includes('Implantação')) {
          categorias['Implantação'] += 1;
        } else {
          categorias['Outros'] += 1;
        }

        const situacao = statusMap[alert.alertaSituacao] || 'Desconhecido';
        if (situacao !== 'Desconhecido') {
          status[situacao] += 1;
        }
      });

      return {
        todos: transformToChartData(categorias),
        status: [
          { name: 'Aguardando', value: status['Aguardando'] },
          { name: 'Atendendo', value: status['Atendendo'] },
          { name: 'Cancelada', value: status['Cancelada'] },
          { name: 'Finalizada', value: status['Finalizada'] },
        ],
      };
    }
  };

  // Função para processar dados de postes para Geolocalização
  const processPosteGeoData = (posteData) => {
    // Filtrar apenas os postes ativos
    const ativos = posteData.filter((poste) => poste.ativo === 'S');

    // Contadores
    let geolocalizadosCount = 0;
    let localizacaoInvalidaCount = 0;
    let naoGeolocalizadosCount = 0;

    ativos.forEach((poste) => {
      // Se não tiver geoLocalizacao ou for '-, -', consideramos como "não geolocalizado"
      if (
        !poste.geoLocalizacao ||
        poste.geoLocalizacao.trim() === '' ||
        poste.geoLocalizacao.trim() === '-, -'
      ) {
        naoGeolocalizadosCount++;
      } else {
        // Tentar separar latitude e longitude
        const [latRaw, lngRaw] = poste.geoLocalizacao
          .split(',')
          .map((coord) => coord.trim());

        // Verificar se a geolocalização é válida usando nosso util
        if (isValidGeolocation(latRaw, lngRaw)) {
          geolocalizadosCount++;
        } else {
          localizacaoInvalidaCount++;
        }
      }
    });

    return [
      { name: 'Postes Geolocalizados', value: geolocalizadosCount },
      { name: 'Postes Não Geolocalizados', value: naoGeolocalizadosCount },
      { name: 'Localização Inválida', value: localizacaoInvalidaCount },
    ];
  };

  // Função para processar dados dos últimos 4 meses
  const processAlertDataLast4Months = (data) => {
    const today = new Date();
    const last4Months = [];

    for (let i = 3; i >= 0; i--) {
      const date = subMonths(today, i);
      const month = format(date, 'MMMM yyyy');
      last4Months.push(month);
    }

    const alertByMonth = {};
    last4Months.forEach((month) => {
      alertByMonth[month] = 0;
    });

    data.forEach((alert) => {
      const alertDate = parseISO(alert.alertaDataGeracao);
      if (!isValid(alertDate)) {
        console.warn(
          `Alerta ID ${alert.alertaId} possui data inválida: ${alert.alertaDataGeracao}`
        );
        return; // Ignora este alerta
      }
      const alertMonth = format(alertDate, 'MMMM yyyy');

      if (alertByMonth.hasOwnProperty(alertMonth)) {
        alertByMonth[alertMonth] += 1;
      }
    });

    return last4Months.map((month) => ({
      month,
      count: alertByMonth[month],
    }));
  };

  // Função para processar dados do mês atual
  const processAlertDataCurrentMonth = (data) => {
    const today = new Date();
    const endOfCurrentMonth = endOfMonth(today);
    const daysInMonth = getDate(endOfCurrentMonth);
    const alertByDay = {};

    for (let day = 1; day <= daysInMonth; day++) {
      alertByDay[day] = 0;
    }

    data.forEach((alert) => {
      const alertDate = parseISO(alert.alertaDataGeracao);
      if (!isValid(alertDate)) {
        console.warn(
          `Alerta ID ${alert.alertaId} possui data inválida: ${alert.alertaDataGeracao}`
        );
        return; // Ignora este alerta
      }
      if (isSameMonth(alertDate, today)) {
        const day = getDate(alertDate);
        alertByDay[day] += 1;
      }
    });

    const chartData = [];
    for (let day = 1; day <= daysInMonth; day++) {
      chartData.push({
        day: day.toString(),
        count: alertByDay[day],
      });
    }

    return chartData;
  };

  // Função para processar dados de Ordens de Serviço por Situação
  const processOSBySituation = (osData) => {
    const situationCount = {};

    osData.forEach((os) => {
      const situation = osSituationMap[os.osSituacaoId] || `Situação ${os.osSituacaoId}`;
      situationCount[situation] = (situationCount[situation] || 0) + 1;
    });

    return transformToChartData(situationCount);
  };

  // Função para processar Ordens de Serviço abertas nos últimos 4 meses
  const processOSLast4Months = (osData) => {
    const today = new Date();
    const last4Months = [];

    for (let i = 3; i >= 0; i--) {
      const date = subMonths(today, i);
      const month = format(date, 'MMMM yyyy');
      last4Months.push(month);
    }

    const osByMonth = {};
    last4Months.forEach((month) => {
      osByMonth[month] = 0;
    });

    osData.forEach((os) => {
      const osDate = parseISO(os.osDataAbertura);
      if (!isValid(osDate)) {
        console.warn(
          `OS ID ${os.osId} possui data de abertura inválida: ${os.osDataAbertura}`
        );
        return; // Ignora esta OS
      }
      const osMonth = format(osDate, 'MMMM yyyy');
      if (osByMonth.hasOwnProperty(osMonth)) {
        osByMonth[osMonth] += 1;
      }
    });

    return last4Months.map((month) => ({
      month,
      count: osByMonth[month],
    }));
  };

  // Função para processar Ordens de Serviço encerradas no mês atual
  const processOSCurrentMonth = (osData) => {
    const today = new Date();
    const endOfCurrentMonth = endOfMonth(today);
    const daysInMonth = getDate(endOfCurrentMonth);
    const osByDay = {};

    for (let day = 1; day <= daysInMonth; day++) {
      osByDay[day] = 0;
    }

    osData.forEach((os) => {
      if (!os.osDataEncerramento) return; // Ignora se não há data de encerramento
      const osDate = parseISO(os.osDataEncerramento);
      if (!isValid(osDate)) {
        console.warn(
          `OS ID ${os.osId} possui data de encerramento inválida: ${os.osDataEncerramento}`
        );
        return; // Ignora esta OS
      }
      if (isSameMonth(osDate, today)) {
        const day = getDate(osDate);
        osByDay[day] += 1;
      }
    });

    const osCurrentMonth = Object.keys(osByDay).map((day) => ({
      day,
      count: osByDay[day],
    }));

    return osCurrentMonth;
  };

  // Função para processar Status de Postes via Fotocelula
  const processPosteStatusFotocelula = (postefotocelulaData) => {
    // Filtrar apenas registros ativos
    const ativos = postefotocelulaData.filter((item) => item.ativo === 'S');

    // Verificar os valores únicos de 'ligado'
    const ligadoValues = [...new Set(ativos.map(item => item.ligado))];
    console.log('Valores únicos de "ligado":', ligadoValues);

    // Contar postes acesos e apagados
    const acesos = ativos.filter((item) => item.ligado === 'S').length;
    const apagados = ativos.filter((item) => item.ligado === 'N' || item.ligado === 'D').length;

    return [
      { name: 'Postes Acesos', value: acesos },
      { name: 'Postes Apagados', value: apagados },
    ];
  };

  // Função para salvar as configurações no localStorage
  const saveSettingsToLocalStorage = (settings) => {
    localStorage.setItem('dashboardSettings', JSON.stringify(settings));
  };

  // Função para carregar as configurações do localStorage
  const loadSettingsFromLocalStorage = () => {
    const settings = localStorage.getItem('dashboardSettings');
    if (settings) {
      const parsedSettings = JSON.parse(settings);
      setVisibleCharts((prev) => ({
        ...prev,
        ...parsedSettings.visibleCharts,
      }));
      if (parsedSettings.chartOrder) {
        setChartOrder(parsedSettings.chartOrder);
      }
    }
  };

  // Função para buscar os dados do Dashboard
  const fetchDashboardData = async () => {
    try {
      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      if (!cidadeId) {
        throw new Error('ID da cidade não encontrado');
      }

      // Imprime o token e o cidadeId no console para debug
      console.log('Token JWT usado no Dashboard:', token);
      console.log('Cidade ID usado no Dashboard:', cidadeId);

      // Realiza as requisições aos endpoints usando BASE_URL
      const [
        alertaResponse,
        posteResponse,
        osResponse,
        fotocelulaResponse,
        postefotocelulaResponse,
      ] = await Promise.all([
        fetch(`${BASE_URL}/alerta`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            cidadeId: cidadeId, // Envia o cidadeId no header
          },
        }),
        fetch(`${BASE_URL}/poste`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            cidadeId: cidadeId, // Envia o cidadeId no header
          },
        }),
        fetch(`${BASE_URL}/os`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            cidadeId: cidadeId, // Envia o cidadeId no header
          },
        }),
        fetch(`${BASE_URL}/fotocelula`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            cidadeId: cidadeId, // Envia o cidadeId no header
          },
        }),
        fetch(`${BASE_URL}/postefotocelula`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            cidadeId: cidadeId, // Envia o cidadeId no header
          },
        }),
      ]);

      // Verifica se todas as requisições foram bem-sucedidas
      if (
        !alertaResponse.ok ||
        !posteResponse.ok ||
        !osResponse.ok ||
        !fotocelulaResponse.ok ||
        !postefotocelulaResponse.ok
      ) {
        if (
          alertaResponse.status === 401 ||
          posteResponse.status === 401 ||
          osResponse.status === 401 ||
          fotocelulaResponse.status === 401 ||
          postefotocelulaResponse.status === 401
        ) {
          toast.error('Sessão expirada. Faça login novamente.');
          logout(); // Usa a função de logout do contexto
          return;
        }
        throw new Error('Erro ao buscar os dados da API');
      }

      // Converte as respostas para JSON
      const [
        alertaData,
        posteData,
        osDataRaw,
        fotocelulaData,
        postefotocelulaData,
      ] = await Promise.all([
        alertaResponse.json(),
        posteResponse.json(),
        osResponse.json(),
        fotocelulaResponse.json(),
        postefotocelulaResponse.json(),
      ]);

      // Debugging: Verifique a estrutura dos dados
      console.log('Dados dos Alertas:', alertaData);
      console.log('Dados dos Postes:', posteData);
      console.log('Dados das Ordens de Serviço:', osDataRaw);
      console.log('Dados das Fotocélulas:', fotocelulaData);
      console.log('Dados das Associações Poste-Fotocélula:', postefotocelulaData);

      // Processa os dados de alertas
      const processedAlertaData = processAlertData(alertaData, cidadeId);
      const processedPosteGeo = processPosteGeoData(posteData);
      const processedLast4Months = processAlertDataLast4Months(alertaData);
      const processedCurrentMonth = processAlertDataCurrentMonth(alertaData);

      // Processa os dados de Ordens de Serviço
      const processedOSBySituation = processOSBySituation(osDataRaw);
      const processedOSLast4Months = processOSLast4Months(osDataRaw);
      const processedOSCurrentMonth = processOSCurrentMonth(osDataRaw);

      // Processa Status de Postes via Fotocelula
      const processedPosteStatusFotocelula = processPosteStatusFotocelula(
        postefotocelulaData
      );

      // Atualiza os estados com os dados processados
      setAlertData({
        ...processedAlertaData,
        postesGeo: processedPosteGeo,
        last4Months: processedLast4Months,
        currentMonth: processedCurrentMonth,
      });

      setOSData({
        bySituation: processedOSBySituation,
        last4Months: processedOSLast4Months,
        currentMonth: processedOSCurrentMonth,
      });

      setPosteStatusFotocelulaData(processedPosteStatusFotocelula);
    } catch (err) {
      console.error(err);
      setError(err.message || 'Erro desconhecido');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadSettingsFromLocalStorage();
  }, []);

  useEffect(() => {
    fetchDashboardData();

    const dataInterval = setInterval(() => {
      fetchDashboardData();
      setTimeLeft(60);
    }, 60000); // Atualiza os dados a cada 60 segundos

    const countdownTimer = setInterval(() => {
      setTimeLeft((prevTimeLeft) =>
        prevTimeLeft > 0 ? prevTimeLeft - 1 : 0
      );
    }, 1000); // Decrementa o tempo a cada segundo

    return () => {
      clearInterval(dataInterval);
      clearInterval(countdownTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, cidadeId]);

  // Funções para gerenciar as configurações
  const handleChartVisibilityChange = (chartKey) => {
    setVisibleCharts((prev) => {
      const updated = { ...prev, [chartKey]: !prev[chartKey] };
      saveSettingsToLocalStorage({
        visibleCharts: updated,
        chartOrder,
      });
      return updated;
    });
  };

  const handleChartOrderChange = (newOrder) => {
    setChartOrder(newOrder);
    saveSettingsToLocalStorage({
      visibleCharts,
      chartOrder: newOrder,
    });
  };

  const handleCompactModeChange = () => {
    setVisibleCharts((prev) => {
      const updated = { ...prev, isCompactMode: !prev.isCompactMode };
      saveSettingsToLocalStorage({
        visibleCharts: updated,
        chartOrder,
      });
      return updated;
    });
  };

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return (
      <PageLayout title="Dashboard">
        <div className={styles.errorContainer}>
          <p className={styles.errorMessage}>Erro: {error}</p>
          <button
            className={styles.retryButton}
            onClick={fetchDashboardData}
          >
            Tentar Novamente
          </button>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout title="Dashboard">
      {/* Ícone de Configuração */}
      <div className={styles.configIconContainer}>
        <FaCog
          className={styles.configIcon}
          onClick={() => setIsConfigModalOpen(true)}
          title="Configurações"
        />
      </div>

      {/* Modal de Configuração */}
      {isConfigModalOpen && (
        <ConfigModal
          visibleCharts={visibleCharts}
          chartOrder={chartOrder}
          onChartVisibilityChange={handleChartVisibilityChange}
          onChartOrderChange={handleChartOrderChange}
          onCompactModeChange={handleCompactModeChange}
          onClose={() => setIsConfigModalOpen(false)}
          getChartDisplayName={getChartDisplayName}
          cidadeId={cidadeId}
        />
      )}

      <div
        className={`${styles.dashboardContent} ${
          visibleCharts.isCompactMode ? styles.compact : ''
        }`}
      >
        {chartOrder.map((chartKey) => {
          // Condicionalmente renderizar com base no cidadeId e visibilidade
          if (parseInt(cidadeId, 10) === 3315) {
            if (
              ![
                'internos',
                'externos',
                'status',
                'postesGeo',
                'last4Months',
                'currentMonth',
                'osBySituation',
                'osLast4Months',
                'osCurrentMonth',
                'posteStatusFotocelula',
              ].includes(chartKey)
            ) {
              return null;
            }
          } else {
            if (
              ![
                'todos',
                'status',
                'postesGeo',
                'last4Months',
                'currentMonth',
                'osBySituation',
                'osLast4Months',
                'osCurrentMonth',
                'posteStatusFotocelula',
              ].includes(chartKey)
            ) {
              return null;
            }
          }

          if (!visibleCharts[chartKey]) {
            return null;
          }

          switch (chartKey) {
            case 'internos':
              return (
                <ChartCard
                  key={chartKey}
                  title="Alertas Internos"
                  type="pie"
                  data={alertData.internos}
                  dataKey="value"
                  colors={COLORS}
                  compactMode={visibleCharts.isCompactMode}
                />
              );
            case 'externos':
              return (
                <ChartCard
                  key={chartKey}
                  title="Alertas Externos"
                  type="pie"
                  data={alertData.externos}
                  dataKey="value"
                  colors={COLORS}
                  compactMode={visibleCharts.isCompactMode}
                />
              );
            case 'todos':
              return (
                <ChartCard
                  key={chartKey}
                  title="Todos os Alertas"
                  type="pie"
                  data={alertData.todos}
                  dataKey="value"
                  colors={COLORS}
                  compactMode={visibleCharts.isCompactMode}
                />
              );
            case 'status':
              return (
                <ChartCard
                  key={chartKey}
                  title="Alertas por Status"
                  type="pie"
                  data={alertData.status}
                  dataKey="value"
                  colors={STATUS_COLORS}
                  compactMode={visibleCharts.isCompactMode}
                />
              );
            case 'postesGeo':
              return (
                <ChartCard
                  key={chartKey}
                  title="Postes Geolocalizados"
                  type="pie"
                  data={alertData.postesGeo}
                  dataKey="value"
                  colors={GEOLABEL_COLOR}
                  compactMode={visibleCharts.isCompactMode}
                />
              );
            case 'last4Months':
              return (
                <ChartCard
                  key={chartKey}
                  title="Total de Alertas nos Últimos 4 Meses"
                  type="bar"
                  data={alertData.last4Months}
                  dataKey="count"
                  colors={[BAR_COLOR]}
                  additionalProps={{
                    margin: { top: 20, right: 30, left: 20, bottom: 5 },
                  }}
                  compactMode={visibleCharts.isCompactMode}
                />
              );
            case 'currentMonth':
              return (
                <ChartCard
                  key={chartKey}
                  title="Alertas Criados no Mês Atual"
                  type="line"
                  data={alertData.currentMonth}
                  dataKey="count"
                  colors={[LINE_COLOR]}
                  additionalProps={{
                    margin: { top: 20, right: 30, left: 20, bottom: 5 },
                  }}
                  compactMode={visibleCharts.isCompactMode}
                />
              );
            case 'osBySituation':
              return (
                <ChartCard
                  key={chartKey}
                  title="Ordens de Serviço por Situação"
                  type="pie"
                  data={osData.bySituation}
                  dataKey="value"
                  colors={STATUS_COLORS}
                  compactMode={visibleCharts.isCompactMode}
                />
              );
            case 'osLast4Months':
              return (
                <ChartCard
                  key={chartKey}
                  title="Ordens de Serviço Abertas nos Últimos 4 Meses"
                  type="bar"
                  data={osData.last4Months}
                  dataKey="count"
                  colors={[BAR_COLOR]}
                  additionalProps={{
                    margin: { top: 20, right: 30, left: 20, bottom: 5 },
                  }}
                  compactMode={visibleCharts.isCompactMode}
                />
              );
            case 'osCurrentMonth':
              return (
                <ChartCard
                  key={chartKey}
                  title="Ordens de Serviço Encerradas no Mês Atual"
                  type="line"
                  data={osData.currentMonth}
                  dataKey="count"
                  colors={[LINE_COLOR]}
                  additionalProps={{
                    margin: { top: 20, right: 30, left: 20, bottom: 5 },
                  }}
                  compactMode={visibleCharts.isCompactMode}
                />
              );
            case 'posteStatusFotocelula':
              return (
                <ChartCard
                  key={chartKey}
                  title="Status de Postes (Fotocélula)"
                  type="pie"
                  data={posteStatusFotocelulaData}
                  dataKey="value"
                  colors={POSTE_COLORS}
                  compactMode={visibleCharts.isCompactMode}
                />
              );
            default:
              return null;
          }
        })}
      </div>

      {/* Barra de Carregamento */}
      <div className={styles.loadingBarContainer}>
        <div
          className={styles.loadingBar}
          style={{ width: `${((60 - timeLeft) / 60) * 100}%` }}
        ></div>
        <div className={styles.loadingText}>
          Atualização em {Math.floor(timeLeft / 60)}:
          {(timeLeft % 60).toString().padStart(2, '0')}
        </div>
      </div>
    </PageLayout>
  );
};

export default Dashboard;
