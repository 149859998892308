// src/utils/markers.js

import React from 'react';
import { Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';

// Ícones PNG para estados de dispositivos
import apagadoIcon from '../assets/blue-icon.png';
import acesaIcon from '../assets/green-icon.png';
import indefinidoIcon from '../assets/indefinido.png';
import sobretensaoIcon from '../assets/sobretensao-icon.png';
import subtensaoIcon from '../assets/subtensao-icon.png';
import offlineIcon from '../assets/offline-icon.png';
import MasterIcon from '../assets/Master.png';
import SlaveIcon from '../assets/Slave.png';
import MQEIcon from '../assets/MQE.png';
import SDLIcon from '../assets/SDL.png'; // SFL
import NFIcon from '../assets/NF.png'; // Relé Comum
import NFProtecaoIcon from '../assets/NF Proteção.png'; // Relé de Proteção
import IndefinidoIcon from '../assets/indefinido.png'; // Ícone para valores indefinidos

// Ícones adicionais
import acesoDiaIcon from '../assets/aceso-dia-icon.png';
import apagadoNoiteIcon from '../assets/apagado-noite-icon.png';
import alertaIcon from '../assets/red-icon.png'; // Ícone vermelho para alertas
import greyIcon from '../assets/grey-icon.png';   // **Novo ícone cinza para substituir CgPinAlt**

/**
 * Retorna o caminho do ícone com base no tipo de marcador.
 * Utilizado para os modos 'device' e 'post'.
 * @param {string} markerType - Tipo de marcador
 * @returns {string} Caminho do ícone
 */
const getIconByMarkerType = (markerType) => {
  switch (markerType) {
    case 'apagada':
      return apagadoIcon;
    case 'acesa':
      return acesaIcon;
    case 'aceso-dia':
      return acesoDiaIcon;
    case 'apagado-noite':
      return apagadoNoiteIcon;
    case 'alerta':
      return alertaIcon;
    case 'overVoltage':
      return sobretensaoIcon;
    case 'underVoltage':
      return subtensaoIcon;
    case 'offline':
      return offlineIcon;
    case 'indefinida':
      return indefinidoIcon;
    default:
      return indefinidoIcon;
  }
};

/**
 * Retorna a URL do ícone com base no fotocelulaTipo
 * 1-Relé comum -> NFIcon
 * 2-Relé de proteção -> NFProtecaoIcon
 * 3-SFL (Sistema de Falha de Luminária) -> SDLIcon
 * 4-MQE (Medição de Qualidade de Energia) -> MQEIcon
 * 5-Telegestor Slave -> SlaveIcon
 * 6-Telegestor Master -> MasterIcon
 * Caso não seja nenhum desses, usamos IndefinidoIcon.
 * @param {string} fotocelulaTipo - Tipo de fotocélula
 * @returns {string} Caminho do ícone da fotocélula
 */
const getFotocelulaIconUrl = (fotocelulaTipo) => {
  const tipoStr = String(fotocelulaTipo);
  switch (tipoStr) {
    case '1':
      return NFIcon;
    case '2':
      return NFProtecaoIcon;
    case '3':
      return SDLIcon;
    case '4':
      return MQEIcon;
    case '5':
      return MasterIcon;
    case '6':
      return SlaveIcon;
    default:
      return IndefinidoIcon;
  }
};

/**
 * Determina o tipo de marcador para um dispositivo com base em seu status.
 * @param {Object} device - Objeto do dispositivo.
 * @returns {string} Tipo de marcador.
 */
export const getDeviceMarkerType = (device) => {
  if (device.offline) return 'offline';

  const currentHour = new Date().getHours();
  const isDaytime = currentHour >= 6 && currentHour < 18;

  if (isDaytime && device.deviceLightOn === 'S') {
    return 'aceso-dia';
  }

  if (!isDaytime && device.deviceLightOn === 'N') {
    return 'apagado-noite';
  }

  // Verifica tensão
  switch (device.voltageStatus) {
    case 'overVoltage':
      return 'overVoltage';
    case 'underVoltage':
      return 'underVoltage';
    default:
      break;
  }

  // Verifica acesa/apagada
  switch (device.deviceLightOn) {
    case 'S':
      return 'acesa';
    case 'N':
      return 'apagada';
    default:
      return 'indefinida';
  }
};

/**
 * Determina o tipo de marcador para um poste com base no status de seus dispositivos e alertas ativos.
 * Apenas alertas com alertaSituacao igual a '1' (aguardando) ou '2' (em atendimento) são considerados ativos.
 * @param {Object} poste - Objeto do poste.
 * @returns {string} Tipo de marcador.
 */
export const getPosteMarkerType = (poste) => {
  // Filtrar alertas ativos
  const alertasAtivos = poste.alertas?.filter(
    (alerta) => ['1', '2'].includes(String(alerta.alertaSituacao))
  );

  if (alertasAtivos?.length > 0) {
    return 'alerta';
  }

  if (!poste.devices || poste.devices.length === 0) {
    return 'indefinida';
  }

  // Prioridade de status do dispositivo
  const priority = [
    'offline',
    'overVoltage',
    'underVoltage',
    'acesa',
    'apagada',
    'indefinida',
  ];

  for (let type of priority) {
    if (poste.devices.some((device) => getDeviceMarkerType(device) === type)) {
      return type;
    }
  }

  return 'indefinida';
};

/**
 * Componente de Marcador para Dispositivos.
 * @param {Object} props - Props do componente.
 * @param {Object} props.device - Dados do dispositivo.
 * @param {Function} props.onClick - Função a ser chamada ao clicar no marcador.
 * @param {boolean} props.animate - Se verdadeiro, anima o marcador (BOUNCE).
 */
export const DeviceMarker = ({ device, onClick, animate }) => {
  if (!device || device.deviceLat == null || device.deviceLng == null) {
    console.warn(`Dispositivo inválido ou sem coordenadas:`, device);
    return null;
  }

  const markerType = getDeviceMarkerType(device);
  const iconURL = getIconByMarkerType(markerType);

  return (
    <Marker
      position={{ lat: device.deviceLat, lng: device.deviceLng }}
      title={`Dispositivo: ${device.deviceName}`}
      icon={{
        url: iconURL,
        scaledSize: new window.google.maps.Size(16, 16),
      }}
      animation={animate ? window.google.maps.Animation.BOUNCE : null}
      onClick={() => onClick(device)}
    />
  );
};

DeviceMarker.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.number.isRequired,
    deviceName: PropTypes.string.isRequired,
    deviceLat: PropTypes.number,
    deviceLng: PropTypes.number,
    offline: PropTypes.bool.isRequired,
    voltageStatus: PropTypes.oneOf(['overVoltage', 'underVoltage', 'normal'])
      .isRequired,
    deviceLightOn: PropTypes.oneOf(['S', 'N', 'I']).isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  animate: PropTypes.bool,
};

DeviceMarker.defaultProps = {
  animate: false,
};

/**
 * Componente de Marcador para Postes.
 * @param {Object} props - Props do componente.
 * @param {Object} props.poste - Dados do poste.
 * @param {Function} props.onClick - Função a ser chamada ao clicar no marcador.
 * @param {string} props.displayMode - Modo de exibição do mapa ('post' ou 'fotocelula').
 * @param {boolean} props.animate - Se verdadeiro, anima o marcador (BOUNCE).
 * @param {boolean} props.isSelected - Se verdadeiro, o marcador está selecionado.
 * @param {boolean} props.isBeingMoved - Se verdadeiro, o marcador está sendo movido.
 */
export const PosteMarker = ({
  poste,
  onClick,
  displayMode,
  animate,
  isSelected,
  isBeingMoved,
}) => {
  if (!poste || poste.lat == null || poste.lng == null) {
    console.warn(`Poste inválido ou sem coordenadas:`, poste);
    return null;
  }

  let iconConfig;

  if (isBeingMoved) {
    // Antigamente usávamos CgPinAlt vermelho.
    // Agora substituímos tudo por grey-icon.png:
    iconConfig = {
      url: greyIcon,
      scaledSize: new window.google.maps.Size(24, 24),
    };
  } else if (isSelected) {
    // Antigamente CgPinAlt azul.
    iconConfig = {
      url: greyIcon,
      scaledSize: new window.google.maps.Size(24, 24),
    };
  } else if (displayMode === 'fotocelula') {
    // Modo fotocélula, usamos ícones específicos:
    const iconUrl = getFotocelulaIconUrl(poste.fotocelulaTipo);
    iconConfig = {
      url: iconUrl,
      scaledSize: new window.google.maps.Size(16, 16),
    };
  } else {
    // Caso comum (modo 'post')
    const markerType = getPosteMarkerType(poste);

    if (markerType === 'indefinida') {
      // Antigamente CgPinAlt preto.
      iconConfig = {
        url: greyIcon, // Substituição do CgPinAlt
        scaledSize: new window.google.maps.Size(16, 16),
      };
    } else {
      // Ícones PNG baseados no tipo do poste
      const iconURL = getIconByMarkerType(markerType);
      iconConfig = {
        url: iconURL,
        scaledSize: new window.google.maps.Size(16, 16),
      };
    }
  }

  return (
    <Marker
      position={{ lat: poste.lat, lng: poste.lng }}
      title={`Poste: ${poste.codigoPoste}`}
      icon={iconConfig}
      animation={animate ? window.google.maps.Animation.BOUNCE : null}
      onClick={() => onClick(poste)}
      draggable={false}
      label={
        isSelected
          ? {
              text: poste.codigoPoste,
              color: 'black',
              fontWeight: 'bold',
              fontSize: '14px',
            }
          : undefined
      }
    />
  );
};

PosteMarker.propTypes = {
  poste: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    codigoPoste: PropTypes.string.isRequired,
    lat: PropTypes.number,
    lng: PropTypes.number,
    devices: PropTypes.arrayOf(PropTypes.object),
    fotocelulaTipo: PropTypes.string,
    grupoIds: PropTypes.arrayOf(PropTypes.string), // Para grupos
    alertas: PropTypes.arrayOf(PropTypes.object),  // Para alertas
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  displayMode: PropTypes.string,
  animate: PropTypes.bool,
  isSelected: PropTypes.bool,
  isBeingMoved: PropTypes.bool,
};

PosteMarker.defaultProps = {
  animate: false,
  displayMode: 'default',
  isSelected: false,
  isBeingMoved: false,
};
