// src/pages/Postes/PosteComponentsModal.jsx

import React, { useState, useEffect, useCallback, useContext } from 'react';
import { FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import ConfirmDeleteLuminariaModal from './ConfirmDeleteLuminariaModal';
import ConfirmDeleteComponentModal from './ConfirmDeleteComponentModal';
import styles from './PosteModal.module.css'; // Usando o mesmo CSS que PosteModal
import Luminarias from './SubComponents/Luminarias';
import Componentes from './SubComponents/Componentes';
import EUIsDevices from './SubComponents/EUIsDevices';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import usePosteDataWithDevices from '../../hooks/usePosteDataWithDevices';

// Importar os hooks customizados
import useGrupos from '../../hooks/useGrupos';
import useGruposComponentes from '../../hooks/useGruposComponentes';
import useComponentes from '../../hooks/useComponentes';
import useAvailableDevices from '../../hooks/useAvailableDevices'; // Importar o hook atualizado

// Importar AuthContext para gerenciamento de autenticação
import { AuthContext } from '../../context/AuthContext';

// Importar o toast do React Toastify
import { toast } from 'react-toastify';

// Importar o componente LoadingOverlay
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay'; // Certifique-se de que o caminho está correto

Modal.setAppElement('#root');

const PosteComponentsModal = ({ isOpen, onRequestClose, initialData }) => {
  // Acessar o contexto de autenticação
  const { token, usuarioId } = useContext(AuthContext);

  // Usar o hook para obter dispositivos disponíveis
  const {
    availableDevices,
    isLoading: isAvailableDevicesLoading,
    error: availableDevicesError,
    refetch: refetchAvailableDevices,
  } = useAvailableDevices();

  // Adicionar a definição do refetchPostes usando o hook usePosteDataWithDevices
  const { refetch: refetchPostes } = usePosteDataWithDevices(token);

  // Estado local para armazenar os dados do poste com valores padrão
  const [posteData, setPosteData] = useState({
    ...initialData,
    luminarias: initialData?.luminarias || [],
    componentes: initialData?.componentes || [],
    euis: initialData?.euis || [],
  });

  // useEffect para atualizar posteData quando initialData mudar
  useEffect(() => {
    setPosteData({
      ...initialData,
      luminarias: initialData?.luminarias || [],
      componentes: initialData?.componentes || [],
      euis: initialData?.euis || [],
    });
  }, [initialData]);

  // Estados para luminárias
  const [savedLuminarias, setSavedLuminarias] = useState([]);
  const [unsavedLuminarias, setUnsavedLuminarias] = useState([]);

  // Estados para dispositivos EUI
  const [savedEUIs, setSavedEUIs] = useState([]);
  const [unsavedEUIs, setUnsavedEUIs] = useState([]);

  // Estados para componentes
  const [savedComponentes, setSavedComponentes] = useState([]);
  const [unsavedComponentes, setUnsavedComponentes] = useState([]);

  // Estados adicionais para confirmação de remoção
  const [isConfirmDeleteLuminariaOpen, setIsConfirmDeleteLuminariaOpen] = useState(false);
  const [isConfirmDeleteComponentOpen, setIsConfirmDeleteComponentOpen] = useState(false);
  const [luminariaToDelete, setLuminariaToDelete] = useState(null);
  const [componentToDelete, setComponentToDelete] = useState(null);

  // Estado para controlar a aba ativa
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // Estados para animação dos botões de salvar
  const [animateSaveButtons, setAnimateSaveButtons] = useState({
    euis: {},
    luminarias: {},
    componentes: {},
  });

  // Estado para controle do carregamento geral
  const [isLoading, setIsLoading] = useState(false); // Novo estado para LoadingOverlay

  // Usar os hooks para obter grupos de luminárias e componentes
  const {
    grupos: gruposLuminarias,
    isGruposLoading,
    gruposError,
    refetch: refetchGruposLuminarias,
  } = useGrupos(isOpen);

  const {
    gruposComponentes,
    isGruposComponentesLoading,
    gruposComponentesError,
    refetch: refetchGruposComponentes,
  } = useGruposComponentes(isOpen);

  // Usar o hook para obter a lista de componentes disponíveis
  const {
    componentes,
    isComponentesLoading,
    componentesError,
    refetch: refetchComponentes,
  } = useComponentes(isOpen);

  // Função para verificar se existem itens não salvos
  const hasUnsavedChanges = () => (
    unsavedLuminarias.length > 0 ||
    unsavedEUIs.length > 0 ||
    unsavedComponentes.length > 0
  );

  // Função para buscar componentes e luminárias salvos
  const fetchSavedComponentsAndLuminarias = useCallback(async () => {
    if (!isOpen || !posteData) return;

    setIsLoading(true); // Inicia o carregamento

    try {
      if (!token) throw new Error('Token de autenticação não encontrado');

      // Buscar todos os itens do poste
      const response = await fetch('https://api.ads10.com.br/api/posteestrutura', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        // Limpar tokens e redirecionar para login
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar dados do poste: ${response.statusText}`);
      }

      const data = await response.json();

      // Filtrar pelo poste específico
      const posteId = String(posteData.idPoste || posteData.id); // Garantir que seja string
      const dadosDoPoste = data.filter(item => String(item.posteestruturaPoste) === posteId);

      console.log('Dados filtrados para o poste:', dadosDoPoste);

      // Filtrar luminárias e componentes com base nos grupos
      const luminariasFiltradas = dadosDoPoste
        .filter(item => {
          const grupo = gruposLuminarias.find(
            grupo => String(grupo.pgId) === String(item.posteestruturaItemGrupo)
          );
          return grupo && grupo.pgAtualizaCapaPoste === 's';
        })
        .map(item => ({
          id: item.posteestruturaId,
          tipoInstalacao: item.posteestruturaTipo === 'i' ? 'Instalação' : 'Alteração',
          grupoId: item.posteestruturaItemGrupo,
          grupoNome:
            gruposLuminarias.find(
              grupo => String(grupo.pgId) === String(item.posteestruturaItemGrupo)
            )?.pgNome || 'Desconhecido',
          componenteId: item.posteestruturaItem,
          componenteDescricao:
            componentes.find(
              comp => String(comp.produtoId) === String(item.posteestruturaItem)
            )?.produtoDescricao || 'Desconhecido',
          quantidade: parseFloat(item.posteestruturaQuantidade),
          data: item.posteestruturaData,
          hora: item.posteestruturaHora,
        }));

      const componentesFiltrados = dadosDoPoste
        .filter(item => {
          const grupo = gruposComponentes.find(
            grupo => String(grupo.pgId) === String(item.posteestruturaItemGrupo)
          );
          return grupo && grupo.pgAtualizaCapaPoste === 'n';
        })
        .map(item => ({
          posteestruturaId: item.posteestruturaId, // Necessário para remoção
          grupoId: item.posteestruturaItemGrupo,
          grupoNome:
            gruposComponentes.find(
              grupo => String(grupo.pgId) === String(item.posteestruturaItemGrupo)
            )?.pgNome || 'Desconhecido',
          componenteId: item.posteestruturaItem,
          componenteDescricao:
            componentes.find(
              comp => String(comp.produtoId) === String(item.posteestruturaItem)
            )?.produtoDescricao || 'Desconhecido',
          tipoInstalacao: item.posteestruturaTipo === 'i' ? 'Instalação' : 'Alteração',
          quantidade: parseFloat(item.posteestruturaQuantidade),
          data: item.posteestruturaData,
          hora: item.posteestruturaHora,
        }));

      console.log('Luminárias filtradas:', luminariasFiltradas);
      console.log('Componentes filtrados:', componentesFiltrados);

      setSavedLuminarias(luminariasFiltradas);
      setSavedComponentes(componentesFiltrados);
      setSavedEUIs(posteData.euis || []);
      setUnsavedLuminarias([]);
      setUnsavedComponentes([]);
      setUnsavedEUIs([]);
    } catch (error) {
      console.error(error);
      toast.error(error.message || 'Erro ao buscar dados do poste.');
    } finally {
      setIsLoading(false); // Termina o carregamento
    }
  }, [
    isOpen,
    posteData,
    gruposLuminarias,
    gruposComponentes,
    componentes,
    token,
  ]);

  // Chamar a função para buscar componentes e luminárias salvos quando o modal abrir ou posteData mudar
  useEffect(() => {
    if (isOpen && posteData) {
      fetchSavedComponentsAndLuminarias();
    }
  }, [isOpen, posteData, fetchSavedComponentsAndLuminarias]);

  // Função para disparar animações nos botões de salvar
  const triggerSaveAnimations = () => {
    const euisToAnimate = unsavedEUIs.map((_, index) => index);
    const luminariasToAnimate = unsavedLuminarias.map((_, index) => index);
    const componentesToAnimate = unsavedComponentes.map((_, index) => index);

    setAnimateSaveButtons({
      euis: euisToAnimate.reduce((acc, idx) => ({ ...acc, [idx]: true }), {}),
      luminarias: luminariasToAnimate.reduce((acc, idx) => ({ ...acc, [idx]: true }), {}),
      componentes: componentesToAnimate.reduce((acc, idx) => ({ ...acc, [idx]: true }), {}),
    });

    // Limpar as animações após a duração
    setTimeout(() => {
      setAnimateSaveButtons({
        euis: {},
        luminarias: {},
        componentes: {},
      });
    }, 500); // Duração da animação
  };

  // Função para lidar com a mudança de aba
  const handleTabSelect = (index) => {
    const hasEUIChanges = unsavedEUIs.length > 0;
    const hasLuminariaChanges = unsavedLuminarias.length > 0;
    const hasComponenteChanges = unsavedComponentes.length > 0;

    if (hasEUIChanges || hasLuminariaChanges || hasComponenteChanges) {
      // Disparar animações nos botões de salvar
      triggerSaveAnimations();

      // Mostrar confirmação depois da animação ser disparada
      setTimeout(() => {
        const confirmSwitch = window.confirm(
          'Você tem mudanças não salvas. Deseja continuar e descartar as mudanças?'
        );
        if (!confirmSwitch) {
          return;
        } else {
          // Limpar luminárias, EUIs e Componentes não salvos
          setUnsavedLuminarias([]);
          setUnsavedEUIs([]);
          setUnsavedComponentes([]);
          setActiveTabIndex(index);
        }
      }, 500); // Tempo para a animação ser percebida antes de exibir o alerta
    } else {
      setActiveTabIndex(index);
    }
  };

  // Função para fechar o modal com verificação de alterações não salvas
  const handleCloseModal = () => {
    const hasEUIChanges = unsavedEUIs.length > 0;
    const hasLuminariaChanges = unsavedLuminarias.length > 0;
    const hasComponenteChanges = unsavedComponentes.length > 0;

    if (hasEUIChanges || hasLuminariaChanges || hasComponenteChanges) {
      // Disparar animações nos botões de salvar
      triggerSaveAnimations();

      // Mostrar confirmação depois da animação ser disparada
      setTimeout(() => {
        const confirmClose = window.confirm(
          'Você tem mudanças não salvas. Deseja fechar sem salvar?'
        );
        if (!confirmClose) {
          return;
        } else {
          // Limpar luminárias, EUIs e Componentes não salvos
          setUnsavedLuminarias([]);
          setUnsavedEUIs([]);
          setUnsavedComponentes([]);
          onRequestClose();
        }
      }, 500); // Tempo para a animação ser percebida antes de exibir o alerta
    } else {
      onRequestClose();
    }
  };

  // Funções para adicionar, editar e remover luminárias
  const handleAddLuminaria = () => {
    if (hasUnsavedChanges()) {
      toast.error('Por favor, salve ou descarte as mudanças antes de adicionar novas luminárias.');
      return;
    }

    const newLuminaria = {
      tipoInstalacao: '',
      grupoId: '',
      grupoNome: '',
      componenteId: '',
      componenteDescricao: '',
      quantidade: '1.00', // Valor padrão
      data: new Date().toISOString().split('T')[0], // "YYYY-MM-DD"
      hora: new Date().toTimeString().split(' ')[0], // "HH:MM:SS"
    };

    setUnsavedLuminarias((prev) => [...prev, newLuminaria]);
  };

  const handleLuminariaChange = (index, e, isSaved = false) => {
    const { name, value } = e.target;
    if (isSaved) {
      setSavedLuminarias((prev) => {
        const updated = [...prev];
        updated[index] = { ...updated[index], [name]: value };
        return updated;
      });
    } else {
      setUnsavedLuminarias((prev) => {
        const updated = [...prev];
        updated[index] = { ...updated[index], [name]: value };
        return updated;
      });
    }

    // **Log da mudança no campo**
    console.log(`Campo alterado na luminária ${isSaved ? 'salva' : 'não salva'}: ${name}, Novo valor: ${value}`);
  };

  const handleSaveLuminaria = async (index) => {
    const luminaria = unsavedLuminarias[index];

    // Validação básica
    if (
      !luminaria.tipoInstalacao ||
      !luminaria.grupoId ||
      !luminaria.componenteId ||
      !luminaria.quantidade
    ) {
      toast.error('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    setIsLoading(true); // Inicia o carregamento

    try {
      if (!token || !usuarioId) {
        toast.error('Usuário não autenticado. Redirecionando para a página de login.');
        window.location.href = '/login';
        return;
      }

      const payload = {
        posteestruturaId: null, // ou 0, conforme a API espera
        posteestruturaPoste: String(posteData.idPoste || posteData.id),
        posteestruturaItem: String(luminaria.componenteId),
        posteestruturaQuantidade: parseFloat(luminaria.quantidade).toFixed(2).toString(),
        posteestruturaData: luminaria.data, // "YYYY-MM-DD"
        posteestruturaHora: luminaria.hora, // "HH:MM:SS"
        posteestruturaTipo: luminaria.tipoInstalacao === 'Instalação' ? 'i' : 'a',
        posteestruturaUsuario: String(usuarioId),
        posteestruturaAppId: null, // ou uma string válida
        posteestruturaItemGrupo: String(luminaria.grupoId),
        estruturaTipo: luminaria.tipoInstalacao === 'Instalação' ? 'i' : 'a',
      };

      // Log para verificar o payload
      console.log('Payload enviado para a API:', payload);

      const response = await fetch('https://api.ads10.com.br/api/posteestrutura', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Erro ao salvar luminária:', errorData);
        throw new Error(`Erro ao salvar luminária: ${response.statusText}`);
      }

      const savedLuminaria = await response.json();

      // Atualizar os estados
      setSavedLuminarias((prev) => [...prev, savedLuminaria]);
      setUnsavedLuminarias((prev) => prev.filter((_, i) => i !== index));

      // Atualizar o posteData para refletir a nova luminária
      setPosteData((prev) => ({
        ...prev,
        luminarias: [...prev.luminarias, savedLuminaria],
      }));

      // Recarregar componentes e luminárias salvos para manter a consistência
      await fetchSavedComponentsAndLuminarias();

      // Atualizar a lista de postes
      await refetchPostes();

      toast.success('Luminária salva com sucesso!');
    } catch (err) {
      console.error(err);
      toast.error('Ocorreu um erro ao salvar a luminária.');
    } finally {
      setIsLoading(false); // Termina o carregamento
    }
  };

  const handleRemoveLuminaria = (luminaria, isUnsaved = false, index = null) => {
    console.log('Removendo luminária:', luminaria, 'IsUnsaved:', isUnsaved, 'Index:', index);
    if (isUnsaved) {
      // Remover luminária não salva diretamente da lista
      setUnsavedLuminarias((prev) => {
        console.log('Antes da remoção (Unsaved Luminarias):', prev);
        const updated = prev.filter((_, i) => i !== index);
        console.log('Depois da remoção (Unsaved Luminarias):', updated);
        return updated;
      });
      toast.info('Luminária removida da lista.');
    } else {
      // Abrir modal de confirmação para luminária salva
      setLuminariaToDelete(luminaria);
      setIsConfirmDeleteLuminariaOpen(true);
    }
  };

  const confirmRemoveLuminaria = async () => {
    if (!luminariaToDelete) return;

    setIsLoading(true); // Inicia o carregamento

    try {
      if (!token) {
        toast.error('Usuário não autenticado. Redirecionando para a página de login.');
        window.location.href = '/login';
        return;
      }

      // Remover luminária via API
      const response = await fetch(
        `https://api.ads10.com.br/api/posteestrutura/${luminariaToDelete.id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao remover luminária: ${response.statusText}`);
      }

      // Atualizar o estado
      setSavedLuminarias((prev) => prev.filter((lum) => lum.id !== luminariaToDelete.id));
      setIsConfirmDeleteLuminariaOpen(false);
      setLuminariaToDelete(null);

      // Atualizar o posteData removendo a luminária
      setPosteData((prev) => ({
        ...prev,
        luminarias: prev.luminarias.filter((lum) => lum.id !== luminariaToDelete.id),
      }));

      // Recarregar componentes e luminárias salvos para manter a consistência
      await fetchSavedComponentsAndLuminarias();

      // Atualizar a lista de postes
      await refetchPostes();

      toast.success('Luminária removida com sucesso!');
    } catch (error) {
      console.error('Erro ao remover luminária:', error);
      toast.error('Erro ao remover luminária. Por favor, tente novamente.');
    } finally {
      setIsLoading(false); // Termina o carregamento
    }
  };

  // Funções para dispositivos EUI
  const handleAddEUI = () => {
    if (hasUnsavedChanges()) {
      toast.error('Por favor, salve ou descarte as mudanças antes de adicionar novos dispositivos EUI.');
      return;
    }

    const newEUI = {
      deviceEUI: '',
      postefotocelulaId: null,
    };

    setUnsavedEUIs((prev) => [...prev, newEUI]);
  };

  const handleEUIChange = (index, e) => {
    const { value } = e.target;
    setUnsavedEUIs((prev) => {
      const updated = [...prev];
      updated[index].deviceEUI = value;
      return updated;
    });
  };

  const handleSaveEUI = async (index) => {
    const eui = unsavedEUIs[index].deviceEUI.trim();

    if (!eui) {
      toast.error('O Device EUI não pode estar vazio.');
      return;
    }

    setIsLoading(true);

    try {
      const posteId = posteData.idPoste || posteData.id;

      // Buscar todas as fotocélulas para encontrar o fotocelulaId correspondente
      const fotocelulaResponse = await fetch(`https://api.ads10.com.br/api/fotocelula`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!fotocelulaResponse.ok) {
        throw new Error('Erro ao buscar fotocélulas.');
      }

      const fotocelulas = await fotocelulaResponse.json();

      // Encontrar o fotocelulaId correspondente ao deviceEUI
      const fotocelula = fotocelulas.find((f) => f.deviceEUI === eui);

      if (!fotocelula) {
        toast.error('Fotocélula correspondente não encontrada.');
        setIsLoading(false);
        return;
      }

      const fotocelulaId = fotocelula.fotocelulaId;

      // Buscar todas as associações para decidir entre PUT e POST
      const associationsResponse = await fetch(`https://api.ads10.com.br/api/postefotocelula`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!associationsResponse.ok) {
        throw new Error('Erro ao buscar associações.');
      }

      const associations = await associationsResponse.json();

      // Verificar se a associação já existe
      const existingAssociation = associations.find(
        (assoc) => assoc.posteId === String(posteId) && assoc.fotocelulaId === String(fotocelulaId)
      );

      if (existingAssociation) {
        // PUT: Reativar associação existente
        const updatedData = {
          ...existingAssociation,
          ativo: 'S', // Reativar
          data: new Date().toISOString(),
        };

        const updateResponse = await fetch(
          `https://api.ads10.com.br/api/postefotocelula/${existingAssociation.postefotocelulaId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(updatedData),
          }
        );

        if (!updateResponse.ok) {
          throw new Error('Erro ao atualizar associação.');
        }

        const updatedAssociation = await updateResponse.json();
        toast.success('Dispositivo EUI reativado com sucesso!');

        // Assegurar que o `deviceEUI` está presente
        if (!updatedAssociation.deviceEUI) {
          updatedAssociation.deviceEUI = eui;
        }

        // Atualizar o estado local com a associação reativada
        setSavedEUIs((prev) => [...prev, updatedAssociation]);
        setPosteData((prev) => ({
          ...prev,
          euis: [...prev.euis, updatedAssociation],
        }));
      } else {
        // POST: Criar nova associação
        const newAssociation = {
          posteId: String(posteId),
          fotocelulaId: String(fotocelulaId),
          data: new Date().toISOString(),
          usuarioId: usuarioId,
          ativo: 'S',
          ligado: 'L',
          manutencao: 'N',
          tensaoRede: 0,
        };

        const createResponse = await fetch(`https://api.ads10.com.br/api/postefotocelula`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(newAssociation),
        });

        if (!createResponse.ok) {
          throw new Error('Erro ao criar associação.');
        }

        const savedEUI = await createResponse.json();
        console.log('Resposta da API ao salvar EUI:', savedEUI);

        // Assegurar que o `deviceEUI` está presente
        if (!savedEUI.deviceEUI) {
          savedEUI.deviceEUI = eui;
        }

        toast.success('Dispositivo EUI adicionado com sucesso!');

        // Atualizar estado local
        setSavedEUIs((prev) => [...prev, savedEUI]);
        setPosteData((prev) => ({
          ...prev,
          euis: [...prev.euis, savedEUI],
        }));
      }

      // Remover o EUI salvo da lista de unsaved
      setUnsavedEUIs((prev) => prev.filter((_, i) => i !== index));

      // Recarregar dispositivos disponíveis
      await refetchAvailableDevices();

      // Atualizar a lista de postes
      await refetchPostes();

      // Não chamar fetchSavedComponentsAndLuminarias para evitar sobrescrita
    } catch (error) {
      console.error('Erro ao salvar dispositivo EUI:', error);
      toast.error('Erro ao salvar dispositivo EUI. Por favor, tente novamente.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveEUI = async (euiObj, index, isUnsaved = false) => {
    if (isUnsaved) {
      // Remover EUI não salvo diretamente do unsavedEUIs
      setUnsavedEUIs((prev) => prev.filter((_, i) => i !== index));
      toast.info('Dispositivo EUI removido da lista.');
    } else {
      // Confirmar exclusão para dispositivos salvos
      const confirmDelete = window.confirm(
        `Tem certeza de que deseja remover o Device EUI: ${euiObj.deviceEUI}?`
      );
      if (!confirmDelete) return;

      setIsLoading(true); // Inicia o carregamento

      try {
        if (!token) {
          toast.error('Usuário não autenticado. Redirecionando para a página de login.');
          window.location.href = '/login';
          return;
        }

        // Deletar associação postefotocelula
        const response = await fetch(
          `https://api.ads10.com.br/api/postefotocelula/${euiObj.postefotocelulaId}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 401) {
          toast.error('Sessão expirada. Faça login novamente.');
          localStorage.removeItem('jwtToken');
          sessionStorage.removeItem('jwtToken');
          window.location.href = '/login';
          return;
        }

        if (!response.ok) {
          throw new Error(`Erro ao remover dispositivo EUI: ${response.statusText}`);
        }

        // Atualizar o estado dos dispositivos EUI
        setSavedEUIs((prev) =>
          prev.filter((eui) => eui.postefotocelulaId !== euiObj.postefotocelulaId)
        );

        // Atualizar o posteData removendo o EUI
        setPosteData((prev) => ({
          ...prev,
          euis: prev.euis.filter((eui) => eui.postefotocelulaId !== euiObj.postefotocelulaId),
        }));

        // Recarregar a lista de dispositivos disponíveis
        await refetchAvailableDevices();

        // Atualizar a lista de postes
        await refetchPostes();

        toast.success('Dispositivo EUI removido com sucesso!');
      } catch (error) {
        console.error('Erro ao remover o dispositivo EUI:', error);
        toast.error('Erro ao remover dispositivo EUI. Por favor, tente novamente.');
      } finally {
        setIsLoading(false); // Termina o carregamento
      }
    }
  };

  // Funções para adicionar, editar e remover componentes
  const handleAddComponent = () => {
    if (hasUnsavedChanges()) {
      toast.error('Por favor, salve ou descarte as mudanças antes de adicionar novos componentes.');
      return;
    }

    const newComponent = {
      grupoId: '',
      grupoNome: '',
      componenteId: '',
      componenteDescricao: '',
      tipoInstalacao: '',
      quantidade: '1.00', // Valor padrão
      data: new Date().toISOString().split('T')[0], // "YYYY-MM-DD"
      hora: new Date().toTimeString().split(' ')[0], // "HH:MM:SS"
    };

    setUnsavedComponentes((prev) => [...prev, newComponent]);
  };

  const handleComponentChange = (index, e) => {
    const { name, value } = e.target;
    setUnsavedComponentes((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [name]: value };
      return updated;
    });
  };

  const handleSaveComponent = async (index) => {
    const componente = unsavedComponentes[index];

    // Validação básica
    if (
      !componente.grupoId ||
      !componente.componenteId ||
      !componente.tipoInstalacao ||
      !componente.quantidade
    ) {
      toast.error('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    setIsLoading(true); // Inicia o carregamento

    try {
      if (!token || !usuarioId) {
        toast.error('Usuário não autenticado. Redirecionando para a página de login.');
        window.location.href = '/login';
        return;
      }

      const payload = {
        posteestruturaId: null, // ou omita este campo se a API não requerer
        posteestruturaPoste: String(posteData.idPoste || posteData.id),
        posteestruturaItem: String(componente.componenteId),
        posteestruturaQuantidade: parseFloat(componente.quantidade).toFixed(2).toString(),
        posteestruturaData: componente.data, // "YYYY-MM-DD"
        posteestruturaHora: componente.hora, // "HH:MM:SS"
        posteestruturaTipo: componente.tipoInstalacao === 'Instalação' ? 'i' : 'a',
        posteestruturaUsuario: String(usuarioId),
        posteestruturaAppId: null, // ou uma string válida
        posteestruturaItemGrupo: String(componente.grupoId),
        estruturaTipo: componente.tipoInstalacao === 'Instalação' ? 'i' : 'a',
      };

      // Log para verificar o payload
      console.log('Payload enviado para a API:', payload);

      const response = await fetch('https://api.ads10.com.br/api/posteestrutura', {
        method: 'POST', // ou 'PUT' se estiver atualizando
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Erro ao salvar componente:', errorData);
        throw new Error(`Erro ao salvar componente: ${response.statusText}`);
      }

      const savedComponente = await response.json();

      // Atualizar os estados
      setSavedComponentes((prev) => [...prev, savedComponente]);
      setUnsavedComponentes((prev) => prev.filter((_, i) => i !== index));

      // Atualizar o posteData para refletir o novo componente
      setPosteData((prev) => ({
        ...prev,
        componentes: [...prev.componentes, savedComponente],
      }));

      // Recarregar componentes e luminárias salvos para manter a consistência
      await fetchSavedComponentsAndLuminarias();

      // Atualizar a lista de postes
      await refetchPostes();

      toast.success('Componente salvo com sucesso!');
    } catch (err) {
      console.error(err);
      toast.error('Ocorreu um erro ao salvar o componente.');
    } finally {
      setIsLoading(false); // Termina o carregamento
    }
  };

  const handleRemoveComponent = (componente, isUnsaved = false, index = null) => {
    console.log('Removendo componente:', componente, 'IsUnsaved:', isUnsaved, 'Index:', index);
    if (isUnsaved) {
      // Remover componente não salvo diretamente da lista
      setUnsavedComponentes((prev) => {
        console.log('Antes da remoção (Unsaved Componentes):', prev);
        const updated = prev.filter((_, i) => i !== index);
        console.log('Depois da remoção (Unsaved Componentes):', updated);
        return updated;
      });
      toast.info('Componente removido da lista.');
    } else {
      // Abrir modal de confirmação para componente salvo
      setComponentToDelete(componente);
      setIsConfirmDeleteComponentOpen(true);
    }
  };

  const confirmRemoveComponent = async () => {
    if (!componentToDelete) return;

    setIsLoading(true); // Inicia o carregamento

    try {
      if (!token) {
        toast.error('Usuário não autenticado. Redirecionando para a página de login.');
        window.location.href = '/login';
        return;
      }

      // Supondo que 'posteestruturaId' é o identificador para a associação
      const response = await fetch(
        `https://api.ads10.com.br/api/posteestrutura/${componentToDelete.posteestruturaId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao remover componente: ${response.statusText}`);
      }

      // Atualizar o estado
      setSavedComponentes((prev) =>
        prev.filter((comp) => comp.posteestruturaId !== componentToDelete.posteestruturaId)
      );
      setIsConfirmDeleteComponentOpen(false);
      setComponentToDelete(null);

      // Atualizar o posteData removendo o componente
      setPosteData((prev) => ({
        ...prev,
        componentes: prev.componentes.filter((comp) => comp.posteestruturaId !== componentToDelete.posteestruturaId),
      }));

      // Recarregar componentes e luminárias salvos para manter a consistência
      await fetchSavedComponentsAndLuminarias();

      // Atualizar a lista de postes
      await refetchPostes();

      toast.success('Componente removido com sucesso!');
    } catch (error) {
      console.error('Erro ao remover componente:', error);
      toast.error('Erro ao remover componente. Por favor, tente novamente.');
    } finally {
      setIsLoading(false); // Termina o carregamento
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      contentLabel={initialData ? `Editar Componentes do Poste - ${initialData.codigoPoste}` : 'Adicionar Componentes ao Poste'}
      className={styles.modal}
      overlayClassName={styles.overlay}
      aria={{
        labelledby: 'poste-components-modal-title',
        describedby: 'poste-components-modal-description',
      }}
    >
      {isLoading && <LoadingOverlay />} {/* Exibe o overlay se isLoading for true */}
      <button
        className={styles.closeModalButton}
        onClick={handleCloseModal}
        aria-label="Fechar Modal"
      >
        <FaTimes />
      </button>
      <div className={styles.posteComponentsPage}>
        {/* Título Dinâmico com Acessibilidade */}
        <h1 id="poste-components-modal-title" className={styles.header}>
          {initialData
            ? `Editar Componentes do Poste - ${initialData.codigoPoste}`
            : 'Adicionar Componentes ao Poste'}
        </h1>
        {posteData ? (
          <form>
            <Tabs selectedIndex={activeTabIndex} onSelect={handleTabSelect}>
              <TabList>
                <Tab>Luminárias</Tab>
                <Tab>Dispositivos EUI</Tab>
                <Tab>Componentes</Tab>
              </TabList>

              <TabPanel>
                {/* Luminárias */}
                <Luminarias
                  savedLuminarias={savedLuminarias}
                  unsavedLuminarias={unsavedLuminarias}
                  handleAddLuminaria={handleAddLuminaria}
                  handleLuminariaChange={handleLuminariaChange}
                  handleSaveLuminaria={handleSaveLuminaria}
                  handleRemoveLuminaria={handleRemoveLuminaria}
                  grupos={gruposLuminarias}
                  isGruposLoading={isGruposLoading}
                  gruposError={gruposError}
                  componentes={componentes}
                  isComponentesLoading={isComponentesLoading}
                  componentesError={componentesError}
                  refetchGrupos={refetchGruposLuminarias}
                  refetchComponentes={refetchComponentes}
                  animateSaveButtons={animateSaveButtons.luminarias}
                />
              </TabPanel>

              <TabPanel>
                {/* Dispositivos EUI */}
                <EUIsDevices
                  euis={savedEUIs}
                  unsavedEUIs={unsavedEUIs}
                  handleAddEUI={handleAddEUI}
                  handleEUIChange={handleEUIChange}
                  handleSaveEUI={handleSaveEUI}
                  handleRemoveEUI={handleRemoveEUI}
                  animateSaveButtons={animateSaveButtons.euis}
                  availableDevices={availableDevices}
                  isAvailableDevicesLoading={isAvailableDevicesLoading}
                  availableDevicesError={availableDevicesError}
                  token={token}
                />
              </TabPanel>

              <TabPanel>
                {/* Componentes */}
                <Componentes
                  savedComponentes={savedComponentes}
                  unsavedComponentes={unsavedComponentes}
                  handleAddComponent={handleAddComponent}
                  handleComponentChange={handleComponentChange}
                  handleSaveComponent={handleSaveComponent}
                  handleRemoveComponent={handleRemoveComponent}
                  grupos={gruposComponentes}
                  isGruposLoading={isGruposComponentesLoading}
                  gruposError={gruposComponentesError}
                  componentes={componentes}
                  isComponentesLoading={isComponentesLoading}
                  componentesError={componentesError}
                  refetchGrupos={refetchGruposComponentes}
                  refetchComponentes={refetchComponentes}
                  animateSaveButtons={animateSaveButtons.componentes}
                />
              </TabPanel>
            </Tabs>
          </form>
        ) : (
          <p>Carregando dados do poste...</p>
        )}

        {/* Modais de confirmação */}
        <ConfirmDeleteLuminariaModal
          isOpen={isConfirmDeleteLuminariaOpen}
          onRequestClose={() => setIsConfirmDeleteLuminariaOpen(false)}
          onConfirmDelete={confirmRemoveLuminaria}
        />

        <ConfirmDeleteComponentModal
          isOpen={isConfirmDeleteComponentOpen}
          onRequestClose={() => setIsConfirmDeleteComponentOpen(false)}
          onConfirmDelete={confirmRemoveComponent}
        />
      </div>
    </Modal>
  );
};

export default PosteComponentsModal;
