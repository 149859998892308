// src/pages/Relatorios/RelatorioConsumoPorPoste.jsx

import React, { useState, useEffect, useMemo } from 'react';
import usePosteDataComAlertas from '../../hooks/usePosteDataComAlertas';
import styles from './Relatorios.module.css';
import * as XLSX from 'xlsx';
import { FaFileExport } from 'react-icons/fa';

import useGruposData from '../../hooks/useGruposData'; // Hook para obter grupos
import useGroupPoste from '../../hooks/useGroupPoste'; // Hook para associações entre grupos e postes

const RelatorioConsumoPorPoste = () => {
  const { postesDetalhados, isLoading, error } = usePosteDataComAlertas();
  
  // Estados para filtros
  const [selectedGroups, setSelectedGroups] = useState([]); // IDs dos grupos selecionados

  // Estados para dados filtrados
  const [consumoPorPoste, setConsumoPorPoste] = useState([]);

  // Hooks para grupos e associações
  const {
    grupos,
    isLoading: isGruposLoading,
    error: gruposError,
  } = useGruposData();

  const {
    associations,
    error: associationsError,
    fetchAssociations,
  } = useGroupPoste();

  // Buscar associações ao montar o componente
  useEffect(() => {
    fetchAssociations();
  }, [fetchAssociations]);

  // Mapear associações aos postes
  const postesComGrupos = useMemo(() => {
    if (!postesDetalhados || !associations) return [];

    return postesDetalhados.map((poste) => {
      const gruposDoPoste = associations
        .filter((assoc) => assoc.posteId === String(poste.id))
        .map((assoc) => assoc.grupoId);
      return { ...poste, grupoIds: gruposDoPoste };
    });
  }, [postesDetalhados, associations]);

  // Lógica de filtragem e preparação dos dados de consumo
  useEffect(() => {
    let filteredPostes = [...postesComGrupos];

    // Filtrar por grupos selecionados
    if (selectedGroups.length > 0) {
      filteredPostes = filteredPostes.filter((poste) =>
        poste.grupoIds.some((id) => selectedGroups.includes(id))
      );
    }

    // Extrair os consumos de cada poste e converter os valores para números
    const consumos = filteredPostes
      .filter((poste) => poste.consumo !== null && !isNaN(parseFloat(poste.consumo)))
      .map((poste) => ({
        numeroPoste: poste.codigoPoste || 'N/A',
        consumo: parseFloat(poste.consumo) || 0, // Converter para número e tratar valores inválidos
        grupos: grupos
          .filter((grupo) => poste.grupoIds.includes(grupo.grupoId))
          .map((grupo) => grupo.grupoNome)
          .join(', ') || 'N/A',
      }));

    setConsumoPorPoste(consumos);
  }, [postesComGrupos, selectedGroups, grupos]);

  const totalConsumo = consumoPorPoste.reduce((total, poste) => total + poste.consumo, 0);
  const mediaConsumo = consumoPorPoste.length > 0 ? (totalConsumo / consumoPorPoste.length).toFixed(2) : 0;

  const handleExportToExcel = () => {
    const exportData = consumoPorPoste.map((poste) => ({
      'Número do Poste': poste.numeroPoste,
      'Consumo (kWh)': poste.consumo.toFixed(2),
      'Grupos': poste.grupos,
    }));

    exportData.push({ 'Número do Poste': 'Total', 'Consumo (kWh)': totalConsumo.toFixed(2), 'Grupos': '' });
    exportData.push({ 'Número do Poste': 'Consumo Médio Por Poste', 'Consumo (kWh)': mediaConsumo, 'Grupos': '' });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Consumo por Poste');
    XLSX.writeFile(workbook, 'consumo_por_poste.xlsx');
  };

  /**
   * Função para lidar com a seleção de grupos no dropdown.
   * @param {Event} e - Evento de mudança.
   */
  const handleGroupChange = (e) => {
    const options = e.target.options;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    setSelectedGroups(selected);
  };

  const handleClearFilters = () => {
    setSelectedGroups([]);
  };

  return (
    <div className={styles.container}>
      <h2>Relatório de Consumo por Poste</h2>
      <div className={styles.controlsTop}>
        {/* Dropdown de Seleção de Grupos */}
        <div className={styles.controlGroup}>
          <label htmlFor="groupFilter">Filtrar por Grupo:</label>
          {!isGruposLoading && !gruposError && grupos && (
            <>
              <select
                id="groupFilter"
                multiple
                value={selectedGroups}
                onChange={handleGroupChange}
                className={styles.dropdown}
              >
                {grupos.map((grupo) => (
                  <option key={grupo.grupoId} value={grupo.grupoId}>
                    {grupo.grupoNome}
                  </option>
                ))}
              </select>
              <small className={styles.selectInfo}>Pressione Ctrl (Windows) ou Command (Mac) para selecionar múltiplos grupos.</small>
            </>
          )}
          {isGruposLoading && <p>Carregando grupos...</p>}
          {gruposError && <p className={styles.errorMessage}>{gruposError}</p>}
        </div>

        {/* Botões de Ação */}
        <div className={styles.actionsContainer}>
          <button onClick={handleExportToExcel} className={styles.exportButton}>
            <FaFileExport /> Exportar para Excel
          </button>
          <button onClick={handleClearFilters} className={styles.clearFiltersButton}>
            Limpar Filtros
          </button>
        </div>
      </div>

      {/* Mensagens de Status */}
      {isLoading && <p>Carregando dados...</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}
      {associationsError && <p className={styles.errorMessage}>{associationsError}</p>}

      {/* Tabela de Dados */}
      {!isLoading && consumoPorPoste.length > 0 && (
        <>
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Número do Poste</th>
                  <th>Consumo (kWh)</th>
                  <th>Grupos</th>
                </tr>
              </thead>
              <tbody>
                {consumoPorPoste.map((poste, index) => (
                  <tr key={index}>
                    <td>{poste.numeroPoste}</td>
                    <td>{poste.consumo.toFixed(2)}</td>
                    <td>{poste.grupos}</td>
                  </tr>
                ))}
                <tr>
                  <td><strong>Total</strong></td>
                  <td><strong>{totalConsumo.toFixed(2)}</strong></td>
                  <td></td>
                </tr>
                <tr>
                  <td><strong>Consumo Médio Por Poste</strong></td>
                  <td><strong>{mediaConsumo}</strong></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
      {!isLoading && consumoPorPoste.length === 0 && (
        <p>Nenhum dado de consumo disponível para os filtros selecionados.</p>
      )}
    </div>
  );
};

export default RelatorioConsumoPorPoste;
