import React, { useState } from 'react';
import styles from './ModalConfirmEdit.module.css';

/**
 * ModalConfirmEdit
 *
 * @param {boolean} isOpen        - Se o modal está aberto ou fechado
 * @param {string} previousAddress - Endereço anterior
 * @param {string} newAddress      - Endereço detectado (novo)
 * @param {string} previousBairro  - Bairro anterior
 * @param {string} newBairro       - Bairro detectado (novo)
 * @param {function} onConfirm     - Recebe (finalAddress, finalBairro) quando o usuário confirma.
 * @param {function} onCancel      - Ação para “Não, somente LAT/LNG”.
 * @param {function} onClose       - Ação para o botão “X”.
 */
function ModalConfirmEdit({
  isOpen,
  previousAddress,
  newAddress,
  previousBairro,
  newBairro,
  onConfirm,
  onCancel,
  onClose,
}) {
  const [showManualAddress, setShowManualAddress] = useState(false);
  const [manualAddress, setManualAddress] = useState('');
  const [manualBairro, setManualBairro] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  // Função para limpar os estados internos
  const resetStates = () => {
    setShowManualAddress(false);
    setManualAddress('');
    setManualBairro('');
    setIsSubmitting(false);
    setError('');
  };

  // Validação do endereço digitado
  const validateInputs = () => {
    if (manualAddress.trim() === '') {
      setError('O endereço não pode estar vazio.');
      return false;
    }
    if (manualBairro.trim() === '') {
      setError('O bairro não pode estar vazio.');
      return false;
    }
    setError('');
    return true;
  };

  // Confirma o endereço digitado pelo usuário
  const handleConfirmManual = () => {
    if (!validateInputs()) return;

    setIsSubmitting(true);
    onConfirm(manualAddress, manualBairro);
    resetStates(); // Limpa os estados após o envio
  };

  // Confirma automaticamente com o novo endereço/bairro detectado
  const handleConfirmDetected = () => {
    setIsSubmitting(true);
    onConfirm(newAddress, newBairro);
    resetStates(); // Limpa os estados após o envio
  };

// Fecha o modal e reseta os estados
const handleClose = () => {
    resetStates();
    if (onClose) onClose(); // Deixe essa função apenas para reverter os estados
  };
  

  // Não renderiza nada se o modal não estiver aberto
  if (!isOpen) return null;

  return (
    <div className={styles.overlay} role="dialog" aria-modal="true">
      <div className={styles.modal}>
        {/* Botão X para fechar o modal */}
        <button
          className={styles.closeButton}
          onClick={handleClose}
          aria-label="Fechar"
        >
          ✕
        </button>

        <h2>Confirmação de Edição</h2>
        <p>O endereço foi alterado. Escolha uma das opções abaixo:</p>

        <div className={styles.infoContainer}>
          <div className={styles.infoBlock}>
            <h4>Endereço Atual:</h4>
            <p>{previousAddress || 'Não disponível'}</p>
            <h4>Novo Endereço:</h4>
            <p>{newAddress || 'Não disponível'}</p>
          </div>
          <div className={styles.infoBlock}>
            <h4>Bairro Atual:</h4>
            <p>{previousBairro || 'Não disponível'}</p>
            <h4>Novo Bairro:</h4>
            <p>{newBairro || 'Não disponível'}</p>
          </div>
        </div>

        {showManualAddress ? (
          <div className={styles.manualAddressContainer}>
            <p>Digite o endereço e bairro desejado:</p>
            <label htmlFor="manualAddress">Endereço:</label>
            <input
              id="manualAddress"
              type="text"
              value={manualAddress}
              onChange={(e) => setManualAddress(e.target.value)}
              placeholder="Digite o endereço completo"
              className={styles.manualAddressInput}
            />
            <label htmlFor="manualBairro">Bairro:</label>
            <input
              id="manualBairro"
              type="text"
              value={manualBairro}
              onChange={(e) => setManualBairro(e.target.value)}
              placeholder="Digite o bairro"
              className={styles.manualAddressInput}
            />
            {error && <p className={styles.errorMessage}>{error}</p>}

            <div className={styles.buttonGroup}>
              <button
                onClick={handleConfirmManual}
                className={styles.confirmButton}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Processando...' : 'Confirmar Endereço Digitado'}
              </button>
              <button
                onClick={handleClose}
                className={styles.cancelButton}
                disabled={isSubmitting}
              >
                Cancelar / Fechar
              </button>
            </div>
          </div>
        ) : (
          <div className={styles.buttonGroup}>
            <button
              onClick={handleConfirmDetected}
              className={styles.confirmButton}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Processando...' : 'Sim, atualizar endereço'}
            </button>
            <button
              onClick={onCancel}
              className={styles.cancelButton}
              disabled={isSubmitting}
            >
              Não, somente LAT/LNG
            </button>
            <button
              onClick={() => setShowManualAddress(true)}
              className={styles.manualButton}
              disabled={isSubmitting}
            >
              Digitar Endereço
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ModalConfirmEdit;
