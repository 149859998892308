// src/pages/Relatorios/RelatorioPostesComFoto.jsx

import React, { useState, useEffect, forwardRef, useMemo } from 'react';
import styles from './Relatorios.module.css';
import usePosteDataComAlertas from '../../hooks/usePosteDataComAlertas';
import * as XLSX from 'xlsx';
import { FaFileExport } from 'react-icons/fa';

import useGruposData from '../../hooks/useGruposData'; // Hook para obter grupos
import useGroupPoste from '../../hooks/useGroupPoste'; // Hook para associações entre grupos e postes

const RelatorioPostesComFoto = forwardRef((props, ref) => {
  const { postesDetalhados, isLoading, error } = usePosteDataComAlertas();
  const [postesComFoto, setPostesComFoto] = useState([]);

  // Estados para filtros
  const [selectedGroups, setSelectedGroups] = useState([]); // IDs dos grupos selecionados

  // Hooks para grupos e associações
  const {
    grupos,
    isLoading: isGruposLoading,
    error: gruposError,
  } = useGruposData();

  const {
    associations,
    error: associationsError,
    fetchAssociations,
  } = useGroupPoste();

  // Buscar associações ao montar o componente
  useEffect(() => {
    fetchAssociations();
  }, [fetchAssociations]);

  // Mapear associações aos postes
  const postesComGrupos = useMemo(() => {
    if (!postesDetalhados || !associations) return [];

    return postesDetalhados.map((poste) => {
      const gruposDoPoste = associations
        .filter((assoc) => assoc.posteId === String(poste.id))
        .map((assoc) => assoc.grupoId);
      return { ...poste, grupoIds: gruposDoPoste };
    });
  }, [postesDetalhados, associations]);

  // Lógica de filtragem e preparação dos dados de postes com foto
  useEffect(() => {
    let filteredPostes = [...postesComGrupos];

    // Filtrar por grupos selecionados
    if (selectedGroups.length > 0) {
      filteredPostes = filteredPostes.filter((poste) =>
        poste.grupoIds.some((id) => selectedGroups.includes(id))
      );
    }

    // Construir as informações dos postes com URL da imagem
    const postesComImagens = filteredPostes.map((poste) => ({
      codigoPoste: poste.codigoPoste || 'N/A',
      address: poste.address || 'N/A',
      image: `https://ceasa.ads10.com.br/images/${poste.codigoPoste}.jpg`,
      tipo: poste.tipoPoste || 'N/A',
      consumo: poste.consumo || 'N/A',
      potencia: poste.potencia || 'N/A',
      grupos: grupos
        .filter((grupo) => poste.grupoIds.includes(grupo.grupoId))
        .map((grupo) => grupo.grupoNome)
        .join(', ') || 'N/A',
    }));

    setPostesComFoto(postesComImagens);
  }, [postesComGrupos, selectedGroups, grupos]);

  const handleExportToExcel = () => {
    const exportData = postesComFoto.map((poste) => ({
      'Código do Poste': poste.codigoPoste,
      'Endereço': poste.address,
      'Tipo': poste.tipo,
      'Consumo (kWh)': poste.consumo,
      'Potência (kW)': poste.potencia,
      'Grupos': poste.grupos,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Postes com Foto');
    XLSX.writeFile(workbook, 'postes_com_foto.xlsx');
  };

  /**
   * Função para lidar com a seleção de grupos no dropdown.
   * @param {Event} e - Evento de mudança.
   */
  const handleGroupChange = (e) => {
    const options = e.target.options;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    setSelectedGroups(selected);
  };

  const handleClearFilters = () => {
    setSelectedGroups([]);
  };

  return (
    <div className={styles.container}>
      <h2>Relatório de Postes com Foto</h2>
      <div className={styles.controlsTop}>
        {/* Dropdown de Seleção de Grupos */}
        <div className={styles.controlGroup}>
          <label htmlFor="groupFilter">Filtrar por Grupo:</label>
          {!isGruposLoading && !gruposError && grupos && (
            <>
              <select
                id="groupFilter"
                multiple
                value={selectedGroups}
                onChange={handleGroupChange}
                className={styles.dropdown}
              >
                {grupos.map((grupo) => (
                  <option key={grupo.grupoId} value={grupo.grupoId}>
                    {grupo.grupoNome}
                  </option>
                ))}
              </select>
              <small className={styles.selectInfo}>
                Pressione Ctrl (Windows) ou Command (Mac) para selecionar múltiplos grupos.
              </small>
            </>
          )}
          {isGruposLoading && <p>Carregando grupos...</p>}
          {gruposError && <p className={styles.errorMessage}>{gruposError}</p>}
        </div>

        {/* Botões de Ação */}
        <div className={styles.actionsContainer}>
          <button onClick={handleExportToExcel} className={styles.exportButton}>
            <FaFileExport /> Exportar para Excel
          </button>
          <button onClick={handleClearFilters} className={styles.clearFiltersButton}>
            Limpar Filtros
          </button>
        </div>
      </div>

      {/* Mensagens de Status */}
      {isLoading && <p>Carregando dados...</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}
      {associationsError && <p className={styles.errorMessage}>{associationsError}</p>}

      {/* Tabela de Dados */}
      {!isLoading && postesComFoto.length > 0 && (
        <>
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Código do Poste</th>
                  <th>Endereço</th>
                  <th>Imagem</th>
                  <th>Tipo</th>
                  <th>Consumo (kWh)</th>
                  <th>Potência (kW)</th>
                  <th>Grupos</th>
                </tr>
              </thead>
              <tbody>
                {postesComFoto.map((poste, index) => (
                  <tr key={index}>
                    <td>{poste.codigoPoste}</td>
                    <td>{poste.address}</td>
                    <td>
                      <img
                        src={poste.image}
                        alt={`Poste ${poste.codigoPoste}`}
                        className={styles.posteImage}
                        onError={(e) => (e.target.style.display = 'none')}
                        style={{ width: '100px', height: '100px' }}
                      />
                    </td>
                    <td>{poste.tipo}</td>
                    <td>{poste.consumo}</td>
                    <td>{poste.potencia}</td>
                    <td>{poste.grupos}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {!isLoading && postesComFoto.length === 0 && (
        <p>Nenhum dado de poste com foto disponível para os filtros selecionados.</p>
      )}
    </div>
  );
});

export default RelatorioPostesComFoto;
